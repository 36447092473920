import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";
function LUTunderGST() {
  const pageName = "LUTunderGST";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Letter of Undertaking under GST</h2>
                  <p>
                    File the Letter of Undertaking that guarantees tax savings
                    before the exports.
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is Letter of Undertaking?</h2>
                <div className="bar"></div>
                <p>
                  Letter of Undertaking is abbreviated as LUT. The Letter of
                  Undertaking is a document submitted by the exporter in order
                  to export goods or services without the payment of taxes. In
                  case the LUT is not filed, the exporter may export by payment
                  of IGST and then claim the refund of tax paid. Filing LUT is
                  convenient than refund mode as the exporters do not wish to
                  indulge into hassles of refunds and block their funds. The
                  eligibility criteria to apply for LUT is comparatively relaxed
                  than to the erstwhile system.
                </p>
                <p>
                  Letter of Undertaking is filed online using the form GST RFD
                  11. By filing LUT, the exporter undertakes that all the
                  requirements prescribed for this route will be fulfilled by
                  him.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of filing LUT under GST</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Exports without tax payment</Tab>
                <Tab>Saves Working Capital</Tab>
                <Tab>An Entire Financial Year Validity</Tab>
                <Tab>Simple and online process</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Exports without tax payment</h2>
                      <p>
                        By filing LUT under GST, an exporter can export goods or
                        services without paying the taxes. If LUT is not filed,
                        the exporter must first pay the required tax while
                        making exports and then claim a refund to avail benefit
                        of zero-rated exports.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Saves Working Capital</h2>
                      <p>
                        The regular exporters are at a huge benefit by filing
                        LUT online as the refund process under another route is
                        a time-consuming process. During which the bunch of
                        capital is blocked in the form of a due refund. With
                        this mode, the capital is free to be utilized for other
                        necessary requirements. It also reduces the cost of
                        exports.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>An Entire Financial Year Validity</h2>
                      <p>
                        The Letter of Undertaking, once filed is valid for the
                        whole financial year in which it is filed. Hence, unlike
                        refund process, an exporter does not need to go through
                        the procedural requirements every time an export
                        consignment is carried out.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Simple and online process</h2>
                      <p>
                        The filing of LUT and its acceptance, both are made
                        online since April, 2018. The applicant does not need to
                        visit the officers for acceptance. The whole process can
                        be completed online by submitting a few required
                        documents.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for LUT filing</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>Entity’s PAN Card</h2>
                    <p>A Copy of PAN card of the exporter entity</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>KYC of Authorised Signatory</h2>
                    <p>
                      A Copy of PAN card and Aadhar card of partners/directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Witnesses</h2>
                    <p>
                      Information of at least two witnesses along with the
                      address proof and PAN
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>GST Registration</h2>
                    <p>
                      GST Registration Certificate of the exporter is required
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>IEC Certificate</h2>
                    <p>The IEC certificate must be submitted</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Cancelled cheque</h2>
                    <p>
                      A Copy of cancelled cheque of current account of exporter
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of filing LUT under GST</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1 - Collection</Tab>
                <Tab>Day 3-4 - Execution</Tab>
                <Tab>Day 5 - Acknowledgement</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and collection of basic Information</li>
                  <li>Collection of required documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Preparation for the Letter of Undertaking</li>
                  <li>Online Submission of LUT</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Sharing Acknowledgment bearing ARN</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How goods or services can be exported from India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exports of goods and services are done either with the
                        payment of applicable GST or without the payment of GST.
                        In the first portion, the taxes paid are refunded to
                        exporters subject to conditions of exports. For exports
                        without payment of taxes, the exporters are required to
                        file Letter of Undertaking.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When is the Letter of Undertaking required to be filed?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        If the exporters wish to opt for exports without the
                        payment of taxes, the Letter of Undertaking is required
                        to be filed. First time filing can be made any time
                        before exporting without taxes. However, if the exporter
                        wants to continue the same to the next financial year,
                        it must be filed before the commencement of the
                        financial year.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who is eligible to file Letter of Undertaking?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Earlier criteria of turnover and inward receipts were
                        prescribed for filing LUT under GST. However, now any
                        exporter willing to export without paying the taxes can
                        file Letter of Undertaking under GST online.{" "}
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether the filing of bond is required for exporters?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, the requirement to file a bond is eliminated. For
                        exports without payment of taxes, online Letter of
                        Undertaking is required to be filed.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How the letter of Undertaking is required to be filed
                        under GST?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Since the financial year 2018-19, the Letter of
                        Undertaking is required to be filed on an online portal
                        of GST Network using GST credentials. The undertaking is
                        executed on the duly stamped paper, which is submitted
                        through the online portal.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the validity of Letter of Undertaking filed?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Letter of Undertaking will be valid for the whole
                        financial year in which it is filed. However, if the
                        conditions with respect to exports are not fulfilled,
                        LUT is deemed to be withdrawn until the fulfillment of
                        conditions.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default LUTunderGST;
