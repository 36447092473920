import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function AddRemovePartnerLLP() {
  const pageName = "AddRemovePartnerLLP";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Add or Remove a Partner (LLP)</h2>
                  <p>
                    Assure you are fully complied for change in LLP Partners
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Know about change in Partners of LLP</h2>
                <div className="bar"></div>
                <p>
                  A Limited Liability Partnership is run by its partners. From
                  its management to the operation, the partners direct the LLP
                  towards its goals and vision. New partners are added or
                  existing leave; it does not affect the status of the LLP, but
                  surely impacts the growth of the business and responsibilities
                  of other partners. The change in partners and their details
                  can be affected only after the approval from the Ministry of
                  Corporate Affairs.
                </p>
                <p>
                  To add or remove a partner from LLP, the consent of other
                  partners must be obtained, which is followed by a change in
                  the LLP Agreement and application to MCA to approve the
                  changes. The application to MCA must be filed within 30 days
                  of effective date of the change.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Why is the change of partners required?</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Expertise with additional capital</Tab>
                <Tab>Inability of the existing Partner</Tab>
                <Tab>Change in terms of Partnership</Tab>
                <Tab>
                  Number of Designated Partners is below the statutory limit
                </Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Expertise with additional capital</h2>
                      <p>
                        Normally, a partner is appointed either for need of
                        capital or the expertise. With increase in capital, the
                        borrowing power of the firm increases with loan
                        opportunities. Admission of a partner not only benefits
                        in form of capital but also leverages the skills and
                        knowledge. The diversity of knowledge and goodwill base
                        helps the business to grow far.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Inability of the existing Partner</h2>
                      <p>
                        The existing partner of the LLP may not be able to
                        contribute his full time after a certain period, whether
                        due to retirement or other reasons. Although the exit of
                        one partner may not affect the existence LLP, it must be
                        dealt with by intimation to MCA and also the appointment
                        of a new partner, if required.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Change in terms of Partnership</h2>
                      <p>
                        It is an agreement between the partners, the terms can
                        be changed mutually at any time. The change might impact
                        the willingness of one or other partner. According to
                        requirements and terms, need both, the addition or
                        removal of a partner may arise. And consequently, the
                        due process must be followed.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>
                        Number of Designated Partners is below the statutory
                        limit
                      </h2>
                      <p>
                        Every LLP requires maintaining minimum 2 Designated
                        Partners all time. If due to the resignation of a
                        designated partner from LLP, the total designated
                        partners reduce below 2, the LLP must appoint a new
                        designated Partner or change the position (status) of
                        the existing another partner.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for Addition or Removal of partner</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Passport size photograph of the partner to be appointed
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>Self-attested PAN card of the partner to be appointed</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Proof of Residence</h2>
                    <p>
                      Aadhar Card/ Voter ID/ Passport/ Driving License partner
                      to be appointed
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Digital Signature Certificate</h2>
                    <p>
                      DSC of the continuing partner and partner to be removed
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>LLP Agreement</h2>
                    <p>
                      LLP Agreement executed while registration and the
                      modifications thereto
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process for addition or removal of partners</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2-3</Tab>
                <Tab>Day 4-7</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Consultancy for the requirement of change of partners</li>
                  <li>Collection of basic Information & documents</li>
                  <li>Application for DSC (for partner to be appointed)</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of necessary Resolutions and Documents</li>
                  <li>Drafting of Supplementary Agreement</li>
                  <li>Provide documents required provided after signature</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Payment of stamp duty on the supplementary agreement</li>
                  <li>Preparation of online application</li>
                  <li>Filing of application for change</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether the LLP Agreement is to be updated to add or
                        remove Partner in the LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, the LLP agreement must be modified with terms of
                        addition or removal by execution of the supplementary
                        deed. All the details including the change of capital
                        and change in terms and profit sharing ratio will be
                        provided in the deed.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When the Supplementary deed shall be filed with MCA?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Supplementary Deed must be filed within 30 days from
                        effective date of change or from the date of execution
                        (whichever falls earlier). The delay in filing levies
                        additional fee of Rs 100 per day till the date of
                        filing.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is difference between Designated Partner and
                        Partner?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The essential difference between both types of partners
                        is the accountability. Where the partner is responsible
                        only for acts and omissions by himself, the Designated
                        Partners are additionally responsible towards compliance
                        and operational matters of the LLP, including penal
                        provisions.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What should I do if my other Partner has resigned and I
                        am the only one left now?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The LLP must appoint a new designated partner within 6
                        months from effective date. However, in case the LLP
                        already has another partner, the status of such a
                        partner can be changed to Designated Partner.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether Stamp Duty is required to be paid on
                        Supplementary Deed?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The stamp duty shall be paid according to the added
                        capital in the LLP as per the rate prescribed by the
                        respective state. Where there is the addition of capital
                        while addition or removal, the Supplementary Agreement
                        shall be executed by payment of Rs 100/- as stamp duty.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the requirements to be a Partner/ Designated
                        Partner of the LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        There are no limitations in terms of citizenship or
                        residency to become a Partner. Therefore, the LLP Act,
                        2008 allows Foreign Nationals including Foreign
                        Companies & LLPs to become LLP in India provided at
                        least one Designated Partner is Indian Resident. The
                        proposed Designated Partner shall hold valid DIN and not
                        be disqualified.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default AddRemovePartnerLLP;
