import React, { useState } from "react";
import "./Login.css";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { API_URL } from "../constants";
import { useHistory } from "react-router-dom";
import Loader from "./Loader";
import { toast, Toaster } from "react-hot-toast";
import Layout from "../common/Layout";
const ResetPassword = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const onSubmit = async (data) => {
    const token = localStorage.getItem("token");
    if (data.password === data.confirmPassword) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };

      const changePassword = await axios.post(
        `${API_URL}/api/ChangeUserPassword`,
        {
          userPassword: data.password,
        },
        {
          headers: headers,
        }
      );
      console.log(changePassword, ">>>change");
      if (changePassword.data.status === 200) {
        toast(changePassword.data.message);
        history.push("/login");
      } else {
        setLoader(false);
        toast(changePassword.data.status);
      }
    } else {
      toast("Password do not match!");
    }
  };
  return (
    <Layout>
      <div className="login-page">
        <div className="login-form">
          <Controller
            name="password"
            control={control}
            rules={{ required: "Password is required" }}
            render={({ field }) => (
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder={
                    errors.password?.message
                      ? errors.password?.message
                      : "Password"
                  }
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            rules={{ required: "Confirm Password is required" }}
            render={({ field }) => (
              <div className="form-group">
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder={
                    errors.confirmPassword?.message
                      ? errors.confirmPassword?.message
                      : "Confirm Password"
                  }
                  {...field}
                />
              </div>
            )}
          />
          <div className="form-group">
            <input
              type="submit"
              value="Change Password"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
        {loader && <Loader />}
        <Toaster position="bottom-center" />
      </div>
    </Layout>
  );
};

export default ResetPassword;
