import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import management from "../assets/management.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function IndianSubsidiary() {
  const pageName = "IndianSubsidiary";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Indian Subsidiary Registration</h2>
                  <p>Let Filing Saga Help With Your Expansion</p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
              <div className="col col6">
                <h2>Overview</h2>
                <div className="bar"></div>
                <p>
                  India is progressing at a fast pace to become one of the most
                  attractive business destinations globally. The pro-business
                  policies, investment opportunities, and inviting foreign
                  companies to collaborate on domestic projects are a few moves
                  to project India as a business-friendly country.
                </p>
                <p>
                  Furthermore, the Indian laws and regulations allow foreign
                  companies to open subsidiaries in the nation and reap numerous
                  benefits. The companies can open various subsidiaries and
                  create a profitable venture by leveraging the vast market size
                  of India and the massive talent pool.
                </p>
                <p>
                  There are two main categories of subsidiaries in India:
                  <ol>
                    <li>
                      <b>Wholly-owned subsidiary </b>Wholly-owned subsidiary
                      where parent company owns 100% of the subsidiary’s shares.
                      However, wholly-owned subsidiaries can only be formed in
                      sectors that allow 100% Foreign Direct Investments (FDI).
                    </li>
                    <li>
                      <b>Subsidiary company</b> where the parent company owns at
                      least 50% of the subsidiary’s shares.
                    </li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of an Indian Subsidiary</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>SEPARATE LEGAL ENTITY</Tab>
                <Tab>LIMITED LIABILITY</Tab>
                <Tab>GEOGRAPHICAL EXPANSION</Tab>
                <Tab>STRATEGIC CONTROL</Tab>
                <Tab>SIMPLIFIED INCORPORATION</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>SEPARATE LEGAL ENTITY</h2>
                      <p>
                        The biggest advantage of a subsidiary company is that
                        its identity is distinct from that of its parent company
                        which ultimately limits the liability Parent company to
                        the extent of its holding.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      src={fundraising}
                      style={{ height: "250px" }}
                      alt="fund-raising-image"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      src={legal}
                      style={{ height: "250px" }}
                      alt="legal-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>LIMITED LIABILITY</h2>
                      <p>
                        A subsidiary has limited liability from the parent
                        company, and shareholders are limited by the amount they
                        invest.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>GEOGRAPHICAL EXPANSION</h2>
                      <p>
                        A company looking for expansion across regions and
                        sectors will have to resort to the formation of
                        subsidiaries. Subsidiaries act like extra arms to the
                        main body and assist the holding company in reaching out
                        to different regions, business sectors, and countries.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      src={owners}
                      style={{ height: "250px" }}
                      alt="owners-image"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      src={management}
                      style={{ height: "250px" }}
                      alt="management-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>STRATEGIC CONTROL</h2>
                      <p>
                        This arrangement helps the promoters of the Foreign
                        Parent company to have control over the operations and
                        activities of its subsidiary in a strategic manner. This
                        arrangement also protects the parent company from any
                        losses or potential litigation.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      src={management}
                      style={{ height: "250px" }}
                      alt="management-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>SIMPLIFIED INCORPORATION</h2>
                      <p>
                        Indian laws and regulations allow foreign companies to
                        open subsidiaries in the nation and reap numerous
                        benefits. Set-up process is a hassle-free and simplified
                        with online filings and single window processing.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required to register an Indian Subsidiary</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Latest Passport size photograph of Shareholders and
                      Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      PAN Card of shareholders and Directors. Foreign nationals
                      must provide a valid passport.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>Identity Proof</h2>
                    <p>
                      Voter ID/ Passport/ Driving License of Shareholders and
                      Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Address Proof</h2>
                    <p>
                      Telephone Bill /Electricity Bill/ Latest Bank Account
                      Statement of Shareholders and Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Electricity Bill/ Telephone Bill of the registered office
                      address in India
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from owner</h2>
                    <p>
                      No Objection Certificate to be obtained from the owner(s)
                      of registered office
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      Rent Agreement of the registered office should be
                      provided, if any
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Constitutional documents</h2>
                    <p>
                      An apostilled copy of the charter, statutes or memorandum
                      and articles. If the instrument is not in English, a
                      certified translation in English
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>List of Directors & Secretaries</h2>
                    <p>
                      It is to be provided on the letterhead of the company
                      (apostilled or notarized)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Place of Business</h2>
                    <p>
                      Full address of the principal place of business of the
                      foreign entity
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Authorised Representative</h2>
                    <p>
                      Name and address of the person (Authorized Representative)
                      who shall receive legal documents on behalf of the company
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Prior registrations</h2>
                    <p>
                      Details of opening and closing of a place of business in
                      India on earlier occasion
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to Register a Subsidiary</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Step 1</Tab>
                <Tab>Step 2</Tab>
                <Tab>Step 3</Tab>
                <Tab>Step 4</Tab>
                <Tab>Step 5</Tab>
                <Tab>Step 6</Tab>
                <Tab>Step 7</Tab>
              </TabList>

              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  All directors of the proposed company must obtain a DIN number
                  and a Digital Signature Certificate for filing documents with
                  the Ministry of Corporate Affairs (MCA).
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  Ensure that the proposed name of the company should be unique
                  and should not be similar to the existing companies registered
                  with the Registrar of Companies (ROC).
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  Draft and file the Memorandum of Association (MoA) and
                  Articles of Association (AoA) in accordance with the
                  provisions of the Companies Act, 2013.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  After filing the necessary documents with the MCA, Filing Saga
                  will obtain the Certificate of Incorporation from the MCA
                  confirming that the company is incorporated as per the
                  provisions of the Companies Act, 2013.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  We would also obtain the Permanent Account Number (PAN) and
                  Tax Deduction and Collection Account Number (TAN) from the
                  Income Tax Department for the company.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  After incorporation, open a bank account in the company’s name
                  for the purpose of making payments and receiving payments.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  Obtain other registrations such as Goods and Services Tax
                  (GST) registration, Professional Tax Registration, etc. as
                  applicable.
                </p>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the minimum requirements to set-up an Indian
                        subsidiary?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        An Indian subsidiary is formed as a Private Limited
                        Company and should have the following:
                        <ol>
                          <li>
                            Minimum 2 directors, out of which one must be
                            resident in India.
                          </li>
                          <li>
                            Minimum 2 shareholders where shareholder and
                            director can be same a individual.
                          </li>
                          <li>Place of business in India.</li>
                          <li>
                            Parent company must hold 50% of total equity capital
                          </li>
                        </ol>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the FDI guidelines for Foreign Nationals in a
                        Private Limited Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Foreign investment is freely permitted in almost all
                        sectors except, retail trading, atomic energy, lottery
                        business, gambling and betting & agriculture.
                      </p>
                      <p>
                        What are the available routes through which FDI can be
                        brought in India?
                      </p>
                      <p>
                        Foreign Direct Investments (FDI) can be made under two
                        routes—Automatic Route and Government Route.
                      </p>
                      <p>
                        Under the Automatic Route, the foreign investor or the
                        Indian company does not require any approval from RBI or
                        Government of India for the investment.
                      </p>
                      <p>
                        Under the Government Route, prior approval of the
                        Government of India, Ministry of Finance, Foreign
                        Investment Promotion Board (FIPB) is required.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Do we need a resident Indian to be a director in the subsidiary?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                      Any contribution from foreign citizen to capital in the company is considered as foreign direct investment (FDI) and accordingly, RBI compliance needs to be secured by the newly registered company in India. It involves the following steps:
                      <ol>
                        <li>Remittance of subscription amount in India bank account from foreign country bank account. </li>
                        <li>Obtaining Foreign Inward Remittance Certificate (FIRC) and Know Your Customer (KYC) documents from the remitter bank.</li>
                        <li>
                        Allotment of shares to the subscriber.
                        </li>
                        <li>Reporting in Form FC-GPR to the concerned AD bank within 30 days of date of allotment and follow up from bank from time to time.</li>
                      </ol>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Is physical presence of a Director or Shareholder required for registration of subsidiary in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                      SPICe+ Form, which is an integrated form for the reservation of name and other services, is to be filled for the registration of subsidiary companies. SPICe+ form has two parts: –
                      </p>
                      <p><b>Part A:</b>{" "}Name Reservation (New Companies)</p>
                      <p><b>Part B:</b>
                      <ol>
                        <li>Incorporation of Company</li>
                        <li>Application For DIN</li>
                        <li>
                        PAN and TAN Application
                        </li>
                        <li>EPFO and ESIC Registration</li>
                        <li>GSTIN Application</li>
                        <li>Bank Account Opening</li>
                        <li>Professional Tax Registration</li>
                      </ol>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the minimum capital requirement?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        To register as a foreign company (private company),
                        minimum authorized capital of INR 1 Lakh is prescribed,
                        whereas the constraint of minimum paid-up capital is
                        eliminated as a part of Government’s initiative to
                        simplify business registration. However, each
                        shareholder must subscribe at least 1 share for
                        registration and introduce the amount sufficient to run
                        the business.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to reserve the company name? Can we apply with name
                        of foreign entity?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The name of a company should be formulated as mentioned
                        in the above section. The applicants can provide a
                        maximum of 2 names with their preference order under the
                        RUN form. The names provided should comply with the
                        provisions of the Act or regulations.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the advantages of a registered partnership
                        firm?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Only a registered partnership firm can claim a set off
                        (i.e. mutual adjustment of debts owned by the disputant
                        parties to one another) or other proceedings in a
                        dispute with a third party. Hence, it is advisable for
                        partnership firms to get it registered sooner or later.
                        Also, only a registered partnership firm can file a suit
                        in any court against the firm or other partners for the
                        enforcement of any right arising from a contract or
                        right conferred by the Partnership Act. An unregistered
                        Partnership Firm can get registered at any point of time
                        after its establishment.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether is it compulsory to appoint an Indian director?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, for any company registration in India at least one
                        of the directors must be an Indian citizen and a
                        resident. This criterion must be fulfilled all time
                        during the existence of the company.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether physical place of business is required for
                        setting up business in India by foreign companies?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        As derived from the definition of a foreign company, it
                        can own a place of business itself or through an agent,
                        physically or through electronic mode. Hence, it does
                        not require having a physical place of business in
                        India.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Does anyone have to be physically present while online
                        company registration in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, none of the promoters are required to be present at
                        the whole process of Pvt Ltd company registration is
                        online. All the forms are filed on the web portal and
                        digitally signed. Also, the required documents can be
                        sent through e-mail or uploaded on our portal for
                        filing.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default IndianSubsidiary;
