import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import management from "../assets/management.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function ProducerCompany() {
  const pageName = "ProducerCompany";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Producer Company Registration</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
              <div className="col col6">
                <h2>What is the Producer Company?</h2>
                <div className="bar"></div>
                <p>
                  A producer company is basically a company registered to deal
                  with the primary production of its active member related to
                  farming. The main objective includes production to its selling
                  and exporting also. For the purpose of incorporation of
                  producer company, Part IXA of Companies Act, 1956 is
                  applicable until any special Act is enacted for such
                  companies.
                </p>
                <p>
                  A producer company is registered with ten or more member being
                  producers; or any two or more producer institutions; or its
                  combination. Alike any other company, the liability of its
                  members is limited to the extent of unpaid share capital by
                  its members. The producer company is deemed to be a private
                  limited company under this Act, however, the threshold of the
                  number of members does not apply to same.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of registering a farmer-producer organization</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Special User Rights</Tab>
                <Tab>Benefits to members</Tab>
                <Tab>Separate legal identity</Tab>
                <Tab>Limited Liability of owners</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Special User Rights</h2>
                      <p>
                        The active members of the producer company may enjoy
                        special user rights, if provided in the AoA of the
                        company. The company may issue appropriate instruments
                        to active members in respect of such rights. The special
                        use rights refer to any right relating to the supply of
                        additional produce by the active Member or any other
                        right relating to his produce.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Benefits to members</h2>
                      <p>
                        Every member can initially receive only the value of the
                        produce as determined by the Board. The withheld price
                        may be disbursed later in cash or by allotment of equity
                        shares. The members are eligible only for limited return
                        but can also be allotted bonus shares. The surplus can
                        also be disbursed as patronage bonus.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Separate legal identity</h2>
                      <p>
                        Alike any other company, the producer company also has a
                        separate legal identity. The assets, liabilities,
                        rights, debts and privileges shall vest in the company.
                        The company can deal in its own name and hold the
                        properties, too. The change in members or the directors
                        neither affects its existence nor the assets &
                        liabilities or rights & obligations.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={owners} alt="owners-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={management} alt="management-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Limited Liability of owners</h2>
                      <p>
                        The members and the producer institutions have limited
                        liability towards the debts and obligations of the
                        company. Their personal assets remain unaffected in any
                        case of loss or winding up. With assurance to limited
                        liability, the producers can invest with lower risk into
                        such businesses.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for producer company registration</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Latest Passport size photograph of Shareholders and
                      Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>PAN Card of shareholders and Directors.</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>Identity Proof</h2>
                    <p>
                      Voter ID/ Passport/ Driving License of Shareholders and
                      Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Address Proof</h2>
                    <p>
                      Telephone Bill /Electricity Bill/ Latest Bank Account
                      Statement of Shareholders and Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Electricity Bill/ Telephone Bill of the registered office
                      address in India
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from owner</h2>
                    <p>
                      No Objection Certificate to be obtained from the owner(s)
                      of registered office
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      Rent Agreement of the registered office should be
                      provided, if any
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to register a producer company online</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2-4</Tab>
                <Tab>Day 5-8</Tab>
                <Tab>Day 9-11</Tab>
                <Tab>Day 12-14</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Application for Digital Signature Certificate</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Checking Name availability</li>
                  <li>Application for Name Reservation under “RUN“</li>
                  <li>Reservation of Name</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of MoA, AoA & other required documents</li>
                  <li>Payment of Stamp Duty</li>
                  <li>Notarization of required documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Filing application for company registration</li>
                  <li>Application for DIN Allotment</li>
                  <li>Application for PAN and TAN of company</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time</li>
                  <li>Certificate of Incorporation</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the minimum requirements to register a farmer
                        producer organization as a company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Following are the minimum requirements to obtain
                        registration as producer company:
                        <br />
                        1) The main object of the company must be too as
                        provided in Section 581B of Companies Act, 1956
                        <br />
                        2) Minimum 10 individuals (who are a producer) or at
                        least 2 producer institutes must subscribe the shares of
                        the company
                        <br />
                        3) Minimum 5 directors in the company, one of which must
                        be Indian citizen and resident
                        <br />
                        4) The registered office of the company must be situated
                        in India
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the minimum capital required?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The amount of capital prescribed for this There is no
                        minimum amount of capital prescribed for the charitable
                        company. The promoters may introduce the amount
                        sufficient to start and run a business.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to reserve the name of company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The name of the company should be formulated as per the
                        naming guidelines. The application for reservation of
                        name is made in web-form called “RUN” with a maximum of
                        2 unique names. The registrar may ask to re-submit the
                        application with a different name if the names do not
                        fulfill the requirements.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether physical presence of the promoters is required
                        while registration?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No. The registration process is completely online, the
                        physical presence of the promoters is not required. All
                        the documents and details can be transmitted through the
                        mail or uploaded on our portal.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can NRIs/Foreign Nationals be a Director in a farmers
                        producer organization?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, an NRI or Foreign National can be a Director after
                        obtaining Director Identification Number. However, at
                        least one Director on the Board of Directors must be an
                        Indian citizen and a resident.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to reserve the company name? Can we apply with name
                        of foreign entity?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The name of a company should be formulated as mentioned
                        in the above section. The applicants can provide a
                        maximum of 2 names with their preference order under the
                        RUN form. The names provided should comply with the
                        provisions of the Act or regulations.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the advantages of a registered partnership
                        firm?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Only a registered partnership firm can claim a set off
                        (i.e. mutual adjustment of debts owned by the disputant
                        parties to one another) or other proceedings in a
                        dispute with a third party. Hence, it is advisable for
                        partnership firms to get it registered sooner or later.
                        Also, only a registered partnership firm can file a suit
                        in any court against the firm or other partners for the
                        enforcement of any right arising from a contract or
                        right conferred by the Partnership Act. An unregistered
                        Partnership Firm can get registered at any point of time
                        after its establishment.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether is it compulsory to appoint an Indian director?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, for any company registration in India at least one
                        of the directors must be an Indian citizen and a
                        resident. This criterion must be fulfilled all time
                        during the existence of the company.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether physical place of business is required for
                        setting up business in India by foreign companies?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        As derived from the definition of a foreign company, it
                        can own a place of business itself or through an agent,
                        physically or through electronic mode. Hence, it does
                        not require having a physical place of business in
                        India.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Does anyone have to be physically present while online
                        company registration in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, none of the promoters are required to be present at
                        the whole process of Pvt Ltd company registration is
                        online. All the forms are filed on the web portal and
                        digitally signed. Also, the required documents can be
                        sent through e-mail or uploaded on our portal for
                        filing.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ProducerCompany;
