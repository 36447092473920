import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import management from "../assets/management.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function TrademarkRenewal() {
  const pageName = "TrademarkRenewal";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Trademark Renewal</h2>
                  <p>
                    File your trademark renewal application to extend your
                    Trademark rights for the next 10 years
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
              <div className="col col6">
                <h2>What is Trademark Renewal?</h2>
                <div className="bar"></div>
                <p>
                  A registered trademark in India expires after 10 years. But,
                  it can be kept permanent by filing a trademark renewal
                  application online or offline by paying necessary renewal fees
                  every 10 years. This application has to be filed on or before
                  6 months from the date of expiration of the registration.
                  Trademark Renewal in India can help you keep your trademark
                  perpetual and permanent as there are no restrictions on how
                  many times and for how many years you wish to keep your
                  trademark status active and registered.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Trademark Renewal in India</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Protection from Frivolous Litigation</Tab>
                <Tab>Public consultation</Tab>
                <Tab>Brand Name Security</Tab>
                <Tab>Monetary Returns</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Protection from Frivolous Litigation</h2>
                      <p>
                        It is possible to have a seamless trademark protection
                        without any chances of litigation. Renewal of trademarks
                        makes impossible for someone to claim rights over it
                        other than the trademark owner.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Extension of Ownership Rights</h2>
                      <p>
                        One can avail protection from infringement of rights
                        over the brand name. The exclusive rights over the
                        trademark are extended for 10 years after each renewal.
                        Hence it provides a permanent protection of your brand
                        and goodwill created by the brand.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Brand Name Security</h2>
                      <p>
                        Renewal of trademark promises continuous and unhindered
                        protection of the brand name. Failure of renewal leads
                        to a lapse of legal protection in Brand name.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={management} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Monetary Returns</h2>
                      <p>
                        A trademark owner has the exclusive rights to assign or
                        license the mark to someone else in return of some
                        monetary compensation. Thus, one can make a profit from
                        a registered trademark as well.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for trademark renewal application</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Proof of Applicant</h2>
                    <p>PAN Card and Address Proof of Applicant </p>
                    <p>
                      Certificate of registration (other than Individual
                      applicant)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>TM Certificate</h2>
                    <p>Trademark registration certificate issued by Registry</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>Power of Attorney</h2>
                    <p>
                      It allows the attorney to file the trademark renewal
                      application on your behalf with the trademark registry
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>Copy of TM-A</h2>
                    <p>
                      The application filed with the TM Registry for its
                      registration is to be provided
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process for Trademark Renewal online</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1 Collection</Tab>
                <Tab>Day 2 - 3 Execution</Tab>
                <Tab>Day 4 - Submission</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and collection of basic Information</li>
                  <li>
                    Provide required documents for trademark renewal services
                  </li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of Documents by Professionals</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Filing of online TM renewal application</li>
                  <li>Sharing the acknowledgement</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is Trademark Renewal Process in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        An online trademark renewal application shall be made
                        with the help of Registered Trademark Attorney in India
                        with the payment of renewal fee and additional or late
                        fee (as applicable). A trademark renewal request can be
                        filed six months prior to the date of trademark expiry.
                        Before the expiry of the trademark, the registrar sends
                        a notice to the owner regarding the expiry and the
                        renewal fees and conditions. In case of lapse, the
                        trademark gets removed from the register. An extension
                        period of six months post-expiration is given in case
                        one misses the deadline along with an additional late
                        fee.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I assign an unregistered Trademark in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Registry of Trademark may condone the period of
                        delay for renewal of trademark by 6 months after the
                        expiry of 10 years. Online application for Trademark
                        Renewal in India can be made after the expiry of 10
                        years with a payment of additional fees.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is Trademark Renewal Process in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        An online trademark renewal application shall be made
                        with the help of Registered Trademark Attorney in India
                        with the payment of renewal fee and additional or late
                        fee (as applicable). A trademark renewal request can be
                        filed six months prior to the date of trademark expiry.
                        Before the expiry of the trademark, the registrar sends
                        a notice to the owner regarding the expiry and the
                        renewal fees and conditions. In case of lapse, the
                        trademark gets removed from the register. An extension
                        period of six months post-expiration is given in case
                        one misses the deadline along with an additional late
                        fee.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What if the trademark expires?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        You can apply for the restoration of an expired
                        trademark within 6 months from its expiry date to keep
                        using it. But the delay of 6 months can be risky and
                        shall attract additional fees and documentation.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is difference between a trademark registration and
                        a Trademark renewal?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Trademark Registration is a long process that takes a
                        number of documents and requires more time than the
                        renewal. A renewal is basically for continuing your
                        ownership and use of the trademark, while registration
                        is the initial process to get its exclusive ownership.
                        After completion of every 10 years, the trademark needs
                        to be renewed.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the consequences of failure to renew the
                        trademark?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In the case of non-renewal, the consequences are severe.
                        Because the trademark would be removed from the register
                        and any other person can claim it and get it registered
                        to their name.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default TrademarkRenewal;
