import * as React from "react";
import onepersonimg from "../assets/One-Person-Company.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import management from "../assets/management.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function NetherlandsIncorporation() {
  const pageName = "NetherlandsIncorporation";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Company Registration in the Netherlands</h2>
                  <p>
                    There is an ease of doing business in the Netherlands.
                    Choose a business structure, trade name, and register as an
                    employer for social security and payroll taxes. Consider
                    environmental regulations and fire safety requirements also
                    while setting up a business there.
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Company Incorporation in Netherlands - An Overview</h2>
                <div className="bar"></div>
                <p>
                  In the atmosphere of Brexit, the Netherlands has become the
                  new hot favourite for starting a business. The country offers
                  many benefits to entrepreneurs and investors. The Dutch have
                  taken on a leadership position within several industries, by
                  continually coming up with innovative and efficient ideas. The
                  fact that the Netherlands is part of the EU obviously plays a
                  large role, since this enabled the Dutch to build outstanding
                  connections with most countries abroad. Due to the European
                  Single Market, you can freely import and export goods and
                  services throughout the entire EU. Next to that, the location
                  of the Netherlands proves to offer great logistical benefits.
                </p>
              </div>
              <div className="col col6">
                <img src={onepersonimg} alt="LLP-Registration" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of starting a business in Netherlands</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Benefits</Tab>
                <Tab>Process Of Registration</Tab>
                <Tab>How can you register</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Benefits</h2>
                      <p>
                        Incorporating a company in Holland offers the following
                        benefits:
                      </p>
                      <ul className="list-style-disc">
                        <li>
                          The lowest corporate income tax rate in the European
                          Union, which the current rate at 19% for income up to
                          € 200,000 [going up to 25% for higher-income
                          brackets].
                        </li>
                        <li>
                          The tax rates are going to be reduced further - for
                          this lowest income bracket, to 16.5% in 2020 and 15%
                          in 2021.
                        </li>
                        <li>
                          No value added tax (VAT) for transactions between the
                          member states of the European Union;
                        </li>
                        <li>
                          Netherlands has the greatest number of treaties for
                          double tax avoidance worldwide;
                        </li>
                        <li>
                          The Netherlands is also among the founding members of
                          the EU;
                        </li>
                        <li>
                          93% of the native population speaks English; many are
                          also proficient in German and French;
                        </li>
                        <li>
                          Highly educated labor force (3rd in the global top for
                          education level);
                        </li>
                        <li>Outstanding international business atmosphere;</li>
                        <li>
                          The country welcomes foreign entrepreneurs and
                          investors: from small enterprises to multinational
                          companies included in the Fortune 500 list;
                        </li>
                        <li>
                          The Netherlands attracts international companies from
                          all sectors with its stable legislation and politics,
                          coupled with excellent international relations.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>How to register a company in the Netherlands?</h2>
                      <p>
                        The following is the process of company registration in
                        the Netherlands for foreigners:
                      </p>
                      <p>
                        <strong>
                          Who can register a company in the Netherlands?
                        </strong>
                      </p>
                      <p>
                        You can register the business in the Commercial Register
                        if you are:
                      </p>
                      <ul className="list-style-disc">
                        <li>a director of the company;</li>
                        <li>
                          a manager of the Dutch branch and have a power of
                          attorney demonstrating that you are the manager. That
                          power of attorney must be in Dutch with original
                          signatures of the director. You are also required to
                          register as a manager.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col12">
                    <h2>How can you register a company?</h2>
                    <p>
                      The following forms have to be filed for incorporating a
                      company in the Netherlands. The forms you have to use for
                      the purpose of registration are:
                    </p>
                  </div>
                  <div className="col col12">
                    <div className="textbox">
                      <ul className="list-style-disc">
                        <li>
                          Form 6: Registration of a non-resident legal entity
                        </li>
                        <li>
                          Form 11: Registration of an official of a legal entity
                        </li>
                        <li>
                          Form 13: Registration of an authorised representative
                          business agent
                        </li>
                        <li>Members are not held liable in an LLC</li>
                        <li>
                          Depending on the limitations of the operating
                          agreement, transferability is planned
                        </li>
                        <li>
                          In general, stakeholders from outside don’t prefer an
                          LLC because they are structured to operate as
                          partnerships mostly
                        </li>
                      </ul>
                      <p>
                        The English-language forms can be used for standard
                        details. Free-form texts such the description of
                        activities etc, must always be stated in Dutch or in
                        English with a translation.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for Netherlands company incorporation</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col12">
                <div className="textbox">
                  <p>
                    Following are the documents that must be submitted in any of
                    the languages Dutch, English, German or French:
                  </p>
                  <ul className="list-style-disc">
                    <li>
                      proof of registration of company from the country where it
                      was founded (no older than one month);
                    </li>
                    <li>certified copy of the Memorandum of Association;</li>
                    <li>certified copy of the Articles of Association;</li>
                    <li>
                      certificate of incumbency, which clearly shows the
                      appointed Board of Directors.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Why Filing Saga</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Business Days</Tab>
                <Tab>Customer Score</Tab>
                <Tab>Strong Team</Tab>
              </TabList>

              <TabPanel>
                <p>
                  At Filing Saga, we can deliver all your documentation
                  requirements in just four working days. And if you're not
                  totally satisfied, we'll take another couple of days to work
                  on the modifications you need. All at the lowest price, both
                  online and offline.
                </p>
              </TabPanel>
              <TabPanel>
                <p>
                  We make your interaction with government as smooth as is
                  possible by doing all the paperwork for you. We will also give
                  you absolute clarity on the process to set realistic
                  expectations.
                </p>
              </TabPanel>
              <TabPanel>
                <p>
                  Our team of experienced business advisors are a phone call
                  away, should you have any queries about the process. But we'll
                  try to ensure that your doubts are cleared before they even
                  arise.
                </p>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is it possible to establish a Dutch company if I reside
                        elsewhere?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, a resident of any country can incorporate a company
                        in Holland.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the company types in Holland?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The entity preferred by most foreign investors is the
                        private limited company (BV). Other popular types are
                        the foundation (Stichting) and the public company (NV).
                        You can also register a collaborative entity, a solitary
                        proprietorship or a partnership.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How many days does it take for starting a business in
                        the Netherlands?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        On average it will take 3 to 5 working days to start a
                        business in the Netherlands.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the minimum required share capital?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Limited companies no longer need to declare a minimum
                        capital. Public companies are required to have a minimum
                        of EUR 45 000 in shares.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the procedure for starting a Netherlands
                        business?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A business can be started in four simple steps: 1)
                        submission of the deed of incorporation; 2) submission
                        of the association articles; 3) tax registration; 4)
                        bank account opening.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the taxes levied upon companies in Holland?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        For annual profit of up to € 200 000 companies are
                        required to pay 20 percent corporate tax. Other taxes,
                        for real estate or transfer in case of property purchase
                        are also levied.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default NetherlandsIncorporation;
