import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function PrivateCompanytoPublicCompany() {
  const pageName = "PrivateCompanytoPublicCompany";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Private Company to Public Company</h2>
                  <p>Go public and influx large funds by inviting investors</p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>
                  Know about Conversion of a Private Limited Company into Public
                  Limited Company
                </h2>
                <div className="bar"></div>
                <p>
                  Conversion of Private Company into a Public Company opens a
                  new door of opportunities, especially in the form of
                  fundraising and reach of the market. The company can raise
                  funds through Public Issue and accept deposits too. This
                  structure is appropriate for the medium and large scale
                  businesses. The conversion will be followed by the approval
                  from Government and alteration to MoA & AoA.
                </p>
                <p>
                  Further, a minimum of 7 members and 3 directors are required
                  for conversion of Pvt Ltd to Public Ltd. The rights,
                  liabilities, powers, and obligations remain the same for the
                  company even after the conversion. Transfer of shares is
                  possible as the restriction on transfer is removed on
                  conversion.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>
                Benefits of Conversion of Private Limited into Public Limited
              </h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Raising capital through public issue of shares</Tab>
                <Tab>Brand Awareness</Tab>
                <Tab>Less Statutory Compliance</Tab>
                <Tab>Raising Capital</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Raising capital through public issue of shares</h2>
                      <p>
                        The ability to raise shares is the most beneficial
                        factor of a public limited company. Especially, if it is
                        listed on a recognized stock exchange market, it has the
                        ability to attract investors from hedge funds, mutual
                        funds etc. The amount of capital that can be raised is
                        much greater than a private limited company.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Brand Awareness</h2>
                      <p>
                        When the company goes public, it gets listed in the
                        stock exchange and hence automatically more people get
                        to know about and its functions, hence the increased
                        brand recognition will bring more business.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Limited liability</h2>
                      <p>
                        The liability of each shareholder or member is limited.
                        This characteristic remains intact with this conversion.
                        Their liability is limited to the extent of their
                        shareholding. The individual or personal assets of
                        shareholders or members are not at risk.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Transferability of shares</h2>
                      <p>
                        Shares in a public limited company are easily
                        transferable as compared to the private limited company.
                        The shareholders can sell the shares and benefit from
                        its liquidity. This acts as an incentive for people to
                        invest as they are not bound to remain with the company
                        forever.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>
                Documents Required for Conversion of Private Company to Public
                Company
              </h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      PAN Card of shareholders and Directors. Foreign nationals
                      must provide a valid a passport.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Identity Proof</h2>
                    <p>
                      Voter ID/ Passport/ Driving License of Shareholders and
                      Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Address Proof</h2>
                    <p>
                      Telephone Bill /Electricity Bill/ Latest Bank Account
                      Statement of Shareholders and Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Latest Passport size photograph of Shareholders and
                      Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Electricity Bill/ Telephone Bill of the registered office
                      address
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from owner</h2>
                    <p>
                      No Objection Certificate to be obtained from the owner(s)
                      of registered office
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      Rent Agreement of the registered office should be
                      provided, if any
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Note</h2>
                    <p>
                      In case of NRI or Foreign National, documents of
                      director(s) must be notarized or apostilled
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Incorporation documents</h2>
                    <p>
                      Certificate of Incorporation, MoA & AoA to be provided
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Financial Statements</h2>
                    <p>
                      Duly certified copy of latest audited Financial Statements
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Income Tax Return</h2>
                    <p>
                      ITR filed for the previous financial year to be submitted
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of conversion into Public Company</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1-2</Tab>
                <Tab>Day 3-7</Tab>
                <Tab>Day 8-15</Tab>
                <Tab>Day 16-20 onwards</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Consultancy and assistance for conversion</li>
                  <li>Collection of basic information & documents</li>
                  <li>Application of DSC of new director</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of necessary resolutions</li>
                  <li>Drafting of other required documents & affidavit</li>
                  <li>Alteration of MoA & AoA</li>
                  <li>Provide documents required drafted after signature</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Online filing of form for alteration of MoA & AoA</li>
                  <li>Government processing time to approve the application</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Online filing of form applicable for conversion</li>
                  <li>
                    Government processing time to approve the application*
                  </li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether change of name is required for conversion?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The suffix “Private Limited” will be replaced with
                        “Limited.” For this change, the company has to seek
                        permission from the shareholders and after that make the
                        required changes in the MoA to get it amended.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the liabilities of a Public Limited Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Since the Public limited company deals with the public’s
                        money, it requires taking measures which increase the
                        statutory compliance on its part. The regulatory
                        liabilities are not restricted to the income tax but
                        with ROC/MCA, SEBI, RBI, etc. It is important to take
                        extra measures as the stakes are pretty high than any
                        other company.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to alter MoA & AoA of the company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Once the name approval letter is received from the ROC,
                        the MoA and AoA are required to be drafted. The name
                        clause and capital clause are altered along with the
                        removal of restriction to Private Company as provided by
                        definition.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When can a company start its business operations as a
                        Public Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The company can start its business operations as a
                        Public Company on receipt of the fresh Certificate of
                        Incorporation from RoC.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default PrivateCompanytoPublicCompany;
