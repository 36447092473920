import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import management from "../assets/management.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function Section8Company() {
  const pageName = "Section8Company";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Section 8 Company Registration</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
              <div className="col col6">
                <h2>What is Section 8 company?</h2>
                <div className="bar"></div>
                <p>
                  Popularly known as Section 8 company is a company registered
                  with charitable objects such as the promotion of Arts,
                  commerce, science, education, sports, protection of the
                  environment, religion, charity, etc. By promoting it as a
                  not-for-profit organization, the Act does not bar the company
                  from profit creation but rather prohibits distributing profit
                  among members. This says that all the income or profit from
                  any activity of the company must be utilized towards
                  charitable purpose for which it is registered.
                </p>
                <p>
                  The charitable company is registered under Section 8 of the
                  Companies Act, 2013, which provides it special status due to
                  its objects. Compared to other types of companies, Section 8
                  company enjoys various exemptions and relaxations.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of incorporation of Section 8 company</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Tax and compliance exemptions</Tab>
                <Tab>Separate legal identity</Tab>
                <Tab>Strategic control over operations</Tab>
                <Tab>Various Financial Returns to the Partners</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Tax and compliance exemptions</h2>
                      <p>
                        With its registration under Section 8 of Companies Act,
                        it obtains a special status – distinct from other
                        entities. Not only the company enjoys tax exemptions,
                        but also the donors can claim an exemption for the
                        donation made (under Income Tax). Apart from tax
                        exemption, the company is also exempted from the
                        applicability of many compliance-related provisions.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Separate legal identity</h2>
                      <p>
                        Being registered as a company, it obtains a separate
                        identity apart from its members. Therefore, it can own
                        and hold the assets and liabilities in its own name
                        irrespective of the connection with the members. The
                        change in members or the directors neither affects its
                        existence or the assets & liabilities or rights &
                        obligations.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Limited Liability</h2>
                      <p>
                        A company registered under Section 8 ensures limited
                        liability to its members. The liability of the members
                        is limited only to the extent of the capital subscribed
                        and unpaid. The losses and obligations do not create a
                        charge over the personal properties of members or
                        directors. This helps the promoters to freely run the
                        non-profit organisations.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={owners} alt="owners-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={management} alt="management-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>No corporate title</h2>
                      <p>
                        Although the company enjoys the benefit of the corporate
                        entity, it is not labelled as the corporate entity. The
                        company does not need to add the suffix “Private
                        Limited” or “Limited” after its name. This exemption is
                        provided so that third parties offer their attention to
                        the company’s purpose and not its status assuming it is
                        profit making organisation.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for formation of Charitable Company</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Latest Passport size photograph of Shareholders and
                      Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      PAN Card of shareholders and Directors. Foreign nationals
                      must provide a valid passport.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>Identity Proof</h2>
                    <p>
                      Voter ID/ Passport/ Driving License of Shareholders and
                      Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Address Proof</h2>
                    <p>
                      Telephone Bill /Electricity Bill/ Latest Bank Account
                      Statement of Shareholders and Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Electricity Bill/ Telephone Bill of the registered office
                      address in India
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from owner</h2>
                    <p>
                      No Objection Certificate to be obtained from the owner(s)
                      of registered office
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      Rent Agreement of the registered office should be
                      provided, if any
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Statement of Income & Expenditure</h2>
                    <p>
                      Estimated Statement of Income & Expenditure for next three
                      years
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Section 8 company registration procedure</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2-4</Tab>
                <Tab>Day 5-8</Tab>
                <Tab>Day 9-13</Tab>
                <Tab>Day 14-16</Tab>
                <Tab>Day 17-19</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Application for Digital Signature Certificate</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Checking Name availability</li>
                  <li>Application for Name Reservation under “RUN“</li>
                  <li>Reservation of Name</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of MoA, AoA & other required documents</li>
                  <li>Payment of Stamp Duty</li>
                  <li>Notarization of required documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Application to grant a license in form INC-12</li>
                  <li>Government processing time for approval</li>
                  <li>License under section 8 in form INC-16</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Filing application for company registration</li>
                  <li>Application for DIN Allotment</li>
                  <li>Application for PAN and TAN of company</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time</li>
                  <li>Certificate of Incorporation</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the minimum requirements to register a
                        charitable company in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        To register such company, the promoters have to ensure
                        the following:
                        <br />
                        1) The main object of the company must be to operate for
                        a charitable purpose and among those prescribed in law
                        <br />
                        2) Minimum 2 members of the company, who shall subscribe
                        to capital on incorporations
                        <br />
                        3) Minimum 2 directors in the company, one of which must
                        be Indian citizen and resident
                        <br />
                        4) The registered office of the company must be situated
                        in India
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the minimum capital required?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        There is no minimum amount of capital prescribed for the
                        charitable company. The promoters may introduce the
                        amount sufficient to start and run business.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to reserve the name of company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The name of the company should be formulated as per the
                        naming guidelines. The application for reservation of
                        name is made in web-form called “RUN” with a maximum of
                        2 unique names. The registrar may ask to re-submit the
                        application with a different name if the names do not
                        fulfill the requirements.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to obtain approval of Central Government for
                        non-profit company registration?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The promoters shall apply in e-form INC-12 for said
                        approval from Central Government. The form is
                        accompanied with MoA – AoA of the company, declarations
                        from promoters, estimated statement of income &
                        expenditure for next three years, etc. If the
                        application is approved, the approval letter is issued
                        in form INC-16.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether physical presence of the promoters is required
                        while registration?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No. The registration process is completely online, the
                        physical presence of the promoters is not required. All
                        the documents and details can be transmitted through the
                        mail or uploaded on our portal.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can NRIs/Foreign Nationals be a Director in a Section 8
                        company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, an NRI or Foreign National can be a Director in a
                        Section 8 Company after obtaining Director
                        Identification Number. However, at least one Director on
                        the Board of Directors must be an Indian citizen and a
                        resident.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether Section 8 company and NGO registrations are
                        same?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Section 8 company is popularly known as NGO.
                        However, NGOs can be registered as other business
                        structure like trust, society, etc.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can section 8 or Charitable company declare dividends?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A Section 8 or Charitable Company shall not pay any
                        dividend to its members, but apply the surplus of
                        receipts over payments for the promotion of its
                        objectives.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the validity of the registration of Section 8
                        company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Once the company is incorporated, it is in existence and
                        active as long as the annual compliances are met with
                        regularly or it is wound-up voluntarily.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Section8Company;
