import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function AnnualFilingForLLP() {
  const pageName = "AnnualFilingForLLP";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Annual Filing for LLP</h2>
                  <p>
                    Escape hefty penalties with timely annual LLP compliance
                    filing
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is Annual Filing for LLP?</h2>
                <div className="bar"></div>
                <p>
                  A Limited Liability Partnership enjoys a separate status.
                  Thus, an organization needs to maintain its active status by
                  regularly filing with the Ministry of Corporate Affairs (MCA).
                  Annual compliance filing is mandatory for any LLP, whether
                  having a business or not. Annual compliance for LLP requires
                  filing two separate forms. One of which is for Annual Return,
                  whereas another one is for Statement of Accounts and Solvency.
                </p>
                <p>
                  The forms are filed for reporting the activities and financial
                  data for each financial year in the upcoming year. The failure
                  to fulfill LLP Annual Compliance requirements levies an
                  additional fee of ₹ 100 each day of a delay till the actual
                  date of filing. Hence, apart from the mandate, the heavy
                  penalty compels the Designated Partners to fulfill the
                  requirements.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Annual Filing</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Higher Credibility</Tab>
                <Tab>Record of Financial Worth</Tab>
                <Tab>Maintain Active Status and avoid penalties</Tab>
                <Tab>Easy conversion and closure</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Higher Credibility</h2>
                      <p>
                        Legal compliance is a primary requirement for any
                        business. The status of LLP annual filing is displayed
                        at the Master Data of the LLP on MCA portal and the same
                        can be accessed by any person. For loan approvals or any
                        other similar requirements, compliance is a major
                        criterion to measure the credibility of the
                        organization.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Record of Financial Worth</h2>
                      <p>
                        The forms filed by the LLP are accessible by companies.
                        Hence, while entering into contracts or major projects,
                        the concerning party may also inspect the financial
                        worth. LLP annual filing provides the record of its
                        financial worth and capacity to an interested person or
                        party.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Maintain Active Status and avoid penalties</h2>
                      <p>
                        In case of consecutive default in annual filing, the LLP
                        can be declared as defunct or receive default status.
                        Also, the partners can be declared as defaulters and may
                        also be disqualified from their further appointment in
                        LLP or company. Hence, LLP needs to file the return to
                        maintain active status. Regular filing also saves the
                        LLP from heavy additional fee and penalties.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Easy conversion and closure</h2>
                      <p>
                        For conversion of the LLP into any other organization,
                        annual filing is very essential. The regular compliance
                        records ease the conversion task. The same applies in
                        case of closure of LLP. Even if the LLP was
                        non-operational, the Registrar may ask to fulfill annual
                        compliance, with additional LLP filing fee, if
                        applicable.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for LLP Annual Filing</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card & COI</h2>
                    <p>PAN Card and Certificate of Incorporation of LLP</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>LLP Agreement</h2>
                    <p>
                      The LLP Agreement along with any supplementary agreement,
                      if any
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Financial Statements</h2>
                    <p>
                      Financial Statement of LLP duly signed by the Designated
                      Partners
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Digital Signature</h2>
                    <p>DSC of all Designated Partners is required</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of LLP Compliances</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1 - Collection</Tab>
                <Tab>Day 2-3 - Preparation</Tab>
                <Tab>Day 4 onwards - Filing</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and collection of basic Information</li>
                  <li>Provide Required Documents</li>
                  <li>Decide the due dates of filing</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting necessary documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Filing of Annual Return (Form 11)</li>
                  <li>Filing of Statement of Accounts & Solvency (Form 8)</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether Annual Filing is necessary for the LLPs?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        LLP Annual Filing&nbsp;is necessary for every LLP since
                        its incorporation. From the closure of its first
                        financial year, the LLP must file both the forms within
                        the prescribed time limit.
                      </p>
                      <p>
                        The&nbsp;annual compliance is mandatory&nbsp;for every
                        LLP, irrespective of the number of transaction, turnover
                        or commercial activity undertaken.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the penalty for non-filing/delayed filing of
                        annual compliance forms for LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In case of delay in filing, the LLP is charged with
                        additional Government fee of ₹ 100 for each day of
                        delay. Also, there is no ceiling limit to an additional
                        fee. For continuous failure to annual LLP compliance,
                        the RoC can remove the name of LLP from its register.
                        Also apart from additional fees, the penalty may also be
                        levied to LLP and its partners.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether the Audit of Financial Statements is required
                        for LLP RoC filing?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The audited books of accounts are necessary for the LLP
                        falling under any of the below-mentioned criteria:
                        <br />
                        1) If turnover of the LLP exceeds ₹ 40 Lakh; or
                        <br />
                        2) Total contribution of Partners exceeds ₹ 25 Lakh.
                      </p>
                      <p>
                        If LLP does not fall under any of the above criteria,
                        statements with the signature of partners are
                        sufficient.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When does the first Financial Year of the LLP end?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Due dates of LLP compliance are based on the closure of
                        each financial year. Financial Year of every LLP must be
                        closed on 31st March. However, period of financial year
                        depends on the month of its incorporation:
                        <br />
                        a) LLPs registered between 1st April and 30th September:
                        The LLP must close its financial year on 31st March of
                        next calendar year. Suppose LLP is registered on 1st
                        May, 2018 , the same should close its financial year on
                        31st March, 2019.
                      </p>
                      <p>
                        b) LLPs registered between 1st October and 31st March:
                        The LLP has an option to choose the end of its financial
                        year. For instance if the LLP is registered on 30th
                        October, 2018 the same can close its financial year
                        either on 31st March, 2019 or 31st March, 2020.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default AnnualFilingForLLP;
