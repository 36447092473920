import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Signup.css";
import { useForm, Controller } from "react-hook-form";
import { API_URL } from "../constants";
import axios from "axios";
import Loader from "./Loader";
import { toast, Toaster } from "react-hot-toast";
import Layout from "../common/Layout";

function Signup() {
  const [passwordError, setPasswordError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    },
  });
  const history = useHistory();
  const watchPassword = watch("password");
  const watchConfirmPassword = watch("confirmPassword");
  useEffect(() => {
    setPasswordError(false);
  }, [watchPassword, watchConfirmPassword]);

  const onSubmit = async (data) => {
    if (!checkBox) {
      toast("Please agree to the terms and conditions");
    } else {
      setLoader(true);
      if (data.password === data.confirmPassword) {
        const signUp = await axios.post(`${API_URL}/api/userSignup`, {
          userName: data.firstName + " " + data.lastName,
          userEmail: data.email,
          userPhone: data.phoneNumber,
          userPassword: data.password,
        });
        localStorage.setItem('email',data.email)

        if ((signUp.data.status = 200)) {
          toast(signUp.data.message);

          history.push({
            pathname: "/verifyOtp",
            search: "?query=login",
          });
        } else {
          setPasswordError(true);
          setLoader(false);
          toast(signUp.data.message);
        }
      }
    }
  };
  return (
    <div className="Signup">
      <section className="signup-page">
        <div className="container">
          <div className="signup-form">
            <h2>Sign Up</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group row">
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: "FirstName is required" }}
                  render={({ field }) => (
                    <div className="col col6">
                      <input
                        type="text"
                        name="firstName"
                        placeholder={
                          errors.firstName?.message
                            ? errors.firstName?.message
                            : "First Name"
                        }
                        {...field}
                      />
                    </div>
                  )}
                />

                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: "LastName is required" }}
                  render={({ field }) => (
                    <div className="col col6">
                      <input
                        type="text"
                        name="lastName"
                        placeholder={
                          errors.lastName?.message
                            ? errors.lastName?.message
                            : "Last Name"
                        }
                        {...field}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="form-group row">
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: "Email is required" }}
                  render={({ field }) => (
                    <div className="col col6">
                      <input
                        type="text"
                        name="email"
                        placeholder={
                          errors.email?.message
                            ? errors.email?.message
                            : "Email"
                        }
                        {...field}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{ required: "Phone Number is required" }}
                  render={({ field }) => (
                    <div className="col col6">
                      <input
                        type="text"
                        name="phoneNumber"
                        placeholder={
                          errors.phoneNumber?.message
                            ? errors.phoneNumber?.message
                            : "Phone Number"
                        }
                        {...field}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="form-group row">
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: "Password is required" }}
                  render={({ field }) => (
                    <div className="col col6">
                      <input
                        type="password"
                        name="password"
                        placeholder={
                          errors.password?.message
                            ? errors.password?.message
                            : "Password"
                        }
                        {...field}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{ required: "Confirm Password is required" }}
                  render={({ field }) => (
                    <div className="col col6">
                      <input
                        type="password"
                        name="confirmPassword"
                        placeholder={
                          errors.confirmPassword?.message
                            ? errors.confirmPassword?.message
                            : "Confirm Password"
                        }
                        {...field}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="form-group options">
                <input
                  type="checkbox"
                  onClick={() => setCheckBox((prev) => !prev)}
                />
                <span>
                  I agree to <a href="#">Terms of use</a> and
                  <a href="#">Refund Policy</a>
                </span>
              </div>
              <div className="form-group">
                <input type="submit" value="Sign Up" />
              </div>
              <p style={{ color: "red" }}>
                {passwordError && "Password do not match! "}
              </p>
            </form>
            <div className="signup">
              Already have account? <Link to="/Login">Login</Link>
            </div>
          </div>
        </div>
        {loader && <Loader />}
        <Toaster position="bottom-center" />
      </section>
    </div>
  );
}

export default Signup;
