import * as React from "react";
import onepersonimg from "../assets/One-Person-Company.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function HongKongIncorporation() {
  const pageName = "HongKongIncorporation";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Easily Form a Company in Hong Kong!</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Form a Company in Hong Kong! An Overview:</h2>
                <div className="bar"></div>
                <p>
                  Hong Kong is one of the world's most lucrative markets.
                  Companies based out of Hong Kong or incorporated there are
                  doing exceptionally well. In Hong Kong, forming a company is
                  simple and inexpensive. Without the presence of the owner or
                  directors, a third party can easily apply for company
                  registration in Hong Kong.
                </p>
                <p>
                  In Hong Kong, any adult above the age of 18 can establish a
                  business. It takes roughly 5-7 working days to register a
                  company online in Hong Kong.
                </p>
              </div>
              <div className="col col6">
                <img src={onepersonimg} alt="LLP-Registration" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="col col12">
              <div className="textbox">
                <h2>
                  What Types of Business Structures Can You Register In Hong
                  Kong?{" "}
                </h2>
                <p>
                  While there are many different types of businesses you can
                  establish in Hong Kong, you must first decide on whether you
                  want to operate your business operations from Hong Kong or
                  from wherever you are. This choice will also benefit you in
                  other areas, such as taxation. Below, we've broken down each
                  business structure to help you decide which is best for you:
                </p>

                <ul className="list-style-disc">
                  <li>Sole proprietorship</li>
                  <li>Business partnership</li>
                  <li>Private limited company by shares</li>
                  <li>Public limited company by shares</li>
                  <li>Company limited by guarantee</li>
                  <li>Branch office</li>
                  <li>Representative office.</li>
                </ul>
                <p>
                  The English-language forms can be used for standard details.
                  Free-form texts such the description of activities etc, must
                  always be stated in Dutch or in English with a translation.
                </p>
              </div>
              <div className="textbox">
                <h2>Types Of Business Structures In Hong Kong</h2>
                <p>
                  <strong>Sole Proprietorship</strong>
                </p>
                <p>
                  A sole proprietorship is a business with only one owner who is
                  solely responsible for all earnings and losses.
                </p>
                <p>
                  <strong>Business Partnership</strong>
                </p>
                <p>
                  A minimum of two to a maximum of twenty partners join together
                  to share profits. A general partnership is an arrangement in
                  which participants are personally liable for profits and
                  obligations. There is also the possibility of forming a
                  limited partnership, in which limited partners are solely
                  liable for their initial investment and general partners are
                  liable for their entire investment.
                </p>
                <p>
                  <strong>Private Limited Company by Shares</strong>
                </p>
                <p>
                  A private limited company by shares is a separate legal entity
                  from its owners/shareholders with a maximum of 50
                  shareholders. This is the most common structure chosen by
                  entrepreneurs, since it allows them to take advantage of all
                  of Hong Kong's tax perks.
                </p>
                <p>
                  <strong>Public Limited Company by Shares</strong>
                </p>
                <p>
                  Large companies that wish to be listed for raising capital
                  should use this option. This company is normally listed on the
                  SEHK and the public can subscribe to its shares if it has more
                  than 50 shareholders.
                </p>
                <p>
                  <strong>Company Limited by Guarantee</strong>
                </p>
                <p>
                  This business structure is suitable for charity and non-profit
                  organisations. Members in this structure are required to
                  guarantee that if the company falls into liquidation, they
                  will pay a previously agreed-upon amount as indicated in the
                  company's article of association.
                </p>
                <p>
                  <strong>Branch Office</strong>
                </p>
                <p>
                  This is a branch of a foreign corporation that allows it to
                  conduct business in Hong Kong and profit from it. In Hong
                  Kong, it does not need to be a separate legal body.
                </p>
                <p>
                  <strong>Representative Office</strong>
                </p>
                <p>
                  An extension of a foreign company that is unable to engage in
                  commercial activities for profit. It is appropriate for
                  non-commercial operations like marketing and human resources.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents Required for Company Registration in Hong Kong</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col12">
                <div className="textbox">
                  <p>
                    For forming a company in Hong Kong, you will require the
                    following documents:
                  </p>
                  <ul className="list-style-disc">
                    <li>
                      A copy of the articles of association for the company
                    </li>
                    <li>
                      A fully completed incorporation form that includes the
                      following
                      <ul className="list-style-disc">
                        <li>
                          Company name (3 proposed company names either in
                          English or Chinese)
                        </li>
                        <li>Address of the company</li>
                        <li>Description of business activities</li>
                        <li>
                          Particulars of shareholders, directors and company
                          secretary
                        </li>
                        <li>Liability of members</li>
                        <li>Share capital registered on incorporation</li>
                        <li>Number of shares taken up by subscribers</li>
                      </ul>
                    </li>
                    <li>
                      For non-resident shareholders and directors:
                      <ul className="list-style-disc">
                        <li>
                          Copy of passport, overseas residential address proof
                        </li>
                      </ul>
                    </li>
                    <li>
                      For resident shareholders and directors:
                      <ul className="list-style-disc">
                        <li>Copy of Hong Kong identity card</li>
                        <li>Copy of residential address proof</li>
                      </ul>
                    </li>
                    <li>
                      For corporate shareholders and directors:
                      <ul className="list-style-disc">
                        <li>
                          Copy of parent company registration documents such as
                          certificate of incorporation and articles of
                          association.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p>
                    <strong>Note: </strong> Any non-English documents must be
                    translated into English or Chinese.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Why Filing Saga</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Business Days</Tab>
                <Tab>Customer Score</Tab>
                <Tab>Strong Team</Tab>
              </TabList>

              <TabPanel>
                <p>
                  At Filing Saga, we can deliver all your documentation
                  requirements in just four working days. And if you're not
                  totally satisfied, we'll take another couple of days to work
                  on the modifications you need. All at the lowest price, both
                  online and offline.
                </p>
              </TabPanel>
              <TabPanel>
                <p>
                  We make your interaction with government as smooth as is
                  possible by doing all the paperwork for you. We will also give
                  you absolute clarity on the process to set realistic
                  expectations.
                </p>
              </TabPanel>
              <TabPanel>
                <p>
                  Our team of experienced business advisors are a phone call
                  away, should you have any queries about the process. But we'll
                  try to ensure that your doubts are cleared before they even
                  arise.
                </p>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What do you need to start a company in Hong Kong?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Though the incorporation procedure takes between 5-7
                        business days on average, with expedited service, your
                        company can be legally active in as little as 24 hours.
                        To incorporate your business, you only need to follow
                        four easy steps:
                      </p>
                      <ul className="list-style-disc">
                        <li>Choose company name</li>
                        <li>Choose business type</li>
                        <li>Register company</li>
                        <li>Open corporate bank.</li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the requirements to start a company in Hong
                        Kong?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        For a private limited company by shares, the following
                        are the minimal requirements:
                      </p>
                      <ul className="list-style-disc">
                        <li>One shareholder</li>
                        <li>One individual director</li>
                        <li>One local corporate secretary</li>
                        <li>HKD 1.00 paid-up capital</li>
                        <li>Local registered address</li>
                        <li>
                          Documents such as company incorporation forms,
                          identification copies of directors/shareholders and
                          proof of residential address.
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the company registration fees that I need to
                        pay in Hong Kong?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>The current costs are as follows:</p>
                      <ul className="list-style-disc">
                        <li>
                          Company secretarial fee for incorporation —
                          approx.HK$2,000
                        </li>
                        <li>Acting as company secretary — approx. HK$4,000</li>
                        <li>
                          Provision of registered office address — approx.
                          HK$2,000
                        </li>
                        <li>
                          Preparation and maintenance of significant controllers
                          register and acting as designated representative —
                          HK$2,000
                        </li>
                        <li>
                          Business registration certificate — HK$250 (waived by
                          $2,000 HKD in 2021)
                        </li>
                        <li>
                          Miscellaneous (including but not limited to government
                          application fee, company kit, seal and chop) — approx.
                          HK$3,500.
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How much tax do I need to pay on business profit in Hong
                        Kong?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Hong Kong has no sales tax, no withholding tax, no
                        capital gains tax, no dividend taxes, and no estate tax,
                        making it one of the most tax-friendly jurisdictions in
                        the world for businesses. The Hong Kong government
                        imposes only three direct taxes, all of which have
                        relatively low rates and are further reduced by
                        substantial allowances and deductions.
                      </p>
                      <ul className="list-style-disc">
                        <li>
                          Profits tax of 8.25% for the first HK$2 million of
                          profits of companies
                        </li>
                        <li>
                          Profits above HK$2 million are taxed at 16.5%. For
                          unincorporated business (i.e. partnerships and sole
                          proprietorships), the corresponding two-tiered tax
                          rates are 7.5% and 15%
                        </li>
                        <li>Salaries tax of 15%</li>
                        <li>Property tax of 15%.</li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who can incorporate a company in Hong Kong?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Any person (foreign or local) over the age of 18 can
                        form a Hong Kong company, which is governed by the
                        Companies Ordinance of Hong Kong.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can a foreigner be a shareholder in a Hong Kong company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In Hong Kong, a foreigner has complete authority to
                        serve as an individual director and shareholder of a
                        company. It is not mandatory for a local resident to do
                        so.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default HongKongIncorporation;
