import React, { useEffect } from "react";

const Auth = ({ children }) => {
  useEffect(() => {
    const token = localStorage.getItem("token");
    // if (!token) {
    //   window.location.replace("/login");
    // }
  }, []);
  return <div>{children}</div>;
};

export default Auth;
