import React, { useState } from "react";

import { API_URL } from "../constants";

import "./Login.css";
import { Link } from "react-router-dom";
// form
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Loader from "./Loader";
import { toast, Toaster } from "react-hot-toast";
import Layout from "../common/Layout";

function Login() {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const [loader, setLoader] = useState(false);

  const history = useHistory();
  const onSubmit = async (data) => {
    setLoader(true);

    const login = await axios.post(`${API_URL}/api/loginUser`, {
      userEmail: data.email,
      userPassword: data.password,
    });

    if (login.data.status === 200) {
      localStorage.setItem("token", login.data.data.token);
      toast(login.data.message);
      history.push("/");
    } else {
      setLoader(false);
      toast(login.data.message);
    }
  };
  return (
    <div className="login">
      <section className="login-page">
        <div className="container">
          <div className="login-form">
            <h2>Login</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="email"
                control={control}
                rules={{ required: "Email is required" }}
                render={({ field }) => (
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      placeholder={
                        errors.email?.message
                          ? errors.email?.message
                          : "Email Address"
                      }
                      {...field}
                    />
                  </div>
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{ required: "Password is required" }}
                render={({ field }) => (
                  <div className="form-group">
                    <input
                      type="password"
                      name="password"
                      placeholder={
                        errors.password?.message
                          ? errors.password?.message
                          : "Password"
                      }
                      {...field}
                    />
                  </div>
                )}
              />

              <div className="form-group options">
                <p>
                  <input type="checkbox" /> <span>Remember Me</span>
                </p>
                <p className="forgot_password">
                  <Link to="Forgotpassword">Forgot Password?</Link>
                </p>
              </div>
              <div className="form-group">
                <input type="submit" value="Login" />
              </div>
            </form>
            <div className="signup">
              Don't have account? <Link to="/Signup">Sign Up</Link>
            </div>
          </div>
        </div>
        {loader && <Loader />}
        <Toaster position="bottom-center" />
      </section>
    </div>
  );
}

export default Login;
