import * as React from "react";
import "./MainService.css";
import catimg from "../assets/cat.png";
import subsidiary from "../assets/f1.jpg";
import section8 from "../assets/f2.jpg";
import company from "../assets/f3.jpg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Layout from "../common/Layout";

function GettingSetUp() {
  const pageName = "pageName";
  return (
    <Layout>
      <div className="catpage-data">
        <section className="sub-banner-section">
          <div className="container">
            <div className="banner-content">
              <h2>Starting a business in India</h2>
            </div>
          </div>
        </section>
        <section className="cat_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <img src={catimg} alt="error" />
              </div>
              <div className="col col6">
                <h2>
                  Start with business structure that best suits your
                  requirements
                </h2>
                <div className="bar"></div>
                <p>
                  Setting up a business is the first step to fulfill your dream
                  of being an entrepreneur. To make this dream a reality, you
                  have to first establish it in eyes of law.
                </p>
                <p>
                  {" "}
                  Every business structure in India derives from different
                  characters and is beneficial for different business
                  activities.{" "}
                </p>
                <p>
                  Therefore, the first thing you do is to plan and discuss your
                  business requirements with our experts and end upon the
                  suitable business structure for you. The popular business
                  structures in India are Proprietorship firm and Partnership
                  firm with corporate entities like Private Limited Company, OPC
                  and Limited Liability Partnership, each serving varied needs
                  of the businesses.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="popular-options">
          <div className="container">
            <div className="section-title">
              <h2>Our Popular Services</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col4">
                <div className="single-services">
                  <div className="icon">
                    <i className="fa fa-building"></i>
                  </div>
                  <h3>Private Limited Company</h3>
                  <p>
                    A Private Limited Company is a corporate structure preferred
                    by start-ups and entrepreneurs in India. This ensures
                    limited liability of owners and numerous options for
                    funding.
                  </p>
                  <a className="get-started-btn cmn_btn" href="#">
                    Get Started
                  </a>
                </div>
              </div>
              <div className="col col4">
                <div className="single-services">
                  <div className="icon">
                    <i className="fa fa-handshake-angle"></i>
                  </div>
                  <h3>Limited Liability Partnership</h3>
                  <p>
                    An LLP is the hybrid structure of a Private Company and a
                    Partnership Firm. It is best suitable for services including
                    professional consultancy apart from small and medium scale
                    businesses.
                  </p>
                  <a className="get-started-btn cmn_btn" href="#">
                    Get Started
                  </a>
                </div>
              </div>
              <div className="col col4">
                <div className="single-services">
                  <div className="icon">
                    <i className="fa fa-user-check"></i>
                  </div>
                  <h3>One Person Company</h3>
                  <p>
                    One Person Company is a type of Private Limited Company but
                    with a different structure. It is a company which is owned
                    by a single person but unlike a proprietorship firm, it is a
                    corporate structure.
                  </p>
                  <a className="get-started-btn cmn_btn" href="#">
                    Get Started
                  </a>
                </div>
              </div>
              <div className="col col4">
                <div className="single-services">
                  <div className="icon">
                    <i className="fa fa-elevator"></i>
                  </div>
                  <h3>Partnership Firm</h3>
                  <p>
                    It is a partnership when two or more person come together
                    for profit from a business. This structure is best suitable
                    for small and medium scale businesses with an additional
                    benefit of lower compliances.
                  </p>
                  <a className="get-started-btn cmn_btn" href="#">
                    Get Started
                  </a>
                </div>
              </div>
              <div className="col col4">
                <div className="single-services">
                  <div className="icon">
                    <i className="fa fa-share"></i>
                  </div>
                  <h3>Sole Proprietorship Firm</h3>
                  <p>
                    Proprietorship firm is the most popular business structure
                    to start a business in India, but it is an unorganized
                    structure. Most of the entrepreneurs start their business
                    journey with this structure.
                  </p>
                  <a className="get-started-btn cmn_btn" href="#">
                    Get Started
                  </a>
                </div>
              </div>
              <div className="col col4">
                <div className="single-services">
                  <div className="icon">
                    <i className="fa fa-users"></i>
                  </div>
                  <h3>Contact Experts</h3>
                  <p>
                    Contact our experts at LegalWiz and simplify your business
                    registration process. Make your business fully compliant
                    with best business professional services from LegalWiz.
                    Connect Now!
                  </p>
                  <a className="get-started-btn cmn_btn" href="#">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="special-business">
          <div className="container">
            <div className="section-title">
              <h2>Special Business Entities</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col5">
                <img src={subsidiary} alt="faq-image" />
              </div>
              <div className="col col7">
                <h3>Indian Subsidiary</h3>
                <p>
                  This structure is exclusive of foreign nationals and entities
                  that want to step into the Indian market. Registering an
                  Indian Subsidiary is a prime way to easily establish their
                  business as a corporate structure.
                </p>
                <a className="get-started-btn cmn_btn" href="#">
                  Get Started
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col col7">
                <h3>Section 8 Company Registration</h3>
                <p>
                  Section 8 company is a special entity registered with a sole
                  objective of charitable purpose. Unlike a trust, this is a
                  corporate business structure that ensures the limited
                  liability and a separate existence.
                </p>
                <a className="get-started-btn cmn_btn" href="#">
                  Get Started
                </a>
              </div>
              <div className="col col5">
                <img src={section8} alt="faq-image" />
              </div>
            </div>
            <div className="row">
              <div className="col col5">
                <img src={company} alt="faq-image" />
              </div>
              <div className="col col7">
                <h3>Producer Company</h3>
                <p>
                  Producer company is the corporate entity that is engaged in an
                  activity connected to any primary produce (in relation to
                  farming) of its active members. It is formed at least 10
                  individuals
                </p>
                <a className="get-started-btn cmn_btn" href="#">
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Which steps should be taken to register a business in
                        India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        To register a business in India, the promoters or owners
                        are required to finalize their business activities and
                        capital requirements first. Based on aspects such as the
                        association of partnership, fund requirements, types of
                        activities, etc. the appropriate business strucutre is
                        chosen.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether business registration is mandatory for all
                        businesses?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, registration of the business entity is a primary
                        requirement to establish the business and get legally
                        recognized in the eyes of law. For a proprietorship
                        firm, registration is not mandatory but preferable owing
                        to registration benefits and the requirement of opening
                        a business account in banks.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the common post registration steps?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The legal requirements do not end with registration. The
                        businesses have to make sure that other registrations
                        such as GST registration, shop & establishment
                        registration or trademark registration, etc. are also
                        obtained after the business set-up. The activity
                        specific registrations such as Import-Export Code, FSSAI
                        registration, etc. are also required.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is there any approval reuqired for business
                        registration?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Every organization structure is regulated by specific
                        registration law. Whether it is a private company or
                        LLP, regulatory approval is required for their
                        registration. In addition to that, businesses such as
                        stockbroking, banking activities, insurance agency, etc.
                        require prior approval from the regulatory authorities
                        such as SEBI, RBI & IRDA. These registrations must be
                        obtained prior to business registration.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What amount of capital should be introduced for starting
                        a business?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The amount of capital depends on the activity and its
                        scale. It varies from small and medium-size businesses
                        to wholesale and retail businesses. The best way to
                        decide the appropriate amount is to first analyze the
                        future expenses and requirements. Also, one has to
                        fulfill the minimum requirement of capital based on the
                        organization structure chosen.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What aspects should be considered while choosing the
                        right business entity?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        To choose the right business structure, the first aspect
                        is to decide if there is any business partner or not.
                        Sole Proprietorship and OPC are for those who wish to be
                        sole owners whereas other structures can be established
                        with partners. Second thing is to decide about the
                        capital requirement. For businesses with more capital
                        requirement, corporate structures are beneficial. Apart
                        from all other pre-registration aspects, one must not
                        miss considering the post-registration aspects, tax
                        liabilities, and compliance matters.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default GettingSetUp;
