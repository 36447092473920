import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function ProvisionalPatent() {
  const pageName = "ProvisionalPatent";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Provisional Patent</h2>
                  <p>
                    Secure a priority date for your patent by filing for a
                    Provisional Patent Application
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is a Provisional Patent Application?</h2>
                <div className="bar"></div>
                <p>
                  The patent is an exclusive legal right of the patentee that
                  grants him the legal authority to prohibit others from making
                  or using a patented invention. A provisional application is a
                  brief synopsis disclosing the essence and the nature of the
                  invention. It is the preliminary application that can be filed
                  before filing the complete specification. It explains the
                  patent in brief but not completely. Filing a provisional
                  patent is very useful for inventors as it locks the priority
                  date and secures the invention so that no one else can claim
                  authority over it.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of filing a provisional patent application</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Establishes Priority Rights</Tab>
                <Tab>Buys Time to make required changes</Tab>
                <Tab>Gauge the Worth of Invention</Tab>
                <Tab>Brand Name Security</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Establishes Priority Rights</h2>
                      <p>
                        Filing a provisional patent enables the applicant to
                        secure a priority date for his patent and thus
                        refraining any other individual from filing such a
                        similar patent.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Buys Time to make required changes</h2>
                      <p>
                        A provisional patent gives the inventor time to work on
                        the invention and brush up on its utility, novelty, and
                        the inventive factor until it is finalized as per the
                        established standards. A period of 12 months is given to
                        file the final patent draft called a complete
                        specification.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Gauge the Worth of Invention</h2>
                      <p>
                        Filing a provisional application is a simpler and
                        affordable step for initial patent protection. The
                        inventor gets 12 months’ time before filing the complete
                        specification which is referred to as complete patent in
                        the common parlance. This period gives the applicant
                        enough time to contemplate whether the invention has
                        enough potential to be patented and gauge its
                        feasibility. Changing the decision within these 12
                        months does not attract any charges/penalties and the
                        applicant can cautiously decide if the investment is
                        worth it. Also in that case, it will also not get
                        published and will not come in the public domain.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Monetary Returns</h2>
                      <p>
                        The patent is a great asset and can extract way bigger
                        monetary benefits than any other intellectual property
                        if managed intelligently. The owner can license, assign,
                        or sell its patent whenever needed. Many pharmaceutical
                        companies have made great profits through their patented
                        products through licensing, assigning, etc.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section patent_search">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for provisional patent filing</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>Title</h2>
                    <p>Title of the invention</p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Applicants Information</h2>
                    <p>
                      Name, address and nationality of each applicant for the
                      patent.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Description of the Invention</h2>
                    <p>
                      Detailed description of the invention and details about
                      what the patentee wants to claim out of the invention
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Technical aspects of the invention</h2>
                    <p>Technical details about the invention and drawings</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process for Trademark Renewal online</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1-2 - Collection</Tab>
                <Tab>Day 3-13 - Execution</Tab>
                <Tab>Day 14-15 - Submission</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>
                    Discussion and collection of basic Information about the
                    invention
                  </li>
                  <li>Provide required documents</li>
                  <li>
                    Provide a draft with information about the invention, its
                    uses and advantages
                  </li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Vetting of the draft provided by the applicant</li>
                  <li>Preparation of Provisional patent application</li>
                  <li>Reviewing and refining the claims</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>
                    File provisional patent online with Form-2 and related
                    documents
                  </li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Why should one patent the invention?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The patent filing is important to get exclusive rights
                        over it. If one doesn’t protect it under the law, anyone
                        can exploit it commercially once it becomes available to
                        the public. To restrict others from utilizing, selling,
                        or making copies, the inventor must obtain a patent
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What all information is needed while consulting a patent
                        attorney?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        History of the invention, possible prototypes, all the
                        minor details about the invention. This is to make the
                        inventive steps stated clearly and help them draft
                        better claims for the patent. The draft should also
                        include the most useful aspect about the invention with
                        the technical drawings, illustrating the functionality
                        of the invention. And whether it is a developed or
                        improved version of an already existing patent.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can a published or disclosed invention be patented?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, once the invention is in the public domain, it
                        cannot be patented anymore. Inventors should not
                        disclose their inventions before filing the patent
                        application.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is there any length restriction or limitation while
                        drafting the provisional patent application ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        As such there are no restrictions, however, there are
                        stipulated additional fees, if the (provisional)
                        application draft exceeds 30 pages
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Are there any government fees included by Finling Saga
                        in the Package ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, the mentioned charges only include professional fees
                        and 18% GST, any government fees for filing provisional
                        patent / complete specification has to be paid by the
                        client, at the time of submitting the application.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the criteria of patentability?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        An invention needs to have these three main qualities to
                        be patentable:
                        <br />
                        i) Novelty – invention should be new; and
                        <br />
                        ii) Inventiveness (Non-obviousness) – needs to have an
                        inventive step that makes the invention unique; and
                        <br />
                        iii) Usefulness/ Industrial utility – it should not be a
                        mere prototype, it should be working and has to have
                        some use.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ProvisionalPatent;
