import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import management from "../assets/management.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function PartnershipFirm() {
  const pageName = "PartnershipFirm";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Partnership Firm Registration</h2>
                  <p>
                    "Alone we can do so little; together we can do so much." —
                    Helen Keller
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
              <div className="col col6">
                <h2>Overview</h2>
                <div className="bar"></div>
                <p>
                  "Partnership" is the relation between persons who have agreed
                  to share the profits of a business carried on by all or any of
                  them acting for all.
                </p>
                <p>
                  Persons who have entered into a partnership with one another
                  are called individually "partners" and collectively a "firm",
                  and the name under which their business is carried on is
                  called the "firm name".
                </p>
                <p>
                  The partnership can be oral or written. A written partnership
                  agreement is called a Partnership Deed. It is not mandatory to
                  register a Partnership Deed. However, it is always advisable
                  to register the same.
                </p>
                <p>
                  The partnership is governed by the Partnership Registration
                  Act, of 1932. In accordance with the act, the partners can
                  register their Firm, its Registrar of Firms in which the
                  firm’s main office is located.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Partnership Firm</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>EASY TO INCORPORATE</Tab>
                <Tab>OPERATING FLEXIBILITY</Tab>
                <Tab>MINIMUM COMPLIANCE</Tab>
                <Tab>ECONOMICAL SETUP</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        The incorporation of a partnership firm is easy as
                        compared to the other forms of business organizations.
                        The partnership firm can be incorporated by drafting the
                        partnership deed and entering into the partnership
                        agreement. It is not mandatory to register a Partnership
                        Deed with the Registrar of Firms.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      src={fundraising}
                      style={{ height: "250px" }}
                      alt="fund-raising-image"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      src={legal}
                      style={{ height: "250px" }}
                      alt="legal-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        The decision-making process in a partnership firm is
                        quick as there is no difference between ownership and
                        management. All the decisions are taken by the partners
                        together, and they can be implemented immediately. The
                        partners have wide powers and activities which they can
                        perform on behalf of the firm. They can even undertake
                        certain transactions on behalf of the partnership firm
                        without the consent of other partners.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        The partnership firm has to adhere to very few
                        compliances as compared to a company or LLP. The
                        partners do not need a Digital Signature Certificate
                        (DSC), or Director Identification Number (DIN), which is
                        required for the company directors or designated
                        partners of an LLP. The partners can introduce any
                        changes in the business easily. They do have legal
                        restrictions on their activities.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      src={owners}
                      style={{ height: "250px" }}
                      alt="owners-image"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      src={management}
                      style={{ height: "250px" }}
                      alt="management-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        The partnership firm is quite economical to set up as
                        compared to a company or LLP. The non-existence of
                        compliances like RoC filings, mandatory audits, and
                        annual Directors KYC makes it economical to operate.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for formation of a Partnership Firm</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>A self-attested copy of PAN Card of all partners</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>Partners Address Proof</h2>
                    <p>
                      Self- attested copy of Aadhar Card and Voter ID/ Passport/
                      Driving License of all partners
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Utility Bill (Electricity Bill) of the place of business
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      Rent Agreement and NOC from the owner of the place of
                      business, if rented
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to Register a Partnership Firm.</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Step 1</Tab>
                <Tab>Step 2</Tab>
                <Tab>Step 3</Tab>
                <Tab>Step 4</Tab>
                <Tab>Step 5</Tab>
                <Tab>Step 6</Tab>
              </TabList>

              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  The first step in incorporating a partnership firm in India is
                  to understand your requirements. Our team will understand your
                  specific needs and objectives.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  Filing Saga will prepare the necessary documents for
                  incorporation, such as the partnership deed, registration
                  application, etc.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  Our expert would then file the documents with the Registrar of
                  Firms. The documents should include the partnership deed and
                  the registration application.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  Filing Saga will then pay the registration fees to the
                  Registrar of Firms on your behalf.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  We will also obtain the PAN and GST number of the partnership
                  firm.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  Lastly, Filing Saga would ensure that the partnership firm
                  complies with all other regulatory requirements, such as
                  obtaining the required licenses and certifications.
                </p>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether Partnership Deed registration is compulsory?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Partnership Act provides that both registered and
                        unregistered partnerships are valid and recognised by
                        law. Partnership registration is not compulsory but is
                        beneficial due to effects of non-registration. Mostly,
                        the businesses at initial level prefer unregistered
                        partnership till they reach stable level. The
                        unregistered partnership can be registered at any time
                        after its formation.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the minimum capital requirement to start a
                        Partnership Firm?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Formation of Partnership Firm does not require any
                        minimum amount. It can be started with any amount of
                        capital contribution by the partners. The Partners can
                        contribute in any amount agreed and in any form being
                        tangible (cash, premise) or intangible (goodwill,
                        intellectual property). The Partners can introduce
                        capital in any ratio, equal or uneven.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the effects of non-registration of Partnership?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Due to non-registration, the firm cannot file suit
                        against any partner or the third party. A partner also
                        cannot sue the partnership firm for his claim. However,
                        the third parties can sue the firm to enforce their dues
                        or claims. Non-registration does not affect the rights
                        of third parties. Also, the partnership can be
                        registered any time after formation to remove the said
                        effects.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the amount of Stamp duty payable on Partnership
                        Deed?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        To confirm the validity of the partnership deed, the
                        partners must pay stamp duty required as per the capital
                        of the firm. The amount of stamp duty payable depends on
                        the amount of capital contribution by partners. The rate
                        of duty is prescribed under State Stamp Act and which is
                        different for every State.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether the notarisation of the Partnership Deed is
                        necessary?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, notary on Partnership Deed is necessary in every
                        case for an unregistered or registered partnership firm.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How many persons are required to register a partnership
                        firm? Is there any specified requirement to become
                        partner?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        It is possible to form a partnership firm with only two
                        partners by following the process described. Further,
                        the Partner to be introduced and appointed in the Firm
                        must be an Indian resident and citizen. NRI and Persons
                        of Indian Origin can only invest in a Partnership with
                        prior approval of the Government. The individual must be
                        competent to contract and not a minor. A minor can be
                        introduced to a Partnership Firm only for profit.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the advantages of a registered partnership
                        firm?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Only a registered partnership firm can claim a set off
                        (i.e. mutual adjustment of debts owned by the disputant
                        parties to one another) or other proceedings in a
                        dispute with a third party. Hence, it is advisable for
                        partnership firms to get it registered sooner or later.
                        Also, only a registered partnership firm can file a suit
                        in any court against the firm or other partners for the
                        enforcement of any right arising from a contract or
                        right conferred by the Partnership Act. An unregistered
                        Partnership Firm can get registered at any point of time
                        after its establishment.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Under which Government Authority is the application of
                        Partnership Firm Registration submitted?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The application for Partnership Firm Registration in
                        India is submitted with the{" "}
                        <strong>Registrar of Firms (RoF)</strong> under whose
                        jurisdiction the Place of Business of Partnership Firm
                        falls. The application of Registration is made in
                        required form along with submitting the Partnership
                        Deed. At the end of the registration procedure, the
                        Certificate of Registration is issued by respective RoF.
                        The process and time of registration may differ for each
                        RoF.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When can the partnership firm apply for PAN and TAN?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Applying for the PAN and TAN is possible after the
                        execution of a Partnership Firm Agreement or after
                        partnership deed registration with respected RoF. The
                        physical copy of the PAN will be received at the
                        registered Business Place only after being dispatched by
                        the Income Tax Department.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How long does it take to register a Partnership Firm in
                        India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The registration of Partnership Firm in India can take
                        12 to 14 working days. However, the issuance of
                        Registration Certificate can take place as per the
                        regulations of the concerned state. The time period for
                        registration of Partnership Firm is subject to
                        Government processing time and that varies for every
                        State.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default PartnershipFirm;
