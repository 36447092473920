import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import logo from "../assets/logoTrans.png";

function Header() {
  const [isMobile, setIsMobile] = useState(false);
  const history = useHistory();
  const redirectHome = () => {
    history.push("/");
  };
  const token = localStorage.getItem("token");
  console.log(token, "token");
  return (
    <div className="site-header">
      <header>
        <div className="container">
          <nav className="navbar">
            <div className="site-logo" style={{ cursor: "pointer" }}>
              <img src={logo} alt="logo" onClick={redirectHome}></img>
            </div>
            <div className="menu-list">
              <ul
                className={isMobile ? "nav-links-mobile" : "nav-links"}
                onClick={() => setIsMobile(false)}
              >
                <li>
                  <Link to="/GettingSetUp">
                    Getting Set Up <i className="fa fa-angle-down"></i>
                  </Link>
                  <div className="submenu">
                    <div className="submenu_container">
                      <div className="row">
                        <div className="sublist col col6">
                          <h4>Domestic Business Setup</h4>
                          <ul>
                            <li>
                              <Link to="/PrivateLimited">
                                Private Limited Company Registration
                              </Link>
                            </li>
                            <li>
                              <Link to="/LimitedLiability">
                                Limited Liability Partnership
                              </Link>
                            </li>
                            <li>
                              <Link to="/OnePersonCompany">
                                One Person Company
                              </Link>
                            </li>
                            <li>
                              <Link to="/SoleProprietorship">
                                Sole Proprietorship
                              </Link>
                            </li>
                            <li>
                              <Link to="/PartnershipFirm">
                                Partnership Firm
                              </Link>
                            </li>
                            <li>
                              <Link to="/IndianSubsidiary">
                                Register an Indian Subsidiary
                              </Link>
                            </li>
                            <li>
                              <Link to="/Section8Company">
                                Section 8 Company Registration
                              </Link>
                            </li>
                            <li>
                              <Link to="/ProducerCompany">
                                Producer Company Registration
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="sublist col col6">
                          <h4>International Business Setup</h4>
                          <ul>
                            <li>
                              <Link to="/USIncorporation">
                                US Incorporation
                              </Link>
                            </li>
                            <li>
                              <Link to="/SingaporeIncorporation">
                                Singapore Incorporation
                              </Link>
                            </li>
                            <li>
                              <Link to="/UKIncorporation">
                                UK Incorporation
                              </Link>
                            </li>
                            <li>
                              <Link to="/NetherlandsIncorporation">
                                Netherlands Incorporation
                              </Link>
                            </li>
                            <li>
                              <Link to="/HongKongIncorporation">
                                Hong Kong Incorporation
                              </Link>
                            </li>
                            <li>
                              <Link to="/DubaiIncorporation">
                                Dubai Incorporation
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to="/ProtectYourBrand">
                    Protect Your Brand <i className="fa fa-angle-down"></i>
                  </Link>
                  <div className="submenu">
                    <div className="submenu_container">
                      <div className="row">
                        <div className="sublist col col4">
                          <h4>Trademark</h4>
                          <ul>
                            <li>
                              <Link to="/TrademarkRegistration">
                                Trademark Registration
                              </Link>
                            </li>
                            <li>
                              <Link to="/TrademarkObjectionReply">
                                Trademark Objection Reply
                              </Link>
                            </li>
                            <li>
                              <Link to="/TrademarkOpposition">
                                Trademark Opposition
                              </Link>
                            </li>
                            <li>
                              <Link to="/TrademarkAssignment">
                                Trademark Assignment
                              </Link>
                            </li>
                            <li>
                              <Link to="/TrademarkRenewal">
                                Trademark Renewal
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="sublist col col4">
                          <h4>Patent</h4>
                          <ul>
                            <li>
                              <Link to="/PatentSearch">Patent Search</Link>
                            </li>
                            <li>
                              <Link to="/ProvisionalPatent">
                                Provisional Patent
                              </Link>
                            </li>
                            <li>
                              <Link to="/PermanentPatent">
                                Permanent Patent
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="sublist col col4">
                          <h4>Copyright</h4>
                          <ul>
                            <li>
                              <Link to="/CopyrightRegistration">
                                Copyright Registration
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to="/StayingCompliant">
                    Staying Compliant <i className="fa fa-angle-down"></i>
                  </Link>
                  <div className="submenu">
                    <div className="submenu_container">
                      <div className="row">
                        <div className="sublist col col3">
                          <h4>Business Filings</h4>
                          <ul>
                            <li>
                              <Link to="/AnnualCompliances">
                                Annual Compliances for Company
                              </Link>
                            </li>
                            <li>
                              <Link to="/AnnualFilingForLLP">
                                Annual Filing for LLP
                              </Link>
                            </li>
                            <li>
                              <Link to="/DIR3KYCFiling">DIR-3 KYC Filings</Link>
                            </li>
                            <li>
                              <Link to="/INC20AFiling">INC-20A Filing</Link>
                            </li>
                            <li>
                              <Link to="/INC22AFiling">INC-22A Filing</Link>
                            </li>
                            <li>
                              <Link to="/NGOCompliance">NGO Compliance</Link>
                            </li>
                            <li>
                              <Link to="/Section8Compliance">
                                Section 8 Compliance
                              </Link>
                            </li>
                            <li>
                              <Link to="/CSR1Filing">CSR-1 Filing</Link>
                            </li>
                            <li>
                              <Link to="/Sec80GSec12A">
                                Sec. 80G & Sec. 12A
                              </Link>
                            </li>
                            <li>
                              <Link to="/NBFCCompliance">NBFC Compliance</Link>
                            </li>
                            <li>
                              <Link to="/GSTFiling">GST Filing</Link>
                            </li>
                            <li>
                              <Link to="/GSTAdvisory">GST Advisory</Link>
                            </li>
                            <li>
                              <Link to="/TDSReturnFiling">
                                TDS Return Filing
                              </Link>
                            </li>
                            <li>
                              <Link to="/IncomeTaxFiling">
                                Income Tax Filing
                              </Link>
                            </li>
                            <li>
                              <Link to="/AccountingandBookkeeping">
                                Accounting and Book Keeping
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="sublist col col3">
                          <h4>Converting Legal Status</h4>
                          <ul>
                            <li>
                              <Link to="/ProprietorshiptoPartnership">
                                Proprietorship to Partnership
                              </Link>
                            </li>
                            <li>
                              <Link to="/ProprietorshiptoLLP">
                                Proprietorship to LLP
                              </Link>
                            </li>
                            <li>
                              <Link to="/ProprietorshiptoPrivateLimitedCompany">
                                Proprietorship to Private Limited Company
                              </Link>
                            </li>
                            <li>
                              <Link to="/ProprietorshiptoOPC">
                                Proprietorship to OPC
                              </Link>
                            </li>
                            <li>
                              <Link to="/PartnershiptoLLP">
                                Partnership to LLP
                              </Link>
                            </li>
                            <li>
                              <Link to="/PartnershiptoPrivateLimitedCompany">
                                Partnership to Private Limited Company
                              </Link>
                            </li>
                            <li>
                              <Link to="/LLPtoPrivateLimitedCompany">
                                LLP to Private Limited Company
                              </Link>
                            </li>
                            <li>
                              <Link to="/OPCtoPrivateLimitedCompany">
                                OPC to Private Limited Company
                              </Link>
                            </li>
                            <li>
                              <Link to="/PrivateLimitedCompanytoLLP">
                                Private Limited company to LLP
                              </Link>
                            </li>
                            <li>
                              <Link to="/PrivateCompanytoPublicCompany">
                                Private company to Public company
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="sublist col col3">
                          <h4>Event Based Filings</h4>
                          <ul>
                            <li>
                              <Link to="/AddRemoveDirectorCompany">
                                Add or Remove a Director (Company)
                              </Link>
                            </li>
                            <li>
                              <Link to="/AddRemovePartnerLLP">
                                Add or Remove a Partner (LLP)
                              </Link>
                            </li>
                            <li>
                              <Link to="/ChangeBusinessActivity">
                                Change Business Activity
                              </Link>
                            </li>
                            <li>
                              <Link to="/ChangeRegisteredOffice">
                                Change Registered Office
                              </Link>
                            </li>
                            <li>
                              <Link to="/ChangeCompanyName">
                                Change Company Name
                              </Link>
                            </li>
                            <li>
                              <Link to="/ChangeLLPAgreement">
                                Change LLP Agreement
                              </Link>
                            </li>
                            <li>
                              <Link to="/ChangePartnershipDeed">
                                Change Partnership Deed
                              </Link>
                            </li>
                            <li>
                              <Link to="/IncreaseAuthorisedShareCapital">
                                Increase Authorised Share Capital
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="sublist col col3">
                          <h4>Closure of Business</h4>
                          <ul>
                            <li>
                              <Link to="/ClosePrivateLimitedCompany">
                                Close a Private Limited Company
                              </Link>
                            </li>
                            <li>
                              <Link to="/CloseLimitedLiabilityPartnership">
                                Close a Limited Liability Partnership
                              </Link>
                            </li>
                            <li>
                              <Link to="/CloseOnePersonCompany">
                                Close a One Person Company
                              </Link>
                            </li>
                            <li>
                              <Link to="/DissolvePartnershipFirm">
                                Dissolve a Partnership Firm
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to="/GrowYourBusiness">
                    Grow Your Business <i className="fa fa-angle-down"></i>
                  </Link>
                  <div className="submenu">
                    <div className="submenu_container">
                      <div className="row">
                        <div className="sublist col col12">
                          <ul>
                            <li>
                              <Link to="/Fundraising">Fundraising</Link>
                            </li>
                            <li>
                              <Link to="/PitchDeck">Pitch Deck</Link>
                            </li>
                            <li>
                              <Link to="/BusinessPlan">Business Plan</Link>
                            </li>
                            <li>
                              <Link to="/Valuation">Valuation</Link>
                            </li>
                            <li>
                              <Link to="/FinancialProjections">
                                Financial Projections
                              </Link>
                            </li>
                            <li>
                              <Link to="/BankProjectReport">
                                Bank Project Report
                              </Link>
                            </li>
                            <li>
                              <Link to="/BusinessLoans">Business Loans</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to="/LegalFormsAndAgreements">
                    Legal Forms & Agreements{" "}
                    <i className="fa fa-angle-down"></i>
                  </Link>
                  <div className="submenu">
                    <div className="submenu_container">
                      <div className="row">
                        <div className="sublist col col12">
                          <ul>
                            <li>
                              <Link to="/ServiceLevelAgreement">
                                Service Level Agreement
                              </Link>
                            </li>
                            <li>
                              <Link to="/SharePurchaseAgreement">
                                Share Purchase Agreement
                              </Link>
                            </li>
                            <li>
                              <Link to="/FranchiseAgreement">
                                Franchise Agreement
                              </Link>
                            </li>
                            <li>
                              <Link to="/ShareholdersAgreement">
                                Shareholder’s Agreement
                              </Link>
                            </li>
                            <li>
                              <Link to="/TermSheet">Term Sheet</Link>
                            </li>
                            <li>
                              <Link to="/BoardResolutions">
                                Board Resolutions
                              </Link>
                            </li>
                            <li>
                              <Link to="/NonDisclosuresAgreement">
                                Non-Disclosures Agreement{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to="/NonCompeteAgreement">
                                Non-Compete Agreement{" "}
                              </Link>
                            </li>
                            <li>
                              <Link to="/MemorandumofUnderstanding">
                                Memorandum of Understanding (MoU){" "}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link to="/LicensesRegistration">
                    Licenses & Registration <i className="fa fa-angle-down"></i>
                  </Link>
                  <div className="submenu">
                    <div className="submenu_container">
                      <div className="row">
                        <div className="sublist col col12">
                          <ul>
                            <li>
                              <Link to="/GSTRegistration">
                                GST Registration
                              </Link>
                            </li>
                            <li>
                              <Link to="/LUTunderGST">LUT under GST</Link>
                            </li>
                            <li>
                              <Link to="/FSSAILicense">FSSAI License</Link>
                            </li>
                            <li>
                              <Link to="/ImportExportCodeRegistration">
                                Import Export Code Registration
                              </Link>
                            </li>
                            <li>
                              <Link to="/StartupIndiaRegistration">
                                Startup India Registration
                              </Link>
                            </li>
                            <li>
                              <Link>MSME (SSI) Registration</Link>
                            </li>
                            <li>
                              <Link>Shop & Establishment Registration</Link>
                            </li>
                            <li>
                              <Link>Professional Tax Registration</Link>
                            </li>
                            <li>
                              <Link>PAN Application</Link>
                            </li>
                            <li>
                              <Link>TAN Application</Link>
                            </li>
                            <li>
                              <Link>FSSAI Registration</Link>
                            </li>
                            <li>
                              <Link>ESI Registration</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <button
                className="mobile-menu-icon"
                onClick={() => setIsMobile(!isMobile)}
              >
                {isMobile ? (
                  <i className="fa fa-times"></i>
                ) : (
                  <i className="fa fa-bars"></i>
                )}
              </button>
            </div>
            <div className="menu-right">
              <ul>
                <li>
                  <Link>
                    <i className="fa fa-user"></i>
                  </Link>
                </li>
                {!token ? (
                  <li>
                    <Link to="/Login" className="login_btn">
                      Login/Signup
                    </Link>
                  </li>
                ) : (
                  <li onClick={()=>localStorage.removeItem('token')}>
                    <Link to="/Login" className="login_btn">
                      LogOut
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}
export default Header;
