import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function PatentSearch() {
  const pageName = "PatentSearch";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Patent Search</h2>
                  <p>
                    Check if your patent is registrable through Patent Search
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is Patent Search and what importance does it hold?</h2>
                <div className="bar"></div>
                <p>
                  Before applying for the patent, a patent search is the first
                  thing that is to be done. A patent search’s main purpose is to
                  determine how different an invention is from what already
                  exists.
                </p>
                <p>
                  The patent search provides an idea about the information
                  available in the public domain related to the proposed
                  invention. A patent search will help identify and compare the
                  similar or relevant patent or non-patent document to decide
                  about patentability of the proposed invention.
                </p>
                <p>
                  There are different types of patent search but when we are
                  performing with the purpose of filing patent, it is called as
                  a patentability search. Patentability search is a search of
                  existing patents and other available documents to find out the
                  closest existing things of the proposed invention. This patent
                  literatures and other available documents are referred to as
                  “prior art”.
                </p>
                <p>
                  During patent examination, the patent office will evaluate the
                  proposed invention, mainly patent claims for its novelty,
                  inventive step and industrial application. If it satisfies
                  all, then only patent is allowed. Therefore performing a
                  patent search will help understand the closest prior art and
                  defend or justify any objections raised by the patent office
                  during the examination.
                </p>
                <p>
                  The patent search’s importance is that the inventor/applicant
                  can identify the closest prior art and can determine the
                  patentability of their invention and identify the most
                  differentiating features of their invention in comparison to
                  prior arts.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Trademark Renewal in India</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Determining the Scope of Patent</Tab>
                <Tab>Provide an idea to eliminate future objections</Tab>
                <Tab>Brand Name Security</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Determining the Scope of Patent</h2>
                      <p>
                        To register a Patent, the person needs to invest time
                        and money. To save time and money, it is advisable to
                        conduct the patent search. From this process, the person
                        who wants to apply for the patent gets an idea if their
                        invention is really a new one or is already exists in
                        public domain.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Provide an idea to eliminate future objections</h2>
                      <p>
                        After filing of patent and, the Patent Office examine
                        the proposed invention for its novelty, inventive step
                        and industrial application. This examination may found
                        some closest prior arts of the proposed invention during
                        the patent search in India. If the patent search is
                        conducted than it is easy to show technical differences
                        in the reply against the cited prior arts.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Patent Specification drafting gets easy</h2>
                      <p>
                        The patent search helps the applicant and the patent
                        agent in drafting the claims and specifications of the
                        patent. This is to ensure that the claimed features of
                        the proposed invention are not overlapping with any
                        prior arts. If the patent search is conducted before,
                        then it is easy to draft the strong specification which
                        can eliminate the chances of overlapping of the
                        invention with prior arts disclosing similar invention.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section patent_search">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for conducting Patent Search online</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>Details of the applicant</h2>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Details of the invention</h2>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Drawings (if any) for better clarity</h2>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process for Trademark Renewal online</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1-2 - Collection</Tab>
                <Tab>Day 3-13 - Execution</Tab>
                <Tab>Day 14-15 - Submission</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>
                    Discussion and collection of Information about the invention
                  </li>
                  <li>Submit required documents</li>
                  <li>
                    Detailed information about novelty factor of the invention &
                    its uses
                  </li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Extensive patent database search</li>
                  <li>Thorough review and comparison of related work</li>
                  <li>Assessment of the invention</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>
                    Submission of the patent search report with information
                    about any existing prior art
                  </li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is it mandatory to conduct a patentability search before
                        filing for a patent?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A professional patent search is not mandatory but it is
                        always better to get it done before applying for patent
                        registration. It saves a lot of time, money, and labor.
                        The patent registration process involves a lot of time
                        and money both, therefore, it is better to be aware of
                        the invention’s patentability and scope.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is it safe to provide information about my invention to
                        a patent search company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, disclosing any information about your invention is
                        risky, as a patent cannot be registered once it becomes
                        public. On the other hand, prior art search is also very
                        important and it is always preferable to consult a
                        professional regarding it. Therefore, because of the
                        crucial nature, it’s better to consult a trustworthy
                        company or a person and ask them to sign a
                        non-disclosure agreement before providing any
                        information regarding your patent to them. LegalWiz.in
                        can be your trustworthy partner for registering patents.
                        For us, confidentiality is a matter of our business
                        integrity.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What if my idea is already patented?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        If the idea of the invention is already patented, one
                        can review the granted patent and identify the scope of
                        improvement to have improved invention which can be
                        patentable in light of patented invention. Thus, Patent
                        Search helps to modify the patent idea and make it
                        patentable.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Does the patent search include only patents?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Every prior publication puts an invention at risk of
                        being declined as a patent. Each and every information
                        available in public domain in any form is considered as
                        prior art. The patent search reports may also include
                        web pages, scientific articles, products, etc.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Does the patent search guarantees that the invention
                        will not get sued in the future?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, the purpose of a patent search is to evaluate if any
                        invention available in the public domain which is
                        closest or similar concerning the proposed invention.
                        The patent search report does not claim to test if your
                        invention’s components are protected by other patents.
                        For this, you need to perform a freedom to operate
                        search.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I do my own patent search on the Internet?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        YES. You can do a preliminary search of your invention
                        on the internet. However, it is always advisable to take
                        consultation of professionals.
                      </p>
                      <p>
                        As a disclaimer performing patent searches, whether
                        through internet search engines or other databases
                        requires the use of keyword searching, which is an art
                        that takes a lot of experience to do well. As such
                        result and accuracy will vary with the experience of the
                        searcher.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default PatentSearch;
