import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function TrademarkOpposition() {
  const pageName = "TrademarkOpposition";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Trademark Opposition</h2>
                  <p>
                    File Trademark Opposition to secure your brands’ exclusivity
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
              <div className="col col6">
                <h2>What is a Trademark Opposition?</h2>
                <div className="bar"></div>
                <p>
                  Trademark Opposition in India comes at a stage after the
                  registrar has approved the trademark application on the
                  distinctiveness factor and publishes the trademark in the
                  journal for the third-party opposition. Anyone can oppose the
                  published trademark within a period of 3 months which can be
                  extended for a month more (3+1); beginning from the day it was
                  first published. If the mark is opposed, an opposition
                  proceeding is initiated. After which, both the parties
                  involved need to come to a conclusion and the decision is
                  taken. The decision whether the mark can be registered or
                  abandoned would be made. There is no restriction on filing an
                  opposition. Anyone who believes that the published mark might
                  create confusion among the public can file for the opposition
                  while the onus of defending the trademark lies in the hands of
                  trademark registrant.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of filing Trademark Opposition</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Highly effective remedy for TM owner</Tab>
                <Tab>Public consultation</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Highly effective remedy for TM owner</h2>
                      <p>
                        Trademark opposition plays a very eminent role as it is
                        a chance for the registered trademark owners to stop any
                        other mark that can hinder/ dilute their brand or cause
                        any possible confusion in the mar
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Public consultation</h2>
                      <p>
                        As the brand is created through the public popularity
                        and demand, it is very important to consult the public
                        for approval about the registrability of the applied
                        trademark.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required to file a Trademark Opposition</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Details of applicant</h2>
                    <p>
                      Name, Address, Nationality, etc of the applicant. Body
                      corporates/other categories need to provide with
                      registration certificate
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Power of Attorney</h2>
                    <p>
                      It allows the attorney to file the trademark opposition on
                      your behalf
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>Affidavit</h2>
                    <p>
                      Affidavit with the basic information about the trademark
                      and its user date and proof of use
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Details about the opposed mark</h2>
                    <p>
                      Detailed information about the mark against which the
                      opposition is to be filed, i.e. name and basic grounds for
                      filing the opposition
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Trademark Opposition Process</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>DAY 1 - 2 Collection</Tab>
                <Tab>DAY 3 - 7 Execution</Tab>
                <Tab>DAY 8 - 9 Submission</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and collection of basic Information</li>
                  <li>Provide required documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of Opposition application by Professionals</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Filing of online TM-O application</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is a notice of opposition?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Any individual who thinks his/her brand name or
                        reputation of the company may be damaged in any way by
                        the registration of a certain mark may choose to oppose
                        such registration by filing the notice of opposition.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the time period for filing the Notice of
                        Opposition?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The notice of opposition can be filed within three
                        months, extendable by one month (3+1) from the date on
                        which the trademark was published in the Trade Marks
                        Journal. If the notice of opposition is filed after
                        three months but before the expiry of four months, then
                        it must be accompanied by a request for an extension by
                        one month, giving sufficient reasons for the delay in
                        filing the opposition
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who can file the Notice of Opposition?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In India, anyone can file for the Trademark Opposition.
                        Usually, it is initiated by a person who is the owner of
                        an earlier trademark or a mark that deals with similar
                        goods/services; or that has a possibility of creating
                        confusion or harm an existing mark can file an
                        opposition, even if its own mark is not registered.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What the plaintiff must establish in a passing off
                        action?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In a case where the notice of opposition is filed on the
                        use of the mark, it is essential to show that the
                        disputed mark or logo has become distinctive and is
                        recognized that of the plaintiff’s goods and services.
                        Hence, any mark that can cause confusion among the
                        consumers cannot be registered as it would harm the
                        already existing users’ business.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        My mark is not registered in India; can I file an
                        opposition based on my pending application and use?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Through a common law of rights of passing off, one can
                        file an opposition even if the Trademark application is
                        pending or already in use if not registered. This law
                        prevents any person from selling his goods/services as
                        that of another and benefiting from it.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the next stage once I file the Notice of
                        Opposition?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The next step is taken by the Registry. The registrar
                        serves the copy to the opponent so that they can file a
                        counter statement. This has to be filed within 2 months
                        of the receipt of the notice of opposition. If it is not
                        filed it is believed to be abandoned.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default TrademarkOpposition;
