import * as React from "react";
import onepersonimg from "../assets/One-Person-Company.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function USIncorporation() {
  const pageName = "USIncorporation";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>US Company Incorporation</h2>
                  <p>
                    The simplest way for anyone to launch and run a US business.
                    Incorporate your company, access one-click growth, stay
                    compliant, and manage everything your business needs — all
                    online, from anywhere.
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Overview</h2>
                <div className="bar"></div>
                <p>
                  The USA is considered to be a world-class economy and first
                  business hub. Hence, setting up a company in the USA gives
                  global class benefit. Further being a developed nation, rules
                  and regulations for Company Registration in USA are much
                  regularized, and corporate tax rates are much lower.
                </p>
                <p>
                  Every state in the US is governed by its state laws and
                  regulations which vastly differs from one another. Delaware,
                  Wyoming and Nevada, in particular, provide the most
                  complimenting business and taxation laws which makes it
                  mainstream for companies to incorporate. In particular,
                  Delaware is said to have no state sales tax and the state’s
                  franchise tax for small US businesses is very meagre.
                  Additionally, there is no need for non-residents to pay
                  separate corporate income tax in Delaware.
                </p>
                <p>
                  Considering the most options, companies are set up in America
                  to address three situations of business opportunities:
                </p>
                <ol>
                  <li>
                    The company would be set up in the USA, but the operation
                    would be carried out in India.
                  </li>
                  <li>
                    The company is set up in the USA for marketing and brand
                    building exercise and the rest of operation is carried out
                    in India.
                  </li>
                  <li>
                    The company would be a subsidiary of an Indian business
                    firm, but would conduct full -fledged operations in the USA.
                  </li>
                </ol>
                <p>
                  There are two types of companies that Indians may register in
                  the USA. One is C- Corporation and the other is LLC.
                </p>
                <p>
                  <b>C- Corporation (closely held corporation): </b>
                </p>
                <ol>
                  <li>
                    C-Corporations are an independent legal entity separate from
                    the people who own, control and manage it.
                  </li>
                  <li>
                    Due to this recognition as an individual entity, it is
                    viewed as a legal “person” in the view of tax laws.
                  </li>
                  <li>
                    It can be engaged in business and contracts, can initiate
                    lawsuits and itself be sued.
                  </li>
                </ol>
                <p>
                  <b>LLC (Limited Liability Corporation): </b>
                </p>
                <ol>
                  <li>
                    LLC is a type of entity that has features of both a
                    Corporation and a Partnership.
                  </li>
                  <li>
                    LLC provides the owners with limited liability protection in
                    case of a lawsuit or bankruptcy.
                  </li>
                  <li>
                    LLCs are also operationally flexible and the compliance
                    requirements for a LLC are simple.
                  </li>
                </ol>
              </div>
              <div className="col col6">
                <img src={onepersonimg} alt="LLP-Registration" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Starting a Company in USA</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>New Markets</Tab>
                <Tab>Law and Regulations</Tab>
                <Tab>Low Corporate Tax</Tab>
                <Tab>Skilled Man Power</Tab>
                <Tab>Business Hub</Tab>
                <Tab>Techno Friendly</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        All 50 states in the USA are interested in increasing
                        the business environment and many cities encourage them
                        to invest in their communities. Hence you can have
                        virtually unlimited choices for where to set up your
                        business and can choose a geographical and climate zone
                        that suits you.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      src={fundraising}
                      style={{ height: "250px" }}
                      alt="fund-raising-image"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      src={legal}
                      style={{ height: "250px" }}
                      alt="legal-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        Businesses in the USA are treated the same, whether
                        foreign or domestically owned. This gives you the
                        benefit of all the laws relevant to acquiring a business
                        or transferring capital. It puts you on an equal
                        standing with your opponent if there is ever a business
                        dispute. The laws & regulations are the same for all
                        businesses.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        Many states and some cities in the USA offer financial
                        incentives to foreign investors who will establish their
                        business in a specific location. Some incentives are in
                        the form of tax credits and the country has recently
                        lowered commercial real estate taxes for foreign
                        investors. Also, corporate tax rates are much lower.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      src={legal}
                      style={{ height: "250px" }}
                      alt="legal-image"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      src={legal}
                      style={{ height: "250px" }}
                      alt="legal-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        No matter what your business in the USA is, you will
                        find hundreds of skilled workers just waiting for the
                        opportunity you can offer them when you begin hiring
                        workers for your new business. As there are community
                        colleges and technical schools all committed to training
                        students to enter the workforce at all levels.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        The USA is worldwide known as a great business hub.
                        Every day foreign nationals are willing to set up US
                        business from larger enterprises to a small startup. The
                        effective way to enlarge business all over the world is
                        to register in the US marketplace key success factors
                        for businesses around the world.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      src={legal}
                      style={{ height: "250px" }}
                      alt="legal-image"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      src={legal}
                      style={{ height: "250px" }}
                      alt="legal-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        Access to advanced technology, which the U.S. is known
                        for, increases the desirability of the U.S. as a place
                        to do business. Many foreign investors are starting
                        companies in the U.S. just to have access to advanced
                        technological innovations which will enhance their
                        business production and world -wide communication.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required to registration</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Company Details</h2>
                    <p>
                      Company details like name, state in which company needs to
                      be incorporated, Business Activity and email for
                      incorporation application.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>Personal Details</h2>
                    <p>
                      Business Member/Director/Partner(s) Name, Address, Mobile
                      Number, Email Address.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN or Tax ID</h2>
                    <p>
                      Existing Foreign Tax Id Number or Pan Card of Shareholders
                      and Directors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Social Security Number of US Director </h2>
                    <p>SSN of US Director if any</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Passport Copy</h2>
                    <p>Uncropped Passport copy of all Directors/Partners</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to Register a Company in US</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Step 1</Tab>
                <Tab>Step 2</Tab>
                <Tab>Step 3</Tab>
                <Tab>Step 4</Tab>
                <Tab>Step 5</Tab>
                <Tab>Step 6</Tab>
                <Tab>Step 7</Tab>
                <Tab>Step 8</Tab>
                <Tab>Step 9</Tab>
              </TabList>

              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  Determine the type of business entity the client wishes to
                  incorporate in the US and provide the necessary information.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  Select the state of incorporation and register the business
                  with the relevant state authority.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  File the required documents and pay the filing fees.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  Obtain the Employer Identification Number (EIN) from the
                  Internal Revenue Service (IRS).
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  Register for any applicable state or local taxes, licenses,
                  and permits.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  Prepare the company’s bylaws and other organizational
                  documents.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  Notify relevant federal, state, and local agencies of the
                  company’s formation.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  Establish a corporate bank account and obtain any necessary
                  insurance.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  Provide the client with the relevant documents and
                  information.
                </p>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Do I require to be a US citizen to register a company in
                        the USA?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The US greets founders from most of the countries
                        without any difference on the ground whether they are US
                        citizens or foreigners. Laws for business establishment,
                        taxation & other corporate agreements are equal for
                        everyone in the USA.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is it mandatory for opening a bank account in the USA
                        after incorporating a company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, it is compulsory to open a bank account in the USA
                        as well as the in-home country in case you want to make
                        transactions or receive money from the USA. It is always
                        advisable to open an account of a bank which has
                        branches in the home country.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>What is EIN?</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        EIN is Employer identification number or US internal
                        revenue services generate a tax ID number. Employer
                        identification number (EIN) is a unique identification
                        number allotted to a company incorporated, which is
                        essential for post-registration procedure or taxation
                        purpose.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How many people are required to open a company in the
                        USA?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Only one person is needed to open a company either LLC
                        or Corporation (Inc) in the USA.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What fees will be charged for opening a bank account?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        There is no minimum deposit required to open a bank
                        account.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default USIncorporation;
