import * as React from "react";
import onepersonimg from "../assets/One-Person-Company.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import management from "../assets/management.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function SingaporeIncorporation() {
  const pageName = "SingaporeIncorporation";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Singapore Company Incorporation</h2>
                  <p>
                    Get Benefit from the robust economy, enjoy low tax rates,
                    repatriate unlimited profits or capital, expand Business
                    Globally, and much more through Singapore Company
                    Registration.
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Overview</h2>
                <div className="bar"></div>
                <p>
                  Singapore is regarded as one of the greatest nations in the
                  world to do business due to its robust yet welcoming
                  investment and trade policies. The proprietors of a business
                  must register their business. Forming a company in Singapore
                  is straightforward and affordable.
                </p>
                <p>
                  The Ease of Doing Business Report 2020, which acts as a
                  standard for foreigners and locals interested in starting a
                  firm in Singapore, ranks Singapore second. Foreign corporate
                  entities or foreign people are not authorised to register as
                  businesses in Singapore under Singaporean law. They must hire
                  a local professional business to register their corporation in
                  Singapore.
                </p>
              </div>
              <div className="col col6">
                <img src={onepersonimg} alt="LLP-Registration" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Starting a Company in Singapore</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>New Markets</Tab>
                <Tab>Law and Regulations</Tab>
                <Tab>Low Corporate Tax</Tab>
                <Tab>Business Hub with Startup Ecosystem</Tab>
                <Tab>Techno Friendly</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        Singapore is increasing the business environment and
                        encourage businesses to invest in their communities.
                        Hence you can have virtually unlimited choices for set
                        up your business and can choose a geographical and
                        climate zone that suits you.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      src={fundraising}
                      alt="fund-raising-image"
                      style={{ height: "250px" }}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        Businesses in Singapore are treated the same, whether
                        foreign or domestically owned. This gives you the
                        benefit of all the laws relevant to acquiring a business
                        or transferring capital. It puts you on an equal
                        standing with your opponent if there is ever a business
                        dispute. The laws & regulations are the same for all
                        businesses.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      src={fundraising}
                      alt="fund-raising-image"
                      style={{ height: "250px" }}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      src={legal}
                      alt="legal-image"
                      style={{ height: "250px" }}
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        While high corporation taxes in other areas of the globe
                        may be a pain at times for many firms, Singapore has
                        created an appealing tax structure with corporate tax
                        rates ranging from 0% to 17 per cent.
                      </p>
                      <p>
                        There are other advantages in addition to the low
                        corporation tax rates, such as:
                      </p>
                      <ol>
                        <li>Tax-free dividends</li>
                        <li>No tax on capital gains</li>
                        <li>Tax credit pooling</li>
                        <li>Unilateral tax credit</li>
                        <li>
                          Reduced withholding tax on foreign-sourced income
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      src={legal}
                      alt="legal-image"
                      style={{ height: "250px" }}
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        Singapore, which was named the World’s Freest Economy in
                        2020, has continuously demonstrated its business
                        destination for numerous startups and developing
                        businesses.
                      </p>
                      <p>
                        Starting a business in Singapore offers an extra benefit
                        in government support, making the early process less
                        complicated.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      src={legal}
                      alt="legal-image"
                      style={{ height: "250px" }}
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        Access to advanced technology, there is increasing
                        desirability for Singapore as a place to do business.
                        Many foreign investors are starting companies in
                        Singapore just to have access to advanced technological
                        innovations which will enhance their business production
                        and world -wide communication.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required to registration</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Company Details</h2>
                    <p>
                      Company details like name, state in which company needs to
                      be incorporated, Business Activity and email for
                      incorporation application.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>Personal Details</h2>
                    <p>
                      Business Member/Director/Partner(s) Name, Address, Mobile
                      Number, Email Address.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN or Tax ID</h2>
                    <p>
                      Existing Foreign Tax Id Number or Pan Card of Shareholders
                      and Directors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Singapore Identity Card of SG Director</h2>
                    <p>Copy of Singapore identity card of SG Director if any</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Passport Copy</h2>
                    <p>Uncropped Passport copy of all Directors/Partners</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to Register a Company in Singapore</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Step 1</Tab>
                <Tab>Stpe 2</Tab>
                <Tab>Step 3</Tab>
                <Tab>Step 4</Tab>
                <Tab>Step 5</Tab>
                <Tab>Step 6</Tab>
                <Tab>Step 7</Tab>
                <Tab>Step 8</Tab>
              </TabList>

              <TabPanel>
                <p className="text-center">
                  Gather the required documents such as passport copies of all
                  directors, shareholders, and company secretary.
                </p>
              </TabPanel>
              <TabPanel>
                <p className="text-center">
                  Choose a name for the company and confirm its availability by
                  searching the Accounting and Corporate Regulatory Authority
                  (ACRA) database.
                </p>
              </TabPanel>
              <TabPanel>
                <p className="text-center">
                  Obtain approval for the company name from ACRA.
                </p>
              </TabPanel>
              <TabPanel>
                <p className="text-center">
                  Prepare the Memorandum and Articles of Association (MAA).
                </p>
              </TabPanel>
              <TabPanel>
                <p className="text-center">
                  Draft the application for the registration of the company.
                </p>
              </TabPanel>
              <TabPanel>
                <p className="text-center">
                  Submit the application along with the required documents to
                  the ACRA.
                </p>
              </TabPanel>
              <TabPanel>
                <p className="text-center">Pay the registration fees.</p>
              </TabPanel>
              <TabPanel>
                <p className="text-center">
                  Obtain the Certificate of Incorporation from the ACRA.
                </p>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Which Business Structures Are Available for You to
                        Register in Singapore?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <h3>1, Private Limited Company</h3>
                      <p>
                        Private Limited Company (PLC) is a form that enables up
                        to 50 shareholders and gives specific tax deductions and
                        incentives, making it the most popular option.
                      </p>
                      <h3>2, Limited Liability Company </h3>
                      <p>
                        An LLP is a flexible option for business owners who wish
                        to form a partnership yet keep their legal identities
                        separate.
                      </p>
                      <h3>3, Subsidiary Company</h3>
                      <p>
                        A subsidiary company is a private limited company that
                        has its own legal identity and is independent of the
                        parent company. The majority shareholder can be based
                        overseas or locally
                      </p>
                      <h3>4, Sole Proprietorship</h3>
                      <p>
                        In a Sole proprietorship, just one owner is liable for
                        the company’s assets and liabilities.
                      </p>
                      <h3>5, Branch Officey</h3>
                      <p>
                        Foreign firms can register a branch office in Singapore
                        and operate as non-residents. It is an extension of the
                        parent firm and is a legal entity in its own right.
                        Branch offices, on the other hand, are not eligible for
                        any tax benefits or deductions.
                      </p>
                      <h3>6, Representative Office</h3>
                      <p>
                        A representative office can only be set up for a maximum
                        of three years, making it a temporary structure. Foreign
                        firms are only authorised to investigate the Singapore
                        market under this setup, and they are not allowed to
                        sell products or services to earn income.
                      </p>
                      <h3>7, Variable Capital Company</h3>
                      <p>
                        The VCC, which was launched in early 2020, is a
                        game-changing investment vehicle for Singapore. The VCC
                        is an upcoming popular vehicle to register because of
                        its great versatility.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the criteria for incorporating a Private
                        Limited Company in Singapore?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ol>
                        <li>Min. 1 Shareholder</li>
                        <li>Min. 1 Resident Director</li>
                        <li>Min. 1 Local Company Secretary</li>
                        <li>Min. S$1 Paid-up Capital</li>
                        <li>Local Registered Address</li>
                        <li>
                          It’s important to note that you can form a private
                          limited business as a foreigner without relocating to
                          Singapore. However, to form a Singapore business, you
                          will need the help of a corporate service provider in
                          any case.
                        </li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default SingaporeIncorporation;
