import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import management from "../assets/management.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function PermanentPatent() {
  const pageName = "PermanentPatent";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Permanent Patent</h2>
                  <p>
                    Secure exclusive rights over your invention with Patent
                    Registration
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is a Complete Patent Application?</h2>
                <div className="bar"></div>
                <p>
                  The complete patent is an exclusive legal right of an
                  inventor, giving him/her the legal authority to exclude others
                  from making, using, selling, offering for selling or importing
                  a particular invention. This right vests in the patent for 20
                  years, from the date of filing. The validity of 20 years is
                  set to inspire people to create more or to add to their
                  innovation. The holder of the patent is entitled to sue any
                  person who violates the patent and chooses who to sell, grant
                  or licence the patent.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Patent Registration</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Exclusive authority over your invention</Tab>
                <Tab>Sue the third party in case of infringement</Tab>
                <Tab>Increases your credibility in the market</Tab>
                <Tab>Monetary Returns</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Exclusive authority over your invention</h2>
                      <p>
                        The patent registration gives the patent owner the right
                        to prohibit others without the permission to make, use,
                        sell, offer or importing patented inventions to others
                        for profit purposes. On the other hand, the patent owner
                        may determine who can use it according to negotiated
                        terms and thus hold rivals at a disadvantage.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Sue the third party in case of infringement</h2>
                      <p>
                        It gives the patent holder the legal right to file a
                        case of infringement if anyone tries to exploit the
                        patent without the owners’ permission. The patentee,
                        his/her assignee, licensee, or agent has the right to
                        institute a civil suit in a court not lower than
                        District Court in a case of infringement.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Increases your credibility in the market</h2>
                      <p>
                        If an invention is patented, it indirectly improves the
                        brand value and can allow the company to charge the
                        consumers a higher price. Especially if a product which
                        is extremely useful on the market helps to extend the
                        patent proprietor ‘s valuation.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Monetary Returns</h2>
                      <p>
                        The patent is a great asset and can to extract way more
                        significant monetary benefits than any other
                        intellectual property if managed intelligently. The
                        owner can license, assign, or sell its patent whenever
                        needed. Many pharmaceutical companies have made great
                        profits through their patented products through
                        licensing, assigning, etc.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section patent_search">
          <div className="container">
            <div className="section-title">
              <h2>
                Documents required for filing a Permanent Patent Application
              </h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>Title</h2>
                    <p>Title of the invention</p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Applicants Information</h2>
                    <p>
                      Name, address, contact details and nationality of each
                      applicant for the patent.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Description of the Invention</h2>
                    <p>
                      Detailed description of the invention and details about
                      what the patentee wants to claim out of the invention
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Technical aspects of the invention</h2>
                    <p>
                      Technical details about the invention and drawings along
                      with copy of Provisional patent (if filed)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>DSC of Applicant</h2>
                    <p>
                      Dsc of the applicant, if the applicant does not have DSC,
                      it can be provided by LegalWiz with an additional charge
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>MSME or StartUp India Certificate (If any)</h2>
                    <p>
                      These documents are required for procuring subsidized cost
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process File Permanent Patent</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1-8 - Collection</Tab>
                <Tab>Day 9-20 - Execution</Tab>
                <Tab>Day 24 - Submission</Tab>
                <Tab>
                  Day 25 - Filing of the Permanent patent application complete
                </Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>
                    Discussion and collection of basic Information and
                    provisional application (if any)
                  </li>
                  <li>
                    Provide required documents about the complete patent
                    application, detailed information about examination of the
                    invention & its uses, with well-defined claims.
                  </li>
                  <li>Finalize the terms and conditions</li>
                  <li>Finalize the type of agreement</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of documents by professionals</li>
                  <li>Drafting of Permanent patent application</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Filing of online Form-1</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>
                    The whole process would be complete once the government
                  </li>
                  <li>
                    approval is received , you will receive a receipt from
                    government (patent office) with application number, title of
                    invention and date of filing
                  </li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Why should one patent the invention?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The patent filing is important to possess exclusive
                        rights over the invention. If one doesn’t protect it
                        under the law, anyone can exploit it commercially once
                        available to the public. To restrict others from
                        utilizing, selling, or making copies, the inventor must
                        apply for a complete patent.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Should I apply for a patent in India in my name or in my
                        company name?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        If you are an employee, then usually all the
                        intellectual property generated during your course of
                        employment would belong to the organization or the
                        company you are working for. If there has been no such
                        agreement you can apply for the patent in your nam, but
                        it is advisable to take a No Objection Certificate (NOC)
                        from your employer or Institute if you have used the
                        resources of your employer or institute to avoid any
                        future disputes.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can a published or disclosed invention be patented?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, once the invention is in the public domain, it
                        cannot be patented anymore. Inventors should not
                        disclose their inventions before filing the patent
                        application online or offline. However, under certain
                        circumstances, the Patents Act provides a period of 12
                        months for filing of patent application from the date of
                        its publication in a journal or its public display in an
                        exhibition organized by the Government or disclosure
                        before any learned society or published by the applicant
                        for the same author and same inventor only.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the criteria of for patentability?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        An invention needs to have these three main qualities to
                        be patentable:
                        <br />
                        i) Novelty – invention should be new; and
                        <br />
                        ii) Inventiveness (Non-obviousness) – needs to have an
                        inventive step that makes the invention unique; and
                        <br />
                        iii) Industrial utility / Usefulness – it should not be
                        a mere prototype but it should be working and has to
                        have some use.
                        <br />
                        iv) It should not attract the provisions of section 3
                        and 4 of the Patents Act 1970 which describes
                        non-patentable invention in India.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is it mandatory to always file a provisional patent
                        before filing the complete specification?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Filing the provisional specification is an optional
                        step, if you already have complete information about
                        your invention, then you can directly go for complete
                        specification, there is no requirement to file the
                        provisional application for patent.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is the patent valid outside India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A patent filed in India is valid only in India. To
                        register a patent in other countries, separate patents
                        should be filed in the respective countries. No patent
                        is global.
                      </p>
                      <p>
                        However, filing an application in India enables the
                        applicant to file a corresponding application for the
                        same invention in convention countries or under PCT,
                        within or before the expiry of twelve months from the
                        filing date in India. Patents should be obtained in each
                        country where the applicant requires protection of his
                        invention.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default PermanentPatent;
