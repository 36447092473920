import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function ImportExportCodeRegistration() {
  const pageName = "ImportExportCodeRegistration";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Apply for Import Export Code (IEC) Registration</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is Importer Exporter Code?</h2>
                <div className="bar"></div>
                <p>
                  Considering the recent trends in the business sector, a
                  gradual shift of businesses is observed from the local market
                  to the international market. To spread reach and conquer the
                  international market, the import and export activities are
                  undertaken which further requires the Import Export Code.
                </p>
                <p>
                  Import Export Code is a 10 digit unique code allotted to the
                  business entities that allow imports and exports. The code is
                  allotted by the Directorate General of Foreign Trade (DGFT) to
                  the applicants.
                </p>
                <p>
                  Register for IEC code online comes with one-time registration
                  and lifetime validity. The process for import export code
                  registration has become simpler with online IEC application
                  supported by a few documents.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Importer Exporter Code</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Global Markets at Fingertips</Tab>
                <Tab>Simple Processing</Tab>
                <Tab>Compliance-free</Tab>
                <Tab>Government benefits</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Global Markets at Fingertips</h2>
                      <p>
                        Unless the entity holds IEC, it is not allowed to import
                        or export goods/services into the foreign market. It is
                        a key to enter the international market and widening the
                        scope of business. With IEC, one can take their products
                        or services to the global market and expand business
                        with ease.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Simple Processing</h2>
                      <p>
                        The complete process of applying for importer exporter
                        code is simple and hassle-free with the online
                        Import-Export license application. It merely takes 3-4
                        working days for obtaining the importer exporter code.
                        The applicant can immediately download the e-IEC through
                        the link provided in the mail after uploading documents
                        and payment of the fee.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Compliance-free</h2>
                      <p>
                        Once the import-export code is obtained, there is no
                        adaptational formalities. Unlike other tax
                        registrations, the IE code holder also does not need to
                        comply with any kind of annual filing or return filing.
                        Also, the code is valid for a lifetime so there is no
                        need for renewal.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Government benefits</h2>
                      <p>
                        The importers and exporters are eligible to benefit from
                        many Government schemes. The entities are eligible for
                        the schemes under DGFT on their import/export. They can
                        avail the subsidies from Customs and Export Promotion
                        Council or similar government organizations.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>
                Documents required for online Import Export code registration
              </h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      A scanned copy of a PAN card of the applicant or the
                      company
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Address Proof</h2>
                    <p>
                      Sale Deed/ Rent Agreement/ Latest Electricity Bill/
                      Telephone Bill in name of applicant
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Cancelled Cheque</h2>
                    <p>
                      A cancelled cheque of current account bearing entity’s
                      pre-printed name or Bank Certificate in form ANF-2A(i)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN card of Director or Partner</h2>
                    <p>
                      For a company/partnership, PAN card of the
                      Director/Partner is a must
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Certificate of Registration</h2>
                    <p>
                      Entity should provide the registration certificate with
                      MoA- AoA / LLP Agreement, or a Partnership Deed as
                      applicable
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>For FSSAI License (State and Central):</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2-3</Tab>
                <Tab>Day 4 onwards</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Provide Required Documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting and preparation of Application</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>
                    Filing of online IEC Code registration application with DGFT
                  </li>
                  <li>Government processing time</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who is required to make online IEC code registration
                        application?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The IEC registration is required under the following
                        circumstances:
                      </p>
                      <p>
                        1) It is a pre-requisite for any entity planning to
                        engage in the business of import and export from India.
                      </p>
                      <p>
                        2) In case of service or technology, if the provider is
                        taking benefits under the Foreign Trade Policy, then
                        the&nbsp;IE code registration&nbsp;is mandatory for
                        them.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether obtaining IE code is mandatory for all?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No.&nbsp;Import Export Code registration&nbsp;is not
                        compulsory for the below-mentioned activities:
                      </p>
                      <ol>
                        <li>
                          If the import or export of goods is done for the
                          personal use.
                        </li>
                        <li>
                          If the import or export is carried out by any
                          Government Ministry or department or a notified
                          charitable organization.
                        </li>
                        <li>
                          Any other category mentioned or defined by the DGFT.
                        </li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can an individual apply for IEC registration?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Any individual involved in the import and export of
                        goods or services for the purpose of conducting business
                        can make IE Code application. They can obtain the IE
                        code by filing an online application accompanied with
                        required documents. In case the person does not have a
                        current account for the business, he/she may provide
                        canceled cheque of the savings account for the purpose
                        of filing IE code application.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether copy of PAN Card is required to be uploaded?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, w.e.f. 09.08.2018, DGFT has integrated PAN web
                        service of CBDT with the server; therefore PAN card is
                        not required to be uploaded. However, for the purpose of
                        filing an application, the copy of the PAN card is
                        required to be submitted to the team.{" "}
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the validity of import export code registration?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Import Export Code comes with a lifelong validity
                        unless the same is withdrawn or surrendered. Once the
                        applicant receives the IE Code, there is no need to
                        re-register as it is a perpetual registration.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether the separate application is to be made for other
                        branch?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, there is no requirement to make a separate
                        application for different places of business. This is a
                        PAN based registration. Hence, the single registration
                        will be valid for all the businesses and branch under
                        the same entity.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ImportExportCodeRegistration;
