import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function IncreaseAuthorisedShareCapital() {
  const pageName = "IncreaseAuthorisedShareCapital";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Increase Authorised Share Capital</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Know about an increase in Authorised Share Capital</h2>
                <div className="bar"></div>
                <p>
                  The share capital is part of company’s capital which is raised
                  through issue of shares. A company may raise capital only to
                  the extent of the authorized capital mentioned in the company.
                  To increase the capital raising capacity, the authorized
                  capital can also be increased any time after incorporation by
                  payment of additional fee and stamp duty.
                </p>
                <p>
                  A company can issues shares only to the extent of authorized
                  capital. Hence, it determines the number of shares a company
                  can issue which is mentioned in the Capital Clause of MoA. In
                  order to increase capital raising capacity, it has to increase
                  the authorized capital first. To alter authorized capital
                  clause, the company need to conduct a meeting of Board and
                  Member that is followed by application to MCA.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of increasing authorised capital</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Allow further issue of capital</Tab>
                <Tab>Increase in borrowing capacity</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Allow further issue of capital</h2>
                      <p>
                        As said, the company cannot raise capital beyond the
                        amount prescribed in the MoA. Therefore, if the need
                        arises to increase the paid-up capital, first authorized
                        capital must be increased.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Increase in borrowing capacity</h2>
                      <p>
                        Increase in internal funding capacity supports the
                        borrowing capacity of the company. Higher the capital,
                        higher the net worth and so the borrowing capacity.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required to increase authorised capital</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Digital Signature Certificate</h2>
                    <p>DSC of one of authorised director to be provided</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>MoA & AoA</h2>
                    <p>A copy of latest amended MoA and AoA of the company</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>CoI</h2>
                    <p>
                      Certificate of Incorporation of company to be provided
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>Copy of PAN card of the company to be provided</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of authorised share capital increase</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2-4</Tab>
                <Tab>Day 5-6</Tab>
                <Tab>Day 7 onwards</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Consultancy for increase in capital</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of necessary resolutions</li>
                  <li>Drafting of other necessary documents</li>
                  <li>Provide documents prepared after signature</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Preparation of application for change</li>
                  <li>Filing application and necessary documents with MCA</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time</li>
                  <li>Update master data of the company</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Which approvals are required for increase in share
                        capital of Private Limited Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The increase in capital shall receive following consent
                        or approval:
                        <br />
                        1. Consent from the Board;
                        <br />
                        2. Consent from the members of the company; and
                        <br />
                        3. Approval from concerned RoC.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When should the forms be filed with MCA?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The form must be filed within 30 days after obtaining
                        consent from shareholders for the share capital
                        increase. The resolution passed is notified in MGT-14
                        and notice of increase is filed in SH-7 with altered MoA
                        and AoA.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether increase of capital is reflected on MCA portal?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, the total authorized and paid-up capital is
                        displayed on the Master Data of the company on the MCA
                        portal.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How does the increase in capital impact the company in
                        filing fee?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Government fee for any e-form filed with MCA depends
                        on the authorized capital of the company. With the
                        increase in Authorised capital, the Government fee for
                        online filing also increases, however to a nominal
                        extent.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether package includes increase in paid-up share
                        capital, too?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, the package cost also includes the increase in
                        paid-up capital of the company, but not the transfer of
                        shares.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default IncreaseAuthorisedShareCapital;
