import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function IncomeTaxFiling() {
  const pageName = "IncomeTaxFiling";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Income Tax Return Filing</h2>
                  <p>Filing ITR made easier than ever!</p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is Income Tax Return Filing?</h2>
                <div className="bar"></div>
                <p>
                  The income earned by the individuals and businesses are
                  subject to tax. The tax levied on the income of a person is
                  Income tax, which is levied and collected by the Central
                  Government. Such tax on income earned is payable in the same
                  financial year wherein it is accrued in the form of advance
                  tax. But the intimation and calculation of the income as well
                  the tax liability is provided in the Assessment Year. This
                  intimation form is called Income Tax Return. The form and time
                  limit for filing ITR are different for different taxpayers
                  based on the criteria.
                </p>
                <p>
                  Income Tax Return forms are recently modified, to simplify the
                  user experience. But through these new or modified schedules,
                  it is apparent that taxpayers are responsible to prove a claim
                  of expenses, exemptions and deductions. Hence, online filing
                  of income tax return requires assistance from experienced
                  professionals to make sure the correct filing of your return.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Income Tax Return Filing</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Allows carry forward of losses</Tab>
                <Tab>Define financial worth</Tab>
                <Tab>Loan Processing and high risk cover</Tab>
                <Tab>Easy compliance requirements</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Allows carry forward of losses</h2>
                      <p>
                        Most businesses in their initial years face losses from
                        the business. The business loss or capital losses can be
                        carried forward up to 8 years if the ITR is filed. This
                        loss can also be adjusted against the future income that
                        lowers taxable income in the future. If ITR is not
                        filed, the taxpayer is deprived of this benefit.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Define financial worth</h2>
                      <p>
                        The ITR filed with the Government defines the financial
                        worth of the taxpayer. The track of ITR shows the
                        financial capacity and also increases the capital base
                        of a person. Hence, the track of income and financial
                        worth is decided by the previously filed ITR. The
                        investors and institutions look forward for returns
                        filed to know the capacity of the business.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Loan Processing and high risk cover</h2>
                      <p>
                        The numbers and the capital base defined by the income
                        tax return is helpful for the loan processing. Higher
                        the financial worth, easier the loan processing. The
                        same applies to high-risk cover insurance. The ITR is a
                        considerable document for making decisions in this
                        regards.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Claim refund of TDS paid from salary</h2>
                      <p>
                        Salaried personnel receives the income after deduction
                        of applicable TDS. It may happen that after the eligible
                        deductions, the tax liability is lower than the amount
                        of TDS actually deducted. In such cases, the excessive
                        payment can be claimed in the form of refund only if ITR
                        is filed by the person.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for ITR filing</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>PAN Card of the taxpayer</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Entities PAN Card</h2>
                    <p>
                      In case of company or firm, PAN card of all directors or
                      partners is required
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Aadhar Card</h2>
                    <p>
                      In case of company or firm, Aadhar card of all directors
                      or partners is required
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Cancelled Cheque</h2>
                    <p>
                      Cancelled cheque of the taxpayer’s bank account is
                      required
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Bank Account Statement</h2>
                    <p>
                      The statement for concerned Financial Year is required to
                      assess other incomes
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Financial Statements</h2>
                    <p>
                      For business entities, except proprietorship, financial
                      statements are required
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Investment/ expenses u/s 80</h2>
                    <p>
                      Details about the investments made or expenditure u/s 80
                      must be provided
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Form 16</h2>
                    <p>
                      The salaried person should provide the TDS Certificate,
                      known as Form 16
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>ITR filing Process</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1 - Collection</Tab>
                <Tab>Day 2 - Execution</Tab>
                <Tab>Day 3 - Submission and Acknowledgement</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and collection of basic Information</li>
                  <li>Consultancy for appropriate ITR form</li>
                  <li>Collection of required documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Computation of payable Income Tax</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Online filing of income tax return</li>
                  <li>Sharing ITR Acknowledgment</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether NIL ITR is mandatory to be filed?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        All the business entities (Company, LLP, Firm) must file
                        ITR even if their total income or tax payable is zero.
                        In case of an individual, when income exceeds the basic
                        exemption limit, it is recommended to file ITR to avoid
                        scrutiny from the Income Tax Department. Also, if your
                        tax liabilities is zero and have filed the ITR before,
                        it is necessary to be filed. The same can be provided as
                        a proof of income whenever required.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the due dates for IT return online filing?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The due dates for filing ITR online are mentioned below
                        – let’s take an e.g. of (F.Y. 2017 – 18 & A.Y. 2018 –
                        19)
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Do I need to file return if there is a loss in my
                        business income?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, filing ITR in case of loss would be in your
                        interest itself. With online ITR filing, you can carry
                        forward the losses to a certain upcoming financial year
                        to set off losses against the future profits.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether payment of tax is made while IT return filing?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, the income tax is paid during the financial year in
                        which the income is earned. While filing ITR, if the tax
                        liability is more than the already paid advance tax, the
                        due amount must be paid with interest, if applicable.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I file ITR once the last date of filing is gone?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In case you fail to file the return on a due date, there
                        is a provision to file return up to a certain date,
                        however with a late filing fee and reduced benefits, the
                        belated return can be filed before the end of Assessment
                        Year for the concerned financial year. That means, for
                        F.Y. 2017-18, belated returns can be filed till 31st
                        March, 2019.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the penalty for a delay in filing ITR?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Late filing fee will be levied based on the date of
                        filing belated return. The late fee for filing depends
                        on the period of filing:
                        <br />
                        1. For return filed after due date but till December –
                        late filing fees of ₹ 5,000 will be charged
                        <br />
                        2. For return filed after 31st December – late filing
                        fees of&nbsp; ₹10,000 will be charged
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default IncomeTaxFiling;
