import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function GSTFiling() {
  const pageName = "GSTFiling";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>GST Filing</h2>
                  <p>
                    Get legally recognised as a supplier with GST registration
                    online
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is GST Filing?</h2>
                <div className="bar"></div>
                <p>
                  The Goods and Service Tax is the biggest indirect tax reform
                  which blends in all the other taxes into one single tax
                  structure. Under GST Regime, the goods and services are now
                  taxed under a single law being Goods and Service Tax Laws. The
                  taxes are levied at a single rate. The collection is then
                  bifurcated between both Central and State Government in name
                  of CGST and SGST or IGST.
                </p>
                <p>
                  <strong>Threshold Limit for Registration</strong>
                </p>
                <p>
                  The registration under GST is mandatory for the business
                  entities based on the criteria of turnover or activities. The
                  business dealing in goods and those providing service have to
                  mandatorily apply for GST Registration if their aggregate
                  turnover for a financial year exceeds Rs.40 Lakhs and Rs.20
                  Lakhs respectively. However, for business making supplies and
                  providing services in the North Eastern States, the same is
                  Rs. 20 lakhs and Rs. 10 lakhs respectively. Owing to its
                  benefits, many dealers also obtain voluntary registration
                  under GST.
                </p>
                <p>
                  The GST registration in India is completely an online process.
                  GST Registration affirms seamless flow of Input Tax Credit in
                  addition to providing recognition as a registered supplier.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of GST Filing in India</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Input tax credit and lower cost</Tab>
                <Tab>Simplified and permanent registration</Tab>
                <Tab>Legally recognized as Supplier</Tab>
                <Tab>Easy compliance requirements</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Input tax credit and lower cost</h2>
                      <p>
                        Once the supplier of goods or services has obtained the
                        registration under GST, he can take credit of the GST
                        paid to the previous supplier concerning business
                        activities. The decreased cost of inputs lowers the
                        pricing of supplies. The registered GST supplier is also
                        eligible to pass the ITC to the consumers.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Simplified and permanent registration</h2>
                      <p>
                        The online registration process is simplified with the
                        assistance from LW experts. With the completion of
                        registration process, the certificate is granted to the
                        applicant stating the GST registration number, which is
                        called GSTIN. The registration granted is permanent
                        registration without any renewal requirement.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Legally recognized as Supplier</h2>
                      <p>
                        With compulsory or voluntary registration under GST, the
                        supplier is eligible to collect the taxes legally.
                        Further, the supplier can also pass on the credit. The
                        voluntary registrant also gets equal status and
                        responsibilities of taxpayer registered under the
                        mandatory criteria. With the said registration, the
                        registrant can also issue pakka invoice to the
                        consumers.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Easy compliance requirements</h2>
                      <p>
                        Upon registration, every registrant is liable to fulfil
                        compliance in the form of return filing in a periodical
                        manner. These compliance requirements are simplified
                        under the GST regime. It is also proposed to simplify
                        the compliance through single return filing from
                        multiple filings. A regular taxpayer would be awarded a
                        higher GST Compliance rating as compared to others.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents Required for GST Filing</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>Digital Signature</h2>
                    <p>
                      DSC of Partners or Directors, in case of LLP and Company
                      only
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Copy of passport size photograph of the Proprietor/
                      Partners /Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Entity’s PAN Card</h2>
                    <p>A copy of PAN Card of business entity</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN & Aadhar Card</h2>
                    <p>
                      A copy of Aadhar Card of Proprietor/ Partners/ Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Latest Electricity bill/ Any Tax paid bill/ Municipal
                      Khata Copy
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      If place is rented, rent agreement is mandatory, else
                      consent letter is sufficient
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Bank details</h2>
                    <p>
                      Latest Bank Statement/ Copy of cancelled cheque/ copy of
                      first page of passbook
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Certificate of Registration</h2>
                    <p>
                      Entity should provide registration certificate with MoA-
                      AoA / LLP Agreement or Partnership Deed as applicable
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process for GST Filing</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2-3</Tab>
                <Tab>Day 4 onwards</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Consultancy for requirement of GST Registration</li>
                  <li>Collection of basic Information & documents</li>
                  <li>Preparation of required documents</li>
                  <li>
                    Share documents for Applicant’s Signature, if required
                  </li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Preparation of online application</li>
                  <li>Filing of registration application</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time</li>
                  <li>GST Registration Certificate*</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who is required to apply for GST number?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Following are the general criteria of mandatory
                        registration:
                      </p>
                      <p>
                        • If the total business turnover exceeds ₹ 40 Lakh/₹20
                        Lakh in case of business of goods/providing service
                        respectively in the concerned financial year (Threshold
                        for North-eastern States is ₹ 20 Lakh/ ₹ 10 Lakh in case
                        of business of goods/providing service respectively)
                        <br />• Casual taxable person / Non-Resident taxable
                        person
                        <br />• Agents of a supplier &amp; Input Service
                        Distributor (ISD)
                        <br />• Electronic Commerce Aggregator
                        <br />• Person supplying through an E-commerce platform
                        <br />
                        In addition to above, there are certain other criteria
                        when registration is mandatory. Apart from compulsory
                        registration, one can also apply for GST registration
                        voluntarily.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        At which place, should I obtain registration for GST?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The supplier of Goods and Services is required to apply
                        for GST registration in the state from where the taxable
                        goods or services are supplied, mentioning the place(s)
                        of business. When the places of Business are situated in
                        more than one state, application of GST shall be made
                        from more than one place.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How is the tax to be collected at source for e-commerce
                        operator?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Every e-commerce operator engaged in facilitating the
                        supply of taxable goods/services shall collect the tax
                        at source at the time of credit or at the time of
                        payment, whichever is earlier.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the proof of registration under GST?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Once the application is made for registration under
                        Goods & Service Tax Act, the competent officer shall
                        verify the application submitted and on his/her
                        satisfaction, the officer issues the Certificate of
                        Registration under GST in soft copy. The Certificate
                        issued also mentions the GSTIN allotted to the
                        applicant.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are taxable events under the GST Act?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The event of supply of Goods and/or Services i.e.
                        transactions such as the sale, transfer, barter,
                        exchange, license, rental, lease or disposal made, or
                        agreed to be made for a consideration of taxable goods
                        or services, is to be considered a taxable event under
                        the GST Act.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the Government fee for online GST Registration
                        in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>There are no government fees for GST registration.</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default GSTFiling;
