import * as React from "react";
import onepersonimg from "../assets/One-Person-Company.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function UKIncorporation() {
  const pageName = "UKIncorporation";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>UK Company Incorporation</h2>
                  <p>
                    Enjoy the Limited Liability Protection, build a Professional
                    Image, get easy credit and expand the business
                    internationally through Company Registration in United
                    Kingdom.{" "}
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Overview</h2>
                <div className="bar"></div>
                <p>
                  The United Kingdom has always attracted business owners from
                  different parts of the globe. The country is one of the top
                  destinations to start new businesses. This is because the
                  United Kingdom has a well-equipped infrastructure,
                  well-implemented rules and regulations, strong governance, and
                  high-quality facilities.
                </p>
              </div>
              <div className="col col6">
                <img src={onepersonimg} alt="LLP-Registration" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Starting a Company in UK</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Conducive Business Environment</Tab>
                <Tab>Easy Business Setup Process</Tab>
                <Tab>Techno Friendly</Tab>
                <Tab>Finance</Tab>
                <Tab>Growth Opportunity</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        World-Class trade centres, high-tech infrastructure and
                        a cohesive communication network. UK has best
                        infrastructure to support your budding and an already
                        thriving business.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      src={fundraising}
                      alt="fund-raising-image"
                      style={{ height: "250px" }}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      src={legal}
                      alt="legal-image"
                      style={{ height: "250px" }}
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        When it comes to business set up process, UK provides an
                        easy way to initiate and complete the process. Business
                        owners prefer to engage with professional business
                        consultants regarding the same that helps to complete
                        the task done at a faster pace.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        Access to advanced technology, there is increasing
                        desirability for UK as a place to do business. Many
                        foreign investors are starting companies in UK just to
                        have access to advanced technological innovations which
                        will enhance their business production and world -wide
                        communication.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      src={fundraising}
                      alt="fund-raising-image"
                      style={{ height: "250px" }}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      src={legal}
                      alt="legal-image"
                      style={{ height: "250px" }}
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        The capital of the United Kingdom, London, is one of the
                        largest financial centres in the world. So, the process
                        of company registration in the United Kingdom attracts
                        more opportunities for the applicants to raise capital
                        and finance from different financial institutions and
                        banks of the country.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <p>
                        UK has good relations with the European Union and the
                        USA and therefore, businesses gets ample opportunities
                        to grow internationally & diversify their product and
                        service lines.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      src={fundraising}
                      alt="fund-raising-image"
                      style={{ height: "250px" }}
                    />
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents Required for Registration</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col12">
                <div className="textbox">
                  <p>
                    <strong>Identity Proof</strong>
                  </p>
                  <p>Address Proof and ID proof documents - Aadhar Card.</p>
                  <p>
                    <strong>Photograph</strong>
                  </p>
                  <p>Photograph of all the shareholders and directors</p>
                  <p>
                    <strong>Business Address Proof</strong>
                  </p>
                  <p>
                    Address proof of a registered physical office in UK. In case
                    of NRI or Foreign National, documents of director (s) must
                    be notarized or apostilled.
                  </p>
                  <p>
                    <strong>MoA & AoA</strong>
                  </p>
                  <p>
                    Certificate of Incorporation, MOA and AOA if a company
                    shareholding is already registered in India.
                  </p>
                  <p>
                    <strong>Passport Copy</strong>
                  </p>
                  <p>Passport of Director & Shareholders of the Business</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to Register a Company in UK</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Step 1</Tab>
                <Tab>Step 2</Tab>
                <Tab>Step 3</Tab>
                <Tab>Step 4</Tab>
                <Tab>Step 5</Tab>
                <Tab>Step 6</Tab>
                <Tab>Step 7</Tab>
              </TabList>
              <TabPanel>
                <p className="text-center">
                  Gather all relevant information such as directors' details,
                  shareholders' details, and proposed company activities.
                </p>
              </TabPanel>
              <TabPanel>
                <p className="text-center">
                  Check the availability of the proposed company name with
                  Companies House.
                </p>
              </TabPanel>
              <TabPanel>
                <p className="text-center">
                  Prepare and submit the required documents to Companies House.
                </p>
              </TabPanel>
              <TabPanel>
                <p className="text-center">
                  Pay the registration fees and submit the application.
                </p>
              </TabPanel>
              <TabPanel>
                <p className="text-center">
                  Receive a certificate of incorporation from Companies House.
                </p>
              </TabPanel>
              <TabPanel>
                <p className="text-center">
                  Register the company for Corporation Tax and VAT if
                  applicable.
                </p>
              </TabPanel>
              <TabPanel>
                <p className="text-center">Open a business bank account.</p>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the benefits of forming a company in the United
                        Kingdom?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In addition to the increased number of technological
                        procedures and tax incentives that come with the UK
                        business formation process, there are several other
                        advantages. As a result, these are the several driving
                        forces behind a potential registrant's decision to
                        proceed with the registration procedure.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        In order to have company registration in United Kingdom,
                        do the directors need to be UK residents?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The director does not need to be a British citizen to
                        have company registration in United Kingdom.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is it necessary for the UK business registration
                        procedure that the firm be included in the company
                        registrar?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, the firm must be registered with the company
                        registrar or House of Companies in order to begin the
                        process of forming a corporation in the United Kingdom.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the benefits of registering a company in the
                        UK?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Advantages include government funding, various
                        incentives, technological centres, and start-up
                        entrepreneurs who offer space to support business
                        expansion.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is it possible for an Indian national to open a business
                        bank account in the United Kingdom?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Hundreds of Indian business owners have benefited from
                        our assistance in setting up a business-entity and
                        business account in the UK or expanding their operations
                        there. We're certain that we can do the same for you. In
                        the UK, there are no restrictions on where you may start
                        a business or what nationality you must be.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is it possible for me to be a shareholder and director
                        of the company in the UK?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes. You can be a shareholder and a director in the same
                        company. Many UK incorporated corporations begin as
                        single owner-managed businesses, and it is quite
                        appropriate to be the single identified shareholder.
                        However, it may also depend on the type of company
                        registration that you may choose.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Does a UK company require a resident governor?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, having a resident director is not necessary for
                        non-UK resident company formation. As long as your
                        company has a UK registered office address, its director
                        can be a non-UK resident.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Do foreign corporations pay tax in the UK?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        If you are a foreigner starting a business in the UK,
                        you must incorporate there and, no matter where you
                        operate the business, you must pay UK taxes. Companies
                        that are not British-incorporated must only pay tax if
                        they have a permanent establishment there or make their
                        profits there.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default UKIncorporation;
