import React from "react";
import "react-jquery-plugin";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import aboutimg from "./assets/digital.png";
import squareimg from "./assets/shape1.png";
import circleimg from "./assets/shape2.png";
import rectimg from "./assets/dot-rect.png";
import triimg from "./assets/tri-circle1.png";
import clientimg1 from "./assets/c1.jpg";
import clientimg2 from "./assets/c2.jpg";
import clientimg3 from "./assets/c3.jpg";
import clientimg4 from "./assets/c4.jpg";
import clientimg5 from "./assets/c5.jpg";
import "./App.css";
import Layout from "./common/Layout";

function App() {
  const partnerimages = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };
  const testimonials = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <Layout>
      <div className="App">
        <main>
          <section className="banner-section">
            <div className="container">
              <div className="banner-content">
                <p
                  style={{
                    fontSize: "33px",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "center",
                    lineHeight: "40px",
                  }}
                >
                  Say Goodbye To The Stress Of Managing{" "}
                  <span style={{ color: "#086ad8" }}>Compliances & Tax </span>
                  Filings
                </p>
                <form className="search_form">
                  <div className="form-group">
                    <input
                      type="text"
                      name="search"
                      placeholder="Search services eg. Legal Drafting..."
                      className="form-control"
                      required=""
                      value=""
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="submit"
                      name="get-quote"
                      className="form-control"
                      required=""
                      value="Search Now"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="default-shape">
              <div className="rect-shape">
                <img src={rectimg} alt="error" />
              </div>
              <div className="tri-shape">
                <img src={triimg} alt="error" />
              </div>
            </div>
          </section>
          <section className="served_section">
            <div className="container">
              <div className="title">
                <h2>
                  Launching, Managing & Growing Your Business Has Never Been
                  Easier!
                </h2>
              </div>
              <div className="row">
                <div className="col col3">
                  <div className="content">
                    <div className="iconbox">
                      <i className="fa fa-user-tie"></i>
                    </div>
                    <div className="textbox">
                      <h2>Expert Professional Assistance</h2>
                      <p>
                        Prompt support from our in-house expert professionals
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col col3">
                  <div className="content">
                    <div className="iconbox">
                      <i className="fa fa-file"></i>
                    </div>
                    <div className="textbox">
                      <h2>Speedy Filing</h2>
                      <p>
                        We believe in serving the Best Service at the Right Time
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col col3">
                  <div className="content">
                    <div className="iconbox">
                      <i className="fa fa-smile"></i>
                    </div>
                    <div className="textbox">
                      <h2>Trusted Services</h2>
                      <p>
                        We ensure that you stay 100% satisfied with our offered
                        services
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col col3">
                  <div className="content">
                    <div className="iconbox">
                      <i className="fa fa-shield"></i>
                    </div>
                    <div className="textbox">
                      <h2>Privacy and Security</h2>
                      <p>All your private information is safe with us</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="about_section">
            <div className="container">
              <div className="row">
                <div className="col col6">
                  <img src={aboutimg} alt="about-image" />
                </div>
                <div className="col col6">
                  <h2>Make Filing Easier with Filing Saga</h2>
                  <div className="bar"></div>
                  <p>
                    Filing Saga is one of the most trusted, affordable, and
                    cost-effective online platforms for business compliance,
                    accounting, taxation & legal services. We have a team of
                    experts who are always up-to-date with the latest changes in
                    the taxation and compliance world, so you can be confident
                    that your business is compliant.
                  </p>
                  <p>
                    We offer a wide range of services, from company formation to
                    tax filing, and we have a 100% satisfaction guarantee. We're
                    here to help you every step of the way, so you can focus on
                    running your business.
                  </p>
                  <p>
                    If you're looking for an easy, stress-free way to take care
                    of your business compliance & legal needs, look no further
                    than Filing Saga.
                  </p>
                  <p>
                    <a href="#" className="get_started_btn cmn_btn">
                      Learn More
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="default-shape">
              <div className="square-shape">
                <img src={squareimg} alt="error" />
              </div>
              <div className="circle-shape">
                <img src={circleimg} alt="error" />
              </div>
            </div>
          </section>
          <section className="services_section">
            <div className="container">
              <div className="section-title">
                <h2>Popular Services</h2>
                <div className="bar"></div>
              </div>
              <div className="row">
                <div className="col col4">
                  <div className="single-services">
                    <div className="icon">
                      <i className="fa fa-user-shield"></i>
                    </div>
                    <h3>Private Limited Company</h3>
                    <p>
                      Private Company is highly popular amongst start-ups and
                      entrepreneurs. Private Company Registration is now
                      simplified. It’s about time to make your entrepreneurial
                      dream true.
                    </p>
                    <a className="get-started-btn cmn_btn" href="#">
                      Get Started
                    </a>
                  </div>
                </div>
                <div className="col col4">
                  <div className="single-services">
                    <div className="icon">
                      <i className="fa fa-user-cog"></i>
                    </div>
                    <h3>Limited Liability Partnership</h3>
                    <p>
                      LLP is the optimum combination of operational flexibility
                      and corporate organization. Start with LLP registration
                      for small and medium-scale businesses to enter a
                      formalized, corporate world.
                    </p>
                    <a className="get-started-btn cmn_btn" href="#">
                      Get Started
                    </a>
                  </div>
                </div>
                <div className="col col4">
                  <div className="single-services">
                    <div className="icon">
                      <i className="fa fa-percentage"></i>
                    </div>
                    <h3>GST Return Filing</h3>
                    <p>
                      Compliance comes with registration and if you are
                      registered under GST, return filing is mandatory for you.
                      Ensure an accurate and on-time GST return filing with
                      Filing Saga.
                    </p>
                    <a className="get-started-btn cmn_btn" href="#">
                      Get Started
                    </a>
                  </div>
                </div>
                <div className="col col4">
                  <div className="single-services">
                    <div className="icon">
                      <i className="fa fa-trademark"></i>
                    </div>
                    <h3>Trademark Registration</h3>
                    <p>
                      Building a brand is simply not enough, protecting and
                      securing it is also an extremely essential step. Don’t let
                      your hard work sink in vain; provide legal backing to your
                      brand with Trademark registration online.
                    </p>
                    <a className="get-started-btn cmn_btn" href="#">
                      Get Started
                    </a>
                  </div>
                </div>
                <div className="col col4">
                  <div className="single-services">
                    <div className="icon">
                      <i className="fa fa-user-check"></i>
                    </div>
                    <h3>ROC Filing</h3>
                    <p>
                      Annual ROC compliances are detailed reporting that every
                      registered company/LLP in India should submit within the
                      due date prescribed in the Companies Act to avoid late
                      fees and penalties.
                    </p>
                    <a className="get-started-btn cmn_btn" href="#">
                      Get Started
                    </a>
                  </div>
                </div>

                <div className="col col4">
                  <div className="single-services">
                    <div className="icon">
                      <i className="fa fa-calculator"></i>
                    </div>
                    <h3>Bank Project Report</h3>
                    <p>
                      Looking For A Bank Loan? Get your Project report for a
                      bank loan which will detail a business or project for
                      which finance is being sought.
                    </p>
                    <a className="get-started-btn cmn_btn" href="#">
                      Get Started
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="testimonial_slider">
            <div className="container">
              <div className="section-title">
                <h2>WHAT PEOPLE ARE SAYING ABOUT US</h2>
                <p>Don't just take our word for it.</p>
                <div className="bar"></div>
              </div>
              <OwlCarousel
                className="owl-theme"
                loop
                margin={20}
                items={3}
                dots
                autoplay
                responsive={testimonials.responsive}
              >
                <div className="item">
                  <div className="clients-item">
                    <div className="icon">
                      <i className="fa fa-quote-left"></i>
                    </div>
                    <p>
                      Knowledgeable staff and awesome customer service. Helped
                      me to register my logo with very prompt service. I wish
                      good luck to the Filing Saga team. Keep rocking
                    </p>
                    <div className="clients-content">
                      <h3>Sathiya Ramasamy</h3>
                      <p>MD, Cloudin Labs</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="clients-item">
                    <div className="icon">
                      <i className="fa fa-quote-left"></i>
                    </div>
                    <p>
                      As an NRI, I was not aware of the tax implications of any
                      remittance to my home in India. Filing Saga team of
                      experts helped to understand every detail and structured a
                      tax-efficient way to make remittances.
                    </p>
                    <div className="clients-content">
                      <h3>Arun Shanker</h3>
                      <p>NRI, Finance Professional</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="clients-item">
                    <div className="icon">
                      <i className="fa fa-quote-left"></i>
                    </div>
                    <p>
                      I highly recommend Filing Saga for anyone who is
                      struggling to file their ITR with trading gains/losses. My
                      previous year’s F&O losses were missed to be carried
                      forward as business losses by my TPA who had filed my
                      returns just for the sake of filing. Filing Saga helped. Wish I had come
                      to them earlier.
                    </p>
                    <div className="clients-content">
                      <h3>Anupam Chaudhary</h3>
                      <p>IT Professional</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="clients-item">
                    <div className="icon">
                      <i className="fa fa-quote-left"></i>
                    </div>
                    <p>
                      I've been working with Filing Saga for my accounting needs
                      for the past 1 year. They have handled my LLP registration
                      process along with GST filings and all annual ROC
                      compliances.
                    </p>
                    <div className="clients-content">
                      <h3>Gunasekaran</h3>
                      <p>Partner, Techswap</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="clients-item">
                    <div className="icon">
                      <i className="fa fa-quote-left"></i>
                    </div>
                    <p>
                      Full marks to the Filing Saga team for getting a balance
                      transfer for my bank loan (LAP). I didn’t know that my
                      current loan interest rates can be reduced and substituted
                      by a fresh top-up loan over and above the current
                      outstanding at a lower rate.
                    </p>
                    <div className="clients-content">
                      <h3>Magesh Bala</h3>
                      <p>MD, Optigrise Technologies</p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="clients-item">
                    <div className="icon">
                      <i className="fa fa-quote-left"></i>
                    </div>
                    <p>
                      Filing Saga team helped us in creating a crisp, clear and
                      concise pitch deck along with a realistic looking
                      financial projection. Suggest all young entrepreneurs to
                      seek their guidance not just to prepare professional pitch
                      deck but also to bring in more clarity of thoughts and
                      establish a detailed business plan.
                    </p>
                    <div className="clients-content">
                      <h3>Dr. Varaprasad</h3>
                      <p>Director, Acceltop Learning</p>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
            <div className="default-shape">
              <div className="square-shape">
                <img src={squareimg} alt="error" />
              </div>
              <div className="circle-shape">
                <img src={circleimg} alt="error" />
              </div>
            </div>
          </section>
          <section className="our_partners">
            <div className="container">
              <div className="section-title">
                <h2>Our Partners</h2>
                <div className="bar"></div>
              </div>
              <OwlCarousel
                className="owl-theme"
                loop
                margin={20}
                items={5}
                dots
                autoplay
                responsive={partnerimages.responsive}
              >
                <div className="item">
                  <img src={clientimg1} alt="error" />
                </div>
                <div className="item">
                  <img src={clientimg2} alt="error" />
                </div>
                <div className="item">
                  <img src={clientimg3} alt="error" />
                </div>
                <div className="item">
                  <img src={clientimg4} alt="error" />
                </div>
                <div className="item">
                  <img src={clientimg5} alt="error" />
                </div>
              </OwlCarousel>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
}

export default App;
