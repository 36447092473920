import React, { useState } from "react";
import "./Login.css";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { API_URL } from "../constants";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "./Loader";
import { toast, Toaster } from "react-hot-toast";
import Layout from "../common/Layout";
const VerifyOtp = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      otp: "",
    },
  });

  const [loader, setLoader] = useState(false);
  const history = useHistory();

  const search = useLocation().search;
  const queryName = new URLSearchParams(search).get("query");

  const onSubmit = async (data) => {
    setLoader(true);
    const email = localStorage.getItem("email");
    const login = await axios.post(`${API_URL}/api/verifyOtp`, {
      userEmail: email,
      otp: data.otp,
      verifyFor: queryName,
    });
    if (login.data.status === 200) {
      const token = localStorage.setItem("token", login.data.data.token);
      toast(login.data.message);
      queryName === "login"
        ? history.push("/")
        : history.push("/resetPassword");
    } else {
      setLoader(false);
      toast(login.data.message);
    }
  };
  return (
    <div className="login-page">
      <div className="login-form">
        <h2>Verify OTP</h2>
        <Controller
          name="otp"
          control={control}
          rules={{ required: "OTP is required" }}
          render={({ field }) => (
            <div className="form-group">
              <input
                type="text"
                name="otp"
                placeholder={errors.otp?.message ? errors.otp?.message : "OTP"}
                {...field}
              />
            </div>
          )}
        />
        <div className="form-group">
          <input
            type="submit"
            value="Verify OTP"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
      {loader && <Loader />}
      <Toaster position="bottom-center" />
    </div>
  );
};

export default VerifyOtp;
