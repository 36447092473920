import * as React from 'react';
import aboutimg from '../assets/digital.png';
import fundraising from '../assets/fundraising.png';
import legal from '../assets/legal-doc.png'; 
import owners from '../assets/owners.png';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from 'react-accessible-accordion';
  import 'react-accessible-accordion/dist/fancy-example.css';
  import rectimg from '../assets/dot-rect.png';
  import triimg from '../assets/tri-circle1.png';
  import LeadInputs from '../common/LeadInputs';
import Layout from '../common/Layout';

function FSSAILicense() {
    const pageName = "FSSAILicense";
    return(
      <Layout>
          <div className="subcatpage-data">
           <section className="sbanner_section">
            <div className="container">
                <div className="row">
                    <div className="col col12">
                    <div className="sbanner_content">
                    <h2>Start your Food Business. Get FSSAI.</h2>
                    <p>Online FSSAI Registration and License</p>
                    <LeadInputs pageName={pageName}/>
                    </div>
                    </div>
                </div>
            </div>
            <div className="default-shape">
                <div className="rect-shape"><img src={rectimg} alt="error" /></div>
                <div className="tri-shape"><img src={triimg} alt="error" /></div>
            </div>
        </section>
        <section className="intro_section">
            <div className="container">
                <div className="row">
                    <div className="col col6">
                        <h2>What is FSSAI?</h2>
                        <div className="bar"></div>
                        <p>FSSAI is an abbreviation for the Food Safety and Standards Authority of India. FSSAI is an independent body recognized under the Ministry of Health & Family Welfare, Government of India that manages the food license registration. This organization works for securing the Laws and Standards for Food Business in India. The main aim of FSSAI is to regulate and check for safe food manufacturing, storage, and handling practices by Food Business Operators (FBOs). A FSSAI registration certificate comes with a 14 digit License number to FBOs which is required to be printed on all the food products with FSSAI logo. The license can be applied for a minimum 1 year to maximum 5 years; it can again be renewed or re-applied for as per rules stated under Food Safety & Standards Act, 2006.</p>
                    </div>
                    <div className="col col6">
                      <img src={aboutimg} alt="about" />
                    </div>
                </div>
                <div className="row margin60 align-items-start">
                    <div className="col col6">
                        <h2>Who requires FSSAI Registration and License?</h2>
                        <div className="bar"></div>
                        <p>Every person who wants to start Food Business including cloud kitchen for Swiggy and Zomato has to mandatorily obtain FSSAI Registration or FSSAI License online. However, it sometimes becomes difficult for a food business operator to decide – which license to opt for: State or Central. Let us help you to understand the eligibility criteria so that you can quickly proceed for online FSSAI registration and acquire requisite food license.</p>
                        <p><strong>Here are few examples of business that require FSSAI Registration and License:</strong></p>
                        <ul className='list_bullets'>
                            <li>Restaurant</li>
                            <li>Club</li>
                            <li>Wholesaler</li>
                            <li>Food Retailer</li>
                            <li>Food Storage Unit</li>
                            <li>Online Food Business Operators</li>
                            <li>Caterers</li>
                            <li>Exporter and Importer of Food Products</li>
                            <li>And other business related to Food</li>
                        </ul>
                    </div>
                    <div className="col col6">
                    <h2>What is applicable to you: FSSAI Registration or FSSAI License?</h2>
                        <div className="bar"></div>
                        <p>FSSAI Registration: <br />Simply called FSSAI Basic Registration. This registration is appropriate for all food businesses and allied activities like Storage, sales, distribution, trading, re-packaging and labelling. Food Businesses having a turnover of less than 12 lacs are eligible to obtain FSSAI Basic Registration. Mostly hawkers, petty retailers, temporary stall holders come under this category. This food license registration online registration is very useful for eCommerce food traders.</p>
                        <ul className='list_bullets'>
                            <li>State License<br /> Eligibility: Food Businesses having turnover above Rs.12 lacs and up to Rs. 20 crores can opt for State License.</li>
                            <li>Central License <br /> Eligibility: Those in the food business and has a turnover of Rs.20 crores and above are required to obtain Central FSSAI License.</li>
                        </ul> 
                    </div>
                </div>
            </div>
        </section>
        <section className="benifits_section">
            <div className="container">
              <div className="section-title">
                  <h2>Benefits of FSSAI License</h2>
                  <div className="bar"></div>
              </div>
                <Tabs>
                    <TabList>
                    <Tab>Informed Consumers</Tab>
                    <Tab>Legal benefits</Tab>
                    <Tab>Create goodwill</Tab>
                    </TabList>
                    <TabPanel>
                    <div className="row">
                    <div className="col col6">
                        <div className="textbox">
                            <h2>Informed Consumers</h2>
                            <p>Food is one of the essential parts of life. So, it is essential to know the quality of goods we consume. With several products and types of food in the market, knowing the quality and standard of the item is very important for every consumer. This is one of the most important benefits of having an online FSSAI License on a food product. The license depicts that the food which consumer is consuming has maintained the hygiene standards and norms as per the Food Safety and Standards Authority of India.</p>
                        </div>
                    </div>
                    <div className="col col6"><img src={fundraising} alt="fund-raising-image" /></div>
                    </div>
                    </TabPanel>
                    <TabPanel>
                    <div className="row">
                <div className="col col6"><img src={legal} alt="legal-image" /></div>
                    <div className="col col6">
                        <div className="textbox">
                            <h2>Legal benefits</h2>
                            <p>FSSAI license is mandatory for every food business. Many businesses say that getting an FSSAI license is a tricky and lengthy task, but Legalwiz.in can help you with the process of getting a license quickly. Getting an online FSSAI registration always gives legal benefits over the competitors who are operating without the license. The FBOs and other food business entrepreneurs can avoid many penalties if they have proper registrations required to run their business.</p> 
                        </div>
                    </div>
                </div>
                    </TabPanel>
                    <TabPanel>
                    <div className="row">
                <div className="col col6"><img src={owners} alt="legal-image" /></div>
                    <div className="col col6">
                        <div className="textbox">
                            <h2>Create goodwill</h2>
                            <p>A packet of food with the FSSAI logo means that it is of a certain standard and hygienic to consume. Taking a FSSAI online license helps spread awareness among the consumers which automatically helps building the goodwill in the market which can drive business expansion.</p>
                        </div>
                    </div>
                </div>
                    </TabPanel>
                </Tabs>
            </div>
        </section>
        <section className="registration_section">
            <div className="container">
              <div className="section-title">
                  <h2>Applying for Registration – Basic FSSAI Registration</h2>
                  <div className="bar"></div>
              </div>
              <div className="row">
                    <div className="col col3">
                        <div className="content">
                            <div className="iconbox"><i className="fa fa-file-lines"></i></div>
                            <div className="textbox">
                                <h2>Declaration Form</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col col3">
                        <div className="content">
                            <div className="iconbox"><i className="fa fa-file"></i></div>
                            <div className="textbox">
                                <h2>Authority Letter</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col col3">
                        <div className="content">
                            <div className="iconbox"><i className="fa fa-clapperboard"></i></div>
                            <div className="textbox">
                                <h2>3 Passport Size Photographs - (Proprietor / Partner / Director)</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col col3">
                        <div className="content">
                            <div className="iconbox"><i className="fa fa-image-portrait"></i></div>
                            <div className="textbox">
                                <h2>Photo Identity of FBO.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col col3">
                        <div className="content">
                            <div className="iconbox"><i className="fa fa-image-portrait"></i></div>
                            <div className="textbox">
                                <h2>Proof of possession of Place of Business such as Rent Agreement, NOC etc.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col col3">
                        <div className="content">
                            <div className="iconbox"><i className="fa fa-image-portrait"></i></div>
                            <div className="textbox">
                                <h2>Electricity Bill or Water Bill of the business premises</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col col3">
                        <div className="content">
                            <div className="iconbox"><i className="fa fa-image-portrait"></i></div>
                            <div className="textbox">
                                <h2>Property Tax Bill</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col col3">
                        <div className="content">
                            <div className="iconbox"><i className="fa fa-image-portrait"></i></div>
                            <div className="textbox">
                                <h2>Product List to be dealt with.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col col3">
                        <div className="content">
                            <div className="iconbox"><i className="fa fa-image-portrait"></i></div>
                            <div className="textbox">
                                <h2>Plan stating Food Safety Management System.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col col3">
                        <div className="content">
                            <div className="iconbox"><i className="fa fa-image-portrait"></i></div>
                            <div className="textbox">
                                <h2>Form-A Completed and Signed</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col col3">
                        <div className="content">
                            <div className="iconbox"><i className="fa fa-image-portrait"></i></div>
                            <div className="textbox">
                                <h2>Constitution of Business such as Incorporation Certificate, Partnership Deed etc.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="the_process">
                <div className="container">
                    <div className="section-title">
                        <h2>For FSSAI License (State and Central):</h2> 
                        <div className="bar"></div>
                    </div>
                    <Tabs>
                        <TabList>
                        <Tab>Day 1 - 4 Data Review</Tab>
                        <Tab>Day 5 – 9 Filling Form and Client confirmation</Tab>
                        <Tab>Day 10 - 11 Final Application filing</Tab>
                        <Tab>Day 12 onwards Notification from Government Awaited</Tab>
                        </TabList>

                        <TabPanel>
                            <ul>
                                <li>Seeking Data from applicant and reviewing it</li>
                                <li>Resolving Queries related to documents</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li>Filling the form for registration</li>
                                <li>Gather the necessary documents</li>
                                <li>Seeking confirmation from applicant for business codes and categories</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li>Making applications for License under FSSAI</li>
                            </ul>
                        </TabPanel>
                        <TabPanel>
                            <ul>
                                <li>Government processing time</li>
                            </ul>
                        </TabPanel>
                    </Tabs>
                </div>
        </section>
        <section className="sub-apge faq_section">
            <div className="container">
            <div className="section-title">
                <h2>Frequently Asked Questions</h2>
                <div className="bar"></div>
            </div>
            <Accordion>
            <div className="row">
            <div className="col col6">
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Will I require different Licenses if I am conducting different type of food business activities at the same premise?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <p>Please note, the online FSSAI license is based and granted on premises – hence every kind of food business being operated from the same premise can be applied in a single FSSAI license.</p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Is FSSAI License required for utensils/packaging material used for serving or packaging of food?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>No, not required. Utensils/packaging materials are not covered under the definition of food as per Section 3(n) of FSS Act, 2006 – so FSSAI License/Registration is not required.</p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Please advise on FSSAI License requirement for operating a food business in more than one state with the same name of the company or organization?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>A Central License is required for the Registered Office or Head Office for a Food Business Operator (FBO) operating a food business premises located in two or more than two States – this is in addition to license/registration for each unit/premise. These Separate license or registration will need to be obtained from the Central or State licensing authorities, depending upon that unit’s capacity or turnover.</p>
                </AccordionItemPanel>
            </AccordionItem>
        </div>
        <div className="col col6">
        <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Is a license required for catering establishment that comes under the purview of Central Government?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <p>Yes. Any and all catering establishments, need license. Including those under central Government such as Railways, Airport, Seaport, and Defence etc… are required to obtain a license from FSSAI.</p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    For 100% export units, is it mandatory to take FSSAI License?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <p>FSSAI license is mandatory for both – export and import of food products. Hence the FSSAI Central License is required for the export unit.</p>
                </AccordionItemPanel> 
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is the maximum period for which I can apply for a FSSAI License/Registration?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <p>You can apply for or renew FSSAI license for 1 year up to maximum 5 years at a time.</p>
                </AccordionItemPanel>
            </AccordionItem>
        </div>
        </div>
        </Accordion>
        </div>
        </section>
        </div>
      </Layout>
    );
}

export default FSSAILicense;