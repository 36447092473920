import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function OPCtoPrivateLimitedCompany() {
  const pageName = "OPCtoPrivateLimitedCompany";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Convert OPC to Private Limited Company</h2>
                  <p>
                    Explore funding options to grow your business from OPC to
                    Pvt. Ltd
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>
                  Know about conversion of OPC to a Private Limited Company
                </h2>
                <div className="bar"></div>
                <p>
                  Converting One Person Company to a Private Company can open
                  avenues to pursue additional benefits such as fund raising. An
                  OPC can voluntarily convert into PLC without fulfilling the
                  criteria of paid up share capital and average annual turnover.
                  The application of conversion is made to Central Government
                  after alternation of MoA and AoA of the OPC. The legal
                  existence of the company continues even after the conversion
                  and so do their rights and liabilities. With its conversion
                  into Private Company, minimum 2 shareholders and directors
                  must be appointed to fulfil the minimum requirement.
                  Conversion helps with the growth opportunities along with
                  additional funding options of private placement funding, ESOP
                  and many more.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>
                Benefits of Conversion of OPC to a Private Limited Company
              </h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Easier to Raise Funds</Tab>
                <Tab>Limited Liability of Owners</Tab>
                <Tab>Taxation Benefits</Tab>
                <Tab>Raising Capital</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Easier to Raise Funds</h2>
                      <p>
                        Raising funds as a private limited company is a
                        comparatively easy task as it gives an opportunity for
                        raising shares and has many ways to raise funds in the
                        form of private equity, ESOP, and more.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Limited Liability of Owners</h2>
                      <p>
                        The obligation or debts of the company does not create a
                        charge over the owner’s personal assets. Their liability
                        is limited only to the subscribed capital unpaid by
                        them.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Taxation Benefits</h2>
                      <p>
                        One Person Company is not recognized under the Income
                        Tax Act and hence it has been put in the same category
                        as other companies for taxation purpose. Private
                        companies have been placed under the tax bracket of 30%
                        on total income. Thus, from the perspective of taxation,
                        the concept of One Person Company becomes a less
                        profitable concept as it imposes a heavy financial load.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Separate Legal Existence</h2>
                      <p>
                        A Private Limited Company is registered, a legal entity
                        is born in eyes of law, which is separate from its
                        owners and managers. The company can operate in its own
                        name from opening a bank account to own assets and enter
                        into a contract with parties. This also provides the
                        capacity to sue third parties.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents Required to convert OPC to Pvt Ltd company</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      PAN Card of shareholders and Directors. Foreign nationals
                      must provide a passport.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Identity Proof</h2>
                    <p>
                      Voter ID/ Passport/ Driving License of Shareholders and
                      Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Address Proof</h2>
                    <p>
                      Telephone Bill /Electricity Bill/ Latest Bank Account
                      Statement of Shareholders and Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Latest Passport size photograph of Shareholders and
                      Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NRI</h2>
                    <p>
                      In case of NRI or Foreign National, documents of partner
                      must be notarized or apostilled
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Financial Statements</h2>
                    <p>
                      Duly certified copy of latest audited Finacial Statements
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Incorporation documents of the OPC</h2>
                    <p>
                      Certificate of Incorporation, MoA & AoA to be provided
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of converting OPC to Pvt. Ltd.</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1-2</Tab>
                <Tab>Day 3-8</Tab>
                <Tab>Day 9-15</Tab>
                <Tab>Day 16 onwards</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Consultancy and assistance for conversion</li>
                  <li>Collection of basic information & documents</li>
                  <li>Application of DSC of new director</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of necessary resolutions</li>
                  <li>Drafting of other required documents & affidavit</li>
                  <li>Alteration of MoA & AoA</li>
                  <li>
                    Provide required documents drafted after the signature
                  </li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Online filing of form for alteration in MoA & AOA</li>
                  <li>
                    Government processing time to approve the application*
                  </li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>
                    Online filing of form INC – 6 for voluntary conversion
                  </li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the conditions for converting OPC to a private
                        limited company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        <p>
                          Rule 6 of Companies (Incorporation) Second Amendment
                          Rules, 2021, there are no such conditions that need to
                          take care of. However, the below mentioned conditioned
                          were applied before the second amendment rules, 2021:
                          <br />
                          – If the paid-up share capital of the OPC hits more
                          than &nbsp;₹ &nbsp;50 lakh.
                          <br />– If the annual turnover exceeds&nbsp; &nbsp;₹ 2
                          crores consecutively for the last three (3) years.
                        </p>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether the OPC can voluntarily proceed for its
                        conversion?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, OPC can voluntarily convert itself into a Private
                        Company or Public Company with adherence to the basic
                        criteria to fulfill. In the case of Private Limited, 2
                        directors and shareholders are necessary. For, public
                        company, a minimum of 3 directors and 7 shareholders are
                        mandatory as per the Companies Act 2013.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can an OPC be converted or incorporated as a non profit
                        organization?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        NO, an OPC cannot be incorporated as or converted into a
                        company for non-profit, charitable purpose, and it
                        cannot carry out non-banking, financial, or investment
                        activities including investment in securities of any
                        corporate body.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the effect after conversion of OPC to a private
                        limited company on the liabilities of the previous
                        company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        After the conversion, the liabilities, debts or
                        obligation of the company shall not be affected in any
                        way. Hence, the company shall be liable for all its
                        previous obligations.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default OPCtoPrivateLimitedCompany;
