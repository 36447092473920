import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function AddRemoveDirectorCompany() {
  const pageName = "AddRemoveDirectorCompany";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Add or Remove a Director (Company)</h2>
                  <p>Get finest directors on board with change of directors</p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Know about directors’ change in a company</h2>
                <div className="bar"></div>
                <p>
                  Directors are the said to be brain of the company. They are
                  the managerial personnel who control and administer the
                  company’s operations. The rotation of directors takes place in
                  one or the other way – either by appointment of new director
                  or resignation of existing. Aim to carry out change of
                  directors is always to ensure optimum combination of experts
                  on board for interest of company.
                </p>
                <p>
                  The authority to approve the resignation of the director lies
                  with the members of BoD whereas the appointment must be made
                  through consent of shareholders. Whether it is an appointment,
                  removal or resignation, the change does not take effect until
                  the intimation is made to Ministry of corporate affairs.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Why changing directors is required?</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Hire new talent on board</Tab>
                <Tab>
                  Assign operational responsibility without dilution ownership
                </Tab>
                <Tab>Inability to work by existing directors</Tab>
                <Tab>Number of directors fall under statutory limit</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Hire new talent on board</h2>
                      <p>
                        With the growth of business, strategies and alliances
                        are developed, that requires inputs of each department
                        are required for effective planning. Also, with an
                        addition of the new product line or department, an
                        expert to lead the team can be hired in a managerial
                        position being director of the company. This benefits
                        the company with specialization and focused efforts.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>
                        Assign operational responsibility without dilution
                        ownership
                      </h2>
                      <p>
                        Directors are responsible for day-to-day operations.
                        With the appointment of an additional director, the
                        shareholders can assign the operational responsibilities
                        to directors keeping strategic control in hand. Here, a
                        director does not require subscribing to share capital,
                        hence, the ownership and voting rights of shareholders
                        does not dilute with a new person on Board.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Inability to work by existing directors</h2>
                      <p>
                        The existing directors may be unable to serve the
                        company after a certain period due to retirement or
                        other personal reasons. Whether it is a resignation by
                        the director or his death, the company needs to make
                        sure that its work is unaffected. It needs to process
                        for both discontinuations by director and appointment of
                        a new director if any.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Number of directors fall under statutory limit</h2>
                      <p>
                        The Companies Act has prescribed the minimum number of
                        directors in any company, which is 2 and 3 for Private
                        and Public company respectively. At any time during the
                        company’s existence, the number of directors shall not
                        reduce below from the limit. The company must appoint a
                        new director(s) within 6 months if the number reduces
                        below 2/3.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for Addition or Removal of director</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Passport size photograph of the director to be appointed
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      Self-attested PAN card of the director to be appointed
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Proof of Residence</h2>
                    <p>
                      Aadhar Card/ Voter ID/ Passport/ Driving License director
                      to be appointed
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Digital Signature Certificate</h2>
                    <p>
                      DSC of the continuing director and director to be removed
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process for addition or removal of directors</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2-4</Tab>
                <Tab>Day 5-7</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Consultancy for requirement of change of directors</li>
                  <li>Collection of basic Information & documents</li>
                  <li>Application for DSC (for director to be appointed)</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting necessary resolutions and documents</li>
                  <li>Provide documents required provided after signature</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Preparation of online application</li>
                  <li>Filing of application for changing directors</li>
                  <li>Sharing master data from MCA with updated information</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What should I know before changing directors in Private
                        Limited Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        While carrying change in the board of directors, the
                        company must obtain consent from its Board and members,
                        as required by passing a resolution. Further, the care
                        must be taken that the number of directors does not fall
                        below the statutory limit after removal or resignation.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Do I need to apply for another DIN, if I have already
                        procured one?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, you are not required to obtain another DIN. It is
                        permanently allotted and can be used for a person’s
                        subsequent appointment in any company/LLP.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can a Body Corporate be appointed as director in the
                        company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Only an individual can act as a director in the company.
                        Hence, if any LLP or Company is willing to be added as a
                        director in the company, only its representative may act
                        as the director.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What should I do if number of director(s) in my company
                        is less than 2/3?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        If the total number of directors is less than the number
                        prescribed, the company shall appoint a director(s) in
                        the company to fulfill the requirement within 6 months
                        from removal/resignation/death of the concerned
                        director.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I resign myself from the company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, a director can voluntarily resign. The notice of
                        resignation must be served to company stating reason of
                        resignation. Also, the resigning director needs to file
                        a form for intimation to MCA about his resignation from
                        company.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the pre-requisites to add a director in
                        company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        An individual to be added as a director must be major
                        and qualified under the Companies Act, 2013. For the
                        appointment, the consent of the members is also
                        required.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default AddRemoveDirectorCompany;
