import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import international from "../assets/international.png";
import management from "../assets/management.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function PrivateLimited() {
  const pageName = "PrivateLimited";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Private Limited Company Registration in India</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
              <div className="col col6">
                <h2>Overview</h2>
                <div className="bar"></div>
                <p>
                  A Private Limited Company is the most preferred business
                  structure for Start-ups and small businesses in India. It is
                  relatively easier to register a private limited company.
                  Ministry of Corporate Affairs is the registering authority &
                  if registered, gives you rights for doing business anywhere in
                  India & outside India. The hallmark features of a private
                  limited company are that it allows outside funding to be
                  raised easily offers the possibility to grow big & expand,
                  limits the liabilities of its shareholders, and enables them
                  to offer employee stock options to pull in top talent.
                </p>
                <p>
                  As per company law, a Private Limited company is a limited
                  liability company with a minimum of two and a maximum of 200
                  members. Although a Private limited company has limited
                  liability and a separate legal entity, at the same time it has
                  all the advantages of partnership namely flexibility, the
                  greater capital combination of different and diversified
                  abilities, etc. Before opting for a private limited company,
                  one must keep in mind:
                </p>
                <p>
                  <ol>
                    <li>
                      That Private limited company’s shares are not listed on
                      stock exchanges & hence, cannot be freely traded.
                    </li>
                    <li>
                      That the scope is limited due to restrictions in several
                      shareholders but enjoys fewer legal restrictions.
                    </li>
                    <li>
                      That the word “Pvt. Ltd.” must be suffixed in the name.
                    </li>
                    <li>
                      That at least one designated partner should be an Indian
                      Citizen.
                    </li>
                  </ol>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Private Limited Company</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>EASE OF FORMATION</Tab>
                <Tab>SEPARATE LEGAL ENTITY</Tab>
                <Tab>LIMITED LIABILITY</Tab>
                <Tab>ATTRACT INVESTMENT</Tab>
                <Tab>TRANSPARENCY & CREDIBILITY</Tab>
                <Tab>EXIT PLAN</Tab>
                <Tab>GOING INTERNATIONAL</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Easy Of Formation</h2>
                      <p>
                        A Private Limited Company can be easily registered and
                        is easy to manage and run with less legal compliances.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={fundraising}
                      alt="fund-raising-image"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={legal}
                      alt="legal-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Separate Legal Entity</h2>
                      <p>
                        The biggest advantage of a Private Limited Company is
                        that its identity is distinct from that of its members
                        which ultimately limits the liability of members.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Limited Liability</h2>
                      <p>
                        If any liability arises then its member’s assets remain
                        unaffected; Stakeholders are not liable for corporate
                        debts and liabilities.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={owners}
                      alt="owners-image"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={management}
                      alt="management-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Attractive Investment</h2>
                      <p>
                        Private limited companies easily accommodate equity
                        funding as there is a clear distinction between
                        shareholders and directors as well as limited liability.
                        Venture capitalists and private equity funds prefer to
                        invest in this structure.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Transparency & Credibility</h2>
                      <p>
                        Private Limited Company enjoys enhanced transparency as
                        the information relating to a company is available in a
                        publicly searchable database. Thus, able to win the
                        trust of the general public & improve business
                        credibility.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={owners}
                      alt="owners-image"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={management}
                      alt="management-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Exit Plan</h2>
                      <p>
                        It offers the best type of exit plan for all promoters.
                        Only the shares of a company can be sold or transferred
                        to another entity without any hassles, while the
                        business remains a going concern.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Going International</h2>
                      <p>
                        A private limited company allows FDI up to 100% through
                        an automatic route without any prior government
                        approval.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={international}
                      alt="owners-image"
                    />
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required to registration</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      PAN Card of shareholders and Directors. Foreign nationals
                      must provide a valid passport.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>Identity Proof</h2>
                    <p>
                      Aadhar card and Voter ID/ Passport/ Driving License of
                      Shareholders and Directors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Director’s Address Proof</h2>
                    <p>
                      Latest Telephone Bill /Electricity Bill/ Bank Account
                      Statement of Shareholders and Directors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Latest Passport size photograph of Shareholders and
                      Directors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Latest Electricity Bill/ Telephone Bill of the registered
                      office address
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from owner</h2>
                    <p>
                      No Objection Certificate is to be obtained from the
                      owner(s) of the registered office
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-contract"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      The rent Agreement of the registered office should be
                      provided if any
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-info"></i>
                  </div>
                  <div className="textbox">
                    <h2>Note</h2>
                    <p>
                      In the case of NRI or Foreign National, documents of
                      director (s) must be notarized or apostilled
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to Register a Company</h2>
              <div className="bar"></div>
            </div>
            <Tabs className="tabsSteps">
              <TabList>
                <Tab>Step 1</Tab>
                <Tab>Step 2</Tab>
                <Tab>Step 3</Tab>
                <Tab>Step 4</Tab>
                <Tab>Step 5</Tab>
                <Tab>Step 6</Tab>
                <Tab>Step 7</Tab>
              </TabList>

              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  <b>
                    Obtain Director Identification Number (DIN) and Digital
                    Signature Certificate (DSC):{" "}
                  </b>
                  All directors of the proposed company must obtain a DIN number
                  and a Digital Signature Certificate for filing documents with
                  the Ministry of Corporate Affairs (MCA).
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  <b>
                  Obtain Name Approval:{" "}
                  </b>
                  Ensure that the proposed name of the company should be unique and should not be similar to the existing companies registered with the Registrar of Companies (ROC).
                </p>
              </TabPanel>
              <TabPanel style={{height: '60px'}}>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  <b>
                  Draft and file the Memorandum of Association (MoA) and Articles of Association (AoA){" "}
                  </b>
                  in accordance with the provisions of the Companies Act, 2013.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  <b>
                  Obtain Certificate of Incorporation:{" "}
                  </b>
                  After filing the necessary documents with the MCA, Filing Saga will obtain the Certificate of Incorporation from the MCA confirming that the company is incorporated as per the provisions of the Companies Act, 2013.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  <b>
                  Obtain PAN and TAN:{" "}
                  </b>
                  We would also obtain the Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN) from the Income Tax Department for the company.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  <b>
                  Open a Bank Account:{" "}
                  </b>
                  After incorporation, open a bank account in the company’s name for the purpose of making payments and receiving payments.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{fontSize: '14px', textAlign: 'center'}}>
                  <b>
                  Obtain other registrations:{" "}
                  </b>
                  Obtain other registrations such as Goods and Services Tax (GST) registration, Professional Tax Registration, etc. as applicable.
                </p>
              </TabPanel>

            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Why is the Private Limited Company the best option for
                        start-ups?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        To execute the idea into a long-term business, choosing
                        the right form of business is important. For start-ups,
                        a Private Limited Company is the best option for the
                        following reasons:
                      </p>
                      <ol>
                        <li>Limited legal compliances</li>
                        <li>No minimum capital contribution</li>
                        <li>
                          Need only 2 directors and shareholders (both can be
                          the same person)
                        </li>
                        <li>Funding can be raised</li>
                        <li>Limited liability of the members</li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who can become a director in a Private Limited Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Any individual or organization or even NRI/foreign
                        national can become a director in an Indian Private
                        Limited Company. Here are the conditions to become a
                        director in an Indian private company:
                      </p>
                      <ol>
                        <li>Director must be a natural person</li>
                        <li>He must be over the age of 18 years</li>
                        <li>
                          He requires a Director Identification Number (DIN)
                        </li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How long is the registration valid for a Private Limited
                        Company in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The private limited company formation comes into
                        existence on issuing of an incorporation certificate. A
                        company remains active even though all the shareholders
                        and directors of the Pvt ltd company may be changed
                        unless it is wound up either voluntarily or by order of
                        the Court/ NCLT. Also, the Company may be struck off
                        from the register of the ROC if:
                      </p>
                      <ol>
                        <li>
                          The Company has not commenced business within a period
                          of 1 year from the date of incorporation, or
                        </li>
                        <li>
                          The Company has not complied with the Annual Filing
                          Compliance In such a case, the company will be liable
                          to be struck off from the Register of the ROC after
                          some time and the Director of such a company will be
                          held in default.
                        </li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can an NRI allow to incorporate of a Private Limited
                        Company in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        NRIs are only allowed to incorporate limited companies
                        in India and the private limited company is ideal for
                        NRIs. Also, there is no requirement to obtain prior
                        approval from the government or RBI. But, to register a
                        private company at least one director must be a resident
                        of India.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Which type of business entity is ideal for medium and
                        large-sized businesses?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A private limited company is an ideal form of business
                        entity for the majority of medium and large-sized
                        businesses as it offers advantages from limited
                        liability protection to easy transferability. But it is
                        not suitable for small businesses.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can a private limited company be converted into any
                        other type of business entity?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, conversion of a private limited company into a
                        public company or one person company, or limited
                        liability partnership is possible. But a private limited
                        company cannot be converted into a traditional
                        partnership or sole proprietorship.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the mandatory compliances for a Private Limited
                        Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>The common mandatory compliances are:</p>
                      <ol>
                        <li>Appointment of auditor</li>
                        <li>Statutory audit of accounts</li>
                        <li>Filing of annual return</li>
                        <li>Filing of financial statements</li>
                        <li>Holding Annual General Meeting (AGM)</li>
                        <li>Prepare directors' report</li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can a Private Limited Company register on residential
                        address?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        You don't need a proper office address to incorporate a
                        company. You can register your residential address as a
                        registered office address of your business with MCA
                        (Ministry of Corporate Affairs) for which some address
                        proof along with the NOC (No Objection Certificate) has
                        to be filed with the prescribed form.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is there a limit on Shareholders in a Private Limited
                        Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A minimum of 2 Shareholders is required for
                        incorporation of Pvt Ltd Company. However, a private
                        company can have a maximum of 200 Shareholders.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I become a director in a Private Limited Company if
                        I am already in employment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, there is no such legal constraint in the Companies
                        Act if not restricted by the employment agreement. All
                        you need to do is check your employment agreement
                        because it may limit you from becoming a director in
                        another company simultaneously.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Does one have to present physically to incorporate a
                        private company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, the whole Pvt ltd company incorporation process is
                        online. You can send a scanned copy of all the required
                        incorporation documents via e-mail. All the forms and
                        documents are filed electronically and even signed
                        digitally.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is foreign direct investment allowed in a private
                        limited company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, FDI (Foreign Direct Investment) is allowed in a
                        private limited company as this form of business entity
                        is easy to raise funds. FDI (Foreign Direct Investment)
                        is allowed in private limited companies for
                        non-residents through an automatic or approval route.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the limit for Directors in a Private Limited
                        Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A minimum of 2 Directors are required for incorporation
                        of Pvt Ltd Company, one of the said Directors shall be a
                        resident in India, i.e. should be in India for a period
                        of 182 days or more in the previous financial year.
                        However, a private company can have a maximum of 15
                        Directors.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to choose a name for a Private Limited Company to
                        avoid rejection?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The company name should be selected with utmost care.
                        The rules for selecting a company are:
                      </p>
                      <ol>
                        <li>
                          The name should be ended with the words 'Private
                          Limited' which are mandated by law.
                        </li>
                        <li>The name must be unique.</li>
                        <li>
                          Follow the naming guidelines for better chances of
                          approval.
                        </li>
                        <li>
                          Name should be suggestive of the company’s business.
                        </li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can a Foreign Company incorporate a Private Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        As per the Companies Act 2013, there is no minimum
                        requirement for Paid-up Share capital. However, each
                        shareholder should subscribe to a minimum of one share.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default PrivateLimited;
