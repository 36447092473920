import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function PartnershiptoPrivateLimitedCompany() {
  const pageName = "PartnershiptoPrivateLimitedCompany";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Partnership to Private Limited Company</h2>
                  <p>
                    Expand your business reach with better funding , credibility
                    and security by converting to private limited company.
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Conversion of Partnership firm into Private limited co.</h2>
                <div className="bar"></div>
                <p>
                  The major benefit of registering a private limited company is
                  that it gives a status of a separate legal entity which a
                  partnership firm does not have. In case of a partnership, a
                  partner ’s personal assets are attached and they would be held
                  personally responsible for each and every debt or liability
                  that the business incurs. Hence, with the expansion of
                  business, if partners want to increase its credibility and put
                  limited liability on its members, it is more favorable for the
                  partners to convert their partnership into a private limited
                  company. Even though statutory compliance for a private
                  limited company are higher than those of a partnership firm,
                  it gives the company more opportunities to flourish and expand
                  its reach.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>
                Benefits of conversion from partnership to a private limited
                company
              </h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Limited Liability of Owners</Tab>
                <Tab>Separation of Management and Ownership</Tab>
                <Tab>Separate legal entity</Tab>
                <Tab>Raising Capital</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Limited Liability of Owners</h2>
                      <p>
                        The liability of members/directors is limited to an
                        extent of capital contribution agreed by the members of
                        the company. The loss or debt of a company cannot be
                        assigned to members even at liquidation. Further, one
                        member is not held responsible for the actions of
                        negligence or misconduct of any other member.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Separation of Management and Ownership</h2>
                      <p>
                        The separate ownership and management help both to focus
                        on their potential works. The shareholders assign
                        responsibility to directors for operating and running
                        the company without losing control in form of voting.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Separate legal entity</h2>
                      <p>
                        A partnership is not a separate legal entity. If one of
                        the partners dies or retires, or has to leave the firm,
                        the partnership ceases to exist and so a new partnership
                        has to be formed. But this is not the case of a private
                        limited company. The private limited company is a
                        separate legal entity hence it also provides the
                        capacity to sue third parties.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Raising Capital</h2>
                      <p>
                        Raising Capital is easier in the Pvt. Ltd. Company as it
                        allows the members to participate without taking on any
                        personal accountability; unlike the general partnership
                        where all common partners have unrestrained liability.
                        The organization itself provides a number of ways to
                        raise funds in the form of private equity, ESOP, and
                        more.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>
                Documents required to convert partnership to private limited
                company
              </h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      PAN Card of shareholders and Directors. Foreign nationals
                      may provide a passport.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Identity Proof</h2>
                    <p>
                      Aadhar card, Voter ID/ Passport/ Driving License of
                      Shareholders and Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Address Proof</h2>
                    <p>
                      Telephone Bill /Electricity Bill/ Latest Bank Account
                      Statement of Shareholders and Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Latest Passport size photograph of Shareholders and
                      Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Electricity Bill/ Telephone Bill of the registered office
                      address
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from partners</h2>
                    <p>
                      No Objection Certificate to be obtained from all the
                      secured creditors of the applicant
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      Rent Agreement of the registered office should be
                      provided, if any
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Verification</h2>
                    <p>
                      A Copy of Partnership deed and Certificate of Registration
                      duty verified by at least two partners of the general
                      partnership.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Copy of ITR</h2>
                    <p>
                      A copy of latest income tax return filed by the
                      Partnership firm
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>
                Process of conversion of partnership firm into private limited
                co
              </h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1-2</Tab>
                <Tab>Day 3-5</Tab>
                <Tab>Day 6-8</Tab>
                <Tab>Day 8-10</Tab>
                <Tab>Day 11-15</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Application for Digital Signature Certificate</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Checking Name availability</li>
                  <li>Application for Name Reservation under “RUN“</li>
                  <li>Reservation of Name</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of MoA, AoA & other required documents</li>
                  <li>Stamp Duty Payment</li>
                  <li>Notarization of required documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Filing out company registration application</li>
                  <li>DIN Allotment Application</li>
                  <li>Application for PAN and TAN of company</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the minimum Capital Requirement?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        While registration, minimum authorized capital of ₹1
                        Lakh should be provided. The requirement of minimum
                        paid-up capital is eliminated as a part of Government’s
                        initiative to simplify business registration in India.
                        However, each shareholder must subscribe at least 1
                        share for the registration and amount sufficient to run
                        a business should be introduced.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who can become director in a Private Limited Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Any person above the age of 18 years can become a
                        director in the company after procuring Director
                        Identification Number (DIN). As there are no criteria
                        provided in terms of citizenship or residency, a foreign
                        national can also become a director. The application of
                        DIN Allotment is now merged with the application for the
                        formation of a company subject to a limit of maximum 3
                        DIN.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the statutory compliance required for a Private
                        Limited Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A private limited company must hold a Board Meeting at
                        least once in every 3 months. In addition to the Board
                        Meetings, an Annual General Meeting must be held by the
                        Private Limited Company at least once every year.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        what is the concept of Common Seal and is importance ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        There is no concept of a common seal in partnership. But
                        a private limited company has a common seal which
                        denotes the signature of the company and every company
                        shall have its own common seal.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is capital gain or stamp duty charged upon conversion?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No Capital Gains tax or stamp duty shall be charged on
                        transfer of property from Partnership firm to a Private
                        Limited Company.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What happens to the loss and unabsorbed depreciation of
                        the partnership firm on conversion?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The accumulated loss and unabsorbed depreciation of
                        Partnership firm are deemed to be loss/ depreciation of
                        the successor company for the previous year in which
                        conversion was effected. Thus, such a loss can be
                        carried out for a further eight years in the hands of
                        the successor company.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default PartnershiptoPrivateLimitedCompany;
