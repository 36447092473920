import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function CloseLimitedLiabilityPartnership() {
  const pageName = "CloseLimitedLiabilityPartnership";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Close a Limited Liability Partnership</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Closure of a Limited Liability Partnership</h2>
                <div className="bar"></div>
                <p>
                  As LLP is a separate legal entity, it is created by following
                  a legal proceeding hence when it comes to its closure there is
                  a proper legal procedure to be followed.
                </p>
                <p>
                  An LLP may be closed through winding-up or through striking
                  off its name from Register of LLP. The winding-up may be a
                  costly or time-consuming affair for many LLPs as it includes
                  approval from Tribunals and involvement of Liquidators.
                  Therefore, the LLP that has been non-operative since its
                  formation or for more than a year, may proceed the easy way of
                  exit i.e. Strike-off. The LLP will be declared struck-off
                  after the publication of notice by Ministry. Once it is
                  struck-off, the LLP cease to be in existence in eyes of law.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Reasons for dissolution of LLP in India</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>
                  To avoid compliance and filing responsibilities for the LLP’s
                  which are not active.
                </Tab>
                <Tab>
                  To avoid fines and penalty for late filing, it is better to
                  officially Wind Up LLP’s which are inactive.
                </Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>
                        To avoid compliance and filing responsibilities for the
                        LLP’s which are not active.
                      </h2>
                      <p>
                        The statutory compliances of maintaining an LLP are
                        higher than the cost of winding up. If the LLP is
                        dormant it’s better to wind up than fulfill the
                        compliances.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>
                        To avoid fines and penalty for late filing, it is better
                        to officially Wind Up LLP’s which are inactive.
                      </h2>
                      <p>
                        All LLPs registered in India have to file the annual
                        returns and statement of accounts for each Financial
                        Year irrespective of annual revenue or profit disregard
                        of its working. LLP that hasn’t opened a bank account or
                        commenced business activity would have to file the
                        following filings each year to maintain LLP compliance
                        and avoid penalty.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents Required to close LLP in India</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      All partners are required to submit their and the firm’s
                      PAN number as identity proof.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>
                      LLP Agreement along with any modifications made therein
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Address Proof of firm</h2>
                    <p>
                      If the registered office place is rented, rent agreement
                      and one utility bill (electricity bill, water bill,
                      property tax bill, gas receipt etc.) have to be submitted.
                      Also, NOC from landlord will be submitted.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Accounting Information</h2>
                    <p>
                      The financial statement of the LLP with Copy of
                      acknowledgment of latest INCOME TAX RETURN.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from Creditors</h2>
                    <p>
                      NoC for strike-off to be obtained from secured creditors,
                      if any
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>
                      Statement of assets and liabilities of the LLP certified
                      as true and correct by auditor/chartered accountant in
                      practice.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of winding up of LLP</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1-2</Tab>
                <Tab>Day 3-8</Tab>
                <Tab>Day 9-13</Tab>
                <Tab>Day 14 onwards</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and collection of basic Information</li>
                  <li>Provide required documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Review of the details and documents furnished</li>
                  <li>Drafting of necessary resolutions</li>
                  <li>
                    Drafting of the affidavit, indemnity bond, and other
                    documents
                  </li>
                  <li>Provide duly executed affidavit & indemnity bond</li>
                  <li>Provide signed documents after review</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Preparation of online applications </li>
                  <li>filing of LLP Form 24 with MCA</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time to approve strike-off</li>
                  <li>
                    The notice of strike-off to be published by MCA after
                    approval
                  </li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the procedure of LLP Strike-off?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The procedure to dissolve LLP is :<br />
                        1)The applicant needs to file online LLP form 24 with
                        MCA, with the required documents.
                        <br />
                        2)NOC has to be obtained from the regulatory authority
                        governing the LLP e.g. SEBI or RBI.
                        <br />
                        3)The registrar shall publish the content of the
                        application filed by the LLP on its website for a period
                        of One Month for information to the general public and
                        receive the representation on the same.
                        <br />
                        4)Upon expiry of the period specified above, where no
                        reply or representation is received, the Registrar on
                        satisfying himself for sufficient cause for the closure
                        of the LLP may pass an order to strike-off the name of
                        the LLP from the Register of the Limited Liability
                        Partnership.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the procedure for closure of LLP in India
                        through mode of striking off?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The procedure of striking off requires the filing of the
                        prescribed form along with the documents as listed in
                        the next FAQ. The Registrar shall further file publish
                        the application of striking off on the website of MCA
                        for a period of 1 month for receiving any representation
                        from the general public. The application once approved
                        will affect the change of status of LLP as “Stuck off
                        (defunct)” in the register and master data of LLP.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the easiest way of closure of LLP in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The name of Limited Liability Partnership can be applied
                        for strike off by way of declaring it as defunct for a
                        period of one year or more. This is the easiest way to
                        close the LLP as there is no involvement or requirement
                        of the Liquidator or Tribunal. To choose this mode, few
                        conditions are required to be fulfilled (prescribed
                        below).
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        whether a Liquidator is required to be appointed for
                        this procedure?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, the appointment of a liquidator or an application
                        before Tribunal is not required to be made. The
                        appointment of a liquidator is applicable in case of
                        Dissolution of the LLP through voluntary or compulsory
                        winding up only.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What should I do if we are operating under the LLP till
                        date?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        If the Limited Liability Partnership is yet operative,
                        the Partners shall wait for a period of one year to be
                        completed since the last transaction in the name of LLP
                        to opt for this method of closure of LLP. However, if
                        Partners do not wish to continue LLP for a longer
                        period, they can alternatively opt the winding-up
                        procedure with the help of Liquidator
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Do I need to apply for surrender of PAN Card of the LLP
                        after is strike off?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, application of the surrender of PAN Card is
                        required to be made. This service is not included in our
                        package.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default CloseLimitedLiabilityPartnership;
