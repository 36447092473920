import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function PrivateLimitedCompanytoLLP() {
  const pageName = "PrivateLimitedCompanytoLLP";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Private Limited Company to LLP</h2>
                  <p>
                    Convert to more simple business structure leaving behind
                    complex business compliances
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Conversion of a private company to LLP</h2>
                <div className="bar"></div>
                <p>
                  LLP structure offers the benefits of both – a partnership and
                  a company. Therefore, by converting the company to LLP, the
                  business gains more operational flexibility. The
                  characteristics of being a separate legal entity and limited
                  liability remain the same. Further, the operations and
                  management come directly in hands of the LLP partner(s) unlike
                  in Private Company where it is with the director(s).
                </p>
                <p>
                  LLP is governed by the LLP Agreement executed by the Partners.
                  It has lesser compliance requirements and it is easier to
                  operate. It preserves the benefits of a partnership with
                  providing security and credibility to a company. The
                  conversion of Private limited to LLP must be followed by the
                  approval of the Ministry by the online application providing
                  necessary documents.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of converting Pvt. Ltd Company to LLP</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Rewards and returns to partners</Tab>
                <Tab>Independent liability</Tab>
                <Tab>Less Statutory Compliance</Tab>
                <Tab>Raising Capital</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Rewards and returns to partners</h2>
                      <p>
                        The partners of the LLP receives multiple returns, such
                        as remuneration, the share of profit and also the
                        interest on capital. The remuneration is paid for active
                        participation by partners, whereas the share of profit
                        is part of profit generated from business activities.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Independent liability</h2>
                      <p>
                        No partner is liable on account of the unauthorized
                        actions of other partners. Thus, individual partners are
                        shielded from the burden of joint liability created by
                        another partner’s wrongful business decisions or
                        misconduct.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Less Statutory Compliance</h2>
                      <p>
                        The compliances to be fulfilled are less as compared to
                        a Private Limited Company. An LLP is not required to
                        maintain statutory records & registers. The requirement
                        of a statutory audit is also relaxed in case of LLP.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Operational Flexibility</h2>
                      <p>
                        The partners are directly associated with the day-to-day
                        operations and management of LLP. Unlike a company, LLP
                        is governed through the LLP Agreement which is executed
                        mutually by the partners.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>
                Documents required to convert Private limited company to LLP
              </h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC</h2>
                    <p>
                      Consent of all the directors and shareholders of the
                      company for conversion in the given format
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Clearance from tax authorities</h2>
                    <p>
                      NoC from the tax authorities is required to be obtained
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Creditors approval</h2>
                    <p>
                      List of all the secured creditors along with their consent
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Financial Statements</h2>
                    <p>
                      The financial statement of the previous year to be
                      provided aling with ITR filed
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Digital signature</h2>
                    <p>DSC of all existing directors</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Pvt.Ltd. company documents</h2>
                    <p>
                      PAN card, certificate of incorporation, GST registration
                      or any other applicable documents/registrations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of conversion into LLP</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1-2</Tab>
                <Tab>Day 3-6</Tab>
                <Tab>Day 7-14</Tab>
                <Tab>Day 15-20</Tab>
                <Tab>Day 20-25</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Consultancy and assistance for conversion</li>
                  <li>Collection of basic information & documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Application for Name Reservation for LLP</li>
                  <li>Drafting of necessary resolutions for company</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of incorporation documents</li>
                  <li>
                    Filing LLP form 17 application for conversion into LLP
                  </li>
                  <li>Online filing of LLP form 2</li>
                  <li>Government processing time to approve the application</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Application for change in PAN and TAN</li>
                  <li>Drafting of LLP Agreement</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Payment of Stamp Duty</li>
                  <li>Filing of LLP Agreement with MCA</li>
                  <li>Government processing time</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the basic conditions for converting Private
                        Limited Company to LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Following are the conditions for conversion:</p>
                      <p>
                        – &nbsp;All the members of the Company shall become the
                        partners of the LLP on conversion.
                        <br /> - No security interest in the assets of the
                        company should be subsisting or in force on the date of
                        conversion i.e. the assets are free of any encumbrance.
                        – Up-to-date Income-tax return has been filed under the
                        Income-tax Act, 1961.
                        <br />
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the annual compliance requirements for LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        LLPs are required to file an annual filing with the
                        Registrar each year. However, if the LLP has a turnover
                        of fewer than ₹40 Lakhs and/or has a capital
                        contribution of fewer than ₹25 Lakhs, the financial
                        statements needs no auditing.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the difference between LLP and a company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A basic difference between the LLP and a company lays in
                        the internal governance structure. The LLP has more
                        flexibility and less compliance requirements compared to
                        a Company.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can NRIs / Foreign Nationals be designated Partners in a
                        LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, the LLP Act 2008 allows Foreign Nationals including
                        Foreign Companies & LLPs to incorporate LLP in India,
                        provided at least one designated partner is resident of
                        India. However, the LLP/Partners would have to comply
                        with all the relevant Foreign Exchange Laws/ Rules/
                        Regulations/ Guidelines.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default PrivateLimitedCompanytoLLP;
