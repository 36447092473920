import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function AnnualCompliances() {
  const pageName = "AnnualCompliances";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Annual Compliances for Company</h2>
                  <p>
                    Blend in business ethics with expert services for Private
                    Limited Company Compliance
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is the Annual Compliance for Private Company?</h2>
                <div className="bar"></div>
                <p>
                  A Private Company is an entity enjoying a separate identity
                  which requires maintaining its active status through the
                  regular filing with MCA. For every company, it is compulsory
                  to file an annual return and audited financial statements with
                  MCA for every financial year. The RoC filing is mandatory
                  irrespective of the turnover, whether it is zero or in crore.
                  Whether a single transaction is undertaken or none, annual
                  compliances for private limited are mandatory for every
                  registered company.
                </p>
                <p>
                  Both the forms are filed to report the activities and
                  financial date for concerned Financial Year. The due dates for
                  annual filing of a company are based on the date of the Annual
                  General Meeting. The continuous failure may lead to the
                  removal of the company’s name from RoC’s register, including
                  disqualification of directors. Also, it has been observed that
                  MCA has actively taken bold steps for dealing with any such
                  failures.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Annual Compliance</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Raising Company’s Credibility</Tab>
                <Tab>Attract Investors</Tab>
                <Tab>Maintain Active Status and avoid penalties</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Raising Company’s Credibility</h2>
                      <p>
                        Compliance of law is the primary requirement for any
                        business. The date of the company’s annual return filing
                        displayed on the Master Data on MCA portal. Government
                        tenders, loan approval or for similar other purposes,
                        the regularity in compliance is a major criterion to
                        measure the credibility of an organization.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Attract Investors</h2>
                      <p>
                        While pulling funds for a company from the investors,
                        the investors demand all financial records and date
                        before finalizing the proposal. The investors may either
                        approach the company directly or can also check the
                        financial records from the MCA portal. Investors also
                        tend to favour the companies with regular compliance
                        records.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Maintain Active Status and avoid penalties</h2>
                      <p>
                        Continuous failure in filing the return turns the
                        company status to default and charges it with heavy
                        penalties. The company may also be declared as defunct
                        or removed from the RoC. The concerned directors are
                        also disqualified and debarred from their further
                        appointment. Since July 2018, an additional fee of ₹100
                        for each day of delay will be levied till the date of
                        filing.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for Annual Filing of company</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>Incorporation Document</h2>
                    <p>
                      PAN Card, Certificate of Incorporation and MoA – AoA of
                      Private Company
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Audited Financial Statements</h2>
                    <p>
                      Financial Statements must be audited by independent
                      auditor
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Audit Report & Board Report</h2>
                    <p>
                      Independent auditor’s report and Board report must be
                      provided
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>DSC of Director</h2>
                    <p>
                      Valid and active DSC of one of the directors must be
                      provided
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Copyright registration Process</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1 - Collection</Tab>
                <Tab>Day 2-4 - Preparation</Tab>
                <Tab>Day 5 onwards - Filing</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and collection of basic Information</li>
                  <li>Provide Required Documents</li>
                  <li>
                    Decide the due dates of ROC filing for Pvt. Ltd. Company
                  </li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting necessary documents</li>
                  <li>Attachment of supporting documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Filing of AOC – 4 (Financial Statements)</li>
                  <li>Filing of MGT – 7 (Annual Return)</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether the Annual Filing is necessary for all
                        companies?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, RoC compliance for Private Limited Companies are
                        necessary for every registered company. Irrespective of
                        the total turnover or the capital amount, the company
                        must comply with the annual compliance requirement. The
                        annual compliance is due after the AGM of the company
                        since its first financial year.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to reserve the name of a company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The name of a company should be formulated as mentioned
                        above. The applicants can provide the maximum of 2 names
                        with their preference order under RUN form. The
                        applicant should comply with the provisions of the Act
                        or regulations. The registrar may ask to re-submit the
                        application with a different name if names do not fall
                        under the criteria of uniqueness, relevancy or do not
                        fulfill other requirements.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the penalty for non-compliance of annual filing
                        of Private Limited Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Since July 2018, companies failing to follow the
                        statutory compliance for Private Limited will be charged
                        ₹100 for each day of a delay till the actual date of
                        filing. There is no ceiling limit to an additional fee.
                        For continuous failure, penalty apart from the
                        additional Government fee can be levied on both –
                        company and directors, including the imprisonment.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is appointment of statutory auditor falls under annual
                        compliance ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A company can opt to appoint a statutory auditor either
                        for a period of five consecutive years or till the
                        conclusion of next AGM. Therefore, an appointment of the
                        statutory auditor cannot be considered as a part of
                        annual compliance.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Which is the form filed for appointment of Statutory
                        Auditor?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Form ADT-1 is required to be filed for appointment or
                        replacement of Statutory Auditor.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Which form is an attachment to the companies Director
                        Report ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        MGT-9 is an attachment to the company’s director report
                        which is an extract of MGT-7 and addresses the
                        following.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default AnnualCompliances;
