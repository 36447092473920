import * as React from "react";
import "./MainService.css";
import "react-accessible-accordion/dist/fancy-example.css";
import Layout from "../common/Layout";

function LicensesRegistration() {
  const pageName = "LicensesRegistration";
  return (
    <Layout>
      <div className="catpage-data">
        <section className="sub-banner-section">
          <div className="container">
            <div className="banner-content">
              <h2>Licenses Registration</h2>
            </div>
          </div>
        </section>
        <section className="coming-soon">
          <div className="container">
            <div className="banner-content">
              <h2>Coming Soon</h2>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default LicensesRegistration;
