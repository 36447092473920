import * as React from "react";
import aboutimg from "../assets/digital.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function ChangeRegisteredOffice() {
  const pageName = "ChangeRegisteredOffice";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Change Registered Office</h2>
                  <p>Easily update your registered office address with MCA</p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Know about change of registered office</h2>
                <div className="bar"></div>
                <p>
                  The registered office is an official address where a company
                  is incorporated or registered. All the official communications
                  made to the company are addressed to the registered office
                  which is also displayed in the master data of the company. In
                  case of any change in address, it must be updated with MCA,
                  although the office is changed within the same city. Companies
                  Act prescribes a specified process to be followed to change
                  the address. The process of registered office change depends
                  on where the office is shifted to i.e. within the city, within
                  the jurisdiction of RoC or to other State. In all cases,
                  consent from shareholders and the approval from concerned RoC
                  is compulsory.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required to change company address</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Digital Signature Certificate</h2>
                    <p>DSC of one of authorised director to be provided</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Electricity Bill/ Telephone Bill/ Gas Bill of the new
                      address
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from owner</h2>
                    <p>
                      No Objection Certificate to be obtained from the owner of
                      registered office
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      Rent Agreement or conveyance deed of the registered office
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of change in registered office</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2-3</Tab>
                <Tab>Day 4-5</Tab>
                <Tab>Day 5 onwards</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Consultancy for a change of official address</li>
                  <li>Collection of basic Information & documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of necessary resolutions and documents</li>
                  <li>Provide documents required drafted after signature</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Preparation of application to update business address</li>
                  <li>Application filing for approval of MCA</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can a registered office be a residential property?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, There is no compulsion for a registered office to
                        be a commercial property. A residential or commercial
                        address can be provided.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether change in office requires alteration of MoA?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Changing your business address does not require the
                        alteration of MoA, as far as the proposed registered
                        office is situated within the same State. If the office
                        is shifted to other State, situation clause that refers
                        to State must be altered.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When should the RoC be informed about the change in
                        registered office?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        RoC must be notified of the change in registered office
                        by filing the appropriate documents within 30 days of a
                        change of premises. If the office is shifted outside
                        State, prior approval of RoC and RD must be obtained.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can a registered office be outside India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A registered office cannot be situated outside India.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What records must be maintained at the registered
                        office?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        All books of accounts must be kept at the registered
                        office of the company. But if they are kept at any other
                        place in India as decided by the Board of Directors, the
                        company must send a notice in writing to the Registrar
                        of that place, mentioning the full address of the place
                        where the books of accounts are kept.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ChangeRegisteredOffice;
