import * as React from "react";
import onepersonimg from "../assets/One-Person-Company.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function DubaiIncorporation() {
  const pageName = "DubaiIncorporation";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Company Incorporation In Dubai</h2>
                  <p>
                    A Dubai Limited Liability Company allows foreigners’
                    ownership of up to 49% of a company. Whereas, a Dubai Free
                    Zone Company permits foreign investors to own 100% ownership
                    of the company.
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Company registration in Dubai - An Overview</h2>
                <div className="bar"></div>
                <p>
                  Every businessman in the world has a dream of starting his own
                  company in Dubai but the process for the same is not easy.
                  Strict rules and regulations by the government and tedious
                  process make company registration in Dubai a difficult and
                  troublesome task.
                </p>
                <p>
                  Before starting a business in Dubai one has to do thorough
                  research on every aspect and must go through all the
                  information about the business activity they are to undertake
                  and ensure that they comply with all the business licensing
                  requirements.
                </p>
                <p>
                  Obtaining a trade license is mandatory for starting any
                  business activity in Dubai. The concerned authority which is
                  responsible for issuing local Dubai company licenses is the
                  Economic Development Department, functioning in different
                  locations.
                </p>
              </div>
              <div className="col col6">
                <img src={onepersonimg} alt="LLP-Registration" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Registration in Dubai</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Types Of Trade</Tab>
                <Tab>Types of Companies</Tab>
                <Tab>Limited Liability</Tab>
                <Tab>Onshore Company</Tab>
                <Tab>Offshore Company</Tab>
                <Tab>Benefits</Tab>
              </TabList>

              <TabPanel>
                <h2>
                  The different types of trade licenses issued in Dubai include
                  -
                </h2>
                <ul className="list-style-disc">
                  <li>
                    Commercial Licenses - Issued to companies engaged in trading
                    activities.
                  </li>
                  <li>
                    Industrial Licenses - Issued to the companies engaged in
                    manufacturing.
                  </li>
                  <li>
                    Professional Licenses - Issued to the companies working as
                    service providers, professionals, artisans, and craftsmen.
                  </li>
                </ul>
              </TabPanel>
              <TabPanel>
                <h2>
                  What are the different types of companies which can be
                  incorporated in Dubai?
                </h2>
                <p>
                  <strong>
                    There Are Numerous Types Of Entities Which Can Be
                    Incorporated In Dubai Such As-
                  </strong>
                </p>
                <ul className="list-style-disc">
                  <li>Limited liability companies,</li>
                  <li>Private and public joint stock companies,</li>
                  <li>Partnerships,</li>
                  <li>Branch offices,</li>
                  <li>Subsidiaries,</li>
                  <li>Free zone limited liability companies,</li>
                  <li>Free zone establishments.</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <h2>Limited Liability Company(LLC)</h2>
                <p>
                  In the Limited Liability Company, the liability of the
                  shareholders is limited to their capital. The maximum number
                  of shareholders in an LLC in Dubai is 50 and minimum is 2.
                  Except for insurance, banking, or monetary investment, the
                  company can carry any business activity.
                </p>
              </TabPanel>
              <TabPanel>
                <h2>Onshore Company</h2>
                <p>
                  An onshore company is a business arrangement which is formed
                  and managed within the jurisdiction of the company. There are
                  numerous perks of having an onshore company which include
                  favorable regulation, taxation policies for companies
                  operating outside of the jurisdiction, broad networks of tax
                  treaties, developed business, banking and supporting sectors,
                  etc. Registration of the onshore company in U.A.E. is crucial
                  and beneficial. Generally, owners manage their business by
                  sitting at their native place, but with the Onshore company,
                  one can easily have the working office and can manage them
                  efficiently.
                </p>
              </TabPanel>
              <TabPanel>
                <h2>Offshore Company</h2>
                <p>
                  An Offshore Company is a legal business configuration that is
                  formed and managed outside the jurisdiction of the company.
                </p>
              </TabPanel>
              <TabPanel>
                <h2>
                  Benefits of setting up your business in Dubai Free Zone are:
                </h2>
                <ul className="list-style-disc">
                  <li>
                    100% exemption from taxes on personal or corporate income.
                  </li>
                  <li>
                    Complete ownership for investors regardless of their
                    nationality and domicile.
                  </li>
                  <li>100% repatriation of capital and profits.</li>
                  <li>Exclusion from all import and export duties .</li>
                  <li>
                    Exemption from all the corporate taxes for 15 years, with a
                    renewal option for 15 years with no requirement of deposit
                    of capital.
                  </li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for registering a company in Dubai</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col12">
                <div className="textbox">
                  <ul className="list-style-disc">
                    <li>
                      The articles of association of the company which should
                      contain information about the shareholders, directors and
                      business activities of the company.
                    </li>
                    <li>
                      Translated and notarized copies of the passports of the
                      shareholders and the directors of the company.
                    </li>
                    <li>Duly completed forms issued by the Trade Registrar.</li>
                    <li>
                      Registration in Dubai can be complicated hence it is
                      advisable that you seek the assistance of an expert
                      company registration consultant to proceed with your
                      registration.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Why Filing Saga</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Business Days</Tab>
                <Tab>Customer Score</Tab>
                <Tab>Strong Team</Tab>
              </TabList>

              <TabPanel>
                <p>
                  At Filing Saga, we can deliver all your documentation
                  requirements in just four working days. And if you're not
                  totally satisfied, we'll take another couple of days to work
                  on the modifications you need. All at the lowest price, both
                  online and offline.
                </p>
              </TabPanel>
              <TabPanel>
                <p>
                  We make your interaction with government as smooth as is
                  possible by doing all the paperwork for you. We will also give
                  you absolute clarity on the process to set realistic
                  expectations.
                </p>
              </TabPanel>
              <TabPanel>
                <p>
                  Our team of experienced business advisors are a phone call
                  away, should you have any queries about the process. But we'll
                  try to ensure that your doubts are cleared before they even
                  arise.
                </p>
              </TabPanel>
            </Tabs>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default DubaiIncorporation;
