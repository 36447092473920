import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { API_URL } from "../constants";
import "./applyForm.scss";
import Loader from "./Loader";
import { toast, Toaster } from "react-hot-toast";

const ApplyForm = () => {
  const [formFields, setFormFields] = useState([]);
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [fileSelected, setFileSelected] = useState();
  const [formValue, setFormvalue] = useState([]);
  const [loading, setLoading] = useState(false);
  const pageName = location.search.slice(1);
  useEffect(() => {
    const params = {
      pageName,
    };
    getFormFields(params);
  }, []);
  const getFormFields = async (params) => {
    const res = await axios.get(`${API_URL}/api/getFormFields`, {
      params: { ...params },
    });
    setFormFields(res.data.data);
  };

  const handleTextChange = (e) => {
    if (formValue.includes(e.target.placeholder)) {
      return null;
    } else {
      setFormvalue([
        ...formValue,
        {
          name: e.target.placeholder,
          value: e.target.value,
          type: e.target.type,
        },
      ]);
    }
  };
  const handleFileSelect = async (e) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const response = await axios({
      method: "post",
      url: `https://backend.filingsaga.com/api/upload`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (response.data.data) {
      setLoading(false);
      toast.success("File Uploaded");
    }
    if (formValue.includes(e.target.placeholder)) {
      return null;
    } else {
      setFormvalue([
        ...formValue,
        {
          name: e.target.placeholder,
          value: e.target.value,
          type: e.target.type,
        },
      ]);
    }
  };
  console.log(formValue, "form");

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    const res = await axios.post(
      "https://backend.filingsaga.com/api/createTicket",
      {
        category: "abc",
        formDataArray: {
          formValue,
        },
      },
      { headers: headers }
    );
    if (res.data.status === 200) {
      toast.success("Lead Successfully Created");
      setLoading(false);
    }
  };

  return (
    <div class="form_wrapper">
      {loading && (
        <Loader
          style={{ width: "1000px", height: "1400px", margin: " 8% auto 0" }}
        />
      )}
      <div class="form_container">
        <div class="title_container">
          <h2> Registration Form</h2>
        </div>
        <div class="row clearfix">
          <div class="">
            <form onSubmit={handleSubmit}>
              <div class="input_field">
                {formFields?.text?.map((d) => (
                  <>
                    <label>{d?.formValue}</label>
                    <input
                      name={d?.valueDisplayName}
                      placeholder={d?.formValue}
                      type="text"
                      onChange={handleTextChange}
                    />
                  </>
                ))}
              </div>
              <div class="input_field">
                {formFields?.multiple?.map((d) => (
                  <>
                    <label>{d?.formValue}</label>
                    <input
                      name={d?.valueDisplayName}
                      placeholder={d?.formValue}
                      type="text"
                      onChange={handleTextChange}
                    />
                  </>
                ))}
              </div>
              <div class="input_field">
                {formFields?.file?.map((d) => (
                  <>
                    <label>{d?.formValue}</label>
                    <input
                      name={d?.valueDisplayName}
                      placeholder={d?.formValue}
                      type="file"
                      onChange={handleFileSelect}
                    />
                    <Toaster position="bottom-center" />
                  </>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: "200px",
                }}
              >
                <button
                  style={{
                    width: "500px",
                    height: "40px",
                    borderRadius: "10px",
                    background: "#086ad8",
                    color: "white",
                    cursor: "pointer",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyForm;
