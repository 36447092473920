import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function ChangeCompanyName() {
  const pageName = "ChangeCompanyName";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Change Company Name</h2>
                  <p>Enhance Brand value with company name change</p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Know about company name change</h2>
                <div className="bar"></div>
                <p>
                  A company’s name can be changed at any time after its
                  incorporation. It being an entity regulated by law, it has to
                  follow a specific process provided by the Companies Act, 2013.
                  The process includes conducting board meeting and member’s
                  meeting for their respective consent, which will be followed
                  by name reservation and approval from Central Government for
                  said change. Fresh Certificate of Incorporation will be issued
                  by RoC in new name after updating the Register of Companies.
                  The company name change does not impact the existence of the
                  company, which leaves all the assets and liabilities
                  unaffected.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Why change company name?</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Voluntary Change</Tab>
                <Tab>Change in Business Objective</Tab>
                <Tab>Enhance Brand Value (Rebranding)</Tab>
                <Tab>Discard boundaries assumed by name</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Voluntary Change</h2>
                      <p>
                        The directors or the shareholders may voluntarily decide
                        to change the business name mutually for any justified
                        reason. Names can be used to reflect the vision or any
                        characteristic of the company. To make the name
                        relatable and easy for the consumer can also be a reason
                        to change business name.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Change in Business Objective</h2>
                      <p>
                        Change in the name may be required due to change of
                        business objective. If the name does not truly reflect
                        the altered objective, it can be changed in line with
                        the objective. The company must apply for a change if
                        the Registrar has directed to do so in such case.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Enhance Brand Value (Rebranding)</h2>
                      <p>
                        Name assumes the brand value of the company and
                        intention to improve the brand value is often a reason
                        to change the name. Also, industries where trends change
                        over a period of time, an appropriate name can be chosen
                        to be in line with the trend and create a distinct
                        identity. Change of name can prove to be a good
                        marketing strategy to enhance brand value.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Discard boundaries assumed by name</h2>
                      <p>
                        By use of words representing geographical region or
                        offerings, the name defines the boundary of operations.
                        Expansion by capturing wide market includes demands
                        removing boundaries assumed by the name. Further, if the
                        name has become irrelevant to products or services
                        offered it must be changed to target the right audience.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required to change the limited company name</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Digital Signature Certificate</h2>
                    <p>DSC of one of authorised director to be provided</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>MoA & AoA</h2>
                    <p>A copy of latest amended MoA and AoA of the company</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>CoI</h2>
                    <p>
                      Certificate of Incorporation of company to be provided
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>Copy of PAN card of the company to be provided</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to change company name online</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1-4</Tab>
                <Tab>Day 5-6</Tab>
                <Tab>Day 7-8</Tab>
                <Tab>Day 8 onwards</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Conducting Board Meeting and passing a resolution</li>
                  <li>Collection of basic Information & documents</li>
                  <li>Checking company name availability</li>
                  <li>Reservation of name through “RUN”</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>
                    Conducting meeting of members and passing a resolution
                  </li>
                  <li>
                    Preparation of documents relating to the application of
                    change
                  </li>
                  <li>Provide documents prepared after signature</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Preparation and filing application with MCA</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time</li>
                  <li>Issuance of fresh CoI with a new name</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Which approvals are required for company name change?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        To effect the change in name, the company has to obtain
                        following consent or approval:
                        <br />
                        1. Consent from Board
                        <br />
                        2. Consent from the members of the company
                        <br />
                        3. Approval from concerned RoC
                        <br />
                        4. Approval from Central Government
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is there any restriction change limited company name?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        There is no restriction provided for the name change of
                        a private limited company, which may be the case for a
                        Public company. The names chosen for application must be
                        in conformity with the rules prescribed.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to reserve new name for company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Ministry has introduced a web form “RUN” (Reserve Unique
                        Name) for reservation of new name of existing companies.
                        The company can apply with 2 different names with its
                        significance. The names should be unique and in
                        accordance with the provisions.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When the application for change should be made?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The application for name change must be made within 30
                        days of obtaining the consent of members in General
                        Meeting (held after reservation of name). Intimation of
                        resolution is made in form MGT – 14 whereas the
                        application for the name change is made in form INC –
                        24.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether MoA & AoA is required to be altered?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, MoA and AoA, both must be altered with the new
                        name. The copy of the proposed alteration is filed in
                        INC – 24.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether change of object can be processed
                        simultaneously?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        If the name change is made due to change in the main
                        object, both the procedures can be carried on
                        simultaneously. As the approval of the Government is
                        involved, the period can vary based on the response and
                        time taken by RoC.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ChangeCompanyName;
