import * as React from "react";
import onepersonimg from "../assets/One-Person-Company.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import management from "../assets/management.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";
function SoleProprietorship() {
  const pageName = "SoleProprietorship";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Sole Proprietorship Firm Registration</h2>
                  <p>
                    Start, control, and manage your own business with a Sole
                    Proprietorship registration!
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Overview</h2>
                <div className="bar"></div>
                <p>
                  The sole proprietorship is the simplest business form under
                  which one can operate a business. The sole proprietorship is
                  not a legal entity. It simply refers to a person who owns the
                  business and is personally responsible for its debts. A sole
                  proprietorship can operate under the name of its owner or it
                  can do business under a fictitious name. The fictitious name
                  is simply a trading name -it does not create a legal entity
                  separate from the sole proprietor owner.
                </p>
                <p>
                  This type of business is the easiest and cheapest form to
                  start. For this reason, it is common among small businesses,
                  freelancers, and other self-employed individuals.
                </p>
                <p>
                  Because a sole proprietorship is indistinguishable from its
                  owner, sole proprietorship taxation is quite simple. The
                  income earned by a sole proprietorship is income earned by its
                  owner.
                </p>
                <p>
                  A sole proprietorship may transform into another, more complex
                  business structure if the business grows substantially.
                </p>
              </div>
              <div className="col col6">
                <img src={owners} alt="owners-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Sole Proprietorship Firm</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>EASE OF FORMATION</Tab>
                <Tab>FULL MANAGEMENT CONTROL</Tab>
                <Tab>RETAIN 100% OF PROFITS</Tab>
                <Tab>FEWER COMPLIANCES</Tab>
                <Tab>TAX BENEFITS</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>EASE OF FORMATION</h2>
                      <p>
                        A Sole Proprietorship can be established easily,
                        instantly, and inexpensively and is easy to manage and
                        run with less legal compliances.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img style={{ height: "250px" }} src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img style={{ height: "250px" }} src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>FULL MANAGEMENT CONTROL</h2>
                      <p>
                        The proprietor gets all the rights to decide what to do
                        and how to operate with no interference from a third
                        party.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img style={{ height: "250px" }} src={management} alt="management-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>RETAIN 100% OF PROFITS</h2>
                      <p>
                      There is no legal separation between the owner and the business, so the owner gets 100% of the profits. 
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>FEWER COMPLIANCES</h2>
                      <p>
                        Unlike a corporate firm, there are minimal compliances
                        or disclosure requirements during the whole financial
                        year. Hence, Government interference is also very less.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img style={{ height: "250px" }} src={owners} alt="owners-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img style={{ height: "250px" }} src={management} alt="management-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>TAX BENEFITS</h2>
                      <p>
                        The proprietorship business is not treated separately
                        for income tax purposes and the rates of tax are lower
                        for individuals compared to other business structures
                        like company partnership ship.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents Required for Registration</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>A self-attested copy of PAN Card of proprietor</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>Aadhar Card</h2>
                    <p>Self- attested copy of Aadhar Card of proprietor</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Bank Details</h2>
                    <p>Details of the saving or current account of bank</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to Register a Sole Proprietorship Firm</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Step 1</Tab>
                <Tab>Step 2</Tab>
                <Tab>Step 3</Tab>
                <Tab>Step 4</Tab>
                <Tab>Step 5</Tab>
                <Tab>Step 6</Tab>
                <Tab>Step 7</Tab>
              </TabList>

              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  The first step in setting up a sole proprietorship firm is to
                  decide on the name of the business. The name should be unique
                  and should not be in violation of any existing trademarks.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  The business owner should obtain a Permanent Account Number
                  (PAN) card from the Income Tax Department. This PAN card is
                  mandatory for all businesses registered in India.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  The business owner must open a separate bank account for the
                  business. This account should be in the name of the business
                  and should be used exclusively for business transactions.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  The business owner should register for GST if the business has
                  an annual turnover of more than Rs. 20 lakhs. The registration
                  process is done online through the GST portal.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  The business owner must obtain a shop and establishment
                  licence from the local Municipal Corporation. This licence is
                  required for all businesses operating in India.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  Depending on the nature of the business, the business owner
                  may need to obtain other licences or permissions from the
                  local Municipal Corporation or other government agencies.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  It is important to maintain proper accounts of all business
                  transactions. The business owner can purchase accounting
                  software to make the task easy.
                </p>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Does the proprietor have to be an Indian to start a
                        Proprietorship firm?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Proprietor must be an Indian citizen and a Resident
                        of India. There is no approval required prior to the
                        commencement of business. But, Non-Resident Indians
                        (NRI) and Persons of Indian Origin can invest or start
                        sole their proprietorship business only with prior
                        approval of the Government of India.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Under which Government Authority is the application of
                        Sole Proprietorship Firm Registration is submitted?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Sole Proprietorship is an unorganized business structure
                        and there is no specific law enforced for the said
                        registration. LegalWiz.in provides services for Sole
                        Proprietorship registration under MSME (Micro, Small and
                        Medium Establishments) Development Act, 2006 of Central
                        Government. The business entity must fulfill the
                        registration requirement.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the minimum amount of capital required to
                        register sole proprietorship online?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        One can start a Sole Proprietorship Firm with any amount
                        of Capital. An amount sufficient to commence the
                        business should be introduced. As there is no
                        restriction on infusion or withdrawal of amount, the
                        proprietor can change capital anytime. Introducing the
                        capital in Business is the sole decision of an owner of
                        the firm i.e. the Proprietor.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to open a bank account for a Proprietorship?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        To open the bank account in the name of Sole
                        Proprietorship Firm, Reserve Bank of India mandates that
                        the proprietor need to provide two forms of registration
                        for the Proprietorship along with the PAN Card, identity
                        proof and address proof of the Proprietor.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to register the name of a sole Proprietorship
                        business?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        There is no registry or regulation for registering the
                        name of a Sole Proprietorship Firm. Therefore, the name
                        of a sole proprietorship company can adopt any name
                        based on its availability such that it does not infringe
                        on registered trademarks. Since there is no registry or
                        regulation for registering the name of a Proprietorship,
                        the only way to ensure the exclusive use of the business
                        name is to obtain a Trademark Registration of a business
                        name.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Will my Proprietorship firm have a separate legal
                        identity or new PAN card after registration?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        There is no separate identity of a proprietor or its
                        business even after the sole proprietorship firm is
                        registered. Hence, the PAN card of the firm and the
                        Proprietor is the same. The assets and liabilities for
                        the proprietor and the proprietorship also remain the
                        same.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the benefits of Proprietorship Firm
                        Registration under MSMED Act?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The registered entity under MSMED Act can avail
                        subsidies, incentives, and schemes launched by the
                        Central Government with respect to the specific Business
                        on the basis of a registration certificate.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I have partners in a Sole Proprietorship Business?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Characteristics of a Sole Proprietorship Firms is such
                        that it cannot have any other Business Partner as it is
                        owned and controlled by one person itself. If your
                        Business requires the involvement of Partners, you may
                        opt for a Partnership Firm, Private Limited Company, or
                        Limited Liability Partnership, as the case may be.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who can apply for registration under MSMED Act?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Any business entity can apply for this registration.
                        However, recently, the Central Government has excluded
                        the activities of Trading to be registered under this
                        Act. The business with Trading Activities can apply for
                        registration under Shop & Establishments Act.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can other people invest in a Proprietorship?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Sole Proprietorship Business entity is owned, managed
                        and controlled by one person. So, Proprietorship firms
                        cannot issue shares nor have investors.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the compliance requirements for starting a Sole
                        Proprietorship Firm in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Proprietorship will have to file their annual tax return
                        with the Income Tax Department. Other tax filings such
                        as GST returns filing may also be necessary from time to
                        time, based on the business activities performed and
                        registrations opted. However, one needs to file the
                        annual report or accounts with the Ministry of Corporate
                        Affairs, which is required for Limited Liability
                        Proprietorships and Companies.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I later convert my Proprietorship Business into a
                        Company or LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A proprietorship can be taken over by the company or
                        LLP. However, the procedures for same are cumbersome,
                        expensive and time-consuming. Therefore, it is wise for
                        many entrepreneurs to consider starting the LLP or
                        Company instead of a Proprietorship after consultation
                        with experts.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default SoleProprietorship;
