import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function PartnershiptoLLP() {
  const pageName = "PartnershiptoLLP";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Convert Partnership to LLP</h2>
                  <p>
                    Get entry into flexible corporate structure with Limited
                    Liability
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Convert Proprietorship to LLP</h2>
                <div className="bar"></div>
                <p>
                  Limited Liability Partnerships have an upper hand over the
                  general partnership structure as it is much more beneficial
                  for the partners involved. LLP is a separate legal entity with
                  compulsory registration with the central government, which is
                  not the case with the partnership. It is a business structure
                  that integrates the advantages of the company’s corporate
                  structure and the flexibility of the partnership, i.e. for
                  organizing their internal composition and operation as a
                  partnership. Therefore conversion of partnership firm into LLP
                  is a good business decision to secure the partners’ rights and
                  limit their liabilities.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of partnership to LLP conversion</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Limited Liability of Owners</Tab>
                <Tab>Separate legal entity</Tab>
                <Tab>Tax benefits</Tab>
                <Tab>Raising Capital</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Limited Liability of Owners</h2>
                      <p>
                        The liability of Partners is limited to an extent of
                        capital contribution as agreed by the partners in the
                        LLP Agreement. The loss or debt of LLP cannot be
                        assigned to partners even at liquidation. Further, one
                        partner is not held responsible for the actions of
                        negligence or misconduct of any other partner.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Separate legal entity</h2>
                      <p>
                        The partnership is not a separate legal entity. In case
                        if the partner(s) dies or retires or in any other case
                        has to leave the firm, the partnership ceases to exist.
                        In this case, a new partnership has to be formed; but
                        this is not the case with a limited liability
                        partnership. Limited liability partnership is a separate
                        legal entity.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Tax benefits</h2>
                      <p>
                        LLP saves the Dividend Distribution Tax, Minimum
                        Alternative Tax, and Income Tax because interest and
                        remuneration are paid to partners as a salary that is
                        payable to directors.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Raising Capital</h2>
                      <p>
                        Raising Capital is easier in the LLP structure as it
                        allows a limited partner to participate without taking
                        on any accountability, unlike the general partnership
                        where all common partners have unrestrained liability.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents Required for the partnership to LLP conversion</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      PAN Card of all partners and the firm Foreign nationals
                      may provide passport
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Address Proof</h2>
                    <p>
                      Latest Bank statement/ Utility bill in the name of
                      director and nominee which should not be older than two
                      months
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>ID Proof</h2>
                    <p>
                      Aadhar Card/ Voter ID/ Passport/ Driving License of all
                      partners
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>Latest Passport size photograph of all partners</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Electricity Bill/ Telephone Bill of the registered office
                      address
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from owner</h2>
                    <p>
                      No Objection Certificate to be obtained from the owner of
                      registered office
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      Rent Agreement of the registered office should be
                      provided, if any
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>RoF</h2>
                    <p>
                      Certificate In case the partnership firm is registered,
                      RoF certificate is compulsory
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Note</h2>
                    <p>
                      In case of NRI or Foreign National, documents of partner
                      must be notarized or apostilled
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process for Partnership to LLP Conversion</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1-2</Tab>
                <Tab>Day 3-6</Tab>
                <Tab>Day 7-14</Tab>
                <Tab>Day 15-20</Tab>
                <Tab>Day 20-25</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Application for Digital Signature Certificate</li>
                  <li>Application for DIN allotment of Designated Partners</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Checking Name availability</li>
                  <li>Application for Name Reservation</li>
                  <li>Reservation of LLP Name</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting documents for incorporation</li>
                  <li>Filing application for conversion into LLP</li>
                  <li>Certificate of Incorporation</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Application for PAN and TAN of LLP</li>
                  <li>Drafting of LLP Agreement</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Stamp Duty Payment</li>
                  <li>Filing LLP Agreement</li>
                  <li>Government processing time</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the primary requirements for the conversion of
                        a partnership firm into an LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The partnership is required to consist of the same
                        partners that were present in the original Partnership
                        and in the same proportion in which their capital
                        accounts stood in the books of the Firm on the date of
                        conversion. Therefore, the LLP cannot have more or less
                        partners than the extant Partnership Firm, and any
                        changes in the number of partners can be made only after
                        conversion into the LLP.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to reserve the name for a Limited Liability
                        Partnership?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        LLP name is reserved through an online form. In
                        accordance with the prescribed regulations, the partners
                        can provide a maximum of 6 names in preferential order
                        to reserve any one. The Registrar may ask to re-submit
                        the application with a different name if given names do
                        not fall under criteria of uniqueness, relevancy or do
                        not fulfill the other requirements.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is there any minimum capital requirement for LLP
                        registration in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No. There is no minimum amount prescribed to form an
                        LLP. It can start off with any amount of capital
                        demanded by the business. Although there is no minimum
                        requirement, every partner must make a contribution to
                        LLP. The amount of capital contribution is disclosed in
                        the LLP Agreement and amount of stamp duty to be paid is
                        decided by total contribution amount.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is LLP Agreement? Does it require filing with MCA?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        LLP Agreement is an agreement executed by all the
                        designated partners and partners after LLP
                        incorporation. The agreement prescribes all the clauses
                        related to business; including the rights, role, duties
                        and responsibilities of partners. The agreement must be
                        filed within 30 days of the issue of a certificate of
                        incorporation. Failure to which will charge an
                        additional fee of ₹ 100 per day till the date of filing.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether any capital gain is payable on conversion of
                        partnership into LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        LLP and general partnership are treated equivalently
                        (except for recovery purpose) in the Act; the conversion
                        from a general partnership firm to LLP will have no tax
                        implication. This is true if the rights and obligation
                        of the partners remain the same after conversion and if
                        there is no transfer of any asset or liability after the
                        conversion. If there is a violation of these conditions,
                        the provision of capital gain will apply.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can the same name be used for the newly incorporated
                        LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Generally, the basic purpose of conversion is for
                        keeping the same name to maintain the brand identity in
                        the market. To convert the LLP under the original name
                        it is essential to attach any valid proof that
                        corroborates the claim of use of the brand name by the
                        firm.in such cases, MCA grants the approval on the basis
                        of documents attached in the concerned form for name
                        reservation.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default PartnershiptoLLP;
