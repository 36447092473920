import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import GettingSetUp from "./components/GettingSetUp";
import LimitedLiability from "./components/LimitedLiability";
import PatentSearch from "./components/PatentSearch";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Forgotpassword from "./components/Forgotpassword";
import Contact from "./components/Contact";
import PrivateLimited from "./components/PrivateLimited";
import OnePersonCompany from "./components/OnePersonCompany";
import SoleProprietorship from "./components/SoleProprietorship";
import PartnershipFirm from "./components/PartnershipFirm";
import IndianSubsidiary from "./components/IndianSubsidiary";
import Section8Company from "./components/Section8Company";
import ProducerCompany from "./components/ProducerCompany";
import USIncorporation from "./components/USIncorporation";

import Pricing from "./components/Pricing";

import SingaporeIncorporation from "./components/SingaporeIncorporation";
import UKIncorporation from "./components/UKIncorporation";
import NetherlandsIncorporation from "./components/NetherlandsIncorporation";
import HongKongIncorporation from "./components/HongKongIncorporation";
import DubaiIncorporation from "./components/DubaiIncorporation";
import ProtectYourBrand from "./components/ProtectYourBrand";
import StayingCompliant from "./components/StayingCompliant";
import GrowYourBusiness from "./components/GrowYourBusiness";
import LegalFormsAndAgreements from "./components/LegalFormsAndAgreements";
import LicensesRegistration from "./components/LicensesRegistration";
import TrademarkRegistration from "./components/TrademarkRegistration";
import TrademarkObjectionReply from "./components/TrademarkObjectionReply";
import TrademarkOpposition from "./components/TrademarkOpposition";
import TrademarkAssignment from "./components/TrademarkAssignment";
import TrademarkRenewal from "./components/TrademarkRenewal";
import ProvisionalPatent from "./components/ProvisionalPatent";
import PermanentPatent from "./components/PermanentPatent";
import CopyrightRegistration from "./components/CopyrightRegistration";
import AnnualCompliances from "./components/AnnualCompliances";
import AnnualFilingForLLP from "./components/AnnualFilingForLLP";
import DIR3KYCFiling from "./components/DIR3KYCFiling";
import INC20AFiling from "./components/INC20AFiling";
import INC22AFiling from "./components/INC22AFiling";
import NGOCompliance from "./components/NGOCompliance";
import Section8Compliance from "./components/Section8Compliance";
import Sec80GSec12A from "./components/Sec80GSec12A";
import NBFCCompliance from "./components/NBFCCompliance";
import GSTFiling from "./components/GSTFiling";
import GSTAdvisory from "./components/GSTAdvisory";
import TDSReturnFiling from "./components/TDSReturnFiling";
import IncomeTaxFiling from "./components/IncomeTaxFiling";
import AccountingandBookkeeping from "./components/AccountingandBookkeeping";
import ProprietorshiptoPartnership from "./components/ProprietorshiptoPartnership";
import ProprietorshiptoLLP from "./components/ProprietorshiptoLLP";
import ProprietorshiptoPrivateLimitedCompany from "./components/ProprietorshiptoPrivateLimitedCompany";
import ProprietorshiptoOPC from "./components/ProprietorshiptoOPC";
import PartnershiptoLLP from "./components/PartnershiptoLLP";
import PartnershiptoPrivateLimitedCompany from "./components/PartnershiptoPrivateLimitedCompany";
import LLPtoPrivateLimitedCompany from "./components/LLPtoPrivateLimitedCompany";
import OPCtoPrivateLimitedCompany from "./components/OPCtoPrivateLimitedCompany";
import PrivateLimitedCompanytoLLP from "./components/PrivateLimitedCompanytoLLP";
import PrivateCompanytoPublicCompany from "./components/PrivateCompanytoPublicCompany";
import AddRemoveDirectorCompany from "./components/AddRemoveDirectorCompany";
import AddRemovePartnerLLP from "./components/AddRemovePartnerLLP";
import ChangeBusinessActivity from "./components/ChangeBusinessActivity";
import ChangeRegisteredOffice from "./components/ChangeRegisteredOffice";
import ChangeCompanyName from "./components/ChangeCompanyName";
import ChangeLLPAgreement from "./components/ChangeLLPAgreement";
import ChangePartnershipDeed from "./components/ChangePartnershipDeed";
import IncreaseAuthorisedShareCapital from "./components/IncreaseAuthorisedShareCapital";
import ClosePrivateLimitedCompany from "./components/ClosePrivateLimitedCompany";
import CloseLimitedLiabilityPartnership from "./components/CloseLimitedLiabilityPartnership";
import CloseOnePersonCompany from "./components/CloseOnePersonCompany";
import DissolvePartnershipFirm from "./components/DissolvePartnershipFirm";
import Fundraising from "./components/Fundraising";
import PitchDeck from "./components/PitchDeck";
import BusinessPlan from "./components/BusinessPlan";
import Valuation from "./components/Valuation";
import BankProjectReport from "./components/BankProjectReport";
import BusinessLoans from "./components/BusinessLoans";
import ServiceLevelAgreement from "./components/ServiceLevelAgreement";
import SharePurchaseAgreement from "./components/SharePurchaseAgreement";
import FranchiseAgreement from "./components/FranchiseAgreement";
import ShareholdersAgreement from "./components/ShareholdersAgreement";
import TermSheet from "./components/TermSheet";
import BoardResolutions from "./components/BoardResolutions";
import NonDisclosuresAgreement from "./components/NonDisclosuresAgreement";
import NonCompeteAgreement from "./components/NonCompeteAgreement";
import MemorandumofUnderstanding from "./components/MemorandumofUnderstanding";
import GSTRegistration from "./components/GSTRegistration";
import LUTunderGST from "./components/LUTunderGST";
import FSSAILicense from "./components/FSSAILicense";
import ImportExportCodeRegistration from "./components/ImportExportCodeRegistration";
import StartupIndiaRegistration from "./components/StartupIndiaRegistration";
import VerifyOtp from "./components/VerifyOtp";
import ResetPassword from "./components/ResetPassword";
import ApplyForm from "./components/applyForm";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route exact path="/GettingSetUp" component={GettingSetUp} />
        <Route exact path="/LimitedLiability" component={LimitedLiability} />
        <Route exact path="/ApplyForm" component={ApplyForm} />

        <Route exact path="/PatentSearch" component={PatentSearch} />
        <Route exact path="/Login" component={Login} />
        <Route exact path="/Signup" component={Signup} />
        <Route exact path="/VerifyOTP" component={VerifyOtp} />
        <Route exact path="/Forgotpassword" component={Forgotpassword} />
        <Route exact path="/ResetPassword" component={ResetPassword} />
        <Route exact path="/Contact" component={Contact} />
        <Route exact path="/PrivateLimited" component={PrivateLimited} />
        <Route exact path="/OnePersonCompany" component={OnePersonCompany} />
        <Route
          exact
          path="/SoleProprietorship"
          component={SoleProprietorship}
        />
        <Route exact path="/PartnershipFirm" component={PartnershipFirm} />
        <Route exact path="/IndianSubsidiary" component={IndianSubsidiary} />
        <Route exact path="/Section8Company" component={Section8Company} />
        <Route exact path="/ProducerCompany" component={ProducerCompany} />
        <Route exact path="/USIncorporation" component={USIncorporation} />
        <Route
          exact
          path="/SingaporeIncorporation"
          component={SingaporeIncorporation}
        />
        <Route exact path="/UKIncorporation" component={UKIncorporation} />
        <Route
          exact
          path="/NetherlandsIncorporation"
          component={NetherlandsIncorporation}
        />
        <Route
          exact
          path="/HongKongIncorporation"
          component={HongKongIncorporation}
        />
        <Route
          exact
          path="/DubaiIncorporation"
          component={DubaiIncorporation}
        />
        <Route exact path="/ProtectYourBrand" component={ProtectYourBrand} />
        <Route exact path="/StayingCompliant" component={StayingCompliant} />
        <Route exact path="/GrowYourBusiness" component={GrowYourBusiness} />
        <Route
          exact
          path="/LegalFormsAndAgreements"
          component={LegalFormsAndAgreements}
        />
        <Route
          exact
          path="/LicensesRegistration"
          component={LicensesRegistration}
        />
        <Route
          exact
          path="/TrademarkRegistration"
          component={TrademarkRegistration}
        />
        <Route
          exact
          path="/TrademarkObjectionReply"
          component={TrademarkObjectionReply}
        />
        <Route
          exact
          path="/TrademarkOpposition"
          component={TrademarkOpposition}
        />
        <Route
          exact
          path="/TrademarkAssignment"
          component={TrademarkAssignment}
        />
        <Route exact path="/TrademarkRenewal" component={TrademarkRenewal} />
        <Route exact path="/ProvisionalPatent" component={ProvisionalPatent} />
        <Route exact path="/PermanentPatent" component={PermanentPatent} />
        <Route
          exact
          path="/CopyrightRegistration"
          component={CopyrightRegistration}
        />
        <Route exact path="/AnnualCompliances" component={AnnualCompliances} />
        <Route
          exact
          path="/AnnualFilingForLLP"
          component={AnnualFilingForLLP}
        />
        <Route exact path="/DIR3KYCFiling" component={DIR3KYCFiling} />
        <Route exact path="/INC20AFiling" component={INC20AFiling} />
        <Route exact path="/INC22AFiling" component={INC22AFiling} />
        <Route exact path="/NGOCompliance" component={NGOCompliance} />
        <Route
          exact
          path="/Section8Compliance"
          component={Section8Compliance}
        />
        <Route exact path="/Sec80GSec12A" component={Sec80GSec12A} />
        <Route exact path="/NBFCCompliance" component={NBFCCompliance} />
        <Route exact path="/GSTFiling" component={GSTFiling} />
        <Route exact path="/GSTAdvisory" component={GSTAdvisory} />
        <Route exact path="/TDSReturnFiling" component={TDSReturnFiling} />
        <Route exact path="/IncomeTaxFiling" component={IncomeTaxFiling} />
        <Route
          exact
          path="/AccountingandBookkeeping"
          component={AccountingandBookkeeping}
        />
        <Route
          exact
          path="/ProprietorshiptoPartnership"
          component={ProprietorshiptoPartnership}
        />
        <Route
          exact
          path="/ProprietorshiptoLLP"
          component={ProprietorshiptoLLP}
        />
        <Route
          exact
          path="/ProprietorshiptoPrivateLimitedCompany"
          component={ProprietorshiptoPrivateLimitedCompany}
        />
        <Route
          exact
          path="/ProprietorshiptoOPC"
          component={ProprietorshiptoOPC}
        />
        <Route exact path="/PartnershiptoLLP" component={PartnershiptoLLP} />
        <Route
          exact
          path="/PartnershiptoPrivateLimitedCompany"
          component={PartnershiptoPrivateLimitedCompany}
        />
        <Route
          exact
          path="/LLPtoPrivateLimitedCompany"
          component={LLPtoPrivateLimitedCompany}
        />
        <Route
          exact
          path="/OPCtoPrivateLimitedCompany"
          component={OPCtoPrivateLimitedCompany}
        />
        <Route
          exact
          path="/PrivateLimitedCompanytoLLP"
          component={PrivateLimitedCompanytoLLP}
        />
        <Route
          exact
          path="/PrivateCompanytoPublicCompany"
          component={PrivateCompanytoPublicCompany}
        />
        <Route
          exact
          path="/AddRemoveDirectorCompany"
          component={AddRemoveDirectorCompany}
        />
        <Route
          exact
          path="/AddRemovePartnerLLP"
          component={AddRemovePartnerLLP}
        />
        <Route
          exact
          path="/ChangeBusinessActivity"
          component={ChangeBusinessActivity}
        />
        <Route
          exact
          path="/ChangeRegisteredOffice"
          component={ChangeRegisteredOffice}
        />
        <Route exact path="/ChangeCompanyName" component={ChangeCompanyName} />
        <Route
          exact
          path="/ChangeLLPAgreement"
          component={ChangeLLPAgreement}
        />
        <Route
          exact
          path="/ChangePartnershipDeed"
          component={ChangePartnershipDeed}
        />
        <Route
          exact
          path="/IncreaseAuthorisedShareCapital"
          component={IncreaseAuthorisedShareCapital}
        />
        <Route
          exact
          path="/ClosePrivateLimitedCompany"
          component={ClosePrivateLimitedCompany}
        />
        <Route
          exact
          path="/CloseLimitedLiabilityPartnership"
          component={CloseLimitedLiabilityPartnership}
        />
        <Route
          exact
          path="/CloseOnePersonCompany"
          component={CloseOnePersonCompany}
        />
        <Route
          exact
          path="/DissolvePartnershipFirm"
          component={DissolvePartnershipFirm}
        />
        <Route exact path="/Fundraising" component={Fundraising} />
        <Route exact path="/PitchDeck" component={PitchDeck} />
        <Route exact path="/BusinessPlan" component={BusinessPlan} />
        <Route exact path="/Valuation" component={Valuation} />
        <Route exact path="/BankProjectReport" component={BankProjectReport} />
        <Route exact path="/BusinessLoans" component={BusinessLoans} />
        <Route
          exact
          path="/ServiceLevelAgreement"
          component={ServiceLevelAgreement}
        />
        <Route
          exact
          path="/SharePurchaseAgreement"
          component={SharePurchaseAgreement}
        />
        <Route
          exact
          path="/FranchiseAgreement"
          component={FranchiseAgreement}
        />
        <Route
          exact
          path="/ShareholdersAgreement"
          component={ShareholdersAgreement}
        />
        <Route exact path="/TermSheet" component={TermSheet} />
        <Route exact path="/BoardResolutions" component={BoardResolutions} />
        <Route
          exact
          path="/NonDisclosuresAgreement"
          component={NonDisclosuresAgreement}
        />
        <Route
          exact
          path="/NonCompeteAgreement"
          component={NonCompeteAgreement}
        />
        <Route
          exact
          path="/MemorandumofUnderstanding"
          component={MemorandumofUnderstanding}
        />
        <Route exact path="/GSTRegistration" component={GSTRegistration} />
        <Route exact path="/LUTunderGST" component={LUTunderGST} />
        <Route exact path="/FSSAILicense" component={FSSAILicense} />
        <Route
          exact
          path="/ImportExportCodeRegistration"
          component={ImportExportCodeRegistration}
        />
        <Route
          exact
          path="/StartupIndiaRegistration"
          component={StartupIndiaRegistration}
        />
        <Route exact path="/Pricing" component={Pricing} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
