import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import management from "../assets/management.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function TrademarkAssignment() {
  const pageName = "TrademarkAssignment";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Trademark Assignment</h2>
                  <p>
                    Liquidate the hard-earned brand value, through Trademark
                    Assignment
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
              <div className="col col6">
                <h2>What is Trademark Assignment?</h2>
                <div className="bar"></div>
                <p>
                  Trademark is an intellectual property and like any other
                  asset, the owner of a trademark has the right to sell, license
                  or transfer the owned intellectual property. Such a transfer
                  can be made through Trademark Assignment Agreement or through
                  Licensing.
                </p>
                <p>
                  When a trademark is assigned, there is a change in the
                  ownership of the registered brand. But when it’s licensed, the
                  rights in the trademark continue to vest with the original
                  owner but only a few restricted rights are given to the third
                  party. This assignment can be made with or without
                  transferring the business goodwill. In case of a registered
                  Trademark, such assignment is required to be recorded in the
                  Register of trademarks.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Assignment of Trademark in India</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Get your trademark's value</Tab>
                <Tab>Public consultation</Tab>
                <Tab>Pre-established brand name</Tab>
                <Tab>Public consultation</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Get your trademark's value</h2>
                      <p>
                        Brand creation involves a lot of money, time and hard
                        work. With the assignment of Trademark in India, the
                        trademark owner(s) can encash the value of a brand which
                        until now existed only on papers. The combined efforts
                        create more value and the assignor can always put
                        appropriate restrictions.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Assignment Agreement is Valid Proof</h2>
                      <p>
                        In case of any trademark-related dispute, the legal
                        rights of the assignee would easily be established
                        through the trademark assignment agreement. The Registry
                        ensures that all the checks are in place by examining
                        the validity of all the clauses mentioned in the
                        agreement and publishing the assignment in the Trade
                        Marks Journal.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Pre-established brand name</h2>
                      <p>
                        The assignee has the advantage of dealing in an already
                        existing and established a brand name. The time, efforts
                        and investment for creating brand value are saved for
                        the assignee.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={management} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Expansion of business</h2>
                      <p>
                        In the case of licensing, the assignor’s business
                        increases as more vendors join to contribute in brand
                        building. The brand value will propel with the combined
                        efforts of assignor and assignee.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for Trademark Assignment in India</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>TM Certificate</h2>
                    <p>Certificate of Trademark Registration (if any)</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Details of the Party</h2>
                    <p>Name and description of the assignor and assignee</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC</h2>
                    <p>
                      No Objection Certificate from the original owner of the
                      registered trademark
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to assign a Trademark</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>DAY 1 - 2 Collection</Tab>
                <Tab>DAY 3 - 5 Execution</Tab>
                <Tab>DAY 6 - 7 Submission</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and collection of basic Information</li>
                  <li>Provide required documents</li>
                  <li>Finalize the terms and conditions</li>
                  <li>Finalize the type of agreement</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of Documents by Professionals</li>
                  <li>Drafting of Trademark Assignment Agreement</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Filing of online TM-P application</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the procedure of Assignment of Trademark in
                        India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The trademark assignment agreement in India referred to
                        as the Trademark Assignment Document, is entered between
                        the parties being Assignor and Assignee. Once the
                        Trademark Agreement is duly executed, an online
                        trademark assignment procedure is to be followed. This
                        application is filed with the Registry in the prescribed
                        form with the help of registered Trademark Attorney
                        along with the payment of prescribed fee.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I assign an unregistered Trademark in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Trademarks, like any other asset, can be transferred
                        from one owner to another. Such transfers can take place
                        in the form of an assignment, or merger or amalgamation
                        between two entities. Yes, an unregistered trademark can
                        also be assigned, with or without goodwill by filing an
                        application with the registry.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What care should be taken in respect of the Assignment
                        Deed?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Like any Agreement, the Deed of Trademark Assignment
                        shall be executed after paying the Stamp Duty of as per
                        the prescribed rates by the concerned State Government.
                        The rate of stamp duty shall be calculated on the basis
                        of the consideration agreed by the Parties. In addition
                        to same, the effective date of Assignment shall be
                        expressly provided.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How long the Registry will take to go through the
                        formalities of trademark assignment in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Trademark Registry in India normally takes up to 6
                        to 8 months to update the data of the transfer. However,
                        where the effective date of transfer is expressly
                        provided in the Trademark Assignment Document i.e.
                        Assignment Deed, the conferred rights are enjoyed by the
                        Transferee from the said date.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the Government Fee prescribed for Assignment of
                        Trademark?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A registry has prescribed Rs 9,000/- as a fee payable
                        for application of transfer Trademark ownership i.e.
                        Trademark Assignment in India.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        I do not want to transfer the ownership, however want to
                        give rights to use the Trademark to other person. Can I
                        proceed with it?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The owner of the Trademark can provide rights of using
                        the Trademark without transfer of ownership. This is
                        called Partial transfer of Trademark or Licensing of
                        Trademark in India. To affect the said transfer of
                        rights, an Agreement is to be executed by providing all
                        the terms and conditions along with covenants as agreed
                        by the Parties.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default TrademarkAssignment;
