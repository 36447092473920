import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function ProprietorshiptoOPC() {
  const pageName = "ProprietorshiptoOPC";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Proprietorship to OPC</h2>
                  <p>
                    Evolve your proprietorship and tap on to the OPC advantage
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Convert Proprietorship to OPC</h2>
                <div className="bar"></div>
                <p>
                  One person company is an improved and better form of a sole
                  proprietorship firm. One person companies are a great business
                  organization structure for medium-sized businesses. One person
                  company is an improved and better form of a sole
                  proprietorship firm and thus conversion of sole proprietorship
                  into One Person Company is a good business decision. This
                  business structure gives the single promoter a full control
                  over the company and at the same time limiting his liabilities
                  to safeguard his personal assets. The owner of this company is
                  a shareholder. Similar to Private Company, OPC may also
                  appoint a distinct individual as director for its management.
                  Appointment of a nominee is mandatory in case of OPC.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of OPC over sole proprietorship</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Separate Legal Entity hence limited liability</Tab>
                <Tab>Opens better business avenue's</Tab>
                <Tab>An easy to manage structure</Tab>
                <Tab>Uninterrupted existence</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Separate Legal Entity hence limited liability</h2>
                      <p>
                        Protection of the company’s personal assets assures that
                        the owner has limited liability to the extent of his/her
                        own share.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Opens better business avenue's</h2>
                      <p>
                        Large organizations prefer to deal with OPC instead of
                        proprietorship firms. OPC is registered just like a
                        private company and private companies are the trusted
                        form of business which makes it easy for them to get
                        funding from the financial institutions. It gives
                        suppliers and customers a sense of confidence in the
                        business.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>An easy to manage structure</h2>
                      <p>
                        The structure of OPC is manageable as there is only one
                        member. There is no requirement to hold an annual or
                        added ordinary general meeting. There is no requirement
                        to wait for anyone’s approval as there is only one
                        person who is the single authority to make decisions.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Organized Structure</h2>
                      <p>
                        The OPC gives the company a structure similar to a
                        private limited company and hence make it organized with
                        the benefit of limited liability. A sole proprietorship
                        does not provide an organized structure.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents Requirement</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Identity Proof</h2>
                    <p>
                      Scanned copy of PAN Card of all directors, nominee and
                      Aadhar card/ Voter ID/ Passport/ Driving License.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Address Proof</h2>
                    <p>
                      Latest Bank statement/ Utility bill in the name of
                      director and nominee which should not be older than two
                      months
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Latest Passport size photograph of all directors and
                      shareholders
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Registered Office Proof</h2>
                    <p>
                      No Objection Certificate (NOC) from the owner, Utility
                      bill (should not be older than two months) and Notarized
                      Rent agreement (in case of rented property)/ Registry
                      Proof or House Tax Receipt (in case of owned property)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Consent of nominee</h2>
                    <p>
                      Written consent of nominee is required to be filed with
                      the Registrar of Companies (RoC)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Passport size photograph</h2>
                    <p>
                      Latest Passport sized photograph of the directors and
                      nominee.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of converting Proprietorship to OPC</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2-4</Tab>
                <Tab>Day 5-8</Tab>
                <Tab>Day 9-10</Tab>
                <Tab>Day 11-15</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Application for Digital Signature Certificate</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Checking Name availability</li>
                  <li>Application for Name Reservation under “RUN”</li>
                  <li>Reservation of Name</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of MoA, AoA & other documents</li>
                  <li>Payment of Stamp Duty</li>
                  <li>Notarisation of required documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Filing application for company registration</li>
                  <li>Application for DIN Allotment</li>
                  <li>Application for PAN and TAN of company</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the minimum capital requirement to start OPC?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The capital requirement of OPC and a private limited
                        company are the same. It needs an authorized capital of
                        ₹1 lakh, to begin with. But, none of this actually needs
                        to be paid-up. This means that you don’t really need to
                        invest any money into the business. The capital should
                        not be more than ₹50 lakh during incorporation.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        For how long is the Company incorporation valid for?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Once a Company is incorporated, it will be active and
                        in-existence as long as the annual compliance is met
                        regularly. In case, annual compliance is not complied
                        with, the Company will become a Dormant Company and may
                        be struck off from the register after a period of time.
                        A struck-off Company can be revived for a period of up
                        to 20 years.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the compliance requirements for OPC?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul>
                        <li>
                          An OPC limited by shares must comply with the
                          following requirements:
                          <br />
                          o Must have a minimum authorized share capital of ₹ 1
                          Lac.
                          <br />
                          o Transfer of shares to anyone else is not allowed.
                          <br />o An OPC is prohibited from giving any
                          invitations to the public to subscribe to the
                          securities of the company.
                        </li>
                        <li>
                          When the OPC limited by shares or by guarantee, enters
                          into a contract with the sole member of the company
                          who is also the director of the company; the terms of
                          contract or offer must be recorded in writing. Also,
                          the same must be contained in a memorandum or recorded
                          in the minutes of the Board meeting held next after
                          entering into the contact.
                        </li>
                        <li>
                          &nbsp;An OPC must inform the Registrar about every
                          contract entered into by the company with the sole
                          member of the company within a period of fifteen days
                          from the date of approval.
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can an individual apply for more than one OPC at a time?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, an individual can form only one OPC at a time. The
                        rule is the same for the nominee director too.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the pre-requisites for One Person Company (OPC)
                        Registration in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        To register One Person Company (OPC) in India, acquiring
                        the DSC (Digital Signature Certificate) and DIN
                        (Director Identification Number) by all the directors
                        and Subscriber to MOA (owner) along with the Nominee is
                        mandatory. The Registered Office shall also be in
                        existence for online Private Limited Company
                        Registration.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who is the nominee in One Person Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The requirement to appoint a nominee is prescribed in
                        order to retain the character of Perpetual Existence
                        i.e. Uninterrupted Existence of the One Person Company.
                        A nominee shall be an individual and is to be appointed
                        at the time of incorporation of OPC. In the event of
                        death or incapacity to enter into any contract by
                        existing member, the nominee will become the member of
                        one person company.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ProprietorshiptoOPC;
