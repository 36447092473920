import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function ProprietorshiptoPartnership() {
  const pageName = "ProprietorshiptoPartnership";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Proprietorship to Partnership</h2>
                  <p>
                    Transform business to Partnership firm and enjoy extended
                    benefits
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Proprietorship to Partnership</h2>
                <div className="bar"></div>
                <p>
                  Where most of the businesses start as a proprietorship firm,
                  one can always change the business structure by exploring the
                  benefits of partnership by adding a partner. Especially when
                  the operations reach certain esteemed levels, a partner may be
                  required to increase the efficiency and act as a catalyst for
                  the faster growth of the present business. With the increase
                  in the number of partner(s) in the business, the efforts and
                  capital both would increase propelling the business growth.
                  For conversion from an unorganized business structure to a
                  partnership firm, the business is likely to pass through
                  procedural requirements. Once the business is converted to the
                  partnership, all the assets, liabilities and rights
                  accompanied to proprietorship will be passed on to the
                  partnership firm; subject to the consent of partners.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of converting Proprietorship to Partnership</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Shared Liabilities</Tab>
                <Tab>
                  With conversion, you do not need to start new business
                </Tab>
                <Tab>Partner net worth is Increased</Tab>
                <Tab>No fixed capital investment required</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>
                        With conversion, you do not need to start new business
                      </h2>
                      <p>
                        The term Partnership, itself describes two or more
                        individuals coming together for fulfilling some common
                        objective. The partnerships referred here are of pure
                        business nature. Therefore, the partners share the
                        responsibility to work and manage the business. Partners
                        share rights and liabilities in the business, dividing
                        the burden of responsibilities among them. Not just
                        money but resources, knowledge and judgment are also
                        pooled in for improving the business.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Timely access to operational information</h2>
                      <p>
                        With conversion, the accumulated loss and unabsorbed
                        depreciation of Proprietorship is deemed adjusted as
                        loss/ depreciation of the successor partnership firm.
                        All the assets and liabilities of the firm immediately
                        after the conversion are turned into the assets and
                        liabilities of the partnership. All movable and
                        immovable properties of the firm automatically vest in
                        the partnership. Hence, the conversion is easy and
                        hassle-free.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Partner net worth is Increased</h2>
                      <p>
                        There is a distribution of Post-Tax profits among the
                        partners with no additional tax liability. No Capital
                        Gains tax shall be charged on transfer of property from
                        Proprietorship to Partnership firm. The reduction of tax
                        liabilities indirectly increases the amount of money
                        earned which results in an increase of net worth of all
                        the partners.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>No fixed capital investment required</h2>
                      <p>
                        The partners can internally decide on their individual
                        investment in the firm and then divide the stakes
                        accordingly, which gives them the flexibility to make
                        decisions in the business. Uneven capital contribution
                        between partners is permissible. There is no predefined
                        limit on partners’ capital contribution, allowing the
                        partners for putting in preferable amounts as capital
                        and make decisions about the withdrawals mutually.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents Required for incorporating a partnership firm</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business proof</h2>
                    <p>
                      Electricity Bill/ Telephone Bill of the registered office
                      address
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>ID Proof</h2>
                    <p>
                      Self- attested copy of Aadhar Card, Voter ID/ Passport/
                      Driving License of all partners. A self-attested copy of
                      PAN Card of all partners
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Details about the sole Proprietors Business</h2>
                    <p>
                      If the proprietorship firm is licensed under GST or any
                      other registrations obtained , forms need to be submitted
                      to the concerned departments for change of status of the
                      business
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Statement of assets and liabilities</h2>
                    <p>
                      updated statement of assets and liabilities certified by a
                      CA.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to change into a Partnership Firm</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2 - 4</Tab>
                <Tab>Day 5 - 7</Tab>
                <Tab>Day 8 onwards</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and collection of basic Information</li>
                  <li>Provide Required Documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of Partnership Deed</li>
                  <li>Review and confirmation from Partners</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Payment of Stamp Duty on Agreement</li>
                  <li>Notarization of Partnership Deed</li>
                  <li>Application for allotment of PAN and TAN</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Registration of Partnership Deed, if subscribed</li>
                  <li>Certificate of Registration from RoF*</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether the registration of partnership after conversion
                        from proprietorship is mandatory?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        It is not mandatory but highly recommended. If it is not
                        registered, the firm cannot file a suit against any
                        partner or third party. The partners also cannot sue the
                        partnership firm for his/her claim. However, third
                        parties can sue the firm to enforce their dues or
                        claims. Due to non-registration, the rights of parties
                        are not affected. Also, the partnership can be
                        registered at any time after the formation to remove
                        said effects.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the advantages of registering a partnership
                        firm?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In a proprietorship firm, there is no legal distinction
                        between you and the business; leaving you personally
                        liable for any debts or obligations the business may
                        incur. Also, there are no limitations and no protection
                        for your personal assets. In case of partnership firm,
                        it is divided amongst the partners.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Under which Government Authority, the application of
                        Partnership Firm Registration is submitted?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The application for Partnership Firm Registration in
                        India is submitted with the Registrar of Firms (RoF)
                        under whose jurisdiction the Place of Business of
                        Partnership Firm falls. The application of Registration
                        is made in required form along with submitting the
                        Partnership Deed. At the end of the registration
                        procedure, the Certificate of Registration is issued by
                        respective RoF. The process and time of registration may
                        differ for each RoF.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can a new partner be admitted into the partnership firm?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A partner can nominate a successor to take his/her place
                        in the event of death or retirement of the partner. The
                        mode of introducing a new partner or successor is based
                        on provisions in the partnership deed. A new partnership
                        deed is required once the new partner is admitted into
                        the firm.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is there a need to pay tax on sale of stock where it is
                        moved from the existing firm to new firm structure?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        As per Schedule-2 of CGST/SGST Act, there is no need to
                        pay tax on the sale of stock where it is moved from
                        proprietorship to the new firm (in case of
                        re-structuring of business) subject to the condition
                        that the existing proprietorship ceases to be a taxable
                        person after such re-structuring.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What should be taken care of by the proprietorship firm
                        during the process of business structure change?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In case of conversion, existing firm should cease to be
                        a taxable person . There should not be any activity in
                        converted proprietorship after transfer of stock into a
                        new entity. In case, there are unutilized input tax
                        credits lying at the time of such conversion, these
                        credits are allowed to transfer into a new entity.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ProprietorshiptoPartnership;
