import * as React from "react";
import onepersonimg from "../assets/One-Person-Company.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import management from "../assets/management.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function OnePersonCompany() {
  const pageName = "OnePersonCompany";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>One Person Company Registration</h2>
                  <p>The first move towards building an empire!</p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Overview</h2>
                <div className="bar"></div>
                <p>
                  To understand one person company definition, we first need to
                  get into the identity it creates. A registration provides
                  corporate status and many benefits to the members and
                  directors. In the case of a Private company, at least two
                  members are required which is not the same in the case of OPC.
                  To eliminate this drawback and allow a single person to reap
                  the advantages of One Person Company, this sort of a company
                  structure is introduced through the Companies Act, 2013. One
                  Person Company registration is simplified with online filing
                  and process.
                </p>
                <p>
                  One Person Company feature is such that it has only one
                  shareholder who owns 100% stake of the company. To maintain
                  the character of perpetuity, the appointment of the nominee is
                  compulsory, who will take place of the owner in case of death
                  or his inability. One person company is a type of Private
                  Limited Company.
                </p>
              </div>
              <div className="col col6">
                <img src={onepersonimg} alt="LLP-Registration" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of One Person Company Registration</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Separate Legal Existence</Tab>
                <Tab>Lower Compliance Requirements</Tab>
                <Tab>Limited Liability of Owners</Tab>
                <Tab>Separation of Management and Ownership</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Separate Legal Existence</h2>
                      <p>
                        A One Person Company would obtain the status of a
                        separate legal entity. Such OPC registration ensures
                        that the entity is separate from the owner, unlike a
                        proprietorship firm. OPC can own the assets in its own
                        name and enter into a contract with the parties. The
                        actions of the company are independent of the owner.
                        This is the main benefit of OPC registration.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={fundraising}
                      alt="fund-raising-image"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={legal}
                      alt="legal-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Lower Compliance Requirements</h2>
                      <p>
                        A Single Person Company is benefited with an exemption
                        to many compliances unlike a private company.
                        Compliances like holding General and Board Meeting, etc.
                        are not applicable to OPC. However, Board Meeting must
                        be held if more than one director is on Board.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Limited Liability of Owners</h2>
                      <p>
                        One of the benefits of registering OPC lies in the
                        separate legal entity of the company where the liability
                        and obligations are not charged over the personal assets
                        of the sole member. The liability of a member is limited
                        to the unpaid amount of the capital subscribed by the
                        member. Even in the case of liquidation, the personal
                        assets of the member are protected, except in certain
                        specified cases.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={owners}
                      alt="owners-image"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={management}
                      alt="management-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Separation of Management and Ownership</h2>
                      <p>
                        Even if the OPC is owned by sole personnel, the owner
                        may appoint a director owing up to the responsibility to
                        operate and run a company. The operational duties are
                        assigned to the director(s) whereas the member would be
                        able to fetch profits channeling efforts towards other
                        businesses. However, in One Person Company, the
                        shareholder holds complete control over being a
                        stakeholder.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents Required for Online OPC Registration</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>PAN Card of shareholder, nominee, and Directors.</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>Identity Proof</h2>
                    <p>
                      Aadhar card and Voter ID/ Passport/ Driving License of
                      Shareholder, Nominee, and Directors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Director’s Address Proof</h2>
                    <p>
                      Latest Telephone Bill /Electricity Bill/ Bank Account
                      Statement of Shareholders and Directors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Latest Passport size photograph of Shareholder, Nominee
                      and Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Latest Electricity Bill/ Telephone Bill of the registered
                      office address
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from owner</h2>
                    <p>
                      No Objection Certificate to be obtained from the owner(s)
                      of registered office
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-contract"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      Rent Agreement of the registered office should be
                      provided, if any
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to Register an OPC</h2>
              <div className="bar"></div>
            </div>
            <Tabs className="tabsSteps">
              <TabList>
                <Tab>Step 1</Tab>
                <Tab>Step 2</Tab>
                <Tab>Step 3</Tab>
                <Tab>Step 4</Tab>
                <Tab>Step 5</Tab>
                <Tab>Step 6</Tab>
              </TabList>

              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  <b>Obtain Digital Signature Certificate (DSC) </b>
                  of the proposed director of the OPC.
                </p>
              </TabPanel>
              <TabPanel style={{ height: "60px" }}>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  <b>Obtain Director Identification Number (DIN) </b>
                  for the proposed director of the OPC.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  <b>Apply for Name Availability </b>
                  for the OPC. The name should be unique and should not be
                  similar to an existing company.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  <b>
                    Draft Memorandum of Association (MOA) and Articles of
                    Association (AOA){" "}
                  </b>
                  of the OPC.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  <b>File the Incorporation Form </b>
                  with the Registrar of Companies (ROC). The form should be
                  accompanied by the necessary documents such as MOA, AOA, DSC,
                  DIN and name availability certificate.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  <b>Obtain Certificate of Incorporation </b>
                  from the ROC. This is the final step in the incorporation
                  process and marks the official commencement of the company.
                </p>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who can form One Person Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        An OPC can be formed only by a natural person who is an
                        Indian citizen and stayed in India for a minimum of 182
                        days in the immediately previous calendar year.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the minimum number of Directors required to form
                        an OPC?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Minimum one Director is required to incorporate an OPC.
                        Further, the sole member can also become the first
                        director of the Company till the member appoints any
                        other director.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the minimum capital requirement to start an OPC?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        As per the Companies Act, 2013 and Company
                        (Incorporation) Rules these is no minimum capital
                        requirement to incorporate an OPC in India. You can even
                        start an OPC with a capital contribution as low as Rs.
                        2. However, the maximum capital allowed is Rs. 50 lakhs.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I form two or more OPCs?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, a member can’t incorporate more than one OPC as
                        under Companies (Incorporation) Rules, 2014. Further, a
                        Nominee can’t be a nominee and a member in two OPCs.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who can be a Nominee?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        As per Rule 3 of Companies (Incorporation) Rules, 2014
                        only a natural person who has attained majority and is
                        an Indian citizen and resident of India in the previous
                        calendar year can become a Nominee Director in an OPC.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When does a One Person Company require to convert itself
                        mandatorily?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A One Person Company is required to be converted into a
                        Private Limited or Public Limited company when it
                        crosses the threshold limit of paid-up capital of Rs. 50
                        Lakhs or Average Turnover of Rs. 2 Crores in any year.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I become a director in an OPC if I am in already in
                        employment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, there is no such legal constraint in the Companies
                        Act if not restricted by the employment agreement. All
                        you need to do is check your employment agreement
                        because it may limit you from becoming a director in
                        other company simultaneously.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        I am a sole member of an OPC (Name can’t be revealed).
                        Recently my Average turnover crossed the threshold limit
                        or Rs. 2 crores. What should I do now?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        <ol>
                          <li>
                            Once your threshold limit is crossed either the
                            paid-up capital or the average turnover; the OPC is
                            mandatorily required to convert itself into a
                            Private Company or a Public Company. Follow the
                            steps below after crossing the threshold limit.
                          </li>
                          <li>
                            <b>File form INC-5</b> to inform ROC about the
                            breach of threshold limit within 60 days of breach
                            of limit.
                          </li>
                          <li>
                            <b>Convert the OPC </b> into public or private
                            Company as per the Companies Act, 2013 within six
                            months period from the date of breach of threshold
                            limit.
                          </li>
                        </ol>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the disadvantages of an OPC?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        <ol>
                          <li>
                            Ineligible to carry Non- Financial Business
                            Activities
                          </li>
                          <li>
                            Can’t convert voluntarily in any form of the company
                            before two years of incorporation and prohibited to
                            convert itself at any time into section 8 Company.
                          </li>
                          <li>
                            Restrictions of a Private Limited Company apply to
                            OPC also.
                          </li>
                          <li>
                            It is more suitable for small entrepreneurs due to
                            limited share capital structure.
                          </li>
                        </ol>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to inform ROC about the cessation of membership?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The OPC is required to file INC-4 in case of cessation
                        of membership on account of death, incapacity or change
                        in ownership. In the same form, the details of a new
                        member are required to be mentioned.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is there any form required to be filed in case of
                        conversion of OPC into a Private Limited or Public
                        Limited Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, a form INC-6 is required to be filed with ROC in
                        case of conversion of OPC into any form of the company
                        whether Private or Public Company. Further, a private
                        company is also required to file INC-6 in case of
                        converting itself into an OPC.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the time limit to file INC-6?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Time limit for filing INC-6 is thirty days in case of
                        voluntary conversion and Six months in case of mandatory
                        conversion.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What if the Nominee of an OPC is now the member of the
                        OPC and at the same time holds the position of the
                        member in other OPC?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        If a member of an OPC becomes the member of another OPC
                        then within 180 days he will be required to fulfil the
                        statutory eligibility criteria for becoming a member of
                        only one OPC and will have to withdraw his membership
                        from either OPC.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to inform ROC about the change in Nominee?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        File INC-4 with the ROC informing about the change in
                        Nominee or withdrawal of consent by the Nominee.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default OnePersonCompany;
