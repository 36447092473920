import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function ChangeBusinessActivity() {
  const pageName = "ChangeBusinessActivity";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Change Business Activity</h2>
                  <p>
                    Make sure your company’s business activities are updated
                    with MCA
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What are the business objectives and its change?</h2>
                <div className="bar"></div>
                <p>
                  When particularly talking about the company, the business
                  objectives are defined in the main object clause of MoA. These
                  objectives define the scope of the company’s principal
                  business activities within which it may operate. A company
                  cannot undertake those activities, which are not prescribed in
                  its object clause.
                </p>
                <p>
                  With reason of expansion or strategic changes, a company may
                  require to undertake new activities or update business
                  activity in MoA. This change in object clause of MoA is
                  possible after consent of shareholders and the approval from
                  Ministry.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Why change the business objective?</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>New Activities to be undertaken</Tab>
                <Tab>Takeover of the company</Tab>
                <Tab>Remove abandoned activities</Tab>
                <Tab>Current activities are banned or prohibited</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>New Activities to be undertaken</h2>
                      <p>
                        Addition of new activities is the main reason to change
                        business objective. The change is also backed by the
                        vertical or horizontal expansion of the activities,
                        which were not mentioned while company incorporation.
                        Therefore, prior to undertaking such activities, the
                        company must undertake the change of business activity.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Takeover of the company</h2>
                      <p>
                        Many times a company is a takeover by another company
                        just for the sake of its market value built. The objects
                        of the company acquired must be updated as required for
                        leading the business further. The object can be changed
                        either before or after the takeover depending on the
                        requirement.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Remove abandoned activities</h2>
                      <p>
                        The company’s memorandum is accessible to any person,
                        who may refer it before binding into contractual
                        relation. Hence, one must make sure that the objects are
                        updated with the Ministry even by deleting those
                        activities the company has abandoned to undertake.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Current activities are banned or prohibited</h2>
                      <p>
                        The Government policies may change anytime, which
                        essentially affects the company and its operations. If
                        the activities undertaken by a company is declared to be
                        prohibited or are banned, such an object must be removed
                        from MoA or changed completely, as required.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required to change Object clause of MoA</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>MoA & AoA</h2>
                    <p>A copy of latest amended MoA and AoA of the company</p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Digital Signature Certificate</h2>
                    <p>DSC of one of the authorized director to be provided</p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Detail of change</h2>
                    <p>Details of the proposed change in activities</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of change in object clause</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2-3</Tab>
                <Tab>Day 4-5</Tab>
                <Tab>Day 5 onwards</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Consultancy and assistance for a change of the object</li>
                  <li>Collection of basic Information & documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of necessary resolutions and documents</li>
                  <li>Drafting of new object clause of MoA</li>
                  <li>Provide documents required drafted after signature</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>
                    Preparation of application for change of business activity
                  </li>
                  <li>Application filing for approval of MCA</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When should be kept in mind while changing object?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        One should make sure that the new object also covers all
                        the ancillary activities to the main object. Also, the
                        name should represent the object correctly even after
                        the change of the object, else the RoC may direct name
                        change.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When is the application to MCA made?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        An application to update business activity is filed
                        within 30 days of obtaining the consent of shareholders
                        (i.e. General Meeting). It must be noted that the
                        approval is obtained before indulging in activities.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When will the change of object be in effect?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        It will be effective only after receipt of approval by
                        the RoC for the application made. Only after approval,
                        the company may carry on updated activities.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether change of business activity requires alteration
                        in MoA?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, the object clause is part of the Memorandum which
                        must be altered to give the effect of the change of
                        business objective. The said altered MoA shall also be
                        filed with RoC while making an application. Where the
                        Registrar deems appropriate, he may ask for the adoption
                        of new set of Memorandum and Articles in line with
                        Companies Act, 2013.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether the change the name of the company is required
                        while change of Object?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The change in name of the company is not necessary in
                        every case. However, where the existing name of the
                        company fails to reflect the new activities or any
                        relations thereto, the registrar may direct to change
                        the name of the company accordingly.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether the procedure of the change of object and change
                        of name can be carried on simultaneously?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        If the change of name of the company is made due to a
                        change in the main object of the company, both the
                        procedures can be carried on simultaneously. However, as
                        the approval of the government is involved, the period
                        can vary based on the response and time taken by RoC.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ChangeBusinessActivity;
