import * as React from "react";
import aboutimg from "../assets/digital.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function INC20AFiling() {
  const pageName = "INC20AFiling";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>INC-20A Filing</h2>
                  <p>File your INC 20A to commence your business.</p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is form INC-20A ?</h2>
                <div className="bar"></div>
                <p>
                  INC-20A is a mandatory form that is to be filed by a company
                  incorporated on or after 02/11/2018 with MCA. It is also known
                  as Declaration of Commencement of Business. It should be filed
                  by the directors within 180 days from date of incorporation of
                  a company which has share capital. Once the company receives a
                  certificate of incorporation, the directors will file
                  declaration certificate of commencement of business. It is
                  mandatory to open a current account in a bank for depositing
                  the share capital subscribed in MOA by each and every
                  promoter.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
            </div>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for filing INC -20A</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>Proof of payment of subscribed capital</h2>
                    <p>Bank statement/ NEFT / IMPS receipts.</p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Registration Certificate</h2>
                    <p>
                      Certificate of registration by RBI/ other regulators
                      (applicable to NBFC)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of filing KYC online</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1 - Collection, Preparation & Filing</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Collection of basic Information</li>
                  <li>Provide Required Documents</li>
                  <li>Preparation and filling an online form</li>
                  <li>Online filing with Professional Certification</li>
                  <li>Sharing approval in an email</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is it mandatory to file form INC 20A?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, it is mandatory to file Declaration of Commencement
                        of Business by filing form 20A; to establish that the
                        company has received the paid up share capital amount
                        and is eligible to start its business and borrow money
                        in its name.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who shall file “Form INC 20A”?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Every company that is incorporated on or after
                        02/11/2018
                      </p>
                      <p>
                        and having a share capital is required to file INC 20A
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who shall not file “Form INC 20A”?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Companies that are limited by guarantee i.e. the ones
                        not having share capital or the ones incorporated before
                        02/11/2018 are not required to file it.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the due date of filing form INC 20A ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The due date for filing the declaration is within 180
                        days from the day of company incorporation.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is it s one-time filing ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, the declaration is a one-time mandatory filing for
                        companies incorporated post 02/11/2018.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the Government fee for filing the form?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Government fee depends on your authorised capital,
                        however a minimum fee is Rs. 200/- for a share capital
                        less than one lakh.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default INC20AFiling;
