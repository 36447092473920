import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function TrademarkObjectionReply() {
  const pageName = "TrademarkObjectionReply";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Trademark Objection Reply</h2>
                  <p>
                    Secure your TM with a timely Reply to the examination report
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
              <div className="col col6">
                <h2>What is Trademark Examination Report?</h2>
                <div className="bar"></div>
                <p>
                  Trademark application passes from several stages, where
                  Examination by Registry is a crucial stage. An officer would
                  examine the application and check for any errors or
                  correctness of the application confirming to the Trade Marks
                  Act and its Rules.
                </p>
                <p>
                  Upon review, an examination report is issued with the comments
                  of the examiner. If there is no conflict of rules or other
                  trademarks, it is passed to publication in Journal. However,
                  in the case of confliction, the examiner raises an objection
                  and alerts the applicant/ attorney to submit his/her reply as
                  to why the application should be passed to next stage. Failure
                  to reply may abandon the application which compels the
                  applicant to file a reply to the examination report issued by
                  the Registry.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Why Trademark Objection Reply is necessary?</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>To Prevent Abandonment</Tab>
                <Tab>Establish your Mark's Distinctiveness</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>To Prevent Abandonment</h2>
                      <p>
                        Filing reply to examination report is mandatory to reach
                        the next stage of the registration process. Failure in
                        filing the reply to trademark objection report within a
                        month from the date of dispatch of the said report would
                        lead to the abandonment of the whole TM application.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Establish your Mark's Distinctiveness</h2>
                      <p>
                        The Trademark objection reply letter provides the
                        opportunity to present the ground as to why the
                        applicant is entitled to get his/her mark registered.
                        With written reply to the report, one put can put
                        forward the arguments on how the objections are not
                        applicable to the TM application and show the mark’s
                        distinctive features.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Common Grounds of Trademark Objection in India</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content height_auto">
                  <div className="textbox">
                    <h2>Mark not Distinguished or Unique</h2>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content height_auto">
                  <div className="textbox">
                    <h2>Deceptively similar Marks</h2>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content height_auto">
                  <div className="textbox">
                    <h2>Mark describing Characteristics of Products</h2>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content height_auto">
                  <div className="textbox">
                    <h2>Descriptive Nature of the Trademark</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Procedure of filing Reply to Examination report</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>DAY 1 - Consultation</Tab>
                <Tab>DAY 2-3 - Execution</Tab>
                <Tab>DAY 4 - Submission</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Collection of basic Information</li>
                  <li>
                    Consultation for grounds of objection raised by the examiner
                  </li>
                  <li>
                    Discussing the suitable responses to the examination report
                  </li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of response by Experienced Lawyers</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Filing of TM Objection Reply by online</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What does it mean by TM application status “Objected”?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        When the examiner raises some objections regarding the
                        registrability of your trademark it is said to be
                        objected. To overcome the objections, a written response
                        needs to be filed with the Trade Mark Registry. If the
                        Examiner is not convinced with the written response, a
                        hearing is scheduled for allowing arguments to be put
                        forth in person.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How can I get my TM application approved for publication
                        in Trademark journal?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        When the Examination report is issued by objecting the
                        registration, a written reply needs to be submitted that
                        gives sufficient justification and clarifications
                        regarding a trademark’s distinctiveness and
                        registrability. If the filed response satisfies the
                        examiner, the mark would be published in the journal.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How should I know when the application of Trademark is
                        under objection by the Ministry?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The status of the Trademark Application is required to
                        be tracked from time to time by the applicant in order
                        to stay updated. When the examination report is issued,
                        the status of the application is changed to “Objected”
                        with alert “Awaiting Reply to Examination Report”.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Do I need to stop using ™ Mark for my Trademark or Logo
                        when Trademark Objection/ Examination Report is issued?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, the applicant of the trademark does not need to stop
                        using the ™ sign. By way of issuing the Examination
                        Report, the Registry does not compel to stop the
                        applicant from using the ™ sign beside the Brand Name or
                        Logo.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        In what time the Response/Reply to online Trademark
                        Objection in India shall be submitted?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The response to the examination report issued by the
                        Trademark Registry is required to be filed within 1
                        month from the date of dispatch. However, the Registry
                        accepts the response even after the stated period is
                        passed. One may submit the reply till the status of the
                        application shows the alert of “Awaiting Reply to
                        Examination Report” and not “abandoned”.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Will the trademark be valid outside India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The registration application filed under the Trademark
                        Act, 1999 is verified and registered in India and it
                        remains valid only in India.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How may I know that the reply is duly filed with the
                        Registry?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Once the Trademark objection reply is duly filed by the
                        registered attorney, the reply will also be displayed on
                        tracking the application status. Further, the alert
                        “Pending for Reply” or “Awaiting Reply to Examination
                        Report” will be removed from the application status on
                        the TM website. The status would still be “objected” as
                        the processing from the Registry would be pending.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What time will be taken to reach the next stage after
                        filing of response with the registry?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Trademark Registry normally takes 6 to 8 months to
                        process, verify and review the reply filed with the
                        registrar.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the chances of issuance of show cause notice
                        after filing reply to TM objection?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The show cause notice for hearing is issued in very
                        exceptional cases, where the trademark applied is very
                        similar to any other applicant or examiner is not
                        satisfied with the reply filed along with the supporting
                        grounds.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the step after the Application is published in
                        the journal?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The application, when it is accepted by the Registry, is
                        published in a journal for a period of 4 months and is
                        open for the opposition by the Third Party. After
                        completion of 4 months, where no opposition by the third
                        party is received, the Registration Certificate of the
                        trademark is issued by the Registry.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default TrademarkObjectionReply;
