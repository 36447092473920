import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function DissolvePartnershipFirm() {
  const pageName = "DissolvePartnershipFirm";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Dissolve a Partnership Firm</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Dissolution of a Partnership firm</h2>
                <div className="bar"></div>
                <p>
                  Partnership firm is the business entity that is formed with a
                  sole purpose of profit from business. Two or more parties come
                  together with a formal agreement (known as Partnership Deed)
                  to own and manage the business. Once the purpose is met or
                  after the partners decide to put in end to the partnership it
                  needs to be dissolved and the partnership comes to an end. On
                  dissolution of the firm, the business of the firm ceases to
                  exist since its affairs are would up by selling the assets and
                  by paying the liabilities and discharging the claims of the
                  partners. The dissolution of partnership among all partners of
                  a firm is called dissolution of partnership firm. This is
                  usually done through a dissolution agreement between the
                  partners.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Methods of dissolution of a partnership firm in India</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Dissolution with intervention of the court</Tab>
                <Tab>Dissolution without courts intervention</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Dissolution with intervention of the court</h2>
                      <p>
                        When a dissolution is initiated by one partner by suing
                        the other then the courts get involved. But a court can
                        dissolve the firm only if it is registered with the
                        registrar of firms. Hence an unregistered partnership
                        firm can’t be dissolved by the court. The court may
                        intervene and help in the dissolution in situations
                        where one partner becomes incapable to carry out his
                        duties due to incapability, or unsoundness of mind, if a
                        partner is guilty of misconduct that can harm the
                        business, repeated breach of the partnership agreement
                        by a partner. In these situations the court may get
                        involved and help the partnership get dissolved legally.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Dissolution without courts intervention</h2>
                      <p>
                        Usually, partnership firms are dissolved through
                        dissolution agreements between the concerned partners.
                        All the partners come together and sign an agreement
                        confirming such a dissolution and mutually settling all
                        the pending liabilities and accounts among them. Hence
                        there is no need of any third party intervention. This
                        may occur for various reasons like insolvency of
                        partner/partners, or Unlawful business carried on in the
                        name of the partnership firm by one of the partner. In
                        case the term of the decided partnership expires or the
                        task for which the partnership was created is finished,
                        Death of one of the partners and resignation of a
                        partner. It can also be dissolved by a notice of
                        dissolution sent by one partner in written to all other
                        partners.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents Required for winding up of partnership firm</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      All partners are required to submit their and the firms
                      PAN number as identity proof.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Address Proof of firm</h2>
                    <p>
                      If the registered office place is rented, rent agreement
                      and one utility bill (electricity bill, water bill,
                      property tax bill, gas receipt etc.) have to be submitted.
                      Also, NOC from landlord will be submitted.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Accounting Information</h2>
                    <p>The financial statement of the partnership firm</p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Legal Liabilities</h2>
                    <p>
                      A statement regarding pending litigations, if any
                      involving the partnership firm.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>List of secured creditors</h2>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>
                      Original partnership deed and all its modified versions
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process for dissolution of Partnership Firm</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1-2</Tab>
                <Tab>Day 3-8</Tab>
                <Tab>Day 9-12</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and collection of basic Information</li>
                  <li>Provide required documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Review of the details and documents furnished</li>
                  <li>Drafting of the necessary resolutions</li>
                  <li>
                    Drafting of the affidavit, indemnity bond, and other
                    documents
                  </li>
                  <li>Provide duly executed affidavit & indemnity bond</li>
                  <li>Provide signed documents after review</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Execution of dissolution agreement</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the difference between the dissolution date and
                        the winding up date?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        When the partnership ceases to exist, that date is known
                        as the dissolution date . after this there is no
                        business relationship between the partners. After which
                        the partners will complete any unfinished work, settle
                        any liabilities, realize any partnership assets and
                        otherwise wind up the partnership. The winding update is
                        the date when the winding-up of the partnership is
                        completed.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How are accounts settled for winding up of the
                        partnership ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        First, the Losses of the firm will be paid out. The
                        Assets of the firm and the capital contributed by the
                        partners to set-off losses of the firm will be applied :
                        Third party debts will be paid first. Next, loan amount
                        taken by a firm from any partner will be repaid to that
                        partner Capital contributed by each partner will be
                        repaid to him in the capital contribution ratio. Balance
                        amount will be shared among the partners in their profit
                        sharing ratios. Upon realization, all assets will be
                        sold off in the market, and the cash realizing out of
                        such a sale will be used for paying the liabilities.
                        Assets or liabilities may also be taken over by the
                        partner(s) for which the respective partner capital
                        accounts will be adjusted by such amount.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the Liabilities of partners after dissolution
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The partners are liable to the third parties for any act
                        done before the dissolution. The liability of a partner
                        finishes when all the event are finished that has been
                        taken up before the dissolution of the firm until public
                        notice is given of the dissolution.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the rights of partners post dissolution ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Every partner is entitled to equal rights or according
                        to the contract. All the partners are entitled to the
                        property of the firm applied in payment of the debts and
                        liabilities of the firm and to have the surplus
                        distributed among the partners or their representatives
                        according to their rights. These rights are given when
                        winding up of the firm is taking place
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the difference between dissolution of
                        partnership firm and dissolution of partnership ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Dissolution of a partnership occurs when a partner
                        ceases to be associated with the business, whereas
                        dissolution of a firm is the winding up the business.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Which are the contingencies due or which a partnership
                        needs to be dissolved ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        1) If the partnership was created for a fixed term, then
                        on the expiration of the term the partnership will be
                        dissolved.
                        <br />
                        2) If the partnership has been created for a particular
                        task or objective, then after the completion of that
                        task or fulfillment of that objective the partnership
                        would come to an end.
                        <br />
                        3)If one of the partners dies than it’s prima facie that
                        the partnership would come to an end unless it is
                        provided otherwise in the agreement either express or
                        implied from the conduct of the partners.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default DissolvePartnershipFirm;
