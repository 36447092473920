import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Forgotpassword.css";
import { useForm, Controller } from "react-hook-form";
import { API_URL } from "../constants";
import Loader from "./Loader";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Layout from "../common/Layout";
function Forgotpassword() {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  const onSubmit = async (data) => {
    setLoader(true);

    const forgetPassword = await axios.post(`${API_URL}/api/forgetPassword`, {
      userEmail: data.email,
    });
    localStorage.setItem('email',data.email)
    if (forgetPassword.data.status === 200) {
      toast(forgetPassword.data.message);
      history.push({
        pathname: "/verifyOtp",
        search: "?query=forgetPassword",
      });
    } else {
      setLoader(false);
      toast(forgetPassword.data.message);
    }
  };
  return (
    <div className="Forgotpassword">
      <section className="forgot-password-page">
        <div className="container">
          <div className="forgot-password-form">
            <h2>Forgot Password</h2>
            <p>
              Enter your email address that you use to sign into the site and
              we'll send you instructions on how to reset your password.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="email"
                control={control}
                rules={{ required: "Email is required" }}
                render={({ field }) => (
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      placeholder={
                        errors.email?.message
                          ? errors.email?.message
                          : "Email Address"
                      }
                      {...field}
                    />
                  </div>
                )}
              />
              <div className="form-group">
                <input type="submit" value="Send New Password" />
              </div>
            </form>
            <div className="login">
              <Link to="Login">Login</Link>
            </div>
          </div>
        </div>
        {loader && <Loader />}
        <Toaster position="bottom-center" />
      </section>
    </div>
  );
}

export default Forgotpassword;
