import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function TDSReturnFiling() {
  const pageName = "TDSReturnFiling";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>TDS Return Filing</h2>
                  <p>Simplify TDS compliance with CA assisted return filing</p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is TDS Return filing?</h2>
                <div className="bar"></div>
                <p>
                  A person who is liable to deduct tax at source has to file the
                  TDS return as part of compliance. A TDS return is submitted by
                  Tax Deductor in respect of every quarter with the Income Tax
                  Department. The return is a statement that entails the details
                  of the tax collected, source of collection and tax paid to
                  Government for the reporting period.
                </p>
                <p>
                  Generally, tax is deducted on transactions such as Salaries,
                  payment to professionals and contractual basis, payment of
                  rents exceeding certain amount, etc. For every such type of
                  transaction and payment, there is prescribed rate of interest.
                  The deductee can claim the benefit of the tax deducted while
                  ITR filing, only if the deductor has filed the TDS return on
                  time.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
            </div>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for online TDS Return filing</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>TAN</h2>
                    <p>The Tax Account Number of the deductor</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Tax Paid Receipt</h2>
                    <p>Acknowledgement receipt of the tax paid to Government</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Details of tax deducted</h2>
                    <p>Provide us details of the tax deducted</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Details of Supplies</h2>
                    <p>
                      Details of the inward and outward taxable supplies along
                      with details of invoice
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process for TDS online filing</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1 - 2</Tab>
                <Tab>Day 3-5</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>
                    Collection of the information from the details shared by you
                  </li>
                  <li>
                    Documentation and reconciliation of the information filed
                  </li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Preparation of TDS return</li>
                  <li>Filing TDS return online</li>
                  <li>Acknowledgment of Filing the return</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who is required to file TDS return online?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Any assessee deducting tax from a source must file the
                        TDS return. Unless one has deducted tax, a requirement
                        to file TDS return does not arise.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is a TDS Certificate?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        As the name says, TDS is collected on source of income
                        and therefore the frequency of collection is as frequent
                        as the transactions are. This makes track of deduction
                        difficult. Therefore, a certificate on the letterhead of
                        the deductor is issued to deductee for the TDS payment.
                        Individuals are advised to request for TDS certificate
                        wherever applicable, if not already provided.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the penalty for delay or non-filing?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The taxpayer failed to file TDS return before due date
                        has to pay ₹ 200 per day of delay in filing as an
                        additional fee. Apart from an additional fee, the
                        penalty can be levied u/s 271H of Income Tax Act. The
                        minimum penalty prescribed is ₹ 10,000 which may extend
                        to One Lakh Rupees.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How and when the TDS must be deposited?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Tax Deducted at Source (TDS) must be deposited through
                        banks using Challan 281 quoting the TAN number of the
                        entity. The deadline for payment of TDS is the 7th day
                        of each month.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default TDSReturnFiling;
