import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function ChangeLLPAgreement() {
  const pageName = "ChangeLLPAgreement";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Change LLP Agreement</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Know about LLP Agreement and change</h2>
                <div className="bar"></div>
                <p>
                  LLP Agreement is a fundamental document that governs the LLP
                  and its operations as a whole. The partners have to abide by
                  the clauses laid by it and not to act beyond the scope drawn.
                  An LLP Agreement can be changed any time after incorporation
                  with the mutual agreement of the partners. Among various
                  reasons, change in activities or capital or rights and
                  responsibilities are top to lead change.
                </p>
                <p>
                  To change any of the clauses, a supplementary agreement is
                  executed as an addendum to the original agreement. It will be
                  executed by payment of required stamp duty. Any change must be
                  notified to the RoC (LLP) within 30 days of change or
                  execution of the supplementary deed.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Reasons for change in LLP Agreement</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Change Business Activity</Tab>
                <Tab>Change capital and profit sharing ratio</Tab>
                <Tab>Change rights and responsibilities of partners</Tab>
                <Tab>Change other clauses</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Change Business Activity</h2>
                      <p>
                        An LLP cannot undertake the activities that are not
                        included in the agreement. Hence, if you want to change
                        the activities modification of the agreement is must
                        task. The activities can be changed by adding new
                        activities or product lines or even eliminating the
                        previous ones that are discontinued.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Change capital and profit sharing ratio</h2>
                      <p>
                        Capital is the most crucial need of the business that
                        requires to be increased with passing time and growth of
                        a business. Capital sharing ratio and profit (loss)
                        sharing ratio are interlinked from the partner’s
                        perspectives. To affect both or any of them, executing
                        supplementary deed would be required.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Change rights and responsibilities of partners</h2>
                      <p>
                        Partners and their status being intact, the rights and
                        responsibilities of the partners can be changed as per
                        their roles and requirements. Administrating powers or
                        restriction on few activities and similar changes are
                        covered mostly while changing such terms.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Change other clauses</h2>
                      <p>
                        Other important clauses, such as jurisdiction of LLP,
                        terms of resignation, notice period, conditions of
                        appointment and removal, change in the duration of the
                        partnership, etc. can be changed as per the requirement
                        of the partners and business. Addition, alteration or
                        deletion of clauses can also be covered.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required to change LLP Agreement</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Digital Signature Certificate</h2>
                    <p>DSC of one of authorised partner to be provided</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>LLP Agreement</h2>
                    <p>
                      An agreement to be provided including the amendments, if
                      any
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>CoI</h2>
                    <p>Certificate of Incorporation of LLP to be provided</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>Copy of PAN card of the LLP to be provided</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to modify LLP Agreement</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2-6</Tab>
                <Tab>Day 7-8</Tab>
                <Tab>Day 9 onwards</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and consultancy for required changes</li>
                  <li>Collection of basic Information & documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of necessary resolutions & documents</li>
                  <li>Drafting of Supplementary LLP Agreement</li>
                  <li>Provide documents prepared after signature</li>
                  <li>Payment of stamp duty on Agreement</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Preparation of application for change</li>
                  <li>Filing LLP Form 3 and necessary documents with MCA</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How is the supplementary agreement executed?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Once the deed is prepared by the professionals and
                        confirmed by the partners, appropriate stamp duty must
                        be paid on the agreement. Further, all the partners and
                        designated partners must put their signature reflecting
                        their approval for the change. A deed should be
                        notarized if required.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the amount of stamp duty payable?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The amount of stamp duty shall be payable based on the
                        capital contribution involved in the change of LLP
                        Agreement. The rates of stamp duty vary from state to
                        state. Where the change of Agreement does not involve
                        capital contribution, the Supplementary Deed to LLP
                        Agreement shall be executed on payment of Rs 100/-
                        towards stamp duty (included in package cost). The stamp
                        duty in addition to Rs 100/- is payable separately.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When is the supplementary deed required to be filed?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Once the deed is executed by the partners, it must be
                        filed with MCA for its approval. It is filed within 30
                        days of execution or the effective date, whichever falls
                        earlier.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether notary is required on supplementary deed?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A requirement of a notary is led by the concerned State
                        of LLP. Although it is not required for all States, for
                        States such as UP or Delhi notarisation is mandatory.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When will the changes in LLP Agreement be effective?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The changes in LLP Agreement shall be effective since
                        the date of execution or effective date, however only
                        after receipt of approval from Registrar for the
                        application made in concerned form.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Do I see the amended LLP agreement on portal?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, LLP Agreement is not a public document and therefore
                        it is not accessible through portal. However, change of
                        capital and name is reflected on portal.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ChangeLLPAgreement;
