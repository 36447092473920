import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout    from "../common/Layout";
function ChangePartnershipDeed() {
  const pageName = "ChangePartnershipDeed";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Change Partnership Deed</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Know about Partnership deed and change</h2>
                <div className="bar"></div>
                <p>
                  Partnership Deed is a fundamental document for the firm as it
                  governs the firm’s operations and activities. The partners
                  must abide by the clauses of partnership deed during its
                  existence and must not act beyond the scope drawn. Hence, to
                  extend or restrict the scope of activities or operations,
                  partners may change the partnership agreement.
                </p>
                <p>
                  The changes in Partnership deed are made by execution of a
                  supplementary deed which is an addendum to the original
                  partnership deed. Payment of appropriate stamp duty is a must
                  for said deed. The registration of the supplementary deed
                  would be compulsory if the firm is already registered with
                  Registrar of Firm.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Reasons of change in Partnership Deed</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Change of Partners and rights and responsibilities</Tab>
                <Tab>Change Business Activity or name</Tab>
                <Tab>Change capital and profit sharing ratio</Tab>
                <Tab>Change other clauses</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>
                        Change of Partners and rights and responsibilities
                      </h2>
                      <p>
                        It is the most common reason for modification indeed.
                        Any existing partner may be unable to continue or new
                        partner may be required to be added with the growth of
                        the business. In all these cases of appointment,
                        addition, removal, resignation or death of a partner,
                        supplementary partnership deed for a change of partners
                        is executed.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Change Business Activity or name</h2>
                      <p>
                        As said, the firm cannot act beyond the scope drawn by
                        the agreement. Thus, if required to undertake new
                        business activity, the partners must amend the business
                        clause of the partnership deed enumerating the new
                        activities. Also, the modification may take place to
                        restrict the scope of activities by removing
                        discontinued activities.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Change capital and profit sharing ratio</h2>
                      <p>
                        Where capital is the need of business, return is need
                        for partners. The capital can be increased to expand
                        business or other requirements. The change in capital
                        mostly comes with change in profit sharing or ownership
                        ratio, too. Both would require the change in partnership
                        deed. The change in capital also demands payment of
                        additional stamp duty to Government.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Change other clauses</h2>
                      <p>
                        Name of partnership or change in place of business, the
                        addition of branch office etc. can be made effective
                        through executing the supplementary deed. Other clauses
                        cover change in term & conditions of appointment,
                        resignation and notice period, the power of operations,
                        jurisdiction of partnership, the manner of dissolution
                        and duration of the partnership, etc.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required to modify Partnership deed</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Partnership Deed</h2>
                    <p>
                      Original Partnership deed with the modifications made, if
                      any
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>Copy of PAN card of the firm to be provided</p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Proof from new partner</h2>
                    <p>
                      Copy of PAN card and address proof of new partner, if
                      applicable
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to modify Partnership Deed</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2-4</Tab>
                <Tab>Day 5-7</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and consultancy for required changes</li>
                  <li>Collection of basic Information & documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of the supplementary deed</li>
                  <li>Review and provide deed after signature</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Payment of required Stamp Duty</li>
                  <li>Notary on the signed supplementary deed</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How does the supplementary agreement is executed?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Once the deed is prepared by the professionals and
                        confirmed by the partners, appropriate stamp duty is
                        paid on the agreement. The notarization of the deed is
                        followed by the signature of all partners reflecting
                        their approval for the change.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the amount of stamp duty payable?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Stamp duty is variable and based on the concerned State
                        law of firm. It is calculated based on the capital
                        contribution involved in the change. However, if there
                        is no change in capital, the supplementary deed must be
                        executed by payment of Rs 100/- towards stamp duty
                        (included in package cost). The stamp duty in addition
                        to said amount shall be payable separately.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether change in constitution of partnership firm is
                        required to be notified to RoF?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        If the original partnership deed is duly registered with
                        Registrar of Firms, then any modifications must also be
                        notified to the RoF after its execution.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When will the changes by supplementary deed be
                        effective?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The changes will be effective after signature of
                        partners and notary on the deed. If a partnership deed
                        is registered, the certificate of modification must be
                        obtained after execution.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How can I add new Partner in the Partnership Firm in
                        India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Addition of a new partner can be made in a manner
                        prescribed by the effective Partnership Deed. The
                        partner to be added should fulfill the requirement as
                        prescribed in the deed if any. The change of partner in
                        partnership deed must be reflected along with the date
                        of addition, terms, and conditions of joining, etc.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether the place of business can be changed?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        For addition or branch place or new principal office, a
                        supplementary partnership deed for a change of address
                        must be executed in a manner explained in the first FAQ.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ChangePartnershipDeed;
