import * as React from "react";
import aboutimg from "../assets/digital.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function INC22AFiling() {
  const pageName = "INC22AFiling";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>INC-22A Filing</h2>
                  <p>
                    Tag your company’s status as ACTIVE by filing form INC- 22A
                    with MCA
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is form INC- 22A?</h2>
                <div className="bar"></div>
                <p>
                  MCA has introduced a new form INC-22A – ACTIVE(Active Company
                  Tagging Identities and Verification) with effect from 25th
                  February 2019 by inserting Rule 25 A to the Companies
                  (Incorporation) Rules, 2014. Every company incorporated on or
                  before 31st December 2017 and are under ‘Active’ status has to
                  file this form on or before 25th April 2019. If the form is
                  not filed within the due date then the MCA will charge late
                  filing fees of Rs. 10,000/-. Further, the company will be
                  marked as ‘ACTIVE non-complaint company’ and the DIN
                  associated with such company will be marked as ‘Director of
                  ACTIVE non-compliant company’.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>
                Documents and information required for filing form INC-22A
              </h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>COI</h2>
                    <p>Certificate of Incorporation</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>
                      Photograph of external building of the registered office
                    </h2>
                    <p>
                      It must include name, registered address and GST number in
                      english and local language
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph of inside the office</h2>
                    <p>
                      It must have at least 1 director therein the office whose
                      DSC will be affix on the form
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Latitude and longitude of the registered office</h2>
                    <p>
                      The company has to specify the details related to latitude
                      and longitude
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Email id of the company</h2>
                    <p>Email id must be provided for OTP verification</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Statutory Auditor</h2>
                    <p>SRN of the form for appointment of Auditor</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Annual compliance forms</h2>
                    <p>SRN of the form AOC-4 and MGT-7</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>DSC & Other details</h2>
                    <p>
                      DSC of at least 2 directors will be required. Details of
                      appointment of MD/CEO/Manager/WTD/Company Secretary/Cost
                      Auditor (if applicable)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of filing online form INC-22A</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Collection of basic Information</li>
                  <li>Provide Required Documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Preparation and filling an online form</li>
                  <li>Drafting of application with required information</li>
                  <li>Online filing with Professional Certification</li>
                  <li>Sharing Company’s Master Data</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Which company is required to file the Form INC-22A?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Every company incorporated on or before 31st December
                        2017 shall file the form with MCA.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the due date?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The form INC-22A must be filed on or before 25th April
                        2019.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Are LLPS’s required to file this form?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, only companies incorporated on or before 31st
                        Decmber 2017 are required to file the form.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the government fee for filing INC-22A?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>There is no government fee filing this form.</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is there any penalty for non filing the form?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, MCA will charge late filing fees of Rs. 10,000/- if
                        the form is filed on or after 26th April 2019.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether OPC is required to file the form?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, OPC is a form of company hence it has to file the
                        form INC-22A.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default INC22AFiling;
