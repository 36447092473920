import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";
function AccountingandBookkeeping() {
  const pageName = "AccountingandBookkeeping";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Accounting and Book keeping</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is Accounting and Book keeping?</h2>
                <div className="bar"></div>
                <p>
                  Recording the day-to-day financial affairs of a business is a
                  part of Bookkeeping. The preparation of financial accounts is
                  known as Accounting. Accounting is mandatory for all forms of
                  businesses including Small Enterprises and Freelancers. Apart
                  from the compulsion, it is always better to keep the financial
                  statements up to date and accurate to avoid financial muddles.
                  Maintenance of accounts is as essential for a home-run
                  business as it is for an industrialist. There are mandatory
                  standards of accounting a business needs to follow. To conform
                  to such criteria, it becomes essential to take help from
                  experts.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Accounting and Book keeping</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>To gauge the income and expenditure of the business</Tab>
                <Tab>Timely access to operational information</Tab>
                <Tab>Regular reconciliation rationalizes decisions</Tab>
                <Tab>Easy compliance requirements</Tab>
                <Tab>Ready to furnish data for timely compliance</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>
                        To gauge the income and expenditure of the business
                      </h2>
                      <p>
                        To ensure smoothness and transparency, every individual,
                        firm, or corporation must know about the income
                        generated and expenditure incurred. This helps them to
                        plan and strategize their financial resources and
                        exploit them accordingly.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Timely access to operational information</h2>
                      <p>
                        Up to date records provide access to operational
                        information at any time to management. Since business
                        owners or managers are not directly associated with all
                        transactions, accounting and bookkeeping will help them
                        to keep an eye on all the activities from time to time.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Regular reconciliation rationalizes decisions</h2>
                      <p>
                        Monthly or quarterly reconciliation of data helps the
                        management to analyze the beneficial or detrimental
                        aspects of a business. Accounting services come with the
                        additional benefit of periodical reconciliation of data.
                        Hence, future decisions can be rationalized after
                        analyzing the profit and loss.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>
                        Attract investors with accurate financial statements
                      </h2>
                      <p>
                        Investing in any business is followed only after a
                        complete analysis of its financial condition. While
                        approaching investors, you need to make sure that your
                        books of accounts are up-to-date and accurate.
                        Inconsistency in financial statements may refrain
                        investors from jumping into your business.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Ready to furnish data for timely compliance</h2>
                      <p>
                        Accounting makes it easy to extract data and submit it
                        on time for regular return filing, compliances, and
                        scrutiny of any business.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>
                Documents required for Accounting and Book keeping Services
              </h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <p>Company / Business Incorporation Documents</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <p>
                      Bank statement of a financial year or monthly statement
                      (with remarks)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <p>Purchase-Sales invoices, if any</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <p>Expense bills, if any</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <p>Any receivable and payable detail</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <p>Any other Government registration taken</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <p>Cash Expenses</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <p>
                      Bank Statement of partner/ members with remarks if used
                      for business transactions
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <p>
                      Expenses made for company or LLP registration by promoters
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Manage Books in 3 Easy Steps</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Follow Quick Process</Tab>
                <Tab>Experts at LegalWiz.in are here to help</Tab>
                <Tab>Get access to updated Accounts</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>
                    Pick the best suitable Package for accounting and book
                    keeping
                  </li>
                  <li>
                    Spare less than 10 minutes to fill our online Questionnaire
                  </li>
                  <li>Upload required Documents</li>
                  <li>Make quick payment through our secured gateways</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>We assign a dedicated Relationship Manager</li>
                  <li>Provide the details of the Business and transactions</li>
                  <li>
                    Entries in the Books of Accounts for a complete Financial
                    Year
                  </li>
                  <li>Maintaining the books of accounts daily</li>
                  <li>Deliver up-to-date data</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>
                    Get access to updated Accounts as per your requirement
                  </li>
                  <li>For us, data secrecy is a priority</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the best Automated Accounting option for Small
                        Businesses/ Freelancers?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The answer depends on your personal needs, which are
                        based on many factors such as the number of employees,
                        your business activities, etc. There are many Automated
                        Accounting Software options. The best ones suitable for
                        Small Enterprises are Quickbooks Online and Zoho Books.
                        Furthermore, even freelancers need to keep track of
                        their income. While working on your terms can be fun,
                        managing your accounts can be a difficult task. Software
                        like Quickbooks Self-Employed will be your savior!
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        If our transaction entries exceed the package we bought,
                        what can we do?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        At LegalWiz.in, we have fixed packages and if your
                        entries exceed the package chosen by you, we will
                        upgrade your services in a few easy steps on a pro-rata
                        basis.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Why take an expert’s assistance when I can maintain my
                        own Accounts?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        You can always do certain tasks on your own but expert
                        help is always the safer option. These are the reasons
                        why you should hire an expert for your Accounting and
                        Book keeping needs
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How much time will I save by delegating my bookkeeping
                        tasks to you?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Generally, if you are well versed with the basics of
                        accounting and bookkeeping, maintaining daily entries
                        will take up an excessive part of your day. As a
                        business owner, this situation is not very profitable
                        for you. Instead, delegating your Bookkeeping to experts
                        will save that part of your day and enable you to focus
                        on strategies and developmental planning.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Do I lose all control of my accounts if I take up your
                        Accounting services?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, you do not lose control of your Accounts if you get
                        services from us. Our experts stay in constant touch
                        with you. Furthermore, you can access the data any time
                        you want as the whole process is handled over the
                        Internet. In case LegalWiz.in experts are using Tally
                        for accounting purposes, you can ask for extraction of
                        data at any point of time.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When is it suitable to get accounting & book-keeping
                        services for an entity?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        You can opt for Accounting Services at any time.
                        However, the best time to go ahead with these services
                        is at the beginning of a Financial Year, so that the
                        records can be maintained properly.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default AccountingandBookkeeping;
