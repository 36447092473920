import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function LLPtoPrivateLimitedCompany() {
  const pageName = "LLPtoPrivateLimitedCompany";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Conversion of LLP into private limited company</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Conversion of LLP into private limited company</h2>
                <div className="bar"></div>
                <p>
                  In India, private companies are one of the most common forms
                  of business structure. They provide higher chances of growth,
                  development and are best for raising equity capital which is
                  not possible in case of LLP. LLP structure is not suitable if
                  the owners require venture capitalist or private equity
                  investors to invest in their company as they would choose to
                  invest in a private limited company and not a partnership or
                  LLP. The second reason for conversion is the that the FDI in
                  case of a private limited company does not require any
                  approval it can be done directly unlike in an LLP. Especially
                  if the promoters or owners of the company are NRI’s or a
                  foreigner incorporating a private limited company is a
                  preferable choice over an LLP. Hence conversion is mandatory
                  if the above-mentioned requirements need to be fulfilled.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of converting LLP into limited company</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Easy Fund raising</Tab>
                <Tab>Separate Legal Existence</Tab>
                <Tab>Separate legal entity</Tab>
                <Tab>Raising Capital</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Easy Fund raising</h2>
                      <p>
                        Stringent registration process makes this structure more
                        credible among other which makes fundraising or
                        borrowing from external sources easier. The organization
                        itself provides a number of ways to raise funds in form
                        of private equity, ESOP, and more.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Separate Legal Existence</h2>
                      <p>
                        The separate ownership and management help both – the
                        company and the management to focus on their potential
                        works. The shareholders assign responsibility to operate
                        and run the company without losing control in form of
                        voting.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>ESOPs to employees</h2>
                      <p>
                        Only private limited companies can offer stock ownership
                        and ESOP plans. It attracts employees as it creates an
                        incentive for them to work in the company and advantage
                        with its growth and prosperity.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Limited Liability of Owners</h2>
                      <p>
                        The obligation or debts of the company does not create a
                        charge over the owner’s personal assets. Their liability
                        is limited only to the capital subscribed and unpaid by
                        them.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for conversion into Private Company</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      PAN Card of shareholders and Directors. Foreign nationals
                      may provide a passport.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Identity Proof</h2>
                    <p>
                      Voter ID/ Passport/ Driving License of Shareholders and
                      Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Address Proof</h2>
                    <p>
                      Telephone Bill /Electricity Bill/ Latest Bank Account
                      Statement of Shareholders and Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Latest Passport size photograph of Shareholders and
                      Directors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Electricity Bill/ Telephone Bill of the registered office
                      address
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from partners</h2>
                    <p>
                      No Objection Certificate to be obtained from the owner(s)
                      of registered office
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      Rent Agreement of the registered office should be
                      provided, if any
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Note</h2>
                    <p>
                      In case of NRI or Foreign National, documents of
                      director(s) must be notarized or apostilled
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Copy of ITR</h2>
                    <p>
                      A copy of the latest income tax return filed by the
                      Limited Liability Partnership.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to convert LLP to pvt ltd</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2-4</Tab>
                <Tab>Day 5-7</Tab>
                <Tab>Day 8-9</Tab>
                <Tab>Day 10-11</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Application for Digital Signature Certificate</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Application for Name Reservation under “RUN“</li>
                  <li>Reservation of Name</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of MoA, AoA & other required documents</li>
                  <li>Payment of Stamp Duty</li>
                  <li>Notarization of required documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Filing application for company registration</li>
                  <li>Application for DIN Allotment</li>
                  <li>Application for PAN and TAN of company</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the statutory requirements to be fulfilled once
                        Private Company is registered?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Once, the company is registered, it should follow
                        below-mentioned requirements on priority:
                      </p>
                      <ul>
                        <li>Opening a current account of the company</li>
                        <li>Appointment of Statutory auditor</li>
                        <li>
                          Deposit of paid-up capital mentioned while
                          registration
                        </li>
                        <li>Issue and allotment of shares</li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How many DIN can be apply through SPICE Form?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Maximum 3 (Three) DIN can be applied through SPICE form.
                      </p>
                      <p>
                        If the applicant wants to incorporation Company with
                        more than 3 Directors and more than 3 persons don’t have
                        DIN. In such situation applicant have to incorporate
                        Company with 3 Directors and have to appoint new
                        directors later on after incorporation.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the minimum Capital Requirement?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        While registration, minimum authorized capital of INR 1
                        Lakh should be provided. The requirement of minimum
                        paid-up capital is eliminated as part of Government’s
                        initiative to simplify business registration in India.
                        However, each shareholder must subscribe at least 1
                        share for registration and amount sufficient to run
                        business should be introduced.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to file the Conversion form in case of more than 7
                        partners in the LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In case of more than 7 partners in the LLP at the time
                        of conversion into Company then Company have to file
                        Scan copy of Physically prepared MOA & AOA and the
                        company has to file 1. URC-1 and 2. INC-32.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether at the time of Conversion whether Latest deed
                        shall be attached in the form URC-1?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, at the time of Conversion LLP have to file “copies
                        of the principal and all subsequent deeds including the
                        latest deed” with the ROC in e-form URC-1.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Are shares of a private company easily transferable?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, shares of Private Company are easily transferable
                        among members of the company but for transferring the
                        shares to a non-member, the share should be first
                        offered to an existing member and if he refuses to
                        purchase the same, the shares can be transferred to
                        non-members.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default LLPtoPrivateLimitedCompany;
