import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="site-footer">
      <footer>
        <div className="subscribe-area">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="subscribe-content">
                  <h2>Join Our Newsletter</h2>
                  <p>
                    Get Better Solution For Your Business &amp; Get Update
                    Newsletter
                  </p>
                </div>
              </div>
              <div className="col">
                <form className="newsletter-form">
                  <input
                    type="email"
                    className="input-newsletter"
                    placeholder="Enter your email"
                    name="email"
                    required=""
                  />
                  <button type="submit">Subscribe Now</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="ft-grids">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="single-footer-widget">
                  <div className="footer-heading">
                    <h3>Popular Services</h3>
                    <div className="bar"></div>
                  </div>
                  <ul className="footer-quick-links">
                    <li>
                      <a href="#">LLP Registration</a>
                    </li>
                    <li>
                      <a href="#">Partnership Firm Registration</a>
                    </li>
                    <li>
                      <a href="#">GST Return Filing</a>
                    </li>
                    <li>
                      <a href="#">Trademark Registration</a>
                    </li>
                    <li>
                      <a href="#">Accounting and Bookkeeping</a>
                    </li>
                    <li>
                      <a href="#">Explore All Services</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="single-footer-widget">
                  <div className="footer-heading">
                    <h3>Important Links</h3>
                    <div className="bar"></div>
                  </div>
                  <ul className="footer-quick-links">
                    <li>
                      <a href="#">About Us</a>
                    </li>
                    <li>
                      <a href="#">Blog</a>
                    </li>
                    <li>
                      <a href="#">Careers</a>
                    </li>
                    <li>
                      <a href="#">Partner With Us</a>
                    </li>
                    <li>
                      <a href="/contact">Contact Us</a>
                    </li>
                    <Link to="/Pricing">
                      Pricing
                    </Link>
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="single-footer-widget">
                  <div className="footer-heading">
                    <h3>Terms And Policies</h3>
                    <div className="bar"></div>
                  </div>
                  <ul className="footer-quick-links">
                    <li>
                      <a href="/termsAndConditions.html" target="_blank">
                        Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a href="/privacypolicy.html" target="_blank">
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="/refundpolicy.html" target="_blank">
                        Refund Policy
                      </a>
                    </li>
                    {/* <li><a href="#">Satisfaction Guarantee</a></li> */}
                  </ul>
                </div>
              </div>
              <div className="col">
                <div className="single-footer-widget">
                  <div className="footer-heading">
                    <h3>Contact</h3>
                    <div className="bar"></div>
                  </div>
                  <div className="footer-info-contact">
                    <i className="fa fa-phone"></i>
                    <h3>Phone</h3>
                    <div>
                      <p>+91 7003970148</p>
                      <p>+91 9749657483</p>
                    </div>
                  </div>
                  <div className="footer-info-contact">
                    <i className="fa fa-envelope"></i>
                    <h3>Email</h3>
                    <span>
                      <a href="#">info@filingsaga.com</a>
                    </span>
                  </div>
                  <div className="footer-info-contact">
                    <i className="fa fa-map-marker-alt"></i>
                    <h3>Address</h3>
                    <span>
                      C1107, Uber Verdant, Tower C, Sarjapur Main Road,
                      Bengaluru, Karnataka 560035
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright_text">
              <p>© 2023 Filing Saga | All Rights Reserved</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
