import React from "react";
import { TailSpin } from "react-loader-spinner";
import "./Loader.css";

const Loader = ({ style }) => (
  <div className="Wrapper" style={style}>
    <div className="Loader">
      <TailSpin />
    </div>
  </div>
);

export default Loader;
