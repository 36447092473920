import * as React from "react";
import aboutimg from "../assets/digital.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function DIR3KYCFiling() {
  const pageName = "DIR3KYCFiling";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>DIR-3 KYC Filing</h2>
                  <p>
                    Update your KYC annually with professional certification
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is form DIR-3 KYC?</h2>
                <div className="bar"></div>
                <p>
                  Filing DIR-3 KYC is a part of annual KYC conducted by Ministry
                  of Corporate Affairs. MCA has announced mandatory annual KYC
                  for all DIN holder w.e.f. 10th July 2018 by inserting Rule 12A
                  to Companies (Appointment and Qualifications of Directors)
                  Rules, 2014. This compliance is applicable to every individual
                  holding DIN with ‘Approved’ status and also to those who are
                  disqualified.
                </p>
                <p>
                  This being annual requirement, every DIN holder has to fulfill
                  the compliance with respect to every financial year. The
                  common due date for filing is 30th April of immediate next
                  financial year. The last date (i.e. 15th September, 2018) for
                  filing the form for a person holding DIN on or before 31st
                  March, 2018 has been passed. Therefore, DIN of the person who
                  has failed to file the form will be deactivated. To reactivate
                  the DIN, the concerned person has to file the KYC with fee of
                  INR 5,000 to Government.
                </p>
                <p>
                  However, considering the technical difficulties faced to
                  comply with KYC requirements, the ministry has allowed DIR-3
                  KYC filing at a reduced fee of Rs 500 for 15 days starting
                  from 21st September. October 6 onwards, fee of Rs 5,000 will
                  apply to reactivate DIN.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
            </div>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for filing DIR-3 KYC</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>Digital Signature Certificate</h2>
                    <p>Class – 2 DSC of the individual based on PAN</p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>Self-attested copy of PAN Card to be provided</p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Address Proof</h2>
                    <p>
                      Self-attested copy of Aadhar Card; or Voter’s ID Card; or
                      Driving Licence
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Self-attested copy of Passport to be provided, if any
                      (Mandatory for Foreign Nationals)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Mobile No. & E-mail Id</h2>
                    <p>
                      Unique personal number and e-mail Id to be provided for
                      OTP verification
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Other Details</h2>
                    <p>
                      Details of Citizenship, Nationality and Residential Status
                      to be provided
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of filing KYC online</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1 - Collection</Tab>
                <Tab>Day 2 - 3 - Preparation & Filing</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Collection of basic Information</li>
                  <li>Provide Required Documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Preparation and filling an online form</li>
                  <li>Online filing with Professional Certification</li>
                  <li>Sharing updated Director/ Partner Master Data</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who is required to file e-form DIR-3 KYC?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Every individual who has been allotted DIN and has
                        ‘approved’ status must file this form if he is:
                        <br />
                        – a director in the company; or
                        <br />
                        – a partner in LLP; or
                        <br />– holding DIN but not associated with any
                        organization.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is it mandatory to have DSC for filing DIR-3 KYC?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, every DIN holder must possess valid PAN-based Class
                        – 2 DSC. The e-form is filed with individual’s own DSC,
                        details of which are verified directly with the Income
                        Tax Department as per PAN. In case of foreign national,
                        the name would be matched with his/her name entered
                        while filing DIR-3 KYC.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the Government fee for filing e-form DIR-3 KYC?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        For filing the said form, there is no government fee
                        prescribed. However, if it is filed after the due date,
                        a penalty of INR 5,000 is imposed.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is professional certification mandatory?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, professional certification for filing DIR-3 KYC is
                        mandatory. The e-form must be certified by the
                        practising professional i.e. CA/CS/CMA with his DSC.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        My DIN status is ‘Deactivated’. Can I file KYC form?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, an Individual with ‘Approved’ status can only file
                        this form. However, if your DIN is deactivated by reason
                        of non-filing of DIR-3 KYC, you can file this form for
                        re-activating DIN by payment of the prescribed fee (INR
                        5,000).
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        I am a non-resident Indian. Can I provide Indian number
                        mobile number?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No. A non-resident must provide a foreign mobile number
                        and foreign address as their permanent address.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default DIR3KYCFiling;
