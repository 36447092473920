import React, { useState, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants";

const initialData = {
  name: "",
  lname: "",
  email: "",
  phone: "",
};

function LeadInputs(props) {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState({
    ...initialData,
    category: location.pathname,
  });
  const [isLoading, setIsLoading] = useState(false);

  const onChangeHandler = (e) => {
    const { name = "", value = "" } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const { name = "", email = "", phone = "", lname = "" } = data;
  const disableSubmit = useMemo(() => {
    if (!name || !email || !phone || isLoading) return true;
    return false;
  }, [name, email, phone, isLoading]);

  const onClickSubmit = (e) => {
    e.preventDefault();
    if (disableSubmit) return;
    setIsLoading(true);
    axios
      .post(`${API_URL}/api/createLead`, data)
      .then((res) => {
        setData((prevState) => ({ ...prevState, ...initialData }));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  console.log(props.pageName, "prospss");

  const applyNow = () => {
    const token = localStorage.getItem("token");
    if (token) {
      history.push({
        pathname: "/applyForm",
        search: `${props.pageName}`,
      });
    } else {
      history.push("/login");
    }
  };

  return (
    <form className="details_form">
      <div className="form-group">
        <input
          type="text"
          name="name"
          placeholder="First Name"
          className="form-control"
          required=""
          value={name}
          onChange={onChangeHandler}
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          name="lname"
          placeholder="Last Name"
          className="form-control"
          required=""
          value={lname}
          onChange={onChangeHandler}
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          name="phone"
          placeholder="Phone Number"
          className="form-control"
          required=""
          value={phone}
          onChange={onChangeHandler}
          maxLength="10"
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          name="email"
          placeholder="Email"
          className="form-control"
          required=""
          value={email}
          onChange={onChangeHandler}
        />
      </div>
      <div className="form-group">
        <input
          type="submit"
          name="get-started"
          className="form-control"
          style={disableSubmit ? { background: "#9a9a9a" } : {}}
          required=""
          value={isLoading ? "Loading..." : "Enquire Now"}
          onClick={onClickSubmit}
        />
      </div>
      <div className="form-group">
        <input
          type="submit"
          name="get-started"
          className="form-control"
          style={
            !disableSubmit
              ? { background: "#9a9a9a" }
              : { background: "#50C878" }
          }
          required=""
          value="Apply Now"
          onClick={applyNow}
        />
      </div>
    </form>
  );
}

export default LeadInputs;
