import * as React from "react";
import "./Services.css";
import Layout from "../common/Layout";
function Sec80GSec12A() {
  const pageName = "Sec80GSec12A";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="banner-content">
              <h2>Sec. 80G & Sec. 12A</h2>
            </div>
          </div>
        </section>
        <section className="coming-soon">
          <div className="container">
            <div className="banner-content">
              <h2>Coming Soon</h2>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Sec80GSec12A;
