import * as React from 'react';
import Layout from '../common/Layout';
import './Services.css';

function MemorandumofUnderstanding() {
  const pageName = "MemorandumofUnderstanding";
  return(
<Layout>
<div className='subcatpage-data'> 
      <section className="sbanner_section">
            <div className="container">
                    <div className="banner-content">
                        <h2>Memorandum of Understanding (MoU) </h2>
                    </div>
            </div>
      </section>
      <section className="coming-soon">
            <div className="container">
                    <div className="banner-content">
                        <h2>Coming Soon</h2>
                    </div>
            </div>
      </section>
   </div>
</Layout>
  );
}

export default MemorandumofUnderstanding; 
