import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function ProprietorshiptoLLP() {
  const pageName = "ProprietorshiptoLLP";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Proprietorship to LLP</h2>
                  <p>
                    Convert proprietorship to LLP to leverage on added benefits
                    with limited liability
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Change from Proprietorship to LLP</h2>
                <div className="bar"></div>
                <p>
                  Limited liability partnership in India was introduced through
                  the LLP Act, 2008. The basic concept behind adopting LLP was
                  to provide a structure that is easy to maintain and reduces
                  the liability as compared to a sole proprietorship structure.
                  LLP combines the advantages of both the Company and
                  Partnership firm into a single form of organization and offers
                  a hybrid structure. Hence, conversion of sole proprietorship
                  into LLP is a good business decision. Under LLP, one partner
                  is not responsible or liable for another partner’s misconduct
                  or negligence. LLP also provides limited liability protection
                  for the owners from the debts of the LLP. Therefore, LLP is
                  preferred mostly by Professionals, Micro and Small businesses
                  that are family-owned or closely-held.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of conversion from proprietorship to LLP</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Separate Legal Existence</Tab>
                <Tab>Limited Liability of Owners</Tab>
                <Tab>Flexibility to Operate</Tab>
                <Tab>Lower Compliance Requirement</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Separate Legal Existence</h2>
                      <p>
                        Limited liability partnership is a separate legal
                        entity, and its existence is separate from its partners,
                        unlike the general partnership firm. This makes it
                        possible to own assets and enter into contracts in the
                        name of the LLP or sue a third party in case of any
                        dispute.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Limited Liability of Owners</h2>
                      <p>
                        The liability of Partners is limited to the extent of
                        capital contribution agreed by the partners in the LLP
                        Agreement. The loss or debt of LLP cannot be assigned to
                        partners even while the dissolution of LLP. Further, one
                        partner is not held responsible for the actions of
                        negligence or misconduct of any other partner.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Flexibility to Operate</h2>
                      <p>
                        The LLP is managed and run according to the LLP
                        agreement. It’s the partners that decide how the LLP
                        would function and divide the duties and
                        responsibilities. Hence, it is a very flexible structure
                        and the partners are free to create their own rules of
                        management which is not possible in other business
                        structures.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Lower Compliance Requirement</h2>
                      <p>
                        Compared to a Private Company, there is a lower
                        compliance requirement in case of LLP, including the
                        audit requirement. The requirement of statutory audit
                        arises on reaching a certain level of turnover or
                        contribution. Further, provisions such as the meeting of
                        partners, operation through resolutions are relaxed and
                        not mandatory in every case.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for conversion into LLP</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      PAN Card of all partners Foreign nationals may provide
                      passport
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Partner’s Address Proof</h2>
                    <p>
                      Aadhar Card/ Voter ID/ Passport/ Driving License of all
                      partners
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>Latest Passport size photograph of all partners</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Electricity Bill/ Telephone Bill of the registered office
                      address
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from owner</h2>
                    <p>
                      No Objection Certificate to be obtained from the owner of
                      registered office
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      Rent Agreement of the registered office should be
                      provided, if any
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NRI/ Foreign National</h2>
                    <p>
                      In case of NRI or Foreign National, documents of partner
                      must be notarized or apostilled
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to convert proprietorship to LLP</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1-2</Tab>
                <Tab>Day 3-5</Tab>
                <Tab>Day 6-7</Tab>
                <Tab>Day 8-12</Tab>
                <Tab>Day 13-18</Tab>
                <Tab>Day 19-21</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Consultancy and assistance for conversion</li>
                  <li>Collection of basic information & documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Application for Digital Signature Certificate (DSC)</li>
                  <li>Application for DIN allotment of Designated Partners</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Checking Name availability</li>
                  <li>Application for Name Reservation</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting the incorporation document</li>
                  <li>Filing form for converting sole proprietorship to LLP</li>
                  <li>Certificate of Incorporation</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Application for PAN and TAN of LLP</li>
                  <li>Drafting of LLP Agreement, with conversion clause</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Payment of Stamp Duty</li>
                  <li>Filing of LLP Agreement with MCA</li>
                  <li>Government processing time</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the key requirements/factors for registering an
                        LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Like all partnerships registration requires two or more
                        individuals to be the designated partners, one partner
                        being an Indian national. The registered place of
                        business has to be in India.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Are there any limitations on being a partner when
                        converting sole proprietorship to LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The LLP Act, 2008 does not put any limitations in terms
                        of citizenship or residency to be a Partner. Foreign
                        Nationals, including Foreign Companies & LLPs, are
                        allowed to incorporate LLP in India provided at least
                        one of the Designated Partners is resident of India.
                        However, the person should be of age 18 years or above
                        i.e. not a minor and competent to enter into a contract.
                        Also, the proposed Designated Partner shall have DIN.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can LLP carry on more than one business activities?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        YES, under LLP one can carry more than one business,
                        provided, the businesses are related or are of the same
                        nature. Unrelated activities such as fashion Designing
                        and Accountancy cannot be carried under the same LLP.
                        The business activities are mentioned in the agreement
                        and must be approved from RoC.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the statutory compliance required for a
                        registering a Limited Liability Partnership?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Once the Limited liability partnership is incorporated,
                        it shall comply with the annual compliance requirements.
                        In case the capital contribution of the LLP is less than
                        ₹25 lakhs or has a turnover of less than ₹40 lakhs, the
                        financial statements are not required to be audited. To
                        know more details, please read our blog post “Mandatory
                        Compliances for a Limited Liability Partnership (LLP)
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Whether LLP can be registered for Not-for-profit
                        activities?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Profit making is an essential condition for an LLP;
                        hence LLPs cannot be incorporated for undertaking
                        non-profit activities.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I continue the trade name of Proprietorship as LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        While making an application for name reservation, the
                        trade name of the proprietorship can be applied to
                        procure as the name of LLP. Ministry may grant the same
                        name considering the fact that proprietorship is
                        converted into an LLP, except where the name of the firm
                        is already reserved by any other company/LLP. The
                        approval of the name application is completely at the
                        discretion of MCA.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ProprietorshiptoLLP;
