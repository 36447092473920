import * as React from "react";
import "react-tabs/style/react-tabs.css";
import "react-accessible-accordion/dist/fancy-example.css";
import Layout from "../common/Layout";

function Pricing() {
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="benifits_section"></section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Pricing</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>One Person Company</h2>
                    <h2 className="font-medium">8999/-</h2>
                    <p>Free consultancy</p>
                    <p>Free search check</p>
                    <p>Preview before filing</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>Private Limited Company</h2>
                    <h2 className="font-medium">9999/-</h2>
                    <p>Free consultancy</p>
                    <p>Free search check</p>
                    <p>Preview before filing</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>Limited Liability Partnership</h2>
                    <h2 className="font-medium">8999/-</h2>
                    <p>Free consultancy</p>
                    <p>Free search check</p>
                    <p>Preview before filing</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>Trademark Individuals</h2>
                    <h2 className="font-medium">7499/-</h2>
                    <p>Free consultancy</p>
                    <p>Free search check</p>
                    <p>Preview before filing</p>
                    <p>Automatic updates</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>Trademark MSME</h2>
                    <h2 className="font-medium">7499/-</h2>
                    <p>Free consultancy</p>
                    <p>Free search check</p>
                    <p>Preview before filing</p>
                    <p>Automatic updates</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>Trademark Company</h2>
                    <h2 className="font-medium">8999/-</h2>
                    <p>Free consultancy</p>
                    <p>Free search check</p>
                    <p>Preview before filing</p>
                    <p>Automatic updates</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>GST Registation</h2>
                    <h2 className="font-medium">1999/-</h2>
                    <p>Free consultancy</p>
                    <p>Preview before filing</p>
                    <p>Super-fast service</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>GST Filing</h2>
                    <h2 className="font-medium">500/-</h2>
                    <p>Free consultancy</p>
                    <p>Preview before filing</p>
                    <p>Super-fast service</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>Individual Tax Filing</h2>
                    <h2 className="font-medium">1500/-</h2>
                    <p>Free consultancy</p>
                    <p>Preview before filing</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>Business Tax Filing</h2>
                    <h2 className="font-medium">2500/-</h2>
                    <p>Free consultancy</p>
                    <p>Preview before filing</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>Annual Compliance</h2>
                    <h2 className="font-medium">14999/-</h2>
                    <p>Free consultancy</p>
                    <p>Preview before filing</p>
                    <p>Super-fast service</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>Change Address</h2>
                    <h2 className="font-medium">3999/-</h2>
                    <p>Free consultancy</p>
                    <p>Preview before filing</p>
                    <p>Super-fast service</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>Appoint Director</h2>
                    <h2 className="font-medium">4999/-</h2>
                    <p>Free consultancy</p>
                    <p>Preview before filing</p>
                    <p>Super-fast service</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>Remove Director</h2>
                    <h2 className="font-medium">3999/-</h2>
                    <p>Free consultancy</p>
                    <p>Preview before filing</p>
                    <p>Super-fast service</p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="flex flex-col justify-center items-center pt-6">
                    <h2>Authorised Capital Increase</h2>
                    <h2 className="font-medium">5999/-</h2>
                    <p>Free consultancy</p>
                    <p>Preview before filing</p>
                    <p>Super-fast service</p>
                  </div>
                </div>
              </div>
            </div>
            <h3>
              At FILING SAGA, we understand the importance of delivering
              exceptional service that not only meets but exceeds your
              expectations. Our pricing can be tailored to fit the unique
              requirements of each client. Please contact us directly for a
              personalized quote.
            </h3>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Pricing;
