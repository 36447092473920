import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function CloseOnePersonCompany() {
  const pageName = "CloseOnePersonCompany";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Close a One Person Company</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Closure of OPC</h2>
                <div className="bar"></div>
                <p>
                  If an OPC is inoperative for more than one year from the date
                  of incorporation then the owner may apply for closure of the
                  company under the normal procedure or Fast Track Exit (FTE)
                  scheme of the MCA. If not so it can be wound up voluntarily or
                  by the order of the Tribunal. As even though it’s inoperative
                  it is compulsorily required to file all regulatory compliances
                  and regular returns punctually, unless it has filed the
                  closure documents with the concerned ROC. Hence it is better
                  to file for the closure, so the members of the company are
                  relived from fulfilling the legal and regulatory compliances.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Methods of Winding Up One Person Company</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Winding up</Tab>
                <Tab>Striking off</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Winding up</h2>
                      <p>
                        This type of dissolution is done by holding a meeting
                        approved by at least 2/3 of the creditors participating
                        in the meeting. Then the management board must submit to
                        the Commercial Register a request (in writing or
                        electronic form via the company registration portal),
                        the members dissolution resolution and the minutes of
                        the general meeting. Winding up is a more elaborate
                        process which is necessarily implemented when the
                        company has assets and liabilities. In case of winding
                        up a liquidator is to be appointed to manage the affairs
                        of the wound up company.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Striking off</h2>
                      <p>
                        Striking off or removal of OPC through the Fast track
                        exit scheme. When a company gains the status of a
                        dormant company i.e. it has no activity as a company
                        sine its inception or in the past one year it becomes a
                        Defunct Company which can be wound up with a fast-track
                        procedure which is through STK-2 form. Condition being
                        that it should have no assets or liabilities. This can
                        be filed by ROC or by the company itself. Strike off is
                        done by the Registrar in accordance with the
                        requirements of the Act.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents Required to dissolve a one person company</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Incorporation Documents</h2>
                    <p>
                      Company’s MoA – AoA, Certificate of Incorporation, PAN
                      card and other registration certificates
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Accounting Information</h2>
                    <p>
                      The financial statement of the Company for the most recent
                      year, prepared prior to 30 days of filing the application
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Details of Activity</h2>
                    <p>
                      Details whether the company has been operative for any
                      period. If yes, since when the operations are discontinued
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Legal Liabilities</h2>
                    <p>
                      A statement regarding pending litigations, if any
                      involving the company
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from Creditors</h2>
                    <p>
                      Company must provide NOC for closure from creditors, if
                      any (Draft to be provided by LW experts)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from Regulatory Bodies</h2>
                    <p>
                      NoC for closure to be obtained from Income Tax Department,
                      SEBI, RBI, etc. if relevant
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of OPC Strike-off</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1-2</Tab>
                <Tab>Day 3-8</Tab>
                <Tab>Day 9-15</Tab>
                <Tab>Day 16 onwards</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and collection of basic Information</li>
                  <li>Provide required documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Review of the details and documents furnished</li>
                  <li>Drafting of necessary resolutions</li>
                  <li>
                    Drafting of the affidavit, indemnity bond, and other
                    documents
                  </li>
                  <li>Provide duly executed affidavit & indemnity bond</li>
                  <li>Provide signed documents after review</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Preparation of applications for online filing </li>
                  <li>Filing of required forms and documents with MCA</li>
                  <li>Application for striking-off company name</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time to approve strike-off</li>
                  <li>
                    The notice of strike-off to be published by MCA after
                    approval
                  </li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is mean by One Person Co. (OPC) Closure?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A closure of an one person company can be filed when the
                        company is not active and wants to shake hands off its
                        liabilities and compliances. It has to repay or
                        extinguish all their liabilities and receive a No
                        Objection Certificate (NOC) from the creditors before
                        filing the closure application. And conduct a meeting
                        where the director and members decide upon the closure
                        by signing a special resolution or a consent of
                        seventy-five percent members regarding paid up share
                        capital.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How to dissolve a one person company in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A Company closure is filed under Form STK 2 along with
                        the government fees of Rs.5000/- and some necessary
                        docs. A One Person Company closure can be filed after
                        the following steps:
                      </p>

                      <ul>
                        <li>
                          First step is to pay all liabilities and get an NOC
                          for the closure
                        </li>
                        <li>2/3rd majority Consent of the creditors</li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How long does it take dissolve a one person company
                        under fast track exit scheme?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        After filing the application with the Ministry of
                        Corporate Affairs, it takes about 90 days for striking
                        off the Company from MCA records.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When can a One person company be said to be dissolved?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        ROC will publish list of companies struck off in the
                        Official Gazette. The Company under fast track exit mode
                        will be considered closed from the date of publication
                        of the notice in Official Gazette.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the time limit to file the closure documents
                        with the Registrar?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The closing documents have to be filed within 30 days
                        from the date of signing of the assets and liabilities
                        statement.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Why is it necessary to intimate the Registrar for
                        closing the One Person Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        It is necessary to intimate the Registrar for the
                        closure of Private Limited Company to update the MCA
                        data and make company free from all its legal
                        compliances.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default CloseOnePersonCompany;
