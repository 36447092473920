import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import management from "../assets/management.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function TrademarkRegistration() {
  const pageName = "TrademarkRegistration";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Online Trademark Registration in India</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
              <div className="col col6">
                <h2>Register Trademark Online</h2>
                <div className="bar"></div>
                <p>
                  It takes a great deal of hard work and resources to build a
                  brand. Hence, It is important to make sure that you have
                  exclusive ownership to use the logo, slogan, shape and
                  packaging of goods, sound, smell, colour combinations or
                  anything else that gives your brand a distinct identity.
                  Trademark Act, 1999 allows you to register a trademark in
                  India. It makes way for exclusive ownership rights and
                  restricts all others from its usage and thereby benefitting
                  the owner of the registered mark.
                </p>
                <p>
                  Application for logo registration can be done quickly and
                  online. One can start using a “TM” symbol as soon as the
                  application is made. However, TM registration process takes up
                  to 15-18 months. It includes various processes for validating
                  original ownership of the mark. Trademark registration in
                  India is going through many innovative changes. Consider this
                  – one can now avail trademark for Unconventional Marks through
                  which even a distinct sound can now be registered as a
                  trademark in India.
                </p>
                <p>
                  Experts at LegalWiz.in file more than 1,000 trademark
                  applications every year. We represent clients in all major
                  cities of India including Mumbai, Delhi, Bangalore, Ahmedabad,
                  Chennai and more.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Trademark Registration in India</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Guards the Commercial Goodwill</Tab>
                <Tab>Advertises Goods and Services</Tab>
                <Tab>Legal Protection</Tab>
                <Tab>Creation of an Asset</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Guards the Commercial Goodwill</h2>
                      <p>
                        A trademark’s registered owner has a right to create,
                        establish and protect the goodwill of his/her products
                        or services. The owner can stop other traders from using
                        his trademark unlawfully. One can also sue the infringer
                        for dilution of the brand name and claim damages for any
                        infringement.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Advertises Goods and Services</h2>
                      <p>
                        Registering a trademark creates a face of the company or
                        the goods and services. This helps to distinguish and
                        facilitate brand creation. Most of the companies find
                        their identity via trademark hence it pays a pivotal
                        role in advertising and increases the brand value.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Legal Protection</h2>
                      <p>
                        A registered trademark gives its owner a legal right in
                        case of infringement. Not registering a trademark may
                        leave the owner of the original brand name without any
                        remedy.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={owners} alt="owners-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={management} alt="management-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Creation of an Asset</h2>
                      <p>
                        Trademark registration in India creates an intangible
                        asset. Registered TM can be sold, assigned, franchised
                        or commercially contracted to bring benefits to the
                        company or the individual proprietor.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required to Register a Trademark</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Proof of Applicant</h2>
                    <p>
                      PAN Card and Address Proof of Applicant. Certificate of
                      registration (other than an Individual applicant)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Brand Name & Logo</h2>
                    <p>
                      The logo must contain the Brand Name applied to register a
                      Trademark
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>User Affidavit</h2>
                    <p>
                      If a specific user date is to be claimed, the user
                      affidavit is also required to be submitted
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Proof of TM Use</h2>
                    <p>
                      To claim specific user date, documentary proof such as
                      invoices, registration certificates, etc. with the brand
                      name should also be provided
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>MSME / Start-up Recognition</h2>
                    <p>
                      Partnership firm, body corporates (other than an
                      individual) can provide a certificate of registration
                      under MSME or Start-up India scheme to avail 50% rebate on
                      the Government fee
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Signed Form TM - 48</h2>
                    <p>
                      TM-48 is a legal document that allows the attorney to file
                      the trademark on your behalf with the trademark registry.
                      The document will be prepared by LW professionals for the
                      signature
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Procedure to Register a Trademark</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>DAY 1 - Collection</Tab>
                <Tab>DAY 2 - Execution</Tab>
                <Tab>DAY 3 - Submission</Tab>
                <Tab>DAY 4 - Start using TM</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and collection of basic Information</li>
                  <li>Provide Required Documents</li>
                  <li>Select appropriate Class</li>
                  <li>Trademark Search & Availability Check</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of Documents by Professionals</li>
                  <li>Franking on Power of Attorney</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Filing of online TM application</li>
                  <li>Sharing the filed application & receipt</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>
                    You can now legally start using ™ beside your logo or brand
                  </li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Should the trademark be filed in the individual’s name
                        or companies name?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Many start-ups register a Trademark based on the
                        founder’s name, whereas large businesses prefer to do so
                        in the name of the company. This is because the future
                        of a start-up is always in doubt. If owned by the
                        founder, the trademark would be valid regardless of the
                        state of the entity.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What cannot be registered as trademark?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Any mark that is identical or deceptively similar to an
                        existing registered trademark, or a trademark for which
                        the application for registration is already in process,
                        such trademarks cannot be registered. Also, a mark that
                        would likely cause a deception or confusion, or that
                        appears offensive may not be registered. Geographical
                        names, common names, common trade words and common
                        abbreviation are also not registrable.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Once registered for what period, a trademark is
                        effective? Can a trademark registration be renewed?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The registered trademark is valid for a period of 10
                        years from the date of application registration. It can
                        be renewed every 10 years, perpetually. In India,
                        renewal request is to be filed within Six months before
                        the expiry of the last registration of trademark.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How long does it take for the trademark to get
                        registered?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Usually, it takes at least 15 to 18 months for Trademark
                        registration in India once applied. Post which the owner
                        can use (R) symbol beside the Trademark. However, as
                        soon as the online Trademark Application is filed, the
                        applicant can start using the (TM) symbol. The
                        registration may get delayed due to the verification
                        procedure.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is trademark class? How to find appropriate class?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Controller General of Patents, Designs, and Trademarks
                        has classified goods and services under 45 classes. Your
                        application must mention the class/classes that the
                        goods/services represent. The trademarks are registered
                        only under those classes. Our experts will help you to
                        find applicable and related classes. For each class, a
                        separate application is filed by paying prescribed
                        Government fee.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Will the trademark be valid outside India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The registration application filed under the Trademark
                        Act, 1999 is verified and registered in India and it
                        remains valid only in India.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How does my start-up qualify for a 50% rebate on
                        Government fee?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        If you have a valid certificate issued by Start-up India
                        (an initiative by Government of India), your start-up
                        gets qualified to avail 50% rebate on Government fees
                        for brand-name registration.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the process of getting a Trademark registered by
                        the Ministry?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Trademark registration process in India consists of the
                        following main components before the Trademark is
                        granted:
                      </p>
                      <ul>
                        <li>
                          Trademark application in the right class, with all the
                          correct details.
                        </li>
                        <li>
                          Send it to Vienna Codification – As soon as a new
                          trademark application is filed, a Vienna Code is
                          assigned if the trademark comprises of figurative
                          elements/logo. Vienna codification is done by the
                          Registry so that the trademark searches can be
                          conducted for artworks/logos.
                        </li>
                        <li>
                          Formalities Check – This is the first step of
                          verifying the Trademark Application whereby the
                          Trademark Registry usually checks if the basic
                          requirements are met. This includes scrutinizing
                          whether the POA has been uploaded (when filed through
                          an attorney) and whether the appropriate
                          translation/transliteration has been filed in case of
                          TM is not filed in English/Hindi.
                        </li>
                        <li>
                          An issue of Examination Report from the Ministry – On
                          verification of basic details, the registry examines
                          the application under various sections to see if the
                          Trademark applied for requires any clarification under
                          any of the Legal sections of the Act. When they feel
                          that any information is required, then they would
                          issue an examination report with the relevant section
                          to which a reply MUST be filed.
                        </li>
                        <li>
                          Exam Report Issued / Accepted – After acceptance of
                          the Trademark application or submission Examination
                          Reply if the registry is satisfied then they will
                          proceed for the Trademark name or symbol or both to be
                          published in the Trademark Journal.
                        </li>
                        <li>
                          “Advertised before acc” or “Advertised” or “Accepted
                          &amp; Advertised” – At this stage, the trademarked
                          name or logo or both are advertised in the Trademark
                          Journal. These are kept open for Objection for 4
                          months for any third party to oppose the published
                          Trademark. When any such objections are received, the
                          status would change to Opposed – to which a proper
                          reply should be filed.
                        </li>
                        <li>
                          Registration– If there are no other objections,
                          clarifications, or oppositions, the trademark
                          application would be accepted and marked as
                          ‘Registered’. This is when the Trademark applicant can
                          start using the ® symbol.
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is covered under Trademark Application service by
                        Filing Saga?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        It includes t the drafting of entire trademark
                        application along with the guidance for appropriate
                        classes. Moreover, the final filing using the Ministry
                        Registered Attorney is also covered under the service
                        offering of Trademark Application.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can a multi-class application be filed in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, one can apply for registration under multiple
                        classes in one single application.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default TrademarkRegistration;
