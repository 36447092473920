import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function ClosePrivateLimitedCompany() {
  const pageName = "ClosePrivateLimitedCompany";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Close a Private Limited Company</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Closure of a Private limited company</h2>
                <div className="bar"></div>
                <p>
                  If the company owners or directors decide to discontinue or
                  wind up the business, they may consider for the options of the
                  closure. Most feasible or easiest way to close a company is
                  striking off its name from Register of Companies. This is
                  preferable when a company is inoperative for a certain period.
                  Other options include a winding-up petition, however that
                  involves more time, investment and compliance.
                </p>
                <p>
                  On approval of the strike off, the company’s name is removed
                  from the register and thus, it is not existent in the eyes of
                  laws. The company must fulfill all the compliance before
                  proceeding for the strike-off application. The application is
                  accompanied by various documents and requires assistance from
                  the professional.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Ways of winding up of a company in India</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Winding up</Tab>
                <Tab>Striking off</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Winding up</h2>
                      <p>
                        This option is preferred when the company is in the
                        operative stage and has assets and liabilities. It will
                        include approval from Members, Directors, and creditors.
                        It also requires appointing a liquidator for the said
                        process to manage the company’s affairs during the whole
                        process. It is often time-consuming process.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Striking off</h2>
                      <p>
                        Striking off is preferred by a company which has
                        relatively no or less outside liabilities. When a
                        company is inoperative since its inception or in the
                        past two years, it may apply for strike-off, often
                        referred as fast-track exit. The primary condition is
                        that the company has no assets or liabilities and has
                        complied with relevant applicable provisions.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for strike-off of company</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Incorporation Documents</h2>
                    <p>
                      Company’s MoA – AoA, Certificate of Incorporation, PAN
                      card and other registration certificates
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Accounting Information</h2>
                    <p>
                      The financial statement of the Company for the most recent
                      year, prepared prior to 30 days of filing the application
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Details of Activity</h2>
                    <p>
                      Details whether the company has been operative for any
                      period. If yes, since when the operations are discontinued
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Legal Liabilities</h2>
                    <p>
                      A statement regarding pending litigations, if any
                      involving the company
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from Creditors</h2>
                    <p>
                      The company must provide NOC for closure from creditors,
                      if any (Draft to be provided by LW experts)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col4">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from Regulatory Bodies</h2>
                    <p>
                      NoC for closure to be obtained from Income Tax Department,
                      SEBI, RBI, etc. if relevant
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to dissolve a private limited company</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1-2</Tab>
                <Tab>Day 3-8</Tab>
                <Tab>Day 9-15</Tab>
                <Tab>Day 26 onwards</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and collection of basic Information</li>
                  <li>Provide required documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Review of the details and documents furnished</li>
                  <li>Drafting of necessary resolutions</li>
                  <li>
                    Drafting of the affidavit, indemnity bond, and other
                    documents
                  </li>
                  <li>Provide duly executed affidavit & indemnity bond</li>
                  <li>Provide signed documents after review</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Preparation of applications for online filing</li>
                  <li>Filing of required forms and documents with MCA</li>
                  <li>Application for the striking-off company name</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time to approve strike-off</li>
                  <li>
                    The notice of strike-off to be published by MCA after
                    approval
                  </li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When an application for strike-off can be filed?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        It can be filed only when the company repays or
                        extinguishes all its liabilities and receive a No
                        Objection Certificate (NOC) from the creditors before
                        filing the closure application. And conduct a meeting
                        where all the directors decide upon the closure by
                        signing a special resolution or a consent of
                        seventy-five percent members regarding paid up share
                        capital.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the benefits of closure of private limited
                        company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The closure is the best option in case the company is
                        not running as it: Saves the yearly compliance cost No
                        non-compliance risk. No risk of high penalties and
                        prosecutions No risk of getting into default
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can Registrar of Company also initiate strike-off?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The Registrar of Companies can remove the company name
                        from the list of companies if, he has reasonable cause
                        to believe
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When can a company be said to be dissolved?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        RoC will publish a list of companies struck off in the
                        Official Gazette. The Company under fast-track exit mode
                        will be considered closed from the date of publication
                        of the notice in Official Gazette.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the time limit to file the closure documents
                        with the Registrar?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The closing documents have to be filed within 30 days
                        from the date of signing of the assets and liabilities
                        statement.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Why is it necessary to intimate the Registrar for
                        closing the Private Limited Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        It is necessary to intimate the Registrar for the
                        closure of Private Limited Company to update the MCA
                        data and make company free from all its legal
                        compliances.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ClosePrivateLimitedCompany;
