import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Auth from "./Auth";

const Layout = ({ children }) => {
  return (
    <div>
      <Auth>
        <Header />
        {children}
        <Footer />
      </Auth>
    </div>
  );
};

export default Layout;
