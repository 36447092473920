import * as React from "react";
import llpimg from "../assets/LLP-Registration.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import management from "../assets/management.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function LimitedLiability() {
  const pageName = "LimitedLiability";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>LLP Registration Online – Anywhere in India</h2>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>Overview</h2>
                <div className="bar"></div>
                <p>
                  LLP Registration in India has become an alternative form of
                  business that provides the advantages of a Company and the
                  flexibility of a Partnership firm into a single organization.
                  The Concept of LLP in India was introduced back in 2008 by the
                  Limited Liability Partnership Act of 2008. This unique hybrid
                  is suitable for setting small, medium-sized businesses.
                </p>
                <p>
                  It is very easy to manage and incorporate a Limited Liability
                  Partnership in India. To register an LLP minimum of two
                  partners are required, there is no upper limit as such. The
                  LLP agreement states the rights and the duties of the
                  Partners. In an LLP one partner is not responsible for the
                  misconduct and negligence of the other partner. The partners
                  are responsible for the compliances and all the provisions
                  that are specified in the LLP agreement.
                </p>
              </div>
              <div className="col col6">
                <img src={llpimg} alt="LLP-Registration" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of Limited Liability Company</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>EASE OF FORMATION</Tab>
                <Tab>CHEAPER TO INCORPORATE</Tab>
                <Tab>SEPARATE LEGAL ENTITY</Tab>
                <Tab>LIMITED LIABILITY</Tab>
                <Tab>TRANSPARENCY & CREDIBILITY</Tab>
                <Tab>EXIT PLAN</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Ease Of Formation</h2>
                      <p>
                        A Limited Liability Partnership can be easily registered
                        and is easy to manage and run with less legal
                        compliances.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={fundraising}
                      alt="fund-raising-image"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={legal}
                      alt="legal-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Cheaper To Incorporate</h2>
                      <p>
                        A Limited Liability Partnership Cheaper to incorporate
                        than a “Private limited company”.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Separate Legal Entity</h2>
                      <p>
                        The biggest advantage of a Limited Liability Partnership
                        is that its identity is distinct from that of its
                        members which ultimately limits the liability of
                        partners.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={owners}
                      alt="owners-image"
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={management}
                      alt="management-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Limited Liability</h2>
                      <p>
                        If any liability arises then its partner’s assets remain
                        unaffected; Partners are not liable for corporate debts
                        and liabilities.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={management}
                      alt="management-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Transparency And Credibility</h2>
                      <p>
                        Limited Liability Partnership enjoys enhanced
                        transparency as the information relating to an LLP is
                        available in a publicly searchable database. Thus, able
                        to win the trust of the general public & improve
                        business credibility.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img
                      style={{ height: "250px" }}
                      src={management}
                      alt="management-image"
                    />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Exit Plan</h2>
                      <p>
                        It offers the best type of exit plan for all partners.
                        Only the shares of a LLP can be sold or transferred to
                        another partner or entity without any hassles, while the
                        business remains a going concern.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2 style={{ maxWidth: "600px" }}>
                Documents required for registration of an LLP
              </h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      PAN Card of shareholders and Directors. Foreign nationals
                      must provide a valid passport.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-id-card-clip"></i>
                  </div>
                  <div className="textbox">
                    <h2>Identity Proof</h2>
                    <p>
                      Aadhar card and Voter ID/ Passport/ Driving License of
                      Shareholders and Directors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Director’s Address Proof</h2>
                    <p>
                      Latest Telephone Bill /Electricity Bill/ Bank Account
                      Statement of Shareholders and Directors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-image-portrait"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Latest Passport size photograph of Shareholders and
                      Directors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-address-card"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Latest Electricity Bill/ Telephone Bill of the registered
                      office address
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from owner</h2>
                    <p>
                      No Objection Certificate to be obtained from the owner(s)
                      of registered office
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-contract"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      Rent Agreement of the registered office should be provided
                      if any
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-info"></i>
                  </div>
                  <div className="textbox">
                    <h2>Note</h2>
                    <p>
                      In case of NRI or Foreign National, documents of director
                      (s) must be notarized or apostilled
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process to Register a Company</h2>
              <div className="bar"></div>
            </div>
            <Tabs className="tabsSteps">
              <TabList>
                <Tab>Step 1</Tab>
                <Tab>Step 2</Tab>
                <Tab>Step 3</Tab>
                <Tab>Step 4</Tab>
                <Tab>Step 5</Tab>
                <Tab>Step 6</Tab>
                <Tab>Step 7</Tab>
              </TabList>

              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  <b>Obtain the Digital Signature Certificate (DSC) </b>
                  from the Certifying Authority.
                </p>
              </TabPanel>
              <TabPanel style={{ height: "60px" }}>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  <b>
                    Obtain the Designated Partner Identification Number (DPIN){" "}
                  </b>
                  with the Ministry of Corporate Affairs.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  <b>Apply for Name Approval of the LLP. </b>
                  The LLP can have a maximum of three proposed names. The
                  Ministry of Corporate Affairs will approve the name that is
                  not similar to any other existing business name.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  <b>
                    File the Incorporation Form with the Ministry of Corporate
                    Affairs{" "}
                  </b>
                  along with the required documents and the prescribed fees.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  <b>Obtain the LLP Agreement </b>
                  that must be signed by all the designated partners.
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  <b>
                    File the LLP Agreement with the Ministry of Corporate
                    Affairs.
                  </b>
                </p>
              </TabPanel>
              <TabPanel>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  The Ministry of Corporate Affairs will then issue the{" "}
                  <b>Certificate of Incorporation</b> and the LLP will be
                  registered with effect from the date of incorporation
                  mentioned in the Certificate.
                </p>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>What is LLP?</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Limited Liability Partnership is a corporate entity
                        registered under Limited Liability Partnership Act,
                        2008. It is a form of partnership firm that enjoys
                        limited liability. It is a hybrid form of a partnership
                        that includes the features of a company. Compliances for
                        a company are applicable to LLP.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are those business activities for which an LLP
                        needs a prior authority from regulatory authorities?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Activities including banking, venture capital, stock
                        exchange, asset management, mutual fund, architecture,
                        merchant banking, securitisation and reconstruction,
                        chit fund and non-banking financial activities require
                        the prior permission from the regulatory body.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the ineligibilities of a designated partner?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        <ol>
                          <li>
                            If he is declared unsound mind by the prescribed
                            court.
                          </li>
                          <li>Is undischarged insolvent</li>
                          <li>
                            Has applied for insolvency and application is
                            pending.
                          </li>
                        </ol>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can a Foreigner become a partner in LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes. Provided minimum one partner is required to be an
                        Indian citizen and resident in a previous calendar year.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is Foreign Direct Investment is allowed in LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Foreign Direct Investment is approved under the
                        automatic route where 100% FDI is permitted. In
                        Electronic System Design & Manufacturing (ESDM) Sector,
                        100% FDI is allowed in LLP under the government route is
                        approved, however, an investment in manufacturing of
                        defence electronics and Brownfield investments in
                        medical devices making are not allowed. In a Government
                        Route, the applications are considered by the Foreign
                        Investment Promotion Board (FIPB).
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Who are required to file their DSC as per the LLP Act?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Only one designated partner is required to file DSC for
                        e-filing purposes.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can an LLP register on the residential address?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        You don't need a proper office to incorporate an LLP.
                        You can register your residential address as a
                        registered place of your business with MCA for which
                        some address proof along with the NOC (No Objection
                        Certificate) has to be filed with the prescribed form.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can LLP be formed without entering into LLP Agreement?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In the absence of LLP Agreement provisions of Schedule I
                        to the LLP Act, 2008 are applicable. Provided the
                        agreement is mandatory if you want to exclude few
                        provisions of Schedule I or wish to exclude it
                        completely.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the advantages of forming LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ol>
                        <li>Incorporation of LLP involves low cost.</li>
                        <li>
                          It inhibits the features of both a partnership firm
                          and a company.
                        </li>
                        <li>
                          Unlike a company, LLP can be formed with minimum two
                          designated partners without any maximum limit.
                        </li>
                        <li>
                          Audit is not mandatory unless an LLP has a turnover
                          exceeds Rs.40 lakhs and capital contribution exceeds
                          Rs. 25 lakhs
                        </li>
                        <li>
                          Personal assets of the partners are secured, as LLP
                          have the feature of limited liability. As compared to
                          the traditional partnership, liability of each partner
                          is limited to his share as mentioned in the agreement.
                        </li>
                        <li>
                          Fewer compliances as there are no requirement to
                          maintain any statutory records except books of
                          accounts.
                        </li>
                      </ol>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>

                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I become a partner in a Limited Liability
                        Partnership if I am already in employment?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, there is no such legal constraint in the LLP Act if
                        not restricted by the employment agreement. All you need
                        to do is check your employment agreement because it may
                        limit you from becoming a partner in an LLP during the
                        employment.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can a partnership firm convert itself into LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        An existing partnership firm can be converted into LLP
                        by complying with the Provisions of clause 58 and
                        Schedule II of the LLP Act. Form 17 needs to be filed
                        along with Form 2 for such conversion and incorporation
                        of LLP.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is the conversion of LLP into a Private Limited Company
                        possible?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        LLP Act, 2008 and Companies Act, 2013, both do not have
                        any provision regarding the conversion of an LLP into a
                        Private Limited Company. You can only incorporate a new
                        private limited company with the same name for which a
                        no objection certificate is required by the LLP.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Does one have to present physically to incorporate an
                        LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, the whole incorporation process is online. You can
                        send the scanned copy of all the required incorporation
                        documents via e-mail. All the forms and documents are
                        filed electronically and even signed digitally.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How many partners can form an LLP? Is there any maximum
                        limit?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        As per LLP Act, 2008 a minimum of two partners can
                        incorporate an LLP. There is no maximum limit for the
                        partners.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the rights and duties of a designated partner?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The rights and duties of a designated partner are
                        governed by LLP Agreement executed between them as per
                        the Act.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can a Foreign LLP establish a business in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, by filing Form 27 with the ROC a foreign LLP can
                        establish a business in India. The form shall include
                        details of Foreign LLP incorporation, designated
                        partners and minimum two authorized representatives for
                        compliances under Act.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the provision for stamp duty payment on LLP
                        Agreement under LLP Act, 2008?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Stamp duty is payable under State Stamp Act of the state
                        in which the LLP is registered. Stamp duty on LLP
                        Agreement is not to be paid on MCA portal.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is it mandatory that all the designated partners have to
                        be the partners of the LLP?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        As per the general rule, every designated partner of an
                        LLP must also be the partner of an LLP. However, there
                        are some exceptions to the general rule:
                        <ol>
                          <li>
                            If the partners of the LLP are a body corporate,
                            then in such case the nominees of the body corporate
                            can act as a designated partner.
                          </li>
                          <li>
                            If the LLP agreement specifies certain persons to be
                            a designated partners in an LLP without being a
                            partner in the same LLP than such people can act as
                            a designated partner.
                          </li>
                        </ol>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is it mandatory to register the LLP Agreement with the
                        ROC?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes. The execution and filing of the LLP Agreement are
                        mandatory under the Act.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default LimitedLiability;
