import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import "./Services.css";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function CopyrightRegistration() {
  const pageName = "CopyrightRegistration";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Copyright Registration</h2>
                  <p>
                    Give a legal backing to your creative work with Copyright
                    Registration
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>What is Copyright Registration?</h2>
                <div className="bar"></div>
                <p>
                  Copyright registration in India gives monetary rights and
                  benefits to the original creators of literature, art, music,
                  sound recordings, cinematographed films and broadcasts. This
                  enables the creators to make money from their creation by
                  making copies, performing in public, broadcasting, and issuing
                  its copies to the public for various other online uses.
                  Copyright is automatically created at the moment when creator
                  creates something. But, the act of copyright registration in
                  India is important as it gives the creator a legal right in
                  public so that others cannot exploit the creator’s work.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>Benefits of copyright registration</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Prima Facie Evidence</Tab>
                <Tab>Protection Extended Even After The Creators Death</Tab>
                <Tab>Public Record</Tab>
                <Tab>For Filing a Case For Infringement</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Prima Facie Evidence</h2>
                      <p>
                        Copyright protection provides a prima facie evidence in
                        case if the litigation arises. The proof is essential to
                        attest to anything in the court of law; hence copyright
                        registration is very beneficial for creators.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Protection Extended Even After The Creators Death</h2>
                      <p>
                        Copyright’s protection is not restricted up to the
                        original creators’ life span; its validity is longer
                        than any other intellectual property. It is secured for
                        the creators’ lifespan and 60 years after his/her death.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Public Record</h2>
                      <p>
                        When the registration of copyright is done, a definite
                        public record is created that helps the original creator
                        to establish his ownership over the said copyright.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>For Filing a Case For Infringement</h2>
                      <p>
                        An individual cannot sue for copyright infringement
                        unless he/she has a registered copyright. Registration
                        gives the copyright holder the official right to file
                        for infringement and claim for damages.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section patent_search">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for Copyright registration in India</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file-lines"></i>
                  </div>
                  <div className="textbox">
                    <h2>Details of the applicant</h2>
                    <p>
                      Name, Address & Nationality of the Applicant (Certificate
                      of Registration must be provided for a body corporate)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Nature of interest</h2>
                    <p>
                      Nature of the Applicant’s Interest in the Copyright i.e.
                      if the applicant is the original creator or a
                      representative of the creator
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>Details about the work</h2>
                    <p>
                      i.e. Type & Description of the Work, Title of the Work or
                      URL Language of the Work
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-clapperboard"></i>
                  </div>
                  <div className="textbox">
                    <h2>The Date of Publication</h2>
                    <p>The date when your creative work was first published</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Copyright registration Process</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1 - 2 Collection</Tab>
                <Tab>Day 3 - 5 Execution</Tab>
                <Tab>Day 6 - 12 Submission</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Discussion and collection of basic Information</li>
                  <li>Provide required documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of Documents by Professionals</li>
                  <li>Verification of documents</li>
                  <li>Preparation of the application and Vakalatnama</li>
                  <li>Form filing of for Copyright Application online</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>
                    Physical submission of payment acknowledgment receipt,
                  </li>
                  <li>
                    prescribed forms in triplicate copies to the Copyright
                    Registry.
                  </li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What cannot be protected through copyright registration
                        in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Works that are not fixed in a tangible form such as
                        ideas, procedures, methods, systems, processes,
                        concepts, principles, or discoveries, unwritten speeches
                        etc. cannot be copyrighted.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the rights of a Copyright owner?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>The copyright owner has the exclusive rights to:</p>
                      <ul>
                        <li>
                          Reproduce and distribute the work in copies or
                          phonorecord to the public by sale or transfer of
                          ownership or license.
                        </li>
                        <li>
                          Make additions to the original work or modify it,
                          display, present or perform them among the public; and
                        </li>
                        <li>
                          Exclude others to claim ownership or use without the
                          original creators’ permission.
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I sue someone legally if I have my copyright
                        registered ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, you can send a legal notice to that person. And,
                        even after that the infringement continues, you can
                        claim your exclusive right in the court of law to claim
                        damages.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can we search for any identical artistic work registered
                        in the copyright registry ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        No, there is no such mechanism to search for already
                        existing works on the copyright registry website
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        If I am a jewellery designer what would be the
                        preferable route to protect my work ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        In the case of jewelry, both copyright and design laws
                        apply. It is preferable to take copyright protection on
                        the sketches of the design as artwork due to the
                        extended protection provided under copyright law than
                        that provided under the design laws.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the fee distribution structure for various works
                        under copyright protection ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        (a)Literary, Dramatic, Musical or Artistic work: Rs.
                        500/- per work
                      </p>
                      <p>
                        (b)Provided that in respect of a Sound Recording,
                        Cinematograph Film, Literary or Artistic work which is
                        used or is capable of being used in relation to any
                        goods (under section 45): Rs. 2,000/- per work
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default CopyrightRegistration;
