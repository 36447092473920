import * as React from "react";
import aboutimg from "../assets/digital.png";
import fundraising from "../assets/fundraising.png";
import legal from "../assets/legal-doc.png";
import owners from "../assets/owners.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import rectimg from "../assets/dot-rect.png";
import triimg from "../assets/tri-circle1.png";
import LeadInputs from "../common/LeadInputs";
import Layout from "../common/Layout";

function ProprietorshiptoPrivateLimitedCompany() {
  const pageName = "ProprietorshiptoPrivateLimitedCompany";
  return (
    <Layout>
      <div className="subcatpage-data">
        <section className="sbanner_section">
          <div className="container">
            <div className="row">
              <div className="col col12">
                <div className="sbanner_content">
                  <h2>Proprietorship to Private Limited Company</h2>
                  <p>
                    Scale your business from Proprietorship to Pvt. Ltd Company
                    to multiply business growth
                  </p>
                  <LeadInputs pageName={pageName} />
                </div>
              </div>
            </div>
          </div>
          <div className="default-shape">
            <div className="rect-shape">
              <img src={rectimg} alt="error" />
            </div>
            <div className="tri-shape">
              <img src={triimg} alt="error" />
            </div>
          </div>
        </section>
        <section className="intro_section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <h2>
                  Conversion from Proprietorship to Private Limited Company
                </h2>
                <div className="bar"></div>
                <p>
                  Initially, when a business is established, the most preferred
                  choice is a sole proprietorship structure because of its low
                  compliance requirements. Once the business grows, it is
                  essential to take steps to limit the liabilities and reduce
                  the burden of compliance on a single person. The best way out
                  over here is to convert proprietorship to private limited
                  company. For the purpose of conversion of sole proprietor to a
                  private limited company, it is necessary by the promoters of
                  the company to indulge into an agreement which is to be made
                  for selling the business. Further, such conversion from sole
                  proprietorship to Private Limited Company must have clearly
                  mentioned about “the takeover of a Sole Proprietorship
                  Concern” as one of the objectives in its Memorandum of
                  Association.
                </p>
              </div>
              <div className="col col6">
                <img src={aboutimg} alt="about" />
              </div>
            </div>
          </div>
        </section>
        <section className="benifits_section">
          <div className="container">
            <div className="section-title">
              <h2>
                Benefits of conversion from Sole Proprietorship to Private
                Limited Company
              </h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Separate Legal Existence</Tab>
                <Tab>Limited Liability of Directors</Tab>
                <Tab>Easy Transferability</Tab>
                <Tab>Uninterrupted existence</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Separate Legal Existence</h2>
                      <p>
                        The private limited company is a separate legal entity,
                        and its existence is separate from its members This
                        makes the business possible to own assets and enter into
                        contracts in the name of the private limited company or
                        sue a third party in case of a dispute. The members
                        (Shareholders/Directors) of a company have no personal
                        liability to the creditors of a company for the
                        company’s debts beyond their holding in the company.
                      </p>
                    </div>
                  </div>
                  <div className="col col6">
                    <img src={fundraising} alt="fund-raising-image" />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={legal} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Limited Liability of Directors</h2>
                      <p>
                        In a private limited company, the personal assets of the
                        director’s remain untouched if there are any outstanding
                        debts on the company. Only the money invested for
                        incorporation or the assets of the company are to be
                        sold off for paying off the dues.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Easy Transferability</h2>
                      <p>
                        The ownership of the business can be transferred to an
                        individual or to a company by transferring the shares
                        with the consent of shareholders. This transfer is much
                        easier whereas in proprietorship such transfer is not
                        allowed.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col col6">
                    <img src={owners} alt="legal-image" />
                  </div>
                  <div className="col col6">
                    <div className="textbox">
                      <h2>Uninterrupted existence</h2>
                      <p>
                        A private limited company is a separate legal entity and
                        hence it has a perpetual succession. Unlike a sole
                        proprietorship, it is unaffected by the death or other
                        departure of any member. It continues to be in existence
                        irrespective of the changes in membership.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="registration_section">
          <div className="container">
            <div className="section-title">
              <h2>Documents required for conversion to a Private Company</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>PAN Card</h2>
                    <p>
                      PAN Card of directors and shareholders Foreign nationals
                      may provide passport
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Address Proof</h2>
                    <p>
                      Aadhar Card and Voter ID/ Passport/ Driving License of all
                      directors and share holders
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Photograph</h2>
                    <p>
                      Latest Passport size photograph of all directors and
                      shareholders
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Business Address Proof</h2>
                    <p>
                      Latest Electricity Bill/ Telephone Bill of the registered
                      office address
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>NOC from owner</h2>
                    <p>
                      No Objection Certificate to be obtained from the owner of
                      registered office
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Rent Agreement</h2>
                    <p>
                      Rent Agreement of the registered office should be
                      provided, if any
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col3">
                <div className="content">
                  <div className="iconbox">
                    <i className="fa fa-file"></i>
                  </div>
                  <div className="textbox">
                    <h2>Income Tax Returns Acknowledgement</h2>
                    <p>
                      the sole proprietor needs to submit an income tax return
                      acknowledgment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="the_process">
          <div className="container">
            <div className="section-title">
              <h2>Process of conversion of proprietorship to Pvt. Ltd.</h2>
              <div className="bar"></div>
            </div>
            <Tabs>
              <TabList>
                <Tab>Day 1</Tab>
                <Tab>Day 2-4</Tab>
                <Tab>Day 5-8</Tab>
                <Tab>Day 9-10</Tab>
                <Tab>Day 11-15</Tab>
              </TabList>

              <TabPanel>
                <ul>
                  <li>Application for Digital Signature Certificate</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Checking Name availability</li>
                  <li>Application for Name Reservation under “RUN”</li>
                  <li>Reservation of Name</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Drafting of MoA, AoA & other documents</li>
                  <li>Payment of Stamp Duty</li>
                  <li>Notarisation of required documents</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Filing application for company registration</li>
                  <li>Application for DIN Allotment</li>
                  <li>Application for PAN and TAN of company</li>
                </ul>
              </TabPanel>
              <TabPanel>
                <ul>
                  <li>Government processing time</li>
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section className="sub-apge faq_section">
          <div className="container">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <div className="bar"></div>
            </div>
            <Accordion>
              <div className="row">
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What are the key requirements/factors for registering a
                        private limited company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A Pvt. Ltd. company would need two or more members who
                        shall act for as directors of the said company. It is a
                        general practice that the shareholders of the company
                        play the role of directors. It does not require any
                        minimum amount to be infused as capital. However, a
                        certain fee must be paid to the Government for issuing a
                        minimum of shares worth ₹ 1 lakh [Authorized Share
                        Capital] during company registration. Also, there is no
                        requirement to show proof of capital invested during the
                        registration process.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Is a Private Limited Company structure suitable for my
                        business?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Starting a business under the Pvt. Ltd structure is
                        advantageous as it creates trust and credibility. Its
                        easier to get loans, and it helps in attracting more
                        financial institutions, suppliers and potential clients.
                        financial institutions and individuals prefer investing
                        in companies that are reliable and private limited
                        companies offer such a reliability factor, as compared
                        to a structure like a sole proprietorship or general
                        partnership. Therefore if you are looking for expanding
                        or trustworthiness is an important part of business its
                        a very good option
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        For how long is the Company registration valid?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Once a Company is incorporated, it will be active and in
                        existence as long as the annual compliances are met
                        regularly. In case, annual compliances are not complied
                        with, the Company will become a Dormant Company and
                        maybe struck off from the register after a period of
                        time. A struck-off Company can be revived for a period
                        of up to 20 years.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
                <div className="col col6">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is the statutory compliance requirement for
                        registering a Private Limited company in India?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The company must hold a Board Meeting at least once in
                        every 3 months. In addition to the Board Meetings, an
                        Annual General Meeting (AGM) must be conducted at least
                        once every year. Fulfillment of Annual Compliance
                        Requirements is a must to maintain the active status of
                        the company.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How we can reserve the name for a Company?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Ministry has introduced a new form “RUN” (Reserve Unique
                        Name) for company name registration on its portal. Under
                        “RUN”, the applicant can make application by providing 2
                        different names with its significance. The names should
                        be unique and in accordance with the provisions.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        what happens to the assets and liabilities of the sole
                        proprietorship?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        All the assets and liabilities of the sole proprietary
                        concern relating to the business are considered to be
                        purchased by the newly formed company. This makes the
                        sole proprietor liable to pay taxes for any capital
                        gains calculated on such transfer. However, there is a
                        provision under section 47(xiv) of the Income Tax Act,
                        which lays down certain conditions for exemption from
                        any capital gains i.e.; if they are transferred
                        immediately before the succession, it becomes the assets
                        and liabilities of the company.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ProprietorshiptoPrivateLimitedCompany;
