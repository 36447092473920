import React from "react";
import Layout from "../common/Layout";
import "./Contact.css";

function Contact() {
  const pageName = "Contact";
  return (
    <Layout>
      <div className="Contact">
        <section className="sub-banner-section">
          <div className="container">
            <div className="banner-content">
              <h2>Contact Us</h2>
            </div>
          </div>
        </section>
        <section className="contact-section">
          <div className="container">
            <div className="row">
              <div className="col col6">
                <div className="contact-text">
                  <h3>Have Any Questions About Us?</h3>
                </div>
                <div className="contact-form">
                  <form>
                    <div className="row">
                      <div className="col col6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="form-control"
                            required=""
                            value=""
                          />
                        </div>
                      </div>
                      <div className="col col6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="email"
                            placeholder="Email"
                            className="form-control"
                            required=""
                            value=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="number"
                        placeholder="Phone number"
                        className="form-control"
                        required=""
                        value=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        className="form-control"
                        required=""
                        value=""
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        name="text"
                        cols="30"
                        rows="6"
                        placeholder="Write your message..."
                        className="form-control"
                        required=""
                      ></textarea>
                    </div>
                    <div className="send-btn">
                      <button type="submit" className="default-btn">
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col col6">
                {/* <div className="single-contact-box">
                  <i className="fa fa-map-marker"></i>
                  <div className="content-title">
                    <h3>Address</h3>
                    <p>Filing Saga Address Here</p>
                  </div>
                </div> */}
                <div className="single-contact-box">
                  <i className="fa fa-envelope"></i>
                  <div className="content-title">
                    <h3>Email</h3>
                    <p>
                    <a href="mailto:filingsaga@gmail.com">filingsaga@gmail.com</a>
                    </p>
                  </div>
                </div>
                <div className="single-contact-box">
                  <i className="fa fa-phone"></i>
                  <div className="content-title">
                    <h3>Phone</h3>
                    <div>
                     <p>+91 7003970148</p>
                     <p>+91 9749657483</p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Contact;
